import * as React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import Segments from '../Segments';
import Sources from '../Sources';

const AFResults: React.FC = () => {
  const [tabIndex, setTabIndex] = React.useState(0);

  return (
    <Tabs
      className="results__tabs"
      selectedIndex={tabIndex}
      onSelect={index => setTabIndex(index)}
    >
      <TabList className="results__tab-list underline">
        <Tab className="results__tab">Скважины</Tab>
        <Tab className="results__tab">Трубы</Tab>
      </TabList>
      <div className="results-tables__container">
        <TabPanel>
          <Sources />
        </TabPanel>
        <TabPanel>
          <Segments />
        </TabPanel>
      </div>
    </Tabs>
  );
};

export default AFResults;
