import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectEquipmentById } from 'store/projectSlice';

import { ReactComponent as SeparatorIcon } from '../../../images/Project/icn-separator.svg';
import { IObject } from '../types';
import ObjectInput from './components/ObjectInput';

const Separator: React.FC<IObject> = ({ objectUid }) => {
  const equipmentById = useSelector(selectEquipmentById);

  const saveValue = () => {};

  return (
    <div className="active-object__content">
      <div className="active-object__top-row">
        <p className="active-object__title">
          <SeparatorIcon />
          {equipmentById[objectUid as string]?.name}
        </p>
      </div>
      <div className="active-object__row">
        <div className="d-flex">
          <div className="active-object__input-with-unit-wrapper left">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Коэф. сепарации газа "
              name="removed_gas__percent"
              initValue={
                equipmentById[objectUid as string]?.removed_gas__percent
              }
              saveNewValue={saveValue}
              disabled
            />
            <p className="active-object__row-input-unit">%</p>
          </div>
          <div className="active-object__input-with-unit-wrapper">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Коэф. сепарации нефти "
              name="removed_gas__percent"
              initValue={
                equipmentById[objectUid as string]?.removed_oil__percent
              }
              saveNewValue={saveValue}
              disabled
            />
            <p className="active-object__row-input-unit">%</p>
          </div>
        </div>
      </div>
      <div className="active-object__row">
        <div className="d-flex">
          <div className="active-object__input-with-unit-wrapper left">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Коэф. сброса воды"
              name="removed_water__percent"
              initValue={
                equipmentById[objectUid as string]?.removed_water__percent
              }
              saveNewValue={saveValue}
              disabled
            />
            <p className="active-object__row-input-unit">%</p>
          </div>
          <div className="active-object__input-with-unit-wrapper">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Перепад давления"
              name="pressure_delta__atm"
              unit="atm"
              initValue={
                equipmentById[objectUid as string]?.pressure_delta__atm
              }
              saveNewValue={saveValue}
              disabled
            />
            <p className="active-object__row-input-unit">атм</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Separator;
