import * as React from 'react';
import { useSelector } from 'react-redux';

import AgGridTable from 'Components/AgGridTable';
import { components } from 'generated/apiTypes';
import { selectAvgWellsTableUA } from 'store/taskSlice';

import { formatTwoDigits } from 'utils';
import { useGetColumnDefs } from '../../../../../hooks/useGetColumnDefs';
import { TABLE_NAME } from './Table';
import { wellfieldsAvgColumnDefs } from './utils';

export interface ISourcesTableProps {
  step?: number;
}

const SourcesAvgTable: React.FC<ISourcesTableProps> = () => {
  const avgWellsTable = useSelector(selectAvgWellsTableUA);
  const [data, setData] = React.useState<any[]>([]);

  const columnDef = useGetColumnDefs(TABLE_NAME, wellfieldsAvgColumnDefs);
  const getData = () => {
    const newData: any = [];
    avgWellsTable?.states.forEach(state => {
      newData.push({
        current_state: state.current_state,
        well_name: state.well_name,
        operation_type: state.operation_type,
        control_type: state.control_type,
        offset_time: formatTwoDigits(state.offset_time),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fluid_flow_rate: formatTwoDigits(state.fluid_flow_rate?.m3_per_day),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        oil_flow_rate: formatTwoDigits(state.oil_flow_rate?.t_per_day),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        gas_flow_rate: formatTwoDigits(
          state.gas_flow_rate?.thousand_m3_per_day,
        ),
        power: state.power?.kW ? formatTwoDigits(state.power?.kW) : null,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pressure: formatTwoDigits(state.pressure?.atm),
        control_flow:
          state.operation_type === 'Газлифт'
            ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              formatTwoDigits(state.control?.thousand_m3_per_day)
            : null,
        control_freq:
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          (state.control as components['schemas']['PNOFrequency'])?.hertz
            ? formatTwoDigits(
                (state.control as components['schemas']['PNOFrequency'])?.hertz,
              )
            : null,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        accumulation_freq: formatTwoDigits(state.accumulation_control?.hertz),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        interval:
          state.operation_type === 'Время'
            ? formatTwoDigits(
                (state.control as components['schemas']['PNOTime'])?.value,
              )
            : null,
        work_time: formatTwoDigits(state.work_time__min as number),
        accumulation_time: formatTwoDigits(
          state.accumulation_time__min as number,
        ),
        annular_pressure_drop:
          state.annular_pressure_drop &&
          formatTwoDigits(state.annular_pressure_drop.atm),
        well_head_pressure_drop:
          state.well_head_pressure_drop &&
          formatTwoDigits(state.well_head_pressure_drop.atm),
      });
    });
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [avgWellsTable]);

  return (
    <AgGridTable tableName={TABLE_NAME} columnDefs={columnDef} rowData={data} />
  );
};

export default SourcesAvgTable;
