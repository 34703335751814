import React from 'react';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { components } from '../../../../generated/apiTypes';
import { ReactComponent as ChartIcon } from '../../../../images/Project/icn-line-chart.svg';
import { ReactComponent as TableIcon } from '../../../../images/Project/icn-table.svg';
import { selectActiveObject } from '../../../../store/mapSlice';
import {
  selectInjectionWellModelById,
  selectProductionWellModelById,
} from '../../../../store/projectSlice';
import ObjectSelect, {
  OptionType,
} from '../../ActiveObject/components/ObjectSelect';
import { getOptions } from '../WellModel/Chart/utils';
import useChartData from './hooks/use-chart-data';

const wellModelPPD: React.FC = () => {
  const wellInjModelById = useSelector(selectInjectionWellModelById);
  const wellProdModelById = useSelector(selectProductionWellModelById);
  const activeObject = useSelector(selectActiveObject);
  const [tabIndex, setTabIndex] = React.useState<number>(0);
  const [targetParametersOptions, setTargetParametersOptions] = React.useState<
    OptionType<string>[]
  >([]);
  const [targetParametersOption, setTargetParametersOption] =
    React.useState<OptionType<string>>();
  const [targetParametersValuesOptions, setTargetParametersValuesOptions] =
    React.useState<OptionType<number>[]>([]);
  const [targetParametersValuesOption, setTargetParametersValuesOption] =
    React.useState<OptionType<number>>();

  const wellModel:
    | components['schemas']['PNOInjectionWellModel']
    | components['schemas']['PNOProductionWellModel']
    | undefined = React.useMemo(() => {
    if (activeObject?.type === 'PROD_WELL_PPD' && activeObject?.object_uid) {
      return wellProdModelById[activeObject?.object_uid];
    }
    if (activeObject?.type === 'INJ_WELL_PPD' && activeObject?.object_uid) {
      return wellInjModelById[activeObject?.object_uid];
    }
    return undefined;
  }, [activeObject]);

  const chartData = useChartData(
    wellModel,
    targetParametersOption?.value as any,
    targetParametersValuesOption?.value || 0,
  );

  React.useEffect(() => {
    /* if (
      (wellModel as components['schemas']['PNOInjectionWellModel'])
        ?.choke_diameters__m
    ) {
      setTargetParametersOptions([
        { value: 'PRESSURE', label: 'Линейное давление' },
        { value: 'FLOW', label: 'Приемистость' },
        {
          value: 'FREQ/DIAMETER',
          label: 'Диаметр штуцера',
        },
      ]);
      if (!targetParametersOption)
        setTargetParametersOption({
          value: 'PRESSURE',
          label: 'Линейное давление',
        });
      else if (targetParametersOption.value === 'FREQ/DIAMETER') {
        setTargetParametersOption({
          value: 'FREQ/DIAMETER',
          label: 'Диаметр штуцера',
        });
      }
    } else */ if (
      (wellModel as components['schemas']['PNOProductionWellModel'])
        ?.frequency__Hz
    ) {
      setTargetParametersOptions([
        { value: 'PRESSURE', label: 'Линейное давление' },
        { value: 'FLOW', label: 'Дебит жидкости' },
        { value: 'FREQ/DIAMETER', label: 'Частота' },
      ]);
      if (!targetParametersOption)
        setTargetParametersOption({
          value: 'PRESSURE',
          label: 'Линейное давление',
        });
      else if (targetParametersOption.value === 'FREQ/DIAMETER') {
        setTargetParametersOption({
          value: 'FREQ/DIAMETER',
          label: 'Частота',
        });
      }
    }
  }, [
    /* (wellModel as components['schemas']['PNOInjectionWellModel'])
      ?.choke_diameters__m, */
    (wellModel as components['schemas']['PNOProductionWellModel'])
      ?.frequency__Hz,
    activeObject?.object_uid,
  ]);

  React.useEffect(() => {
    const options: OptionType<number>[] = [];
    switch (targetParametersOption?.value) {
      case 'PRESSURE': {
        wellModel?.linear_pressures__atm?.map((item, ix) =>
          options.push({ value: ix, label: `${item.toString()} атм` }),
        );
        break;
      }
      case 'FLOW': {
        wellModel?.liquid_flows__m3_per_day?.map((item, ix) =>
          options.push({ value: ix, label: `${item.toString()} м3/сут` }),
        );
        break;
      }
      case 'FREQ/DIAMETER': {
        /* if (
          (wellModel as components['schemas']['PNOInjectionWellModel'])
            ?.choke_diameters__m
        ) {
          (
            wellModel as components['schemas']['PNOInjectionWellModel']
          )?.choke_diameters__m?.map((item, ix) =>
            options.push({
              value: ix,
              label: `${(item * 1000).toString()} мм`,
            }),
          );
        } else */ if (
          (wellModel as components['schemas']['PNOProductionWellModel'])
            ?.frequency__Hz
        ) {
          (
            wellModel as components['schemas']['PNOProductionWellModel']
          )?.frequency__Hz?.map((item: any, ix: number) =>
            options.push({ value: ix, label: `${item.toString()} Гц` }),
          );
        }
        break;
      }
      default:
        break;
    }
    setTargetParametersValuesOptions(options);
    if (options?.length > 0) setTargetParametersValuesOption(options[0]);
  }, [
    /* (wellModel as components['schemas']['PNOInjectionWellModel'])
      ?.choke_diameters__m, */
    (wellModel as components['schemas']['PNOProductionWellModel'])
      ?.frequency__Hz,
    activeObject?.object_uid,
    targetParametersOption,
  ]);

  return (
    <>
      <div className="well-model-ppd-panel__select-wrapper">
        <ObjectSelect
          label="Параметр"
          classNames="active-object__row-input"
          classNamesWrapper="active-object__row-input-wrapper full-size"
          name=""
          options={targetParametersOptions}
          value={targetParametersOption}
          saveNewValue={value =>
            setTargetParametersOption(
              targetParametersOptions?.find(item => item.value === value),
            )
          }
        />
        <ObjectSelect
          label="Значение"
          classNames="active-object__row-input"
          classNamesWrapper="active-object__row-input-wrapper full-size"
          name=""
          options={targetParametersValuesOptions}
          value={targetParametersValuesOption}
          saveNewValue={value =>
            setTargetParametersValuesOption(
              targetParametersValuesOptions?.find((item, ix) => ix === value),
            )
          }
        />
      </div>
      {(wellModel as components['schemas']['PNOProductionWellModel'])
        ?.bottomhole_pressures__atm?.length > 0 ||
      (wellModel as components['schemas']['PNOInjectionWellModel'])
        ?.bottom_hole_pressures__atm?.length > 0 ? (
        <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
          <div className="well-model-panel__tab-list-wrapper">
            <TabList className="results__tab-list">
              <Tab className="results__icon">
                <ChartIcon />
              </Tab>
              <Tab className="results__icon" disabled>
                <TableIcon />
              </Tab>
            </TabList>
          </div>
          <div>
            <TabPanel>
              <div className="results__single-chart">
                <div className="well-model-panel__chart-container">
                  <Line
                    data={chartData}
                    options={
                      getOptions(
                        'BottomHolePressures',
                        /* (
                          wellModel as components['schemas']['PNOInjectionWellModel']
                        )?.choke_diameters__m
                          ? 'InjWell'
                          : */ 'ProdWell',
                        (targetParametersOption?.value as any) || 'PRESSURE',
                      ) as any
                    }
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <></>
            </TabPanel>
          </div>
        </Tabs>
      ) : (
        <p className="no-model__message">
          Модель насоса отсутствует в данных конфигурационного файла.
        </p>
      )}
    </>
  );
};

export default wellModelPPD;
