import * as React from 'react';

import { usePutTableToStorage } from '../../../../../hooks/usePutTableToStorage';
import EquipmentsTable from './AvgTable';
import { equipmentMOColumnDefs } from './utils';

export const TABLE_NAME = 'PPD_MO_EQUIPMENT';
const Table: React.FC = () => {
  usePutTableToStorage(TABLE_NAME, equipmentMOColumnDefs);
  return <EquipmentsTable />;
};

export default Table;
