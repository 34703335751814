import * as React from 'react';

import { usePutTableToStorage } from '../../../../hooks/usePutTableToStorage';
import UATable from './Table/Table';
import { layersUAColumnDefs } from './Table/utils';

export const TABLE_NAME = 'PPD_UA_LAYERS';
const Layers: React.FC = () => {
  usePutTableToStorage(TABLE_NAME, layersUAColumnDefs);
  return <UATable />;
};

export default Layers;
