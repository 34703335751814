import * as React from 'react';

import { usePutTableToStorage } from '../../../../../hooks/usePutTableToStorage';
import LayersTable from './AvgTable';
import { layersMOColumnDefs } from './utils';

export const TABLE_NAME = 'PPD_MO_LAYERS';
const Table: React.FC = () => {
  usePutTableToStorage(TABLE_NAME, layersMOColumnDefs);
  return <LayersTable />;
};

export default Table;
