import React, { RefObject } from 'react';

import { AgGridReact } from '@ag-grid-community/react';
import { ReactComponent as ExportIcon } from 'images/Project/icn-export.svg';

import './style.scss';

interface IExportToCSV {
  tableRef: RefObject<AgGridReact>;
}
export const ExportToCSV: React.FC<IExportToCSV> = ({ tableRef }) => {
  const handleClick = () => {
    tableRef.current!.api.exportDataAsCsv({
      allColumns: true,
    });
  };
  return (
    <button type="button" className="export-table__btn" onClick={handleClick}>
      <ExportIcon />
    </button>
  );
};
