import React from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as CloseIcon } from 'images/Project/close-icon.svg';

import loader from '../../images/NewProject/loader_small.gif';
import { selectShowDialog } from '../../store/commonSlice';
import Button from '../Button';
import RadioButton from '../RadioButton';

import './style.scss';

interface ICancelTaskConfirmationWindowProps {
  closeWindow(): void;
  createProject(
    projectName: string,
    url: string,
    sinkId: string,
    fileToSend: FormData,
  ): void;
  progress?: boolean;
}

const DeleteConfirmationWindow: React.FC<
  ICancelTaskConfirmationWindowProps
> = ({ createProject, closeWindow, progress }) => {
  const showDialog = useSelector(selectShowDialog);
  const [sinkId, setSinkId] = React.useState<string>('');

  const onBtnClick = () => {
    createProject(
      showDialog?.objectName as string,
      showDialog?.objectId as string,
      sinkId,
      showDialog?.file as FormData,
    );
  };

  React.useEffect(() => {
    const onKeyDownHandler = (e: React.KeyboardEvent) => {
      const { key } = e;
      if (key === 'Enter') {
        onBtnClick();
      }
    };
    document.addEventListener('keydown', onKeyDownHandler as any);
    return () => {
      document.removeEventListener('keydown', onKeyDownHandler as any);
    };
  }, []);

  React.useEffect(() => {
    if (showDialog?.options)
      setSinkId(showDialog?.options[0]?.gathering_centers[0]?.uid as string);
  }, [showDialog?.options]);

  return (
    <div className="dialog-window-background">
      <div className="dialog-window-container">
        <div className="dialog-window-wrapper">
          <button
            type="button"
            className="dialog-window-close-button"
            onClick={closeWindow}
          >
            {!progress && <CloseIcon />}
          </button>
          <div className="dialog-window-title">
            Выберите центр сбора для определения подсети
          </div>
          <div className="dialog-window-options">
            {showDialog.options?.map((item, index) => (
              <RadioButton
                id={`subnet-radio-${index}`}
                value={item.gathering_centers[0]?.uid as string}
                label={item.gathering_centers[0]?.name}
                name="subnet"
                setValue={setSinkId}
                checked={sinkId === (item.gathering_centers[0]?.uid as string)}
              />
            ))}
          </div>
          <div className="dialog-window-buttons-wrapper">
            <Button
              type="button"
              className="dialog-window__create-button"
              onClick={onBtnClick}
              disabled={progress}
            >
              {progress && (
                <img className="left-col__loader" src={loader} alt=".." />
              )}
              {progress ? 'Подготовка...' : 'Создать проект'}
            </Button>
            <Button
              type="button"
              mode="secondary"
              className="dialog-window__cancel-button"
              onClick={closeWindow}
              disabled={progress}
            >
              Отмена
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationWindow;
