import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import {
  selectGatheringCentersFactPressuresById,
  selectGatheringCentersModelById,
  updateGatheringCentersFactPressures,
  updateGatheringCentersModel,
} from 'store/projectSlice';

import {
  getTasks,
  setGatheringCenterFactPressure as saveGatheringCenterFactPressure,
  setGatheringCentersModel as saveGatheringCentersModel,
} from 'services/apiRequests';
import { ReactComponent as GCIcon } from '../../../../images/Project/icn-gathering-center.svg';
import { selectTaskId, setIsActual } from '../../../../store/taskSlice';
import { IObject } from '../../types';
import SinkView from './SinkView';
import SinkViewAF from './SinkViewAF';

import '../style.scss';

const Sink: React.FC<IObject> = ({ objectUid, projectUid }) => {
  const gatheringCentersModelById = useSelector(
    selectGatheringCentersModelById,
  );
  const gatheringCentersFactPressuresById = useSelector(
    selectGatheringCentersFactPressuresById,
  );
  const params = useParams() as { id: string };
  const taskId = useSelector(selectTaskId);
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = React.useState(0);

  const saveNewGatheringCentersModelValue = async (
    value: number | string,
    name: string,
  ) => {
    const newValue = {
      ...gatheringCentersModelById[objectUid as string],
      [name]: value,
    };
    try {
      const response = await saveGatheringCentersModel(
        projectUid,
        objectUid as string,
        newValue,
      );
      if (response.data) dispatch(updateGatheringCentersModel(response.data));
      const res = await getTasks(params.id);
      if (res?.data?.length > 0) {
        const task = res?.data?.find(item => item.task_uid === taskId);
        if (task && !task.is_actual) {
          dispatch(setIsActual(task.is_actual!));
        }
      }
    } catch (e) {
      //
    }
  };

  const saveNewGatheringCenterFactPressureValue = async (
    value: number | string,
    name: string,
  ) => {
    try {
      const response = await saveGatheringCenterFactPressure(
        projectUid,
        objectUid as string,
        {
          [name]: value as number,
        },
      );
      if (response.data) {
        const sink = response.data.find(item => item.gc_uid === objectUid);
        if (sink) dispatch(updateGatheringCentersFactPressures(sink));
      }
    } catch (e) {
      //
    }
  };

  return (
    <>
      <div className="active-object__content">
        <div className="active-object__top-row">
          <p className="active-object__title">
            <GCIcon />
            {gatheringCentersModelById[objectUid as string]?.name}
          </p>
        </div>
      </div>
      {/* <div className="active-object__row">
        <ObjectStringInput
          className="input active-object__row-input long"
          label="Идентификатор"
          name="name"
          initValue={gatheringCentersModelById[objectUid as string]?.name}
          saveNewValue={saveNewNodeValue}
        />
      </div>
       <div className="active-object__row">
        <label className="input-label">Координаты</label>
        <div className="d-flex">
          <div className="active-object__row-input-prefix">lon</div>
          <ObjectInput
            className="input active-object__row-input short with-prefix left"
            label=""
            name="lon"
            initValue={nodeById[objectUid as string]?.coordinates?.[1]}
            saveNewValue={saveNewNodeValue}
          />
          <div className="active-object__row-input-prefix">lat</div>
          <ObjectInput
            className="input active-object__row-input short with-prefix"
            label=""
            name="lat"
            initValue={nodeById[objectUid as string]?.coordinates?.[0]}
            saveNewValue={saveNewNodeValue}
          />
        </div>
      </div> */}
      <Tabs
        className="active-object__tabs"
        selectedIndex={tabIndex}
        onSelect={index => setTabIndex(index)}
      >
        <TabList className="active-object__tab-list">
          <Tab className="active-object__tab">Текущее состояние</Tab>
          <Tab className="active-object__tab">Факт. состояние</Tab>
        </TabList>
        <div className="active-object__panels">
          <TabPanel>
            <SinkView
              gatheringCentersModel={
                gatheringCentersModelById[objectUid as string]
              }
              saveNewGatheringCentersModelValue={
                saveNewGatheringCentersModelValue
              }
            />
          </TabPanel>
          <TabPanel>
            <SinkViewAF
              gatheringCenterFactPressure={
                gatheringCentersFactPressuresById[objectUid as string]
              }
              saveNewGatheringCenterFactPressureValue={
                saveNewGatheringCenterFactPressureValue
              }
            />
          </TabPanel>
        </div>
      </Tabs>
    </>
  );
};

export default Sink;
