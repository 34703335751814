import React from 'react';

import { usePutTableToStorage } from '../../../../hooks/usePutTableToStorage';
import AvgTable from './AvgTable';
import { wellsAFColumnDefs } from './utils';

export const TABLE_NAME = 'AF_SOURCES';
const AFSourcesResults: React.FC = () => {
  usePutTableToStorage(TABLE_NAME, wellsAFColumnDefs);
  return (
    <>
      <div className="separators__table-title">Средние значения</div>
      <AvgTable />
    </>
  );
};

export default AFSourcesResults;
