import { threeDigitsFormatter, twoDigitsFormatter } from '../../../utils';

export const isNumber = (value: string) => {
  const isInt = /^-?\d+$/.test(value);
  if (isInt) return true;
  return /^-?\d+\.\d+?(e[+-]?\d+)?$/.test(value);
};

// |[.]\d+(e[+-]?\d+)?

export const formatFloat = (value: number, error: boolean) => {
  if (error) return twoDigitsFormatter.format(value);
  return threeDigitsFormatter.format(value);
};

export const prepareTableValue = (value: number) => {
  if (value > 0) {
    return `+${formatFloat(value, true)}`;
  }
  return formatFloat(value, true);
};
