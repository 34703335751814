import * as React from 'react';

export const seriesColors = [
  '#019DDC',
  '#FF72BE',
  '#FAB400',
  '#23D692',
  '#FA8700',
  '#F9D100',
  '#F153FF',
  '#644BA5',
  '#FA3C00',
];

export const templateRowsNumber = 5;
export const segmentsUAColumnDefs = [
  {
    colId: '0',
    headerName: 'Начальный узел',
    field: 'start_node_name',
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '1',
    headerName: 'Конечный узел',
    field: 'end_node_name',
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '2',
    headerName: 'Расход нефти, т\u2060/\u2060сут',
    field: 'oil_flow_rate',
  },
  {
    colId: '3',
    headerName: 'Расход жидкости, м\u00B3\u2060/\u2060сут',
    field: 'fluid_flow_rate',
    minWidth: 180,
  },
  {
    colId: '4',
    headerName: 'Расход газа, тыс.м\u00B3\u2060/\u2060сут',
    field: 'gas_flow_rate',
  },
  {
    colId: '5',
    headerName: 'Давление на входе, атм',
    field: 'p_in',
  },
  {
    colId: '6',
    headerName: 'Перепад давлений, атм/км',
    field: 'dp_dl',
    minWidth: 170,
  },
  {
    colId: '7',
    headerName: 'Перепад температур, ℃\u2060/\u2060км',
    field: 'dt_dl',
    minWidth: 190,
  },
  {
    colId: '8',
    headerName: 'Макс. скорость жидкости, м\u2060/\u2060с',
    field: 'fluid_velocity',
    minWidth: 190,
  },
  {
    colId: '9',
    headerName: 'Макс. скорость газа, м\u2060/\u2060с',
    field: 'gas_velocity',
    minWidth: 190,
  },
];
export const segmentsAvgTableHead = {
  start_node_name: {
    title: 'Начальный узел',
    component: <>Начальный узел</>,
  },
  end_node_name: {
    title: 'Конечный узел',
    component: <>Конечный узел</>,
  },
  oil_flow_rate: {
    title: 'Расход нефти',
    component: (
      <>
        Расход нефти, <br />
        т/сут
      </>
    ),
  },
  fluid_flow_rate: {
    title: 'Расход жидкости',
    component: (
      <>
        Расход жидкости,
        <br />
        м3/сут
      </>
    ),
  },
  gas_flow_rate: {
    title: 'Расход газа',
    component: (
      <>
        Расход газа,
        <br />
        тыс.м3/сут
      </>
    ),
  },
  p_in: {
    title: 'Давление на входе',
    component: (
      <>
        Давление на входе,
        <br />
        атм
      </>
    ),
  },
  dp_dl: {
    title: 'Перепад давлений',
    component: (
      <>
        Перепад давлений,
        <br />
        атм
      </>
    ),
  },
  dt_dl: {
    title: 'Перепад температур',
    component: (
      <>
        Перепад температур, <br />
        ℃/км
      </>
    ),
  },
  fluid_velocity: {
    title: 'Макс. скорость жидкости',
    component: (
      <>
        Макс. скорость жидкости <br />
        м/с
      </>
    ),
  },
  gas_velocity: {
    title: 'Макс. скорость газа',
    component: (
      <>
        Макс. скорость газа <br />
        м/с
      </>
    ),
  },
};

export const segmentsByStepTableHead = {
  start_node_name: {
    title: 'V0',
    component: (
      <>
        V<span className="subscript">0</span>
      </>
    ),
  },
  end_node_name: {
    title: 'V1',
    component: (
      <>
        V<span className="subscript">1</span>
      </>
    ),
  },
  length: 'L, м',
  diameter: 'D, м',
  p_in: {
    title: 'P0, атм',
    component: (
      <>
        Р<span className="subscript">0</span>,
        <br /> атм
      </>
    ),
  },
  oil_flow_rate: {
    title: 'Qн, т/сут',
    component: (
      <>
        Qн,
        <br />
        т/cут
      </>
    ),
  },
  fluid_flow_rate: {
    title: 'Qж, м3/сут',
    component: (
      <>
        Qж,
        <br />
        <span className="relative">
          м<span className="index">3</span>
        </span>
        &nbsp; /сут
      </>
    ),
  },
  gas_flow_rate: {
    title: 'Qг, тыс. м3/сут',
    component: (
      <>
        Qг, <br />
        <span className="relative">
          тыс. м<span className="index">3</span>
        </span>
        &nbsp; /сут
      </>
    ),
  },
  dp_dl: {
    title: 'ΔP/ΔL, атм/км',
    component: (
      <>
        ΔP/ΔL,
        <br />
        атм/км
      </>
    ),
  },
  dt_dl: {
    title: 'Δt/ΔL, °С/км',
    component: (
      <>
        Δt/ΔL,
        <br />
        °С/км
      </>
    ),
  },
  fluid_velocity: {
    title: 'υж-max, м/c',
    component: (
      <>
        υ<span className="subscript">ж-max</span>,
        <br /> м/c
      </>
    ),
  },
  gas_velocity: {
    title: 'υг-max, м/c',
    component: (
      <>
        υ<span className="subscript">г-max</span>,
        <br /> м/c
      </>
    ),
  },
};

export const dropdownOptions = [
  { label: 'Расход нефти, т/сут', value: 'oil_flow_rate_mass' },
  { label: 'Расход жидкости, м3/сут', value: 'fluid_flow_rate_mass' },
  { label: 'Расход газа, тыс. м3/сут', value: 'gas_flow_rate_volume' },
  { label: 'Перепад давлений, атм/км', value: 'dp_dl' },
];
