import * as React from 'react';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { ReactComponent as ChartIcon } from 'images/Project/icn-line-chart.svg';
import { ReactComponent as TableIcon } from 'images/Project/icn-table.svg';
import { selectActiveObject } from 'store/mapSlice';
import {
  selectUpdatedModel,
  selectWellById,
  selectWellModelById,
} from 'store/projectSlice';

import { components } from '../../../../../generated/apiTypes';
import ObjectSelect, {
  OptionType,
} from '../../../ActiveObject/components/ObjectSelect';
import { getWellOperationType } from '../../../utils';
import Chart from '../Chart';
import Table from '../Table';
import { isModelExistByType } from '../Table/utils';
import useChartData from './hooks/use-chart-data';

const Power: React.FC = () => {
  const wellsModelsById = useSelector(selectWellModelById);
  const wellsById = useSelector(selectWellById);
  const activeObject = useSelector(selectActiveObject);
  const updatedModel = useSelector(selectUpdatedModel);
  const [tabIndex, setTabIndex] = React.useState<number>(
    isModelExistByType(
      'Power',
      wellsModelsById[activeObject.object_uid as string],
    )
      ? 0
      : 1,
  );
  const [modelExist, setModelExist] = React.useState<boolean>(
    isModelExistByType(
      'Power',
      wellsModelsById[activeObject.object_uid as string],
    ),
  );
  const [wellOperationType, setWellOperationType] = React.useState<
    | components['schemas']['PNOWellOperationType']
    | '5D_ЭЦН'
    | '5D_Газлифт'
    | 'Газлифт редуц.'
    | 'ЭЦН редуц.'
    | 'ФЧЗ'
    | 'ФЧЗ редуц.'
  >();
  const [wellModel, setWellModel] = React.useState<
    components['schemas']['GetWellModelQueryResult']
  >(wellsModelsById[activeObject.object_uid as string]);
  const [targetParametersOption, setTargetParametersOption] = React.useState<
    OptionType<string> | undefined
  >(undefined);
  const [targetParametersOptionsAFW] = React.useState<OptionType<string>[]>([
    { label: 'Частота работы', value: 'FREQ' },
    { label: 'Перепад затр. давления', value: 'ANNULAR_PRESSURE_DROP' },
    { label: 'Перепад буф. давления', value: 'WELL_HEAD_PRESSURE_DROP' },
  ]);
  const [targetParametersOptions5DPump] = React.useState<OptionType<string>[]>([
    { label: 'Частота работы', value: 'WORK_FREQ_5D' },
    { label: 'Длительность интервала работы', value: 'WORK_TIMES_5D' },
    {
      label: 'Длительность интервала накопления',
      value: 'ACCUMULATION_TIMES_5D',
    },
  ]);
  const chartData = useChartData(
    wellModel,
    wellOperationType as components['schemas']['PNOWellOperationType'],
    ['ФЧЗ', '5D_ЭЦН'].includes(wellOperationType as string)
      ? (targetParametersOption?.value as
          | 'FREQ'
          | 'ANNULAR_PRESSURE_DROP'
          | 'WELL_HEAD_PRESSURE_DROP'
          | 'WORK_FREQ_5D'
          | 'WORK_GASLIFT_FLOWS_5D'
          | 'WORK_TIMES_5D'
          | 'ACCUMULATION_TIMES_5D')
      : undefined,
  );
  const [tableState, setTableState] = React.useState<
    'noModel' | 'view' | 'edit'
  >(
    updatedModel.includes(activeObject.object_uid as string) &&
      isModelExistByType('Power', wellModel)
      ? 'edit'
      : 'view',
  );

  React.useEffect(() => {
    const exist = isModelExistByType('Power', wellModel);
    setModelExist(exist);
    setWellOperationType(
      getWellOperationType(wellsById[activeObject.object_uid as string].type),
    );
    setWellModel(wellsModelsById[activeObject.object_uid as string]);
    if (!exist) setTabIndex(1);
  }, [
    activeObject.object_uid,
    (
      wellsModelsById[activeObject.object_uid as string]
        ?.model as components['schemas']['GetPeriodicPumpWellModelQueryResult']
    ).powers__kW,
  ]);

  React.useEffect(() => {
    setTableState(
      updatedModel.includes(activeObject.object_uid as string) &&
        isModelExistByType('Power', wellModel)
        ? 'edit'
        : 'view',
    );
  }, [activeObject.object_uid]);

  React.useEffect(() => {
    if (wellOperationType === 'ФЧЗ') {
      setTargetParametersOption(targetParametersOptionsAFW[0]);
    } else if (wellOperationType === '5D_ЭЦН') {
      setTargetParametersOption(targetParametersOptions5DPump[0]);
    }
  }, [wellOperationType]);

  React.useEffect(() => {
    if (tableState === undefined || tableState === 'view') {
      if (wellModel)
        setTableState(
          isModelExistByType('Power', wellModel) ? 'view' : 'noModel',
        );
    }
    if (tableState === 'edit') setTabIndex(1);
  }, [wellModel, tableState]);

  const getOptionsByType = () => {
    if (wellOperationType === 'ФЧЗ') {
      return targetParametersOptionsAFW;
    }
    if (wellOperationType === '5D_ЭЦН') {
      return targetParametersOptions5DPump;
    }
    return [];
  };

  return (
    <>
      <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
        <div className="well-model-panel__tab-list-wrapper">
          <TabList className="results__tab-list">
            <Tab className="results__icon" disabled={!modelExist}>
              <ChartIcon />
            </Tab>
            <Tab className="results__icon">
              <TableIcon />
            </Tab>
          </TabList>
          {/* tabIndex === 1 && (
            <div className="well-model-panel__table-caption">
              {getTableCaptionByType('Power')}
            </div>
          ) */}
        </div>
        <div>
          <TabPanel>
            {['ФЧЗ', '5D_ЭЦН'].includes(wellOperationType as string) && (
              <ObjectSelect
                label="Параметр"
                classNames="active-object__row-input"
                classNamesWrapper="active-object__row-input-wrapper well-model-param long"
                name=""
                options={getOptionsByType()}
                value={targetParametersOption}
                saveNewValue={value =>
                  setTargetParametersOption(
                    getOptionsByType()?.find(item => item.value === value),
                  )
                }
              />
            )}
            <Chart
              chartType="Power"
              chartData={chartData}
              wellOperationType={
                wellOperationType as
                  | components['schemas']['PNOWellOperationType']
                  | '5D_ЭЦН'
                  | '5D_Газлифт'
                  | 'Газлифт редуц.'
                  | 'ЭЦН редуц.'
                  | 'ФЧЗ'
                  | 'ФЧЗ редуц.'
              }
              tableState={tableState}
              setTableState={setTableState}
              targetParam={
                ['ФЧЗ', '5D_ЭЦН', '5D_Газлифт'].includes(
                  wellOperationType as string,
                )
                  ? (targetParametersOption?.value as
                      | 'FREQ'
                      | 'ANNULAR_PRESSURE_DROP'
                      | 'WELL_HEAD_PRESSURE_DROP'
                      | 'WORK_FREQ_5D'
                      | 'WORK_GASLIFT_FLOWS_5D'
                      | 'WORK_TIMES_5D'
                      | 'ACCUMULATION_TIMES_5D')
                  : undefined
              }
            />
          </TabPanel>
          <TabPanel>
            {['ФЧЗ', '5D_ЭЦН'].includes(wellOperationType as string) && (
              <ObjectSelect
                label="Параметр"
                classNames="active-object__row-input"
                classNamesWrapper="active-object__row-input-wrapper well-model-param"
                name=""
                options={getOptionsByType()}
                value={targetParametersOption}
                saveNewValue={value =>
                  setTargetParametersOption(
                    getOptionsByType()?.find(item => item.value === value),
                  )
                }
              />
            )}
            <Table
              wellOperationType={
                wellOperationType as
                  | components['schemas']['PNOWellOperationType']
                  | '5D_ЭЦН'
                  | '5D_Газлифт'
                  | 'Газлифт редуц.'
                  | 'ЭЦН редуц.'
                  | 'ФЧЗ'
                  | 'ФЧЗ редуц.'
              }
              tableType="Power"
              tableState={tableState}
              setTableState={setTableState}
              targetParam={
                ['ФЧЗ', '5D_ЭЦН', '5D_Газлифт'].includes(
                  wellOperationType as string,
                )
                  ? (targetParametersOption?.value as
                      | 'FREQ'
                      | 'ANNULAR_PRESSURE_DROP'
                      | 'WELL_HEAD_PRESSURE_DROP'
                      | 'WORK_FREQ_5D'
                      | 'WORK_GASLIFT_FLOWS_5D'
                      | 'WORK_TIMES_5D'
                      | 'ACCUMULATION_TIMES_5D')
                  : undefined
              }
            />
          </TabPanel>
        </div>
      </Tabs>
    </>
  );
};

export default Power;
