import * as React from 'react';

import Table from './Table/Table';

const Sources: React.FC = () => {
  return (
    <>
      <Table />
    </>
  );
};

export default Sources;
