import * as React from 'react';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { NamedProps } from 'react-select';

import Dropdown from 'Components/Dropdown';
import { ArrayElement } from 'Pages/Project/types';
import { selectWellsStepTableUA } from 'store/taskSlice';

import useChartData from './hooks/use-chart-data';
import { dropdownOptions, options } from './utils';

import 'Pages/Project/ResultsPanel/chartStyle.scss';

type OptionType = ArrayElement<typeof dropdownOptions> | null;

const SourcesChart: React.FC = () => {
  const [option, setOption] = React.useState<OptionType>(dropdownOptions[0]);
  const wellsStepTable = useSelector(selectWellsStepTableUA);

  const onChangeHandler: NamedProps['onChange'] = value =>
    setOption(value as OptionType);

  const chartData = useChartData(wellsStepTable, option);

  return (
    <div className="results__single-chart">
      <div className="results__single-chart-dropdown">
        <Dropdown
          options={dropdownOptions}
          onChange={onChangeHandler}
          value={option}
          menuWidth="100%"
        />
      </div>
      <div>
        <Line
          data={chartData}
          options={options(option?.label as string) as any}
        />
      </div>
    </div>
  );
};

export default SourcesChart;
