import { ChartOptions } from 'chart.js';
import numeral from 'numeral';

export const seriesColors = [
  '#019DDC',
  '#FF72BE',
  '#FAB400',
  '#23D692',
  '#FA8700',
  '#F9D100',
  '#F153FF',
  '#644BA5',
  '#FA3C00',
];

export const options = (
  optionLabel: string,
  datasetLength: number,
): ChartOptions => {
  return {
    aspectRatio: datasetLength > 40 ? 1.2 : 2,
    layout: {
      padding: 0,
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          pointStyle: 'line',
          usePointStyle: true,
        },
        align: 'start',
      },
    },
    scales: {
      y: {
        ticks: {
          padding: 8,
          color: '#999999',
          font: {
            size: 10,
            family: 'Inter, sans-serif',
          },
          callback: value => {
            if (value === 0) return value;
            if (
              Math.abs(value as number) < 0.01 ||
              Math.abs(value as number) > 99999
            ) {
              return numeral(value).format('0.0[00]e+0');
            }
            return numeral(value).format('0[.]0[00]');
          },
        },
        title: {
          display: true,
          text: optionLabel,
          color: '#999999',
          font: {
            size: 10,
            family: 'Inter, sans-serif',
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          padding: 8,
          color: '#999999',
          font: {
            size: 10,
            family: 'Inter, sans-serif',
          },
        },
        title: {
          display: true,
          text: 'Время, мин',
          color: '#999999',
          font: {
            size: 10,
            family: 'Inter, sans-serif',
          },
        },
      },
    },
  };
};

export const dropdownOptions = [
  { label: 'Расход нефти, т/сут', value: 'oil_flow_rate_mass' },
  { label: 'Расход жидкости, м3/сут', value: 'fluid_flow_rate_volume' },
  { label: 'Расход газа, тыс. м3/сут', value: 'gas_flow_rate_volume' },
  { label: 'Давление на входе, атм', value: 'pressure_in' },
  { label: 'Перепад давлений, атм/км', value: 'dp_dl' },
  { label: 'Перепад температур, ℃/км', value: 'dt_dl' },
  { label: 'Макс. скорость жидкости, м/с', value: 'fluid_velocity' },
  { label: 'Макс. скорость газа, м/с', value: 'gas_velocity' },
] as const;
