import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import update from 'immutability-helper';

import { components } from '../../../../generated/apiTypes';
import { selectActiveObject } from '../../../../store/mapSlice';
import {
  selectSegmentById,
  updateSegments,
  updateSegmentsNetwork,
} from '../../../../store/projectSlice';
import ObjectInput from '../../ActiveObject/components/ObjectInput';
import Point from './Point';

const HeightProfile: React.FC = () => {
  const dispatch = useDispatch();
  const segmentById = useSelector(selectSegmentById);
  const activeObject = useSelector(selectActiveObject);

  const [heightProfile, setHeightProfile] =
    React.useState<components['schemas']['PNOSegmentInternalCoordinate'][]>();

  const saveNewSegment = async (
    value: components['schemas']['PNOSegmentInternalCoordinate'][],
  ) => {
    try {
      const newValue = {
        ...segmentById[activeObject.object_uid as string],
        height_profile: value,
      };
      dispatch(updateSegments(newValue));
      dispatch(updateSegmentsNetwork(true));
    } catch (e) {
      //
    }
  };

  React.useEffect(() => {
    if (
      segmentById[activeObject.object_uid as string]?.height_profile?.length > 1
    ) {
      setHeightProfile(
        segmentById[activeObject.object_uid as string]?.height_profile,
      );
    } else {
      const initHeightProfile = [
        { coordinate: { m: 0 }, height: { m: 0 } },
        {
          coordinate: {
            m: segmentById[activeObject.object_uid as string]?.length.m,
          },
          height: { m: 0 },
        },
      ];
      saveNewSegment(initHeightProfile);
    }
  }, [segmentById[activeObject.object_uid as string]]);

  const saveStoreHeightProfile = (
    pointIx: number,
    value: components['schemas']['PNOSegmentInternalCoordinate'],
  ) => {
    const newArr = update(heightProfile, {
      [pointIx]: { $set: value },
    });
    setHeightProfile(newArr);
  };

  const saveHeightProfile = (
    pointIx: number,
    value: components['schemas']['PNOSegmentInternalCoordinate'],
  ) => {
    const newArr = update(heightProfile, {
      [pointIx]: { $set: value },
    });
    if (newArr) saveNewSegment(newArr);
  };

  // const addPoint = () => {
  //   if (heightProfile) {
  //     const newPoint: components['schemas']['PNOSegmentInternalCoordinate'] = {
  //       coordinate: { m: 0 },
  //       height: { m: 0 },
  //     };
  //     const newArr = update(heightProfile, {
  //       $push: [newPoint],
  //     });
  //     setHeightProfile(newArr);
  //   }
  // };

  const deletePoint = (pointIx: number) => {
    if (heightProfile) {
      const newArr = update(heightProfile, {
        $splice: [[pointIx, 1]],
      });
      if (newArr) saveNewSegment(newArr);
    }
  };

  const isCoordinateError = (value: number): boolean => {
    const isDuplicated = heightProfile?.some(
      item => item.coordinate.m === value,
    );
    console.log(heightProfile);
    const isLengthExceeded =
      value > segmentById[activeObject.object_uid as string].length.m;
    console.log(isLengthExceeded);
    return isDuplicated || isLengthExceeded;
  };

  return (
    <>
      <div className="d-flex">
        <div className="active-object__input-with-unit-wrapper left">
          <ObjectInput
            className="input active-object__row-input short with-unit"
            label="Длина трубопровода"
            name=""
            initValue={
              segmentById[activeObject.object_uid as string]?.length?.m
            }
            saveNewValue={() => {}}
            disabled
          />
          <p className="active-object__row-input-unit">м</p>
        </div>
        <div className="active-object__input-with-unit-wrapper">
          <ObjectInput
            className="input active-object__row-input short with-unit"
            label="Перепад высот"
            name=""
            initValue={0}
            saveNewValue={() => {}}
            disabled
          />
          <p className="active-object__row-input-unit">м</p>
        </div>
      </div>
      <div className="height-profile-panel__points-container">
        {heightProfile?.map((item, index) => (
          <Point
            index={index}
            item={item}
            saveStoreHeightProfile={saveStoreHeightProfile}
            saveHeightProfile={saveHeightProfile}
            deletePoint={deletePoint}
            isCoordinateError={isCoordinateError}
          />
        ))}
      </div>
      {/* <button
        type="button"
        className="active-object__add-point-btn height-profile-panel__add-btn"
        onClick={addPoint}
      >
        <AddIcon className="active-object__add-icon" />
        Добавить точку
      </button> */}
    </>
  );
};

export default HeightProfile;
