export const seriesColors = [
  '#019DDC',
  '#FF72BE',
  '#FAB400',
  '#23D692',
  '#FA8700',
  '#F9D100',
  '#F153FF',
  '#644BA5',
  '#FA3C00',
];

export const templateRowsNumber = 5;

export const segmentsUAColumnDefs = [
  {
    colId: '0',
    headerName: 'Начальный узел',
    field: 'start_node_name',
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '1',
    headerName: 'Конечный узел',
    field: 'end_node_name',
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '2',
    headerName: 'Расход нефти, т\u2060/\u2060сут',
    field: 'oil_flow_rate',
  },
  {
    colId: '3',
    headerName: 'Расход жидкости, м\u00B3\u2060/\u2060сут',
    field: 'fluid_flow_rate',
  },
  {
    colId: '4',
    headerName: 'Расход газа, тыс.м\u00B3\u2060/\u2060сут',
    field: 'gas_flow_rate',
  },
  {
    colId: '5',
    headerName: 'Давление на входе, атм',
    field: 'p_in',
  },
  {
    colId: '6',
    headerName: 'Перепад давлений, атм/км',
    field: 'dp_dl',
  },
  {
    colId: '7',
    headerName: 'Перепад температур, ℃\u2060/\u2060км',
    field: 'dt_dl',
  },
  {
    colId: '8',
    headerName: 'Макс. скорость жидкости, м\u2060/\u2060с',
    field: 'fluid_velocity',
  },
  {
    colId: '9',
    headerName: 'Макс. скорость газа, м\u2060/\u2060с',
    field: 'gas_velocity',
  },
];

export const dropdownOptions = [
  { label: 'Расход нефти, т/сут', value: 'oil_flow_rate_mass' },
  { label: 'Расход жидкости, м3/сут', value: 'fluid_flow_rate_mass' },
  { label: 'Расход газа, тыс. м3/сут', value: 'gas_flow_rate_volume' },
  { label: 'Перепад давлений, атм/км', value: 'dp_dl' },
];
