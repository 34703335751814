import React from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as CloseIcon } from 'images/Project/close-icon.svg';
import { ReactComponent as ErrorIcon } from 'images/Project/icn-validation-error.svg';

import { selectShowDialog } from '../../store/commonSlice';

import './style.scss';

interface ICancelTaskConfirmationWindowProps {
  closeWindow(): void;
}

const ErrorWindow: React.FC<ICancelTaskConfirmationWindowProps> = ({
  closeWindow,
}) => {
  const showDialog = useSelector(selectShowDialog);

  return (
    <div className="dialog-window-background">
      <div className="dialog-window-container">
        <div className="dialog-window-wrapper">
          <button
            type="button"
            className="dialog-window-close-button"
            onClick={closeWindow}
          >
            <CloseIcon />
          </button>
          <div className="dialog-window__with-icon-wrapper">
            <ErrorIcon />
            <div className="dialog-window-title error">
              {showDialog?.objectName}
            </div>
          </div>
          <div className="dialog-window-message cancel-task-message">
            {showDialog?.message}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorWindow;
