import * as React from 'react';
import { useSelector } from 'react-redux';

import AgGridTable from 'Components/AgGridTable';
import { components } from 'generated/apiTypes';
import { selectSolution } from 'store/taskSlice';

import { formatTwoDigits } from 'utils';
import { useGetColumnDefs } from '../../../../../hooks/useGetColumnDefs';
import { TABLE_NAME } from './Table';
import { wellfieldsUAColumnDefs } from './utils';

export interface ISourcesTableProps {
  step?: number;
}

const SourcesTable: React.FC<ISourcesTableProps> = () => {
  const solution = useSelector(selectSolution)?.solution;
  const wellStates = (
    solution?.solution as components['schemas']['PNOReservoirBaseSolution']
  )?.well_states;
  const [data, setData] = React.useState<any[]>([]);
  const columnDef = useGetColumnDefs(TABLE_NAME, wellfieldsUAColumnDefs);

  const getData = () => {
    const newData: any = [];
    wellStates?.forEach(state => {
      newData.push({
        current_state: state.current_state,
        well_name: state.well_name,
        operation_type: state.operation_type,
        control_type: state.control_type,
        offset_time: state.offset_time,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fluid_flow_rate: formatTwoDigits(state.fluid_flow_rate?.m3_per_day),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        oil_flow_rate: formatTwoDigits(state.oil_flow_rate?.t_per_day),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        gas_flow_rate: formatTwoDigits(
          state.gas_flow_rate?.thousand_m3_per_day,
        ),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pressure: formatTwoDigits(state.pressure?.atm),
        bottomhole_pressure: formatTwoDigits(state.bottomhole_pressure?.atm),
        frequency:
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          state.operation_type === 'Добывающая'
            ? formatTwoDigits(
                (state.control as components['schemas']['PNOFrequency'])?.hertz,
              )
            : null,
        diameter:
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          state.operation_type === 'Нагнетательная'
            ? formatTwoDigits(
                (state.control as components['schemas']['PNODistance'])?.mm,
              )
            : null,
      });
    });

    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [wellStates]);

  return (
    <AgGridTable tableName={TABLE_NAME} columnDefs={columnDef} rowData={data} />
  );
};

export default SourcesTable;
