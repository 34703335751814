import React from 'react';

import classnames from 'classnames';

import './statusRenderer.scss';

export const StatusRenderer = (props: any) => {
  return (
    <p
      className={classnames(
        'ag-table__status-component',
        props.value === 'Работает' ? 'working' : 'stopped',
      )}
    >
      {' '}
      {props.value.slice(0, 3).toUpperCase()}
    </p>
  );
};
