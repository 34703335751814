import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { selectAuth } from 'store/commonSlice';
import { resetProjectsSliceState } from 'store/projectsSlice';

import { getProjects } from '../../services/apiRequests';
import { handleStorage } from '../../services/handleStorage';
import { setProjects } from '../../store/projectsSlice';
import updateAuthToken from '../../utils/updateAuthToken';
import ProjectsView from './ProjectsView';
import { ProjectsContainerPropsType } from './types';

const ProjectsContainer: React.FC<ProjectsContainerPropsType> = ({
  history,
}) => {
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);
  const [projectsIsLoading, setProjectsIsLoading] =
    React.useState<boolean>(true);

  const fetchProjects = async () => {
    try {
      const response = await getProjects();
      if (response?.data) {
        dispatch(setProjects(response.data));
      }
    } catch (e) {
      //
    }
    setProjectsIsLoading(false);
  };

  const resetStore = () => {
    dispatch(resetProjectsSliceState());
  };

  React.useEffect(() => {
    fetchProjects().then();
    return () => resetStore();
  }, [auth]);

  const createProject = () => {
    history.push('/new-project');
  };
  const logout = () => {
    handleStorage.removeToken();
    handleStorage.removeUsername();
    updateAuthToken(false);
    history.push('/login');
  };

  return (
    <>
      <ProjectsView
        createProject={createProject}
        logout={logout}
        projectsIsLoading={projectsIsLoading}
      />
    </>
  );
};

export default withRouter(ProjectsContainer);
