import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { components } from 'generated/apiTypes';
import {
  selectExperimentSettings,
  selectExperimentStatus,
  setExperimentSettings as setStoreExperimentSettings,
} from 'store/taskSlice';

import { setExperimentSettings } from 'services/apiRequests';
import ObjectSelect from '../ActiveObject/components/ObjectSelect';
import useGetExperimentSettings from './hooks/use-get-experiment-settings';
import { getAFCalibrationTypesOptions } from './options';
import TaskSettingsInputRow from './TaskSettingInputRow';

type SettingsType = components['schemas']['ExperimentSettings'];

interface IAFSettingsProps {
  setExperimentSettingsSaved(val: boolean): void;
}

const AFSettings: React.FC<IAFSettingsProps> = ({
  setExperimentSettingsSaved,
}) => {
  const params = useParams() as { id: string };
  const status = useSelector(selectExperimentStatus);
  const settings = useSelector(selectExperimentSettings);
  const [settingsState, setSettingsState] = React.useState<SettingsType>();
  const dispatch = useDispatch();

  useGetExperimentSettings(params.id);

  React.useEffect(() => {
    setSettingsState(settings);
  }, [settings]);

  const saveNewValue = async (value: number, name: string) => {
    dispatch(
      setStoreExperimentSettings({
        ...(settingsState as SettingsType),
        [name]: value,
      }),
    );
    setSettingsState({
      ...(settingsState as SettingsType),
      [name]: value,
    });
    setExperimentSettingsSaved(false);
    await setExperimentSettings(params.id, {
      ...(settingsState as SettingsType),
      [name]: value,
    });
    setExperimentSettingsSaved(true);
  };

  const saveNewCalibrationTypeValue = async (value: string) => {
    if (settings) {
      const newValue = {
        ...settings,
        fact_adaptation_experiment_settings: {
          ...settings.fact_adaptation_experiment_settings,
          calibration_type: value,
        },
      };
      dispatch(setStoreExperimentSettings(newValue as any));
      setExperimentSettingsSaved(false);
      await setExperimentSettings(params.id, newValue as any);
      setExperimentSettingsSaved(true);
    }
  };

  return (
    <>
      <div className="project-task-settings__row">
        <TaskSettingsInputRow
          containerClassName="full-width"
          label="Точность расчетов"
          value={settingsState?.accuracy}
          unit="aтм"
          saveNewValue={saveNewValue}
          name="accuracy"
          disabled={[
            'DRAFT',
            'SENT',
            'PREPARING',
            'PREPARED',
            'SOLVING',
          ].includes(status)}
        />
      </div>
      <div className="project-task-settings__row">
        <ObjectSelect
          label="Варьируемый параметр"
          classNamesWrapper="full-width"
          classNames="active-object__row-input"
          name=""
          options={getAFCalibrationTypesOptions()}
          value={getAFCalibrationTypesOptions()?.find(
            el =>
              el.value ===
              settings?.fact_adaptation_experiment_settings?.calibration_type,
          )}
          saveNewValue={saveNewCalibrationTypeValue}
        />
      </div>
    </>
  );
};

export default AFSettings;
