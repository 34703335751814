import React from 'react';

import classnames from 'classnames';

import { ICompareCell } from '../types';

import './compareCellRenderer.scss';

export const CompareCellRenderer = (props: any) => {
  const { colDef, data } = props;
  const { value, abs, rel, unit, cssClass } = data[colDef.field];
  if (cssClass) props.eGridCell.classList.add(cssClass);
  return (
    <div className="ag-table__compare-cell">
      <p className={classnames('ag-table__compare-cell-value')}>{value}</p>
      {value !== '0' && value !== undefined && value !== null && (
        <div className={classnames('ag-table__compare-cell-compare')}>
          <p>
            <text>{abs}</text>
            <text>{abs !== undefined && abs !== null ? ` ${unit}` : ''}</text>
          </p>
          <p>
            <text>{rel}</text>
            <text>{rel !== undefined && rel !== null ? '%' : ''}</text>
          </p>
        </div>
      )}
    </div>
  );
};

export const CompareCellFilterGetter = (props: any) => {
  const { data, colDef } = props;
  return data[colDef.field].value;
};

export const CompareCellComparator = (
  valueA: ICompareCell,
  valueB: ICompareCell,
) => {
  if (valueA.value === null && valueB.value === null) {
    return 0;
  }
  if (valueA.value === null) {
    return -1;
  }
  if (valueB.value === null) {
    return 1;
  }
  const floatA = parseFloat(valueA.value as string);
  const floatB = parseFloat(valueB.value as string);
  // eslint-disable-next-line no-nested-ternary
  return floatA - floatB;
};

export const CompareCellValueGetter = (props: any) => {
  const { data, colDef } = props;
  return data[colDef.field].value;
};
