import * as React from 'react';
import { useSelector } from 'react-redux';

import { components } from 'generated/apiTypes';
import { selectSolution } from 'store/taskSlice';

import AgGridTable from '../../../../../../Components/AgGridTable';
import { useGetColumnDefs } from '../../../../hooks/useGetColumnDefs';
import { ISegmentsTableProps } from '../../../types';
import { TABLE_NAME } from './index';
import { segmentsMOColumnDefs, segmentsTableItems } from './utils';

const SegmentsByStepTable: React.FC<ISegmentsTableProps> = ({ keyD }) => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOTechModeOptimizationProblem'];
  const [data, setData] = React.useState<any[]>([]);
  const columnDef = useGetColumnDefs(TABLE_NAME, segmentsMOColumnDefs);
  const getData = () => {
    const newData: any = [];
    keyD &&
      solution?.solution?.segment_step_table?.time2states[keyD]?.forEach(
        state => {
          newData.push(segmentsTableItems(state));
        },
      );
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [solution, keyD]);

  return (
    <AgGridTable tableName={TABLE_NAME} columnDefs={columnDef} rowData={data} />
  );
};

export default SegmentsByStepTable;
