import React, { useEffect, useRef, useState } from 'react';

import classnames from 'classnames';
import { ReactComponent as SettingsIcon } from 'images/Project/inc-settings.svg';

import Button from '../../Button';
import Checkbox from '../../Checkbox';
import { IStoreColDef } from '../types';

import './style.scss';

interface IColumnToolPanel {
  tableName: string;
  colDef: any[];
  onColumnVisibleChange(visible: boolean, colID: string): void;
}
export const ColumnToolPanel: React.FC<IColumnToolPanel> = ({
  tableName,
  colDef,
  onColumnVisibleChange,
}) => {
  const [show, setShow] = useState(false);
  const [columns, setColumns] = useState([]);
  const [updateColumns, setUpdateColumns] = useState(false);
  const toolPanelRef = useRef<HTMLDivElement | null>(null);
  const handleClickOutside = (event: any) => {
    if (!toolPanelRef?.current?.contains(event.target)) {
      try {
        event.target.click();
      } catch (e) {
        console.error(e);
      }
      setShow(false);
    }
  };
  useEffect(() => {
    if (show) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show]);
  useEffect(() => {
    setColumns(JSON.parse(localStorage.getItem(tableName) || '[]'));
  }, [updateColumns]);

  const onChangeVisibility = (visible: boolean, colID: string) => {
    onColumnVisibleChange(visible, colID);
    setUpdateColumns(!updateColumns);
  };
  return (
    <div className="column-tool-panel__container" ref={toolPanelRef}>
      <Button
        className={classnames('column-tool-panel__btn', { show })}
        type="button"
        mode="secondary"
        onClick={() => setShow(!show)}
      >
        <SettingsIcon />
      </Button>
      {show && (
        <div className="column-tool-panel__items-wrapper">
          <div>
            <div className="column-tool-panel__items-content">
              {columns.map((params: IStoreColDef) => (
                <div className="column-tool-panel__item-row">
                  <Checkbox
                    checkedValue={params[1]}
                    setCheckedValue={onChangeVisibility}
                    label=""
                    disabled={
                      params[1] && columns.filter(p => p[1]).length === 1
                    }
                    id={params[0]}
                  />
                  <div className="column-tool-panel__item-text">
                    {colDef.find(def => def.colId === params[0])?.headerName}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
