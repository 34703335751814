import * as React from 'react';

import {
  CompareCellComparator,
  CompareCellFilterGetter,
  CompareCellRenderer,
} from 'Components/AgGridTable/CompareCellRenderer/compareCellRenderer';
import { StatusRenderer } from 'Components/AgGridTable/StatusRenderer/statusRenderer';

import { formatThreeDigits, formatTwoDigits } from 'utils';
import { isNumber } from '../../../../utils';

export const templateRowsNumber = 5;
const compareCellColumnDefs = {
  cellRenderer: CompareCellRenderer,
  filterValueGetter: CompareCellFilterGetter,
  comparator: CompareCellComparator,
};
export const wellfieldsTableColumnDefs = [
  {
    colId: '0',
    headerName: 'Название',
    field: 'well_name',
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '1',
    headerName: 'Дебит нефти, т\u2060/\u2060сут',
    field: 'oil_flow_rate',
    ...compareCellColumnDefs,
  },
  {
    colId: '2',
    headerName: 'Дебит жидкости, м\u00B3\u2060/\u2060сут',
    field: 'fluid_flow_rate',
    ...compareCellColumnDefs,
  },
  {
    colId: '3',
    headerName: 'Дебит газа, тыс.м\u00B3\u2060/\u2060сут',
    field: 'gas_flow_rate',
    ...compareCellColumnDefs,
  },
  {
    colId: '4',
    headerName: 'Лин. давление, атм',
    field: 'pressure',
    ...compareCellColumnDefs,
  },
  {
    colId: '5',
    headerName: 'Офсет, мин',
    field: 'offset_time',
    minWidth: 140,
    ...compareCellColumnDefs,
  },
  {
    colId: '6',
    headerName: 'Длит. интервала работы, мин',
    field: 'work_time',
    ...compareCellColumnDefs,
  },
  {
    colId: '7',
    headerName: 'Длит. интервала накопления, мин',
    field: 'accumulation_time',
    ...compareCellColumnDefs,
  },
  {
    colId: '8',
    headerName: 'Частота работы, Гц',
    field: 'control_freq',
    ...compareCellColumnDefs,
  },
  {
    colId: '9',
    headerName: 'Расход АГ, тыс.м\u00B3\u2060/\u2060сут',
    field: 'control_flow',
    ...compareCellColumnDefs,
  },
  {
    colId: '10',
    headerName: 'Мощность, кВт',
    field: 'power',
    ...compareCellColumnDefs,
  },
  {
    colId: '11',
    headerName: 'Перепад буферного давления, атм',
    field: 'well_head_pressure_drop',
    minWidth: 140,
    ...compareCellColumnDefs,
  },
  {
    colId: '12',
    headerName: 'Перепад затрубного давления, атм',
    field: 'annular_pressure_drop',
    minWidth: 140,
    ...compareCellColumnDefs,
  },
  {
    colId: '13',
    headerName: 'Статус',
    field: 'current_state',
    cellRenderer: StatusRenderer,
    initialWidth: 120,
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '14',
    headerName: 'Способ эксплуатации',
    field: 'operation_type',
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '15',
    headerName: 'Режим работы',
    field: 'control_type',
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '16',
    headerName: 'Интервал работы, мин',
    field: 'interval',
    ...compareCellColumnDefs,
  },
  {
    colId: '17',
    headerName: 'Частота накопления, Гц',
    field: 'accumulation_freq',
    ...compareCellColumnDefs,
  },
];

export const wellfieldsMOAvgRow = ({
  well_name,
  operation_type,
  control_type,
  current_state,
  compare_offset_time,
  compare_oil_flow_rate,
  compare_fluid_flow_rate,
  compare_gas_flow_rate,
  compare_pressure,
  compare_control,
  compare_power,
  compare_work_time,
  compare_accumulation_time,
  compare_accumulation_control,
  compare_annular_pressure_drop,
  compare_well_head_pressure_drop,
}: any) => {
  return {
    current_state,
    well_name,
    operation_type,
    control_type,
    offset_time: {
      value: formatTwoDigits(compare_offset_time.fact),
      abs: formatTwoDigits(compare_offset_time.abs),
      rel: formatTwoDigits(compare_offset_time.rel),
      unit: 'мин',
    },
    oil_flow_rate: {
      value: formatThreeDigits(compare_oil_flow_rate?.fact?.t_per_day),
      abs: formatThreeDigits(compare_oil_flow_rate?.abs?.t_per_day),
      rel: formatThreeDigits(compare_oil_flow_rate?.rel),
      unit: 'т/сут',
      cssClass:
        // eslint-disable-next-line no-nested-ternary
        isNumber(compare_oil_flow_rate?.abs?.t_per_day) &&
        compare_oil_flow_rate?.abs?.t_per_day !== 0
          ? compare_oil_flow_rate?.abs?.t_per_day > 0
            ? 'green'
            : 'red'
          : undefined,
    },
    fluid_flow_rate: {
      value: formatThreeDigits(compare_fluid_flow_rate?.fact?.m3_per_day),
      abs: formatThreeDigits(compare_fluid_flow_rate?.abs?.m3_per_day),
      rel: formatThreeDigits(compare_fluid_flow_rate?.rel),
      unit: 'м3/сут',
    },
    gas_flow_rate: {
      value: formatThreeDigits(
        compare_gas_flow_rate?.fact?.thousand_m3_per_day,
      ),
      abs: formatThreeDigits(compare_gas_flow_rate?.abs?.thousand_m3_per_day),
      rel: formatThreeDigits(compare_gas_flow_rate?.rel),
      unit: 'тыс. м3/сут',
    },
    pressure: {
      value: formatThreeDigits(compare_pressure?.fact?.atm),
      abs: formatThreeDigits(compare_pressure?.abs?.atm),
      rel: formatThreeDigits(compare_pressure?.rel),
      unit: 'атм',
      cssClass:
        // eslint-disable-next-line no-nested-ternary
        isNumber(compare_pressure?.abs?.atm) && compare_pressure?.abs?.atm !== 0
          ? compare_pressure?.abs?.atm > 0
            ? 'red'
            : 'green'
          : undefined,
    },
    control_freq: {
      value: compare_control?.fact?.hertz
        ? formatThreeDigits(compare_control?.fact?.hertz)
        : null,
      abs: compare_control?.fact?.hertz
        ? formatThreeDigits(compare_control?.abs?.hertz)
        : null,
      rel: compare_control?.fact?.hertz
        ? formatThreeDigits(compare_control?.rel)
        : null,
      unit: 'Гц',
    },
    accumulation_freq: {
      value: formatThreeDigits(compare_accumulation_control?.fact?.hertz),
      abs: formatThreeDigits(compare_accumulation_control?.abs?.hertz),
      rel: formatThreeDigits(compare_accumulation_control?.rel),
      unit: 'Гц',
    },
    work_time: {
      value: formatThreeDigits(compare_work_time?.fact),
      abs: formatThreeDigits(compare_work_time?.abs),
      rel: formatThreeDigits(compare_work_time?.rel),
      unit: 'мин',
    },
    accumulation_time: {
      value: formatThreeDigits(compare_accumulation_time?.fact),
      abs: formatThreeDigits(compare_accumulation_time?.abs),
      rel: formatThreeDigits(compare_accumulation_time?.rel),
      unit: 'мин',
    },
    control_flow: {
      value:
        operation_type === 'Газлифт'
          ? formatThreeDigits(compare_control?.fact?.thousand_m3_per_day)
          : null,
      abs:
        operation_type === 'Газлифт' &&
        formatThreeDigits(compare_control?.abs?.thousand_m3_per_day),
      rel:
        operation_type === 'Газлифт' && formatThreeDigits(compare_control?.rel),
      unit: 'тыс. м3/сут',
      cssClass:
        // eslint-disable-next-line no-nested-ternary
        operation_type === 'Газлифт' &&
        isNumber(compare_control?.abs?.thousand_m3_per_day) &&
        compare_control?.abs?.thousand_m3_per_day !== 0
          ? compare_control?.abs?.thousand_m3_per_day > 0
            ? 'red'
            : 'green'
          : undefined,
    },
    power: {
      value: compare_power?.fact?.kW
        ? formatThreeDigits(compare_power.fact.kW)
        : undefined,
      abs: compare_power?.fact?.kW && formatTwoDigits(compare_power?.abs?.kW),
      rel: compare_power?.fact?.kW && formatTwoDigits(compare_power?.rel),
      unit: 'кВт',
      cssClass:
        // eslint-disable-next-line no-nested-ternary
        isNumber(compare_power?.abs?.kW) && compare_power?.abs?.kW !== 0
          ? compare_power?.abs?.kW > 0
            ? 'red'
            : 'green'
          : undefined,
    },
    interval: {
      value:
        operation_type === 'Время' && compare_control?.fact?.min !== undefined
          ? formatThreeDigits(compare_control.fact.min)
          : undefined,
      abs:
        operation_type === 'Время' &&
        formatTwoDigits(compare_control?.abs?.min),
      rel: operation_type === 'Время' && formatTwoDigits(compare_control?.rel),
      unit: 'мин',
    },
    annular_pressure_drop: {
      value: compare_annular_pressure_drop
        ? formatTwoDigits(compare_annular_pressure_drop.fact.atm)
        : undefined,
      abs: compare_annular_pressure_drop
        ? formatTwoDigits(compare_annular_pressure_drop.abs.atm)
        : undefined,
      rel: compare_annular_pressure_drop
        ? formatTwoDigits(compare_annular_pressure_drop.rel)
        : undefined,
      unit: 'атм',
    },
    well_head_pressure_drop: {
      value: compare_well_head_pressure_drop
        ? formatTwoDigits(compare_well_head_pressure_drop.fact.atm)
        : undefined,
      abs: compare_well_head_pressure_drop
        ? formatTwoDigits(compare_well_head_pressure_drop.abs.atm)
        : undefined,
      rel: compare_well_head_pressure_drop
        ? formatTwoDigits(compare_well_head_pressure_drop.rel)
        : undefined,
      unit: 'атм',
    },
  };
};
export const wellfieldsTableHead = {
  current_state: 'Статус',
  well_name: 'Название',
  operation_type: {
    title: 'Способ эксплуатации',
    component: (
      <>
        Способ <br /> эксплуатации
      </>
    ),
  },
  control_type: {
    title: 'Режим работы',
    component: (
      <>
        Режим <br /> работы
      </>
    ),
  },
  offset_time: {
    title: 'Тек. офсет',
    component: <>Офсет, мин</>,
  },

  oil_flow_rate: {
    title: 'Дебит нефти',
    component: (
      <>
        Дебит нефти,
        <br /> т/сут
      </>
    ),
  },
  fluid_flow_rate: {
    title: 'Дебит жидкости',
    component: (
      <>
        Дебит жидкости,
        <br /> м3/сут
      </>
    ),
  },
  gas_flow_rate: {
    title: 'Дебит газа',
    component: (
      <>
        Дебит газа,
        <br /> тыс. м3/сут
      </>
    ),
  },
  pressure: {
    title: 'Лин. давление',
    component: (
      <>
        Лин. давление, <br /> атм
      </>
    ),
  },
  control_freq: {
    title: 'Управляющее воздействие',
    component: <>Частота работы, Гц</>,
  },
  accumulation_freq: {
    title: 'Частота накопления',
    component: (
      <>
        Частота <br />
        накопления, Гц
      </>
    ),
  },
  work_time: {
    title: 'Длит. интервала работы',
    component: (
      <>
        Длит. интервала <br />
        работы, мин
      </>
    ),
  },
  accumulation_time: {
    title: 'Длит. интервала накопления',
    component: (
      <>
        Длит. интервала <br />
        накопления, мин
      </>
    ),
  },
  control_flow: {
    title: 'Управляющее воздействие',
    component: (
      <>
        Расход АГ, <br /> тыс. м3/сут
      </>
    ),
  },
  power: {
    title: 'Мощность',
    component: <>Мощность, кВт</>,
  },
  interval: {
    title: 'Интервал работы',
    component: (
      <>
        Интервал работы, <br /> мин
      </>
    ),
  },
};

export const wellsTableItems = ({
  well_name,
  operation_type,
  control_type,
  current_state,
  compare_offset_time,
  compare_oil_flow_rate,
  compare_fluid_flow_rate,
  compare_gas_flow_rate,
  compare_pressure,
  compare_control,
  compare_power,
  compare_work_time,
  compare_accumulation_time,
  compare_accumulation_control,
}: any = {}) => [
  {
    dem: 'current_state',
    value: current_state,
  },
  {
    dem: 'well_name',
    value: well_name,
  },
  {
    dem: 'operation_type',
    value: operation_type,
  },
  {
    dem: 'control_type',
    value: control_type,
  },
  {
    dem: 'offset_time',
    value:
      compare_offset_time?.fact !== undefined
        ? compare_offset_time.fact
        : undefined,
    abs: compare_offset_time?.abs ? compare_offset_time.abs : undefined,
    unit: 'мин',
  },
  {
    dem: 'oil_flow_rate',
    value: compare_oil_flow_rate?.fact?.t_per_day,
    abs: compare_oil_flow_rate?.abs?.t_per_day,
    rel: compare_oil_flow_rate?.rel,
    unit: 'т/сут',
  },
  {
    dem: 'fluid_flow_rate',
    value: compare_fluid_flow_rate?.fact?.m3_per_day,
    abs: compare_fluid_flow_rate?.abs?.m3_per_day,
    rel: compare_fluid_flow_rate?.rel,
    unit: 'м3/сут',
  },
  {
    dem: 'gas_flow_rate',
    value: compare_gas_flow_rate?.fact?.thousand_m3_per_day,
    abs: compare_gas_flow_rate?.abs?.thousand_m3_per_day,
    rel: compare_gas_flow_rate?.rel,
    unit: 'тыс. м3/сут',
  },
  {
    dem: 'pressure',
    value: compare_pressure?.fact?.atm,
    abs: compare_pressure?.abs?.atm,
    rel: compare_pressure?.rel,
    unit: 'атм',
  },
  {
    dem: 'control_freq',
    value:
      operation_type === 'ЭЦН' && compare_control?.fact?.hertz !== undefined
        ? compare_control.fact.hertz
        : undefined,
    abs: operation_type === 'ЭЦН' && compare_control?.abs?.hertz,
    rel: operation_type === 'ЭЦН' && compare_control?.rel,
    unit: 'Гц',
  },
  {
    dem: 'accumulation_freq',
    value:
      compare_accumulation_control?.fact?.hertz !== undefined
        ? compare_accumulation_control.fact.hertz
        : undefined,
    abs:
      compare_accumulation_control?.abs?.hertz !== undefined
        ? compare_accumulation_control.abs.hertz
        : undefined,
    rel: compare_accumulation_control?.rel,
    unit: 'Гц',
  },
  {
    dem: 'work_time',
    value: compare_work_time?.fact,
    abs: compare_work_time?.abs,
    rel: compare_work_time?.rel,
    unit: 'мин',
  },
  {
    dem: 'accumulation_time',
    value: compare_accumulation_time?.fact,
    abs: compare_accumulation_time?.abs,
    rel: compare_accumulation_time?.rel,
    unit: 'мин',
  },
  {
    dem: 'control_flow',
    value:
      operation_type === 'Газлифт' &&
      compare_control?.fact?.thousand_m3_per_day !== undefined
        ? compare_control.fact.thousand_m3_per_day
        : undefined,
    abs:
      operation_type === 'Газлифт' && compare_control?.abs?.thousand_m3_per_day,
    rel: operation_type === 'Газлифт' && compare_control?.rel,
    unit: 'тыс. м3/сут',
  },
  {
    dem: 'power',
    value:
      operation_type === 'ЭЦН' && compare_power?.fact?.kW !== undefined
        ? compare_power.fact.kW
        : undefined,
    abs: operation_type === 'ЭЦН' && compare_power?.abs?.kW,
    rel: operation_type === 'ЭЦН' && compare_power?.rel,
    unit: 'кВт',
  },
  {
    dem: 'control',
    value:
      operation_type === 'Время' && compare_control?.fact?.min !== undefined
        ? compare_control.fact.min
        : undefined,
    abs: operation_type === 'Время' && compare_control?.abs?.min,
    rel: operation_type === 'Время' && compare_control?.rel,
    unit: 'мин',
  },
];
