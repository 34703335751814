import React from 'react';
import { useDispatch } from 'react-redux';

import { components } from 'generated/apiTypes';

import { getExperimentSettings } from 'services/apiRequests';
import { setExperimentSettings } from '../../../../store/taskSlice';

const useGetExperimentSettings = (id: string) => {
  const [data, setData] =
    React.useState<components['schemas']['ExperimentSettings']>();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const getData = async () => {
      try {
        const response = await getExperimentSettings(id);
        dispatch(setExperimentSettings(response.data));
        setData(response.data);
      } catch (e) {
        //
      }
    };

    getData();
  }, [id]);

  return data;
};

export default useGetExperimentSettings;
