import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import { RootState } from './store';
import { ISocketSlice } from './types';

const socketInitialState = initialState as ISocketSlice;

export const socketSlice = createSlice({
  name: 'socket',
  initialState: {
    socketState: socketInitialState.socketState,
  },
  reducers: {
    setSocketState: (
      state,
      action: PayloadAction<ISocketSlice['socketState']>,
    ) => {
      state.socketState = { ...state.socketState, ...action.payload };
    },
    setSocketClear: state => {
      state.socketState = socketInitialState.socketState;
    },
  },
});

export const { setSocketState, setSocketClear } = socketSlice.actions;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const selectSelf = (state: RootState) => state.socket;

export default socketSlice.reducer;
