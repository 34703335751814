import * as React from 'react';
import { useSelector } from 'react-redux';

import AgGridTable from 'Components/AgGridTable';
import { components } from 'generated/apiTypes';
import { selectSolution } from 'store/taskSlice';

import { useGetColumnDefs } from '../../../../../hooks/useGetColumnDefs';
import { TABLE_NAME } from './Table';
import { sinksMOColumnDefs, sinksTableItems } from './utils';

export interface ISinksTableProps {
  sinksUID: any;
}
const SinksAvgTable: React.FC<ISinksTableProps> = ({ sinksUID }) => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOTechModeOptimizationProblem'];
  const [data, setData] = React.useState<any[]>([]);
  const columnDef = useGetColumnDefs(TABLE_NAME, sinksMOColumnDefs);
  const getData = () => {
    const newData: any[] = [];
    solution?.solution?.node_avg_table?.states
      ?.filter(state => sinksUID?.includes(state.node_uid))
      .forEach(state => {
        newData.push(sinksTableItems(state));
      });
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [solution, sinksUID]);

  return (
    <AgGridTable tableName={TABLE_NAME} columnDefs={columnDef} rowData={data} />
  );
};

export default SinksAvgTable;
