import * as React from 'react';
import { useSelector } from 'react-redux';

import { components } from 'generated/apiTypes';
import { selectSolution } from 'store/taskSlice';

import AgGridTable from '../../../../../../../Components/AgGridTable';
import { useGetColumnDefs } from '../../../../../hooks/useGetColumnDefs';
import { TABLE_NAME } from './Table';
import { nodesMOColumnDefs, nodesTableItems } from './utils';

export interface INodesTableProps {
  keyD?: string;
  nodesUID: any;
}
const NodesByStepTable: React.FC<INodesTableProps> = ({ keyD, nodesUID }) => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOTechModeOptimizationProblem'];
  const [data, setData] = React.useState<any[]>([]);
  const columnDef = useGetColumnDefs(TABLE_NAME, nodesMOColumnDefs);
  const getData = () => {
    const newData: any[] = [];
    keyD &&
      solution?.solution?.node_step_table?.time2states[keyD]
        ?.filter(state => nodesUID.includes(state.node_uid))
        .forEach(state => {
          newData.push(nodesTableItems(state));
        });
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [solution, keyD]);

  return (
    <AgGridTable tableName={TABLE_NAME} columnDefs={columnDef} rowData={data} />
  );
};

export default NodesByStepTable;
