import * as React from 'react';

import { formatter } from 'utils';

export const dashboardItems = ({
  oil_flow_rate_avg,
  consumed_electricity,
  consumed_active_gas,
  fluid_flow_rate_avg,
  associated_petroleum_gas,
  water_flow_rate_avg,
  consumed_electricity_per_oil,
  consumed_active_gas_per_oil,
}: any = {}) => [
  {
    dem: 'Сбор нефти',
    value:
      oil_flow_rate_avg !== undefined
        ? formatter.format(oil_flow_rate_avg.t_per_day)
        : oil_flow_rate_avg,
    unit: 'т/сут',
  },
  {
    dem: 'Затраты электроэнергии',
    value:
      consumed_electricity !== undefined
        ? formatter.format(consumed_electricity.kW_h_per_day)
        : consumed_electricity,
    unit: 'кВт·ч/сут',
  },
  {
    dem: 'Расход активного газа',
    value:
      consumed_active_gas !== undefined
        ? formatter.format(consumed_active_gas.thousand_m3_per_day)
        : consumed_active_gas,
    unit: (
      <>
        <span className="unit">
          тыс.м<span className="index">3</span>
        </span>
        {' /сут'}
      </>
    ),
  },
  {
    dem: 'Сбор жидкости',
    value:
      fluid_flow_rate_avg !== undefined
        ? formatter.format(fluid_flow_rate_avg.m3_per_day)
        : fluid_flow_rate_avg,
    unit: (
      <>
        <span className="unit">
          м<span className="index">3</span>
        </span>
        {' /сут'}
      </>
    ),
  },
  {
    dem: 'Сбор ПНГ',
    value:
      associated_petroleum_gas !== undefined
        ? formatter.format(associated_petroleum_gas.thousand_m3_per_day)
        : associated_petroleum_gas,
    unit: (
      <>
        <span className="unit">
          тыс.м<span className="index">3</span>
        </span>
        {' /сут'}
      </>
    ),
  },
  {
    dem: 'Сбор воды',
    value:
      water_flow_rate_avg !== undefined
        ? formatter.format(water_flow_rate_avg.m3_per_day)
        : water_flow_rate_avg,
    unit: (
      <>
        <span className="unit">
          м<span className="index">3</span>
        </span>
        {' /сут'}
      </>
    ),
  },
  {
    dem: 'УРЭ',
    value:
      consumed_electricity_per_oil !== undefined
        ? formatter.format(consumed_electricity_per_oil.value)
        : consumed_electricity_per_oil,
    unit: 'кВт·ч/т',
  },
  {
    dem: 'УРГГ',
    value:
      consumed_active_gas_per_oil !== undefined
        ? formatter.format(consumed_active_gas_per_oil.thousand_m3_per_t)
        : consumed_active_gas_per_oil,
    unit: (
      <>
        <span className="unit">
          тыс.м<span className="index">3</span>
        </span>
        {' /т'}
      </>
    ),
  },
];
