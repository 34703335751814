// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react';

import { smartFormat, timeFormatter } from 'utils';

export const dashboardItems = ({
  calculation_lasting,
  achieved_calculation_accuracy,
  specified_calculation_accuracy,
}: any = {}) => [
  {
    dem: 'Точность расчета',
    value:
      specified_calculation_accuracy &&
      smartFormat(specified_calculation_accuracy),
    unit: 'атм',
  },
  {
    dem: 'Достигнутая точность',
    value:
      achieved_calculation_accuracy &&
      smartFormat(achieved_calculation_accuracy),
    unit: 'атм',
  },
  {
    dem: 'Время выполнения',
    value: calculation_lasting && timeFormatter(calculation_lasting),
  },
];
