import * as React from 'react';
import { useSelector } from 'react-redux';

import AgGridTable from 'Components/AgGridTable';
import { components } from 'generated/apiTypes';
import { selectSolution } from 'store/taskSlice';

import { formatThreeDigits, formatTwoDigits } from 'utils';
import { useGetColumnDefs } from '../../../../../hooks/useGetColumnDefs';
import { TABLE_NAME } from '../index';
import { nodesUAColumnDefs } from './utils';

export interface INodesTableProps {
  step?: number;
  nodeUids: (string | undefined)[];
}

const NodesTable: React.FC<INodesTableProps> = ({ nodeUids }) => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOReservoirBaseProblem'];
  const [data, setData] = React.useState<any[]>([]);
  const columnDef = useGetColumnDefs(TABLE_NAME, nodesUAColumnDefs);
  const getTypeName = (type: components['schemas']['PNOPipelineNodeType']) => {
    switch (type) {
      case 'SOURCE':
        return 'Исток';
      case 'SINK':
        return 'Сток';
      case 'JUNCTION':
        return 'Узел';
      default:
        return '';
    }
  };
  const getData = () => {
    const newData: any[] = [];
    solution?.solution?.node_states.forEach(state => {
      newData.push({
        name: state.name,
        type: getTypeName(state.type),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        oil_flow_rate: formatThreeDigits(state.oil_flow_rate_mass.t_per_day),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fluid_flow_rate: formatThreeDigits(
          state.fluid_flow_rate_volume.m3_per_day,
        ),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        gas_flow_rate: formatThreeDigits(
          state.gas_flow_rate_volume.thousand_m3_per_day,
        ),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pressure: formatTwoDigits(state.pressure.atm),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        temperature: formatTwoDigits(state.temperature.celsius),
      });
    });
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [solution, nodeUids]);

  return (
    <AgGridTable tableName={TABLE_NAME} columnDefs={columnDef} rowData={data} />
  );
};

export default NodesTable;
