import React from 'react';

import { components } from '../../../../../../generated/apiTypes';
import { formatter, smartFormat } from '../../../../../../utils';
import { UseChartData } from '../../Chart/types';
import { seriesColors } from '../../Chart/utils';

const useChartData: UseChartData = (
  wellModel,
  wellOperationType,
  targetParam,
) => {
  const { pressures } = React.useMemo(() => {
    if (wellModel?.model.pressures__atmg) {
      return {
        pressures: wellModel?.model.pressures__atmg?.map(pressure => {
          return formatter.format(pressure);
        }),
      };
    }
    return {};
  }, [wellModel?.model.pressures__atmg, targetParam]);

  return React.useMemo(() => {
    if (pressures) {
      const chartData: { name: string; data: any[] }[] = [];
      let controlActions: any[] =
        (wellModel?.model as Record<string, any>).frequencies__Hz ||
        (wellModel?.model as Record<string, any>).pumping_times__min ||
        (wellModel?.model as Record<string, any>)
          .annular_pressure_drops__atmg ||
        (wellModel?.model as Record<string, any>)
          .gaslift_flows__thos_m3_per_day;
      if (targetParam === 'FREQ')
        controlActions = (wellModel?.model as Record<string, any>)
          .frequencies__Hz;
      else if (targetParam === 'ANNULAR_PRESSURE_DROP')
        controlActions = (wellModel?.model as Record<string, any>)
          .annular_pressure_drops__atmg;
      else if (targetParam === 'WELL_HEAD_PRESSURE_DROP')
        controlActions = (wellModel?.model as Record<string, any>)
          .well_head_pressure_drops__atmg;
      else if (targetParam === 'WORK_FREQ_5D')
        controlActions = (wellModel?.model as Record<string, any>)
          .work_frequencies__Hz;
      else if (targetParam === 'WORK_TIMES_5D')
        controlActions = (wellModel?.model as Record<string, any>)
          .work_times__min;
      else if (targetParam === 'ACCUMULATION_TIMES_5D')
        controlActions = (wellModel?.model as Record<string, any>)
          .accumulation_times__min;
      controlActions?.map((control_action, control_action_ix) => {
        let legendName;
        if (
          wellOperationType === 'ЭЦН' ||
          ((wellOperationType as any) === 'ФЧЗ' && targetParam === 'FREQ') ||
          ((wellOperationType as any) === '5D_ЭЦН' &&
            targetParam === 'WORK_FREQ_5D')
        ) {
          legendName =
            (control_action !== undefined &&
              `${formatter.format(control_action)} Гц`) ||
            '';
        } else if (wellOperationType === 'Газлифт') {
          legendName =
            (control_action !== undefined &&
              `${smartFormat(control_action)} тыс. м3/сут`) ||
            '';
        } else if (
          (wellOperationType as any) === 'ЭЦН редуц.' ||
          targetParam === 'WORK_TIMES_5D' ||
          targetParam === 'ACCUMULATION_TIMES_5D'
        ) {
          legendName =
            (control_action !== undefined &&
              `${smartFormat(control_action)} мин`) ||
            '';
        } else if (
          (wellOperationType as any) === 'ФЧЗ редуц.' ||
          ((wellOperationType as any) === 'ФЧЗ' &&
            targetParam === 'ANNULAR_PRESSURE_DROP') ||
          ((wellOperationType as any) === 'ФЧЗ' &&
            targetParam === 'WELL_HEAD_PRESSURE_DROP')
        ) {
          legendName =
            (control_action !== undefined &&
              `${smartFormat(control_action)} атм`) ||
            '';
        } else if (wellOperationType === 'Фонтан') {
          legendName = 'QP-характеристика';
        }
        return chartData.push({
          name: legendName || '',
          data: pressures.map((pressure, pressure_ix) => {
            if (targetParam) {
              if (targetParam === 'FREQ') {
                return (
                  (
                    wellModel?.model as components['schemas']['GetPermanentAnnularFountainWellModelQueryResult']
                  ).powers__kW &&
                  (
                    wellModel?.model as components['schemas']['GetPermanentAnnularFountainWellModelQueryResult']
                  ).powers__kW[control_action_ix][0][0][pressure_ix]
                );
              }
              if (targetParam === 'ANNULAR_PRESSURE_DROP') {
                return (
                  (
                    wellModel?.model as components['schemas']['GetPermanentAnnularFountainWellModelQueryResult']
                  ).powers__kW &&
                  (
                    wellModel?.model as components['schemas']['GetPermanentAnnularFountainWellModelQueryResult']
                  ).powers__kW[0][0][control_action_ix][pressure_ix]
                );
              }
              if (targetParam === 'WELL_HEAD_PRESSURE_DROP') {
                return (
                  (
                    wellModel?.model as components['schemas']['GetPermanentAnnularFountainWellModelQueryResult']
                  ).powers__kW &&
                  (
                    wellModel?.model as components['schemas']['GetPermanentAnnularFountainWellModelQueryResult']
                  ).powers__kW[0][control_action_ix][0][pressure_ix]
                );
              }
              if (targetParam === 'WORK_FREQ_5D') {
                return (
                  (
                    wellModel?.model as components['schemas']['GetPump5dWellModelQueryResult']
                  ).powers__kW &&
                  (
                    wellModel?.model as components['schemas']['GetPump5dWellModelQueryResult']
                  ).powers__kW[pressure_ix][control_action_ix][0][0][0]
                );
              }
              if (targetParam === 'WORK_TIMES_5D') {
                return (
                  (
                    wellModel?.model as components['schemas']['GetPump5dWellModelQueryResult']
                  ).powers__kW &&
                  (
                    wellModel?.model as components['schemas']['GetPump5dWellModelQueryResult']
                  ).powers__kW[pressure_ix][0][0][control_action_ix][0]
                );
              }
              if (targetParam === 'ACCUMULATION_TIMES_5D') {
                return (
                  (
                    wellModel?.model as components['schemas']['GetPump5dWellModelQueryResult']
                  ).powers__kW &&
                  (
                    wellModel?.model as components['schemas']['GetPump5dWellModelQueryResult']
                  ).powers__kW[pressure_ix][0][0][0][control_action_ix]
                );
              }
              return undefined;
            }
            return (
              (
                wellModel?.model as components['schemas']['GetPeriodicPumpWellModelQueryResult']
              ).powers__kW &&
              (
                wellModel?.model as components['schemas']['GetPeriodicPumpWellModelQueryResult']
              ).powers__kW[control_action_ix][pressure_ix]
            );
          }),
        });
      });

      return {
        labels: pressures,
        datasets: Array.from(chartData).map((el, index) => {
          return {
            label:
              wellOperationType !== 'Фонтан'
                ? `${el.name} [${index + 1}]`
                : el.name,
            data: el.data,
            fill: false,
            backgroundColor: seriesColors[index % 9],
            borderColor: seriesColors[index % 9],
            color: seriesColors[index % 9],
            borderWidth: 1,
            tension: 0.1,
            pointHoverBorderWidth: 0,
            pointRadius: 0,
            pointHitRadius: 0,
            pointBorderWidth: 1,
            pointHoverRadius: 1,
          };
        }),
      };
    }
    return { labels: [], datasets: [] };
  }, [
    pressures,
    (
      wellModel?.model as components['schemas']['GetPeriodicPumpWellModelQueryResult']
    ).powers__kW,
    wellOperationType,
  ]);
};

export default useChartData;
