import * as React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../store/store';
import { IProjectSlice } from '../../store/types';
import NetworkObjectsToggle from './NetworkObjectsToggle';
import { Node } from './types';

interface Props {
  nodes: IProjectSlice['nodes'];
  segments: IProjectSlice['segments'];
  wells: IProjectSlice['wells'];
  physChemPackages: IProjectSlice['physChemPackages'];
  separators: IProjectSlice['separators'];
  injection_wells: IProjectSlice['injection_wells'];
  production_wells: IProjectSlice['production_wells'];
  three_phase_separators: IProjectSlice['three_phase_separators'];
  sinks: IProjectSlice['sinks'];
  pumps: IProjectSlice['pumps'];
  chokes: IProjectSlice['chokes'];
  sources: IProjectSlice['sources'];
  reservoir: IProjectSlice['reservoir'];
}

const isUidStringGuard = (args: {
  end_uid?: string | null;
  start_uid?: string | null;
}): args is { end_uid: string; start_uid: string } =>
  !!args.end_uid && !!args.start_uid;

const NetworkObjects: React.FC<Props> = (props: Props) => {
  const {
    nodes,
    segments,
    wells,
    physChemPackages,
    separators,
    production_wells,
    injection_wells,
    three_phase_separators,
    sinks,
    pumps,
    chokes,
    sources,
    reservoir,
  } = props;

  const nodesById = React.useMemo(() => {
    return nodes?.items.reduce<{ [key: string]: Node }>((acc, cur) => {
      if (cur.uid) {
        acc[cur.uid] = cur;
      }
      return acc;
    }, {});
  }, [nodes]);

  return (
    <div className="network-objects">
      {sources.items.length > 0 && (
        <NetworkObjectsToggle
          title="Истоки"
          type="SOURCE_PPD"
          data={sources?.items}
        />
      )}
      {sinks.items.length > 0 ? (
        <NetworkObjectsToggle
          title="Стоки"
          type="SINK_PPD"
          data={sinks?.items}
        />
      ) : (
        <NetworkObjectsToggle
          title="Стоки"
          type="SINK"
          data={nodes?.items?.filter(({ type }) => type === 'SINK')}
        />
      )}
      <>
        {production_wells?.items.length > 0 && (
          <NetworkObjectsToggle
            title="Добывающие скважины"
            type="PROD_WELL_PPD"
            data={production_wells?.items}
          />
        )}
        {injection_wells?.items.length > 0 && (
          <NetworkObjectsToggle
            title="Нагн. скважины"
            type="INJ_WELL_PPD"
            data={injection_wells?.items}
          />
        )}
      </>
      {wells?.items?.length > 0 && (
        <NetworkObjectsToggle
          title="Скважины"
          type="SOURCE"
          // data={nodes?.items?.filter(({ type }) => type === 'SOURCE')}
          data={wells?.items}
        />
      )}
      <NetworkObjectsToggle
        title="Соединения"
        type="JUNCTION"
        data={nodes?.items?.filter(({ type }) => type === 'JUNCTION')}
      />
      {pumps.items.length > 0 && (
        <NetworkObjectsToggle title="Насосы" type="PUMP" data={pumps?.items} />
      )}
      {chokes.items.length > 0 && (
        <NetworkObjectsToggle
          title="Штуцеры"
          type="CHOKE"
          data={chokes?.items}
        />
      )}
      {separators.length > 0 && (
        <NetworkObjectsToggle
          title="Путевые сепараторы"
          type="EQUIPMENT"
          data={separators?.map(item => ({
            uid: item.id,
            name: item.name || '',
          }))}
        />
      )}
      {three_phase_separators.items.length > 0 && (
        <NetworkObjectsToggle
          title="Трехфазн. сепараторы"
          type="THREE_PHASE_SEPARATOR"
          data={three_phase_separators?.items}
        />
      )}
      <NetworkObjectsToggle
        title="Трубопроводы"
        type="PIPELINE"
        data={segments?.items?.filter(isUidStringGuard).map(segment => ({
          uid: segment.uid,
          name: `${nodesById?.[segment.start_uid as string]?.name} - ${
            nodesById?.[segment.end_uid as string]?.name
          }`,
        }))}
      />
      {reservoir?.uid && (
        <NetworkObjectsToggle
          title="Пласты"
          type="LAYER_PPD"
          data={[reservoir]}
        />
      )}
      <NetworkObjectsToggle
        title="Пакеты ФХС"
        type="PACKAGE"
        data={physChemPackages?.map(item => ({
          uid: item.uid,
          name: item.name || '',
        }))}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  nodes: state.project.nodes,
  segments: state.project.segments,
  wells: state.project.wells,
  physChemPackages: state.project.physChemPackages,
  separators: state.project.separators,
  production_wells: state.project.production_wells,
  injection_wells: state.project.injection_wells,
  three_phase_separators: state.project.three_phase_separators,
  sinks: state.project.sinks,
  pumps: state.project.pumps,
  chokes: state.project.chokes,
  sources: state.project.sources,
  reservoir: state.project.reservoir,
});

export default connect(mapStateToProps)(NetworkObjects);
