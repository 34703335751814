import * as React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import { selectIsActual, setShowResults } from 'store/taskSlice';

import { ReactComponent as NewResultsIcon } from '../../../images/Project/new-results-icon.svg';
import { ReactComponent as ResultsIcon } from '../../../images/Project/results-icon.svg';
import { ReactComponent as SettingsIcon } from '../../../images/Project/settings-icon.svg';
import { RootState } from '../../../store/store';
import { IControlPanel } from '../types';
import ExperimentSelect from './ExperimentSelect';
import TaskStatus from './TaskStatus';

const ControlPanel: React.FC<IControlPanel> = ({
  onSettingsClick,
  activeObjects,
  status,
  showRes,
}) => {
  const dispatch = useDispatch();
  const isActual = useSelector(selectIsActual);

  const showResults = () => {
    dispatch(setShowResults(!showRes));
  };

  return (
    <>
      {[
        'DRAFT',
        'SENT',
        'PREPARING',
        'PREPARED',
        'SOLVING',
        'INTERRUPTING',
      ].includes(status) &&
        !showRes && <TaskStatus />}
      <button
        className={classNames(
          'control-panel__icon-button results',
          showRes && 'view',
        )}
        aria-label=" "
        type="button"
        onClick={showResults}
        disabled={[
          'DRAFT',
          'SENT',
          'PREPARING',
          'PREPARED',
          'SOLVING',
          'INTERRUPTING',
        ].includes(status)}
      >
        <ResultsIcon className="results-icon" />
        {['SOLVED', 'FAILED', 'VALIDATION_ERROR'].includes(status) &&
          !showRes && (
            <NewResultsIcon
              className={classNames(
                'new-results-icon',
                isActual === false &&
                  !['FAILED', 'VALIDATION_ERROR'].includes(status) &&
                  'not-actual',
                ['FAILED', 'VALIDATION_ERROR'].includes(status) && 'error',
              )}
            />
          )}
      </button>
      <ExperimentSelect />
      <button
        className={classNames(
          'control-panel__icon-button',
          showRes && 'close-button',
          activeObjects && 'active-objects',
        )}
        aria-label=" "
        type="button"
        onClick={onSettingsClick}
      >
        {!showRes && <SettingsIcon className="settings-icon" />}
      </button>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  status: state.task.experiment.status,
  showRes: state.task.showResults,
});

export default connect(mapStateToProps)(ControlPanel);
