import { StatusRenderer } from 'Components/AgGridTable/StatusRenderer/statusRenderer';

import { threeDigitsFormatter, twoDigitsFormatter } from 'utils';

export const templateRowsNumber = 5;

export const wellfieldsAvgColumnDefs = [
  {
    colId: '0',
    headerName: 'Название',
    field: 'well_name',
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '1',
    headerName: 'Дебит нефти, т\u2060/\u2060сут',
    field: 'oil_flow_rate',
  },
  {
    colId: '2',
    headerName: 'Дебит жидкости, м\u00B3\u2060/\u2060сут',
    field: 'fluid_flow_rate',
  },
  {
    colId: '3',
    headerName: 'Дебит газа, тыс.м\u00B3\u2060/\u2060сут',
    field: 'gas_flow_rate',
  },
  { colId: '4', headerName: 'Лин. давление, атм', field: 'pressure' },
  { colId: '5', headerName: 'Офсет, мин', field: 'offset_time', minWidth: 140 },
  {
    colId: '6',
    headerName: 'Длит. интервала работы, мин',
    field: 'work_time',
    minWidth: 170,
  },
  {
    colId: '7',
    headerName: 'Длит. интервала накопления, мин',
    field: 'accumulation_time',
    minWidth: 170,
  },
  { colId: '8', headerName: 'Частота работы, Гц', field: 'control_freq' },
  {
    colId: '9',
    headerName: 'Расход АГ, тыс.м\u00B3\u2060/\u2060сут',
    field: 'control_flow',
  },
  { colId: '10', headerName: 'Мощность, кВт', field: 'power' },
  {
    colId: '11',
    headerName: 'Перепад буферного давления, атм',
    field: 'well_head_pressure_drop',
    minWidth: 140,
  },
  {
    colId: '12',
    headerName: 'Перепад затрубного давления, атм',
    field: 'annular_pressure_drop',
    minWidth: 140,
  },
  {
    colId: '13',
    headerName: 'Статус',
    field: 'current_state',
    cellRenderer: StatusRenderer,
    initialWidth: 120,
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '14',
    headerName: 'Способ эксплуатации',
    field: 'operation_type',
    cellClass: 'ag-cell__content-left',
    minWidth: 160,
  },
  {
    colId: '15',
    headerName: 'Режим работы',
    field: 'control_type',
    cellClass: 'ag-cell__content-left',
  },
  { colId: '16', headerName: 'Интервал работы, мин', field: 'interval' },
  {
    colId: '17',
    headerName: 'Частота накопления, Гц',
    field: 'accumulation_freq',
    minWidth: 170,
  },
];

export const compareTableItems = ({
  well_name,
  operation_type,
  control_type,
  calc_current_state,
  preset_current_state,
  calc_avg_pressure,
  preset_avg_pressure,
  pressure_abs_error,
  pressure_rel_error,
  calc_oil_avg_flow_rate,
  preset_oil_avg_flow_rate,
  oil_flow_rate_abs_error,
  oil_flow_rate_rel_error,
  calc_fluid_avg_flow_rate,
  preset_fluid_avg_flow_rate,
  fluid_flow_rate_abs_error,
  fluid_flow_rate_rel_error,
  calc_gas_avg_flow_rate,
  preset_gas_avg_flow_rate,
  gas_flow_rate_abs_error,
  gas_flow_rate_rel_error,
  calc_control,
  preset_control,
  control_abs_error,
  control_rel_error,
  preset_offset_time,
  calc_offset_time,
  offset_time_abs_error,
  calc_work_time__min,
  preset_work_time__min,
  work_time_abs_error__min,
  work_time_rel_error,
  calc_accumulation_time__min,
  preset_accumulation_time__min,
  accumulation_time_abs_error__min,
  accumulation_time_rel_error,
}: any = {}) => [
  {
    dem: 'fact_current_state',
    value: preset_current_state,
  },
  {
    dem: 'base_current_state',
    value: calc_current_state,
  },
  {
    dem: 'well_name',
    value: well_name,
  },
  {
    dem: 'operation_type',
    value: operation_type,
  },
  {
    dem: 'control_type',
    value: control_type,
  },
  {
    dem: 'fact_offset_time',
    value: preset_offset_time && twoDigitsFormatter.format(preset_offset_time),
  },
  {
    dem: 'base_offset_time',
    value: calc_offset_time && twoDigitsFormatter.format(calc_offset_time),
    abs:
      offset_time_abs_error && twoDigitsFormatter.format(offset_time_abs_error),
    unit: 'мин',
  },
  {
    dem: 'fact_pressure',
    value:
      preset_avg_pressure &&
      threeDigitsFormatter.format(preset_avg_pressure.atm),
  },
  {
    dem: 'base_pressure',
    value:
      calc_avg_pressure && threeDigitsFormatter.format(calc_avg_pressure.atm),
    abs:
      pressure_abs_error && twoDigitsFormatter.format(pressure_abs_error.atm),
    rel: pressure_rel_error && twoDigitsFormatter.format(pressure_rel_error),
    unit: 'атм',
  },
  {
    dem: 'fact_fluid_flow_rate',
    value:
      preset_fluid_avg_flow_rate &&
      threeDigitsFormatter.format(preset_fluid_avg_flow_rate.m3_per_day),
  },
  {
    dem: 'base_fluid_flow_rate',
    value:
      calc_fluid_avg_flow_rate &&
      threeDigitsFormatter.format(calc_fluid_avg_flow_rate.m3_per_day),
    abs:
      fluid_flow_rate_abs_error &&
      twoDigitsFormatter.format(fluid_flow_rate_abs_error.m3_per_day),
    rel:
      fluid_flow_rate_rel_error &&
      twoDigitsFormatter.format(fluid_flow_rate_rel_error),
    unit: 'м3/сут',
  },
  {
    dem: 'fact_gas_flow_rate',
    value:
      preset_gas_avg_flow_rate &&
      threeDigitsFormatter.format(preset_gas_avg_flow_rate.thousand_m3_per_day),
  },
  {
    dem: 'base_gas_flow_rate',
    value:
      calc_gas_avg_flow_rate &&
      threeDigitsFormatter.format(calc_gas_avg_flow_rate.thousand_m3_per_day),
    abs:
      gas_flow_rate_abs_error &&
      twoDigitsFormatter.format(gas_flow_rate_abs_error.thousand_m3_per_day),
    rel:
      gas_flow_rate_rel_error &&
      twoDigitsFormatter.format(gas_flow_rate_rel_error),
    unit: 'тыс. м3/сут',
  },
  {
    dem: 'fact_oil_flow_rate',
    value:
      preset_oil_avg_flow_rate &&
      threeDigitsFormatter.format(preset_oil_avg_flow_rate.t_per_day),
  },
  {
    dem: 'base_oil_flow_rate',
    value:
      calc_oil_avg_flow_rate &&
      threeDigitsFormatter.format(calc_oil_avg_flow_rate.t_per_day),
    abs:
      oil_flow_rate_abs_error &&
      twoDigitsFormatter.format(oil_flow_rate_abs_error.t_per_day),
    rel:
      oil_flow_rate_rel_error &&
      twoDigitsFormatter.format(oil_flow_rate_rel_error),
    unit: 'т/сут',
  },

  {
    dem: 'fact_control_freq',
    value:
      operation_type === 'ЭЦН' && preset_control
        ? threeDigitsFormatter.format(preset_control.hertz)
        : undefined,
  },
  {
    dem: 'base_control_freq',
    value:
      operation_type === 'ЭЦН' && calc_control
        ? threeDigitsFormatter.format(calc_control.hertz)
        : undefined,
    abs:
      operation_type === 'ЭЦН' && control_abs_error
        ? twoDigitsFormatter.format(control_abs_error.hertz)
        : 0,
    rel:
      operation_type === 'ЭЦН' && control_rel_error
        ? twoDigitsFormatter.format(control_rel_error)
        : 0,
    unit: 'Гц',
  },
  {
    dem: 'fact_control_flow',
    value:
      operation_type === 'Газлифт' && preset_control
        ? threeDigitsFormatter.format(preset_control.thousand_m3_per_day)
        : undefined,
  },
  {
    dem: 'base_control_flow',
    value:
      operation_type === 'Газлифт' && calc_control
        ? threeDigitsFormatter.format(calc_control.thousand_m3_per_day)
        : undefined,
    abs:
      operation_type === 'Газлифт' && control_abs_error
        ? twoDigitsFormatter.format(control_abs_error.thousand_m3_per_day)
        : 0,
    rel:
      operation_type === 'Газлифт' && control_rel_error
        ? twoDigitsFormatter.format(control_rel_error)
        : 0,
    unit: 'тыс. м3/сут',
  },
  {
    dem: 'fact_work_time',
    value:
      preset_work_time__min && twoDigitsFormatter.format(preset_work_time__min),
  },
  {
    dem: 'base_work_time',
    value:
      calc_work_time__min && twoDigitsFormatter.format(calc_work_time__min),
    abs:
      work_time_abs_error__min &&
      twoDigitsFormatter.format(work_time_abs_error__min),
    rel: work_time_rel_error && twoDigitsFormatter.format(work_time_rel_error),
    unit: 'мин',
  },
  {
    dem: 'fact_accumulation_time',
    value:
      preset_accumulation_time__min &&
      twoDigitsFormatter.format(preset_accumulation_time__min),
  },
  {
    dem: 'base_accumulation_time',
    value:
      calc_accumulation_time__min &&
      twoDigitsFormatter.format(calc_accumulation_time__min),
    abs:
      accumulation_time_abs_error__min &&
      twoDigitsFormatter.format(accumulation_time_abs_error__min),
    rel:
      accumulation_time_rel_error &&
      twoDigitsFormatter.format(accumulation_time_rel_error),
    unit: 'мин',
  },
];
