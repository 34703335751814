import * as React from 'react';

import { components } from 'generated/apiTypes';

import { formatThreeDigits, formatTwoDigits } from 'utils';
import {
  CompareCellComparator,
  CompareCellFilterGetter,
  CompareCellRenderer,
} from '../../../../../../../Components/AgGridTable/CompareCellRenderer/compareCellRenderer';

export const templateRowsNumber = 5;
const compareCellColumnDefs = {
  cellRenderer: CompareCellRenderer,
  filterValueGetter: CompareCellFilterGetter,
  comparator: CompareCellComparator,
};
export const nodesMOColumnDefs = [
  {
    colId: '0',
    headerName: 'Название',
    field: 'name',
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '1',
    headerName: 'Тип',
    field: 'type',
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '2',
    headerName: 'Расход нефти, т\u2060/\u2060сут',
    field: 'oil_flow_rate',
    ...compareCellColumnDefs,
  },
  {
    colId: '3',
    headerName: 'Расход жидкости, м\u00B3\u2060/\u2060сут',
    field: 'fluid_flow_rate',
    ...compareCellColumnDefs,
  },
  {
    colId: '4',
    headerName: 'Расход газа, тыс.м\u00B3\u2060/\u2060сут',
    field: 'gas_flow_rate',
    ...compareCellColumnDefs,
  },
  {
    colId: '5',
    headerName: 'Давление, атм',
    field: 'pressure',
    ...compareCellColumnDefs,
  },
  {
    colId: '6',
    headerName: 'Температура, ˚C',
    field: 'temperature',
    ...compareCellColumnDefs,
  },
];
export const nodesTableHead = {
  name: 'Название',
  type: {
    title: 'Тип',
    component: <>Тип</>,
  },
  oil_flow_rate: {
    title: 'Расход нефти',
    component: (
      <>
        Расход нефти,
        <br /> т/сут
      </>
    ),
  },
  fluid_flow_rate: {
    title: 'Расход жидкости',
    component: (
      <>
        Расход жидкости,
        <br /> м<span className="sup">3</span>/сут
      </>
    ),
  },
  gas_flow_rate: {
    title: 'Расход газа',
    component: (
      <>
        Расход газа, <br />
        тыс.м<span className="sup">3</span>/сут
      </>
    ),
  },
  pressure: {
    title: 'Давление',
    component: (
      <>
        Давление, <br />
        атм
      </>
    ),
  },
  temperature: {
    title: 'Температура',
    component: (
      <>
        Температура,
        <br />
        ˚C
      </>
    ),
  },
};

const getTypeName = (type: components['schemas']['PNOPipelineNodeType']) => {
  switch (type) {
    case 'SOURCE':
      return 'Исток';
    case 'SINK':
      return 'Сток';
    case 'JUNCTION':
      return 'Узел';
    default:
      return '';
  }
};

export const nodesTableItems = ({
  name,
  type,
  oil_flow_rate_mass,
  oil_flow_rate_mass_abs,
  oil_flow_rate_mass_rel,
  fluid_flow_rate_volume,
  fluid_flow_rate_volume_abs,
  fluid_flow_rate_volume_rel,
  gas_flow_rate_volume,
  gas_flow_rate_volume_abs,
  gas_flow_rate_volume_rel,
  pressure,
  pressure_abs,
  pressure_rel,
  temperature,
  temperature__abs,
  temperature__rel,
}: any = {}) => {
  return {
    name,
    type: getTypeName(type),
    oil_flow_rate: {
      value: formatThreeDigits(oil_flow_rate_mass.t_per_day),
      abs: formatTwoDigits(oil_flow_rate_mass_abs?.t_per_day),
      rel: formatTwoDigits(oil_flow_rate_mass_rel),
      unit: 'т/сут',
    },
    fluid_flow_rate: {
      value: formatThreeDigits(fluid_flow_rate_volume.m3_per_day),
      abs: formatTwoDigits(fluid_flow_rate_volume_abs?.m3_per_day),
      rel: formatTwoDigits(fluid_flow_rate_volume_rel),
      unit: 'м\u00B3\u2060/\u2060сут',
    },
    gas_flow_rate: {
      value: formatThreeDigits(gas_flow_rate_volume.thousand_m3_per_day),
      abs: formatTwoDigits(gas_flow_rate_volume_abs?.thousand_m3_per_day),
      rel: formatTwoDigits(gas_flow_rate_volume_rel),
      unit: 'тыс.м\u00B3\u2060/\u2060сут',
    },
    pressure: {
      value: formatThreeDigits(pressure.atm),
      abs: formatTwoDigits(pressure_abs?.atm),
      rel: formatTwoDigits(pressure_rel),
      unit: 'атм',
    },
    temperature: {
      value: formatThreeDigits(temperature.celsius),
      abs: formatTwoDigits(temperature__abs?.celsius),
      rel: formatTwoDigits(temperature__rel),
      unit: '℃',
    },
  };
};
