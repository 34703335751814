import * as React from 'react';
import { useSelector } from 'react-redux';

import AgGridTable from 'Components/AgGridTable';
import { components } from 'generated/apiTypes';
import { selectSolution } from 'store/taskSlice';

import { formatThreeDigits, formatTwoDigits } from 'utils';
import { useGetColumnDefs } from '../../../../../hooks/useGetColumnDefs';
import { ISegmentsTableProps } from '../../../../types';
import { TABLE_NAME } from './index';
import { segmentsUAColumnDefs } from './utils';

const SegmentsAvgTable: React.FC<ISegmentsTableProps> = () => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOPeriodicNodalAnalysisProblem'];
  const [data, setData] = React.useState<any[]>([]);
  const columnDef = useGetColumnDefs(TABLE_NAME, segmentsUAColumnDefs);
  const getData = () => {
    const newData: any = [];
    solution?.solution?.segment_avg_table?.states.forEach(segment => {
      newData.push({
        start_node_name: segment?.start_node_name,
        end_node_name: segment?.end_node_name,

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        p_in: formatTwoDigits(segment.pressure_in?.atm),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dp_dl: formatThreeDigits(segment.dp_dl?.atm_per_km),
        dt_dl:
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          formatThreeDigits(segment.dt_dl?.celsius_per_km),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        oil_flow_rate: formatThreeDigits(segment.oil_flow_rate_mass?.t_per_day),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fluid_flow_rate: formatThreeDigits(
          segment.fluid_flow_rate_volume?.m3_per_day,
        ),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        gas_flow_rate: formatThreeDigits(
          segment.gas_flow_rate_volume?.thousand_m3_per_day,
        ),
        fluid_velocity: formatThreeDigits(segment.fluid_velocity?.m_per_second),
        gas_velocity: formatThreeDigits(segment.gas_velocity?.m_per_second),
      });
    });
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [solution]);

  return (
    <AgGridTable tableName={TABLE_NAME} columnDefs={columnDef} rowData={data} />
  );
};

export default SegmentsAvgTable;
