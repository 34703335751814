import { ChartOptions } from 'chart.js';

import { ChartType } from '../WellModel/types';

export const seriesColors = [
  '#019DDC',
  '#FF72BE',
  '#FAB400',
  '#23D692',
  '#FA8700',
  '#F9D100',
  '#F153FF',
  '#644BA5',
  '#FA3C00',
];

export const getOptions = (chartType: ChartType): ChartOptions => {
  const getXAxisTitleByType = () => {
    if (chartType === 'Head') return 'Подача, м3/сут';
    return 'Р лин, атм';
  };

  return {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        align: 'start',
        labels: {
          pointStyle: 'line',
          usePointStyle: true,
          filter: item => item.text !== '',
        },
        title: {
          display: true,
          text: '',
          position: 'start',
          color: '#aaa',
          font: {
            family: 'Inter, sans-serif',
            size: 10,
          },
          padding: { top: 10, bottom: 6 },
        },
      },
    },
    scales: {
      y: {
        grid: {
          display: true,
          borderWidth: 0,
          drawBorder: false,
        },
        title: {
          display: true,
          text: 'Напор, м',
          color: '#aaa',
          font: {
            family: 'Inter, sans-serif',
            size: 10,
          },
          padding: { top: 0, bottom: 4 },
        },
        ticks: {
          padding: 0,
          color: '#999999',
          font: {
            size: 10,
            family: 'Inter, sans-serif',
          },
        },
      },
      y1: {
        position: 'right',
        grid: {
          display: false,
          borderWidth: 0,
          drawBorder: false,
        },
        title: {
          display: true,
          text: 'Эффективность, %',
          color: '#aaa',
          font: {
            family: 'Inter, sans-serif',
            size: 10,
          },
          padding: { top: 0, bottom: 4 },
        },
        ticks: {
          padding: 0,
          color: '#999999',
          font: {
            size: 10,
            family: 'Inter, sans-serif',
          },
        },
      },
      x: {
        grid: {
          display: true,
          borderDash: [6, 3],
          borderWidth: 0,
          drawBorder: false,
        },
        title: {
          display: true,
          text: getXAxisTitleByType(),
          color: '#aaa',
          font: {
            family: 'Inter, sans-serif',
            size: 10,
          },
          padding: { top: 4, bottom: 0 },
        },
        ticks: {
          padding: 0,
          color: '#999999',
          font: {
            size: 10,
            family: 'Inter, sans-serif',
          },
        },
      },
    },
  };
};
