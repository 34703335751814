import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import classNames from 'classnames';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';

import { ReactComponent as DeleteIcon } from '../../images/Project/icn-delete-project.svg';
import { ReactComponent as PointsIcon } from '../../images/Projects/icn-points.svg';
import {
  selectLastProjectsState,
  setLastProjectsState,
  setShowDialog,
} from '../../store/commonSlice';
import { shortenTitle } from '../../utils';
import MenuList from '../Project/ProjectHeader/menuList';
import { ProjectRowPropsType } from './types';

import './style.scss';

const maxProjectNameLength = 50;

const ProjectRow: React.FC<ProjectRowPropsType> = ({
  history,
  id,
  index,
  name,
  date,
  searchValue,
  setSearchValue,
}) => {
  const dispatch = useDispatch();
  const [activeMenu, setActiveMenu] = React.useState<boolean>(false);
  const [canScroll, setCanScroll] = React.useState<boolean>(false);
  const controlRef = React.useRef<HTMLButtonElement>(null);
  const rowRef = React.useRef<HTMLButtonElement>(null);
  const lastProjectsState = useSelector(selectLastProjectsState);

  const onRowClick = () => {
    dispatch(
      setLastProjectsState({
        lastSearchValue: searchValue,
        lastProjectId: id,
        lastProjectIndex: index,
      }),
    );
    history.push(`/project/${id}`);
  };
  const toggleMenu = () => {
    setActiveMenu(!activeMenu);
  };
  const closeMenu = () => {
    setActiveMenu(false);
  };
  const onMenuClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    toggleMenu();
  };
  const onDeleteProjectClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(
      setShowDialog({
        dialogType: 'DELETE_CONFIRM',
        objectType: 'PROJECT',
        objectName: shortenTitle(name, maxProjectNameLength),
        objectId: id,
      }),
    );
    closeMenu();
  };

  React.useEffect(() => {
    if (lastProjectsState) {
      if (lastProjectsState?.lastSearchValue)
        setSearchValue(lastProjectsState?.lastSearchValue);
      else setCanScroll(true);
      if (lastProjectsState?.lastProjectId === '')
        dispatch(setLastProjectsState(null));
    }
  }, [lastProjectsState]);

  React.useEffect(() => {
    if (
      lastProjectsState?.lastProjectIndex === index &&
      id === lastProjectsState?.lastProjectId
    )
      setCanScroll(true);
  }, [index]);

  React.useEffect(() => {
    if (canScroll) {
      if (
        lastProjectsState?.lastProjectId &&
        id === lastProjectsState?.lastProjectId
      ) {
        rowRef.current?.scrollIntoView();
        dispatch(setLastProjectsState(null));
      }
    }
  }, [canScroll]);

  return (
    <button
      className="project-row"
      type="button"
      onClick={onRowClick}
      ref={rowRef}
    >
      <div className="project-row-name">
        {shortenTitle(name, maxProjectNameLength)}
      </div>
      <div className="project-row-date">
        {format(new Date(date), 'dd MMM yyyy HH:mm', { locale: ru })}
      </div>
      <button
        className={classNames(
          'project-row__menu-button',
          activeMenu && 'active',
        )}
        type="button"
        ref={controlRef}
        onClick={onMenuClick}
      >
        <PointsIcon className="project-row__points-icon" />
      </button>
      {activeMenu && (
        <MenuList
          closeMenu={closeMenu}
          controlRef={controlRef}
          className="project-row__menu-list"
        >
          <button
            type="button"
            className="project-header__menu-list-item"
            onClick={onDeleteProjectClick}
          >
            <div className="project-header__menu-list-item-delete">
              <DeleteIcon className="project-header__icon-delete" />
              Удалить проект
            </div>
          </button>
        </MenuList>
      )}
    </button>
  );
};

export default withRouter(ProjectRow);
