import React from 'react';

import { ReactComponent as CloseIcon } from 'images/Project/close-icon.svg';
import loader from 'images/Project/loader_big.gif';

import Button from '../Button';
import Checkbox from '../Checkbox';

import './style.scss';

interface IExportResultsWindowProps {
  closeWindow(): void;
  exportResults(dynamic: boolean): void;
  progress?: boolean;
}

const DeleteConfirmationWindow: React.FC<IExportResultsWindowProps> = ({
  exportResults,
  closeWindow,
  progress,
}) => {
  const [dynamic, setDynamic] = React.useState<boolean>(false);

  const onBtnClick = () => {
    exportResults(dynamic);
  };

  React.useEffect(() => {
    const onKeyDownHandler = (e: React.KeyboardEvent) => {
      const { key } = e;
      if (key === 'Enter') {
        onBtnClick();
        closeWindow();
      }
    };
    document.addEventListener('keydown', onKeyDownHandler as any);
    return () => {
      document.removeEventListener('keydown', onKeyDownHandler as any);
    };
  }, []);
  return (
    <div className="dialog-window-background">
      <div className="dialog-window-container">
        <div className="dialog-window-wrapper">
          {!progress && (
            <button
              type="button"
              className="dialog-window-close-button"
              onClick={closeWindow}
            >
              <CloseIcon />
            </button>
          )}
          <div className="dialog-window-title">
            Экспорт результатов расчетов
          </div>
          {!progress ? (
            <>
              <div className="dialog-window-message cancel-task-message">
                В рамках экспорта будет создан Excel-файл (.xlsx), включающий
                результаты базового расчета и оптимизации режима
              </div>
              <div className="dialog-window-options">
                <Checkbox
                  label="Включить в отчет результаты по шагам"
                  checkedValue={dynamic}
                  id="export-result-dynamic"
                  setCheckedValue={setDynamic}
                />
              </div>
              <div className="dialog-window-buttons-wrapper">
                <Button
                  type="button"
                  className="dialog-window-delete-button"
                  onClick={onBtnClick}
                >
                  Экспортировать
                </Button>
                <Button type="button" mode="secondary" onClick={closeWindow}>
                  Отмена
                </Button>
              </div>
            </>
          ) : (
            <>
              <img
                className="dialog-window__loader"
                src={loader}
                alt="Загрузка..."
              />
              <div className="dialog-window__loader-text">
                Подготовка файла...
                <br />
                Скачивание произойдет автоматически
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationWindow;
