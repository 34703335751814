import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { withRouter } from 'react-router-dom';

import classNames from 'classnames';

import { ReactComponent as BackIcon } from '../../../images/Project/icn-back.svg';
import { ReactComponent as DeleteIcon } from '../../../images/Project/icn-delete-project.svg';
import { ReactComponent as ExportIcon } from '../../../images/Project/icn-export.svg';
import { ReactComponent as MenuIcon } from '../../../images/Project/menu-icon.svg';
import { setShowDialog } from '../../../store/commonSlice';
import { selectProjectName } from '../../../store/projectSlice';
import {
  selectExperimentStatus,
  selectIsActual,
  selectTaskId,
} from '../../../store/taskSlice';
import { IMenuPanel } from '../types';
import MenuList from './menuList';

const MenuPanel: React.FC<IMenuPanel> = ({ title, history }) => {
  const dispatch = useDispatch();
  const params = useParams() as { id: string };
  const projectName = useSelector(selectProjectName);
  const [activeMenu, setActiveMenu] = React.useState<boolean>(false);
  const controlRef = React.useRef<HTMLButtonElement>(null);
  const isActual = useSelector(selectIsActual);
  const taskId = useSelector(selectTaskId);
  const status = useSelector(selectExperimentStatus);
  const toggleMenu = () => {
    setActiveMenu(!activeMenu);
  };
  const closeMenu = () => {
    setActiveMenu(false);
  };

  const exportProject = async () => {
    setActiveMenu(false);
    dispatch(
      setShowDialog({
        dialogType: 'CHOOSE_EXPORT',
        objectName: title,
      }),
    );
  };

  const onDeleteProject = () => {
    dispatch(
      setShowDialog({
        dialogType: 'DELETE_CONFIRM',
        objectType: 'PROJECT',
        objectName: projectName,
        objectId: params.id,
      }),
    );
  };

  const onResetOffsets = () => {
    dispatch(
      setShowDialog({
        dialogType: 'RESET_OFFSETS',
      }),
    );
  };

  const onExportResults = () => {
    dispatch(
      setShowDialog({
        dialogType: 'EXPORT_RESULTS',
      }),
    );
  };
  const onRecoverFactState = () => {
    dispatch(
      setShowDialog({
        dialogType: 'RECOVER_FACT_STATE',
      }),
    );
  };
  return (
    <div>
      <div className="d-flex">
        <button
          className={classNames(
            'project-header__menu-icon-wrapper',
            activeMenu && 'active',
          )}
          type="button"
          ref={controlRef}
          onClick={toggleMenu}
        >
          <MenuIcon className="project-header__menu-icon" />
        </button>
        <h1 className="project-header__title">{title}</h1>
      </div>
      {activeMenu && (
        <MenuList closeMenu={closeMenu} controlRef={controlRef}>
          <button
            type="button"
            className="project-header__menu-list-item"
            onClick={() => {
              history.push('/');
            }}
          >
            <div className="project-header__menu-list-item-delete">
              <BackIcon className="project-header__icon-delete" />
              <div style={{ marginLeft: '-16px' }}>К списку проектов</div>
            </div>
          </button>
          <div className="project-header__menu-list-separator" />
          <button
            className="project-header__menu-list-item"
            type="button"
            onClick={onResetOffsets}
          >
            <div style={{ marginLeft: '23px' }}>Задать факт. офсеты</div>
          </button>
          <button
            className="project-header__menu-list-item"
            type="button"
            onClick={onRecoverFactState}
          >
            <div style={{ marginLeft: '23px' }}>
              Восстановить факт. состояние
            </div>
          </button>
          <div className="project-header__menu-list-separator" />
          <button
            className="project-header__menu-list-item"
            type="button"
            onClick={exportProject}
          >
            <div className="project-header__menu-list-item-delete">
              <ExportIcon className="project-header__icon-delete" />
              <div style={{ marginLeft: '-16px' }}>Экспортировать проект</div>
            </div>
          </button>
          <button
            className="project-header__menu-list-item"
            type="button"
            onClick={onExportResults}
            disabled={!isActual || !taskId || status !== 'SOLVED'}
          >
            <div className="project-header__menu-list-item-delete">
              <ExportIcon className="project-header__icon-delete" />
              <div style={{ marginLeft: '-16px' }}>
                Экспортировать результаты
              </div>
            </div>
          </button>
          <div className="project-header__menu-list-separator" />
          <button
            type="button"
            className="project-header__menu-list-item"
            onClick={onDeleteProject}
          >
            <div className="project-header__menu-list-item-delete">
              <DeleteIcon className="project-header__icon-delete" />
              <div style={{ marginLeft: '-16px' }}>Удалить проект</div>
            </div>
          </button>
        </MenuList>
      )}
    </div>
  );
};

export default withRouter(MenuPanel);
