import * as React from 'react';
import { useSelector } from 'react-redux';

import { components } from 'generated/apiTypes';
import { selectSolution } from 'store/taskSlice';

import AgGridTable from '../../../../../../../Components/AgGridTable';
import { useGetColumnDefs } from '../../../../../hooks/useGetColumnDefs';
import { ISourcesTableProps } from '../../../../types';
import { TABLE_NAME } from './Table';
import { wellfieldsMOAvgRow, wellfieldsTableColumnDefs } from './utils';

const SourcesByStepTable: React.FC<ISourcesTableProps> = ({ keyD }) => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOTechModeOptimizationProblem'];
  const [data, setData] = React.useState<any[]>([]);
  const columnDef = useGetColumnDefs(TABLE_NAME, wellfieldsTableColumnDefs);
  const getData = () => {
    const newData: any = [];
    keyD &&
      solution?.solution?.well_step_table?.time2states[keyD]?.forEach(state => {
        newData.push(wellfieldsMOAvgRow(state));
      });

    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [solution, keyD]);

  return (
    <AgGridTable tableName={TABLE_NAME} columnDefs={columnDef} rowData={data} />
  );
};

export default SourcesByStepTable;
