import * as React from 'react';

export const templateRowsNumber = 5;

export const equipmentUAColumnDefs = [
  {
    colId: '0',
    headerName: 'Название',
    field: 'name',
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '1',
    headerName: 'Тип',
    field: 'type',
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '2',
    headerName: 'Расход нефти (Вх.), т\u2060/\u2060сут',
    field: 'in_oil_flow_rate',
  },
  {
    colId: '3',
    headerName: 'Расход нефти (Вых.), т\u2060/\u2060сут',
    field: 'out_oil_flow_rate',
  },
  {
    colId: '4',
    headerName: 'Расход жидкости (Вх.), м\u00B3\u2060/\u2060сут',
    field: 'in_fluid_flow_rate',
  },
  {
    colId: '5',
    headerName: 'Расход жидкости (Вых.), м\u00B3\u2060/\u2060сут',
    field: 'out_fluid_flow_rate',
  },
  {
    colId: '6',
    headerName: 'Расход газа (Вх.), тыс.м\u00B3\u2060/\u2060сут',
    field: 'in_gas_flow_rate',
  },
  {
    colId: '7',
    headerName: 'Расход газа (Вых.), тыс.м\u00B3\u2060/\u2060сут',
    field: 'out_gas_flow_rate',
  },
  { colId: '8', headerName: 'Давление (Вх.), атм', field: 'in_pressure' },
  { colId: '9', headerName: 'Давление (Вых.), атм', field: 'out_pressure' },
];

export const equipmentsTableHead = {
  name: 'Название',
  type: {
    title: 'Тип',
    component: <>Тип</>,
  },
  in_oil_flow_rate: {
    title: 'Расход нефти',
    component: (
      <>
        Расход нефти (Вх.),
        <br /> т/сут
      </>
    ),
  },
  out_oil_flow_rate: {
    title: 'Расход нефти',
    component: (
      <>
        Расход нефти (Вых.),
        <br /> т/сут
      </>
    ),
  },
  in_fluid_flow_rate: {
    title: 'Расход жидкости',
    component: (
      <>
        Расход жидкости (Вх.),
        <br /> м<span className="sup">3</span>/сут
      </>
    ),
  },
  out_fluid_flow_rate: {
    title: 'Расход жидкости',
    component: (
      <>
        Расход жидкости (Вых.),
        <br /> м<span className="sup">3</span>/сут
      </>
    ),
  },
  in_gas_flow_rate: {
    title: 'Расход газа',
    component: (
      <>
        Расход газа (Вх.), <br />
        тыс.м<span className="sup">3</span>/сут
      </>
    ),
  },
  out_gas_flow_rate: {
    title: 'Расход газа',
    component: (
      <>
        Расход газа (Вых.), <br />
        тыс.м<span className="sup">3</span>/сут
      </>
    ),
  },
  in_pressure: {
    title: 'Давление',
    component: (
      <>
        Давление (Вх.), <br />
        атм
      </>
    ),
  },
  out_pressure: {
    title: 'Давление',
    component: (
      <>
        Давление (Вых.), <br />
        атм
      </>
    ),
  },
};
