import { ChartOptions } from 'chart.js';
import numeral from 'numeral';

export const seriesColors = [
  '#019DDC',
  '#FF72BE',
  '#FAB400',
  '#23D692',
  '#FA8700',
  '#F9D100',
  '#F153FF',
  '#644BA5',
  '#FA3C00',
];

export const options = (displayLegend: boolean = false): ChartOptions => {
  return {
    plugins: {
      legend: {
        display: displayLegend,
        position: 'bottom',
        labels: {
          pointStyle: 'line',
          usePointStyle: true,
        },
        align: 'start',
      },
    },
    scales: {
      y: {
        ticks: {
          padding: 8,
          color: '#999999',
          font: {
            size: 10,
            family: 'Inter, sans-serif',
          },
          callback: value => {
            if (value === 0) return value;
            if (
              Math.abs(value as number) < 0.01 ||
              Math.abs(value as number) > 99999
            ) {
              return numeral(value).format('0.0[00]e+0');
            }
            return numeral(value).format('0[.]0[00]');
          },
        },
        title: {
          display: true,
          text: 'Расход электроэнергии, кВт·ч/сут',
          color: '#999999',
          font: {
            size: 10,
            family: 'Inter, sans-serif',
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          padding: 8,
          color: '#999999',
          font: {
            size: 10,
            family: 'Inter, sans-serif',
          },
        },
        title: {
          display: true,
          text: 'Время, мин',
          color: '#999999',
          font: {
            size: 10,
            family: 'Inter, sans-serif',
          },
        },
      },
    },
  };
};

export const dropdownOptions = [
  { label: 'Расход нефти, т/сут', value: 'oil_flow_rate' },
  { label: 'Расход жидкости, м3/сут', value: 'fluid_flow_rate' },
  { label: 'Расход газа, тыс. м3/сут', value: 'gas_flow_rate' },
  { label: 'Расход г/г, тыс. м3/сут / Частота, Гц', value: 'control' },
  { label: 'Линейное давление, атм', value: 'pressure' },
  { label: 'Мощность, кВт', value: 'power' },
] as const;
