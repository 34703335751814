import * as React from 'react';

import { usePutTableToStorage } from '../../../../hooks/usePutTableToStorage';
import UATable from './Table/Table';
import { nodesUAColumnDefs } from './Table/utils';

export const TABLE_NAME = 'PPD_UA_NODES';
const Nodes: React.FC = () => {
  usePutTableToStorage(TABLE_NAME, nodesUAColumnDefs);
  return <UATable nodeUids={[]} />;
};

export default Nodes;
