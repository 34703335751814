import * as React from 'react';
import { useSelector } from 'react-redux';

import AgGridTable from 'Components/AgGridTable';
import { components } from 'generated/apiTypes';
import { selectSolution } from 'store/taskSlice';

import { useGetColumnDefs } from '../../../../hooks/useGetColumnDefs';
import { TABLE_NAME } from './index';
import { segmentsMOColumnDefs, segmentsTableItems } from './utils';

const SegmentsTable: React.FC = () => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOReservoirOptimizationProblem'];
  const [data, setData] = React.useState<any[]>([]);
  const columnDefs = useGetColumnDefs(TABLE_NAME, segmentsMOColumnDefs);

  const getData = () => {
    const newData: any = [];
    solution?.solution?.segment_states.forEach(state => {
      newData.push(segmentsTableItems(state));
    });
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [solution]);

  return (
    <AgGridTable
      tableName={TABLE_NAME}
      columnDefs={columnDefs}
      rowData={data}
    />
  );
};

export default SegmentsTable;
