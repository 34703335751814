import * as React from 'react';
import { useSelector } from 'react-redux';

import AgGridTable from 'Components/AgGridTable';
import { PNOAFSolution } from 'Pages/Project/types';
import { selectSolution } from 'store/taskSlice';

import { formatThreeDigits } from '../../../../../../utils';
import { useGetColumnDefs } from '../../../../hooks/useGetColumnDefs';
import { TABLE_NAME } from './index';
import { segmentsAFColumnDefs } from './utils';

const AvgTable: React.FC = () => {
  const solution = useSelector(selectSolution);
  const [data, setData] = React.useState<any[]>([]);
  const columnDef = useGetColumnDefs(TABLE_NAME, segmentsAFColumnDefs);
  const getData = () => {
    const newData: any = [];
    (solution.solution as PNOAFSolution)?.solution?.pipeline_states?.forEach(
      state => {
        newData.push({
          start_node_name: state.start_node_name,
          end_node_name: state.end_node_name,
          friction_factor_correction: formatThreeDigits(
            state.friction_factor_correction,
          ),
          holdup_factor_correction: formatThreeDigits(
            state.holdup_factor_correction,
          ),
          pressure_correction: formatThreeDigits(state.dP_shift__atm),
        });
      },
    );
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [(solution.solution as PNOAFSolution)?.solution?.pipeline_states]);

  return (
    <AgGridTable tableName={TABLE_NAME} columnDefs={columnDef} rowData={data} />
  );
};

export default AvgTable;
