import React from 'react';
import { useSelector } from 'react-redux';

import { selectSegments } from 'store/projectSlice';

import { UseChartData } from '../types';
import { seriesColors } from '../utils';

const useChartData: UseChartData = (projectSolution, option) => {
  const segments = useSelector(selectSegments);
  const { time } = React.useMemo(() => {
    const statesByTime =
      projectSolution?.solution?.segment_step_table?.time2states;
    if (statesByTime) {
      return {
        time: Object.keys(statesByTime).map(item =>
          parseFloat(item).toFixed(0),
        ),
      };
    }
    return {};
  }, [projectSolution?.solution]);

  return React.useMemo(() => {
    if (time && option) {
      const chartData: { name: string; data: any[] }[] = [];
      segments?.items.map(segment => {
        return chartData.push({
          name: `${segment?.start_node_name} - ${segment?.end_node_name}`,
          data: projectSolution?.solution?.segment_step_table?.time2states
            ? Object.values(
                projectSolution?.solution?.segment_step_table?.time2states,
              ).map((state: any) => {
                const segmentStateById = state?.find(
                  (val: any) => val.segment_uid === segment.uid,
                );
                if (option.value === 'pressure_in') {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  return segmentStateById?.[option.value]?.atm || 0;
                }
                if (option.value === 'gas_flow_rate_volume') {
                  return (
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    segmentStateById?.[option.value]?.thousand_m3_per_day || 0
                  );
                }
                if (option.value === 'fluid_flow_rate_volume') {
                  return (
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    segmentStateById?.[option.value]?.m3_per_day || 0
                  );
                }
                if (option.value === 'dp_dl') {
                  return (
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    segmentStateById?.[option.value]?.atm_per_km || 0
                  );
                }
                if (option.value === 'dt_dl') {
                  return (
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    segmentStateById?.[option.value]?.celsius_per_km || 0
                  );
                }
                if (option.value === 'fluid_velocity') {
                  return segmentStateById?.[option.value]?.m_per_second || 0;
                }
                if (option.value === 'gas_velocity') {
                  return segmentStateById?.[option.value]?.m_per_second || 0;
                }
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return segmentStateById?.[option.value]?.t_per_day || 0;
              })
            : [],
        });
      });

      return {
        labels: time,
        datasets: Array.from(chartData).map((el, index) => {
          return {
            label: el.name,
            data: el.data,
            fill: false,
            backgroundColor: seriesColors[index % 9],
            borderColor: seriesColors[index % 9],
            color: seriesColors[index % 9],
            borderWidth: 1,
            tension: 0.1,
            pointHoverBorderWidth: 0,
            pointRadius: 0,
            pointHitRadius: 0,
            pointBorderWidth: 1,
            pointHoverRadius: 1,
            hidden: index !== 0,
          };
        }),
      };
    }
    return { labels: [], datasets: [] };
  }, [time, option?.value]);
};

export default useChartData;
