import * as React from 'react';

import Button from 'Components/Button';
import Dropzone from 'Components/Dropzone';
import Input from 'Components/Input';

import { ReactComponent as DeleteIcon } from '../../images/NewProject/delete-icon.svg';
import { ReactComponent as ExcelIcon } from '../../images/NewProject/excel-file-icon.svg';
import { ReactComponent as LinkIcon } from '../../images/NewProject/icn-link.svg';
import { ReactComponent as JsonIcon } from '../../images/NewProject/json-file-icon.svg';
import loader from '../../images/NewProject/loader_small.gif';
import CreateProjectButtons from './CreateProjectButtons';
import { LeftColPropsType } from './types';

const LeftCol: React.FC<LeftColPropsType> = ({
  onCancelClick,
  createProjectFromFile,
  createProjectFromDetails,
  progress,
}) => {
  const [active, setActive] = React.useState<'project' | 'file'>('project');
  const [file, setFile] = React.useState<File | null>(null);
  const [wellFile, setWellFile] = React.useState<File | null>(null);
  const [projectName, setProjectName] = React.useState<string>('');
  const [nameError, setNameError] = React.useState<boolean>(false);
  const [url, setUrl] = React.useState<string>('');
  const [urlError, setUrlError] = React.useState<boolean>(false);
  const [dropzoneError, setDropzoneError] = React.useState<boolean>(false);
  const [wellFileError, setWellFileError] = React.useState<boolean>(false);

  const onDropJSONFile = (files: File[]) => {
    setDropzoneError(false);
    setFile(files[0]);
  };

  const onDropWellFile = (files: File[]) => {
    setWellFileError(false);
    setWellFile(files[0]);
  };

  const deleteFile = () => {
    setFile(null);
  };

  const deleteWellFile = () => {
    setWellFile(null);
  };

  const onCreateProject = () => {
    switch (active) {
      case 'file':
        if (projectName && file) {
          createProjectFromFile(file, projectName);
          break;
        }
        if (!projectName) setNameError(true);
        if (!file) setDropzoneError(true);
        break;
      case 'project':
        if (projectName && wellFile && url) {
          createProjectFromDetails(wellFile, projectName, url);
          break;
        }
        if (!projectName) setNameError(true);
        if (!wellFile) setWellFileError(true);
        if (!url) setUrlError(true);
        break;
      default:
        break;
    }
  };

  const onChangeProjectName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameError(false);
    setProjectName(e.target.value);
  };

  const onChangeUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrlError(false);
    setUrl(e.target.value);
  };

  return (
    <div className="left-col">
      <div>
        <h1 className="left-col__title">Создание проекта</h1>
        <CreateProjectButtons active={active} setActive={setActive} />
        <div>
          <div className="left-col__row">
            <Input
              label="Название проекта"
              value={projectName}
              onChange={onChangeProjectName}
              error={nameError}
            />
          </div>
          {active === 'project' && (
            <>
              <div className="left-col__row">
                <label className="left-col__row-label">Модель сети</label>
                <div className="left-col__with-prefix-row">
                  <div className="left-col__link-icon">
                    <LinkIcon />
                  </div>
                  <div className="left-col__with-prefix-input">
                    <Input
                      value={url}
                      onChange={onChangeUrl}
                      error={urlError}
                    />
                  </div>
                </div>
              </div>
              <div className="left-col__row">
                <p className="left-col__sub-title">Модель скважин</p>
                {!wellFile ? (
                  <Dropzone
                    onDrop={onDropWellFile}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xls"
                    multiple={false}
                    error={wellFileError}
                  />
                ) : (
                  <div className="left-col__file-name">
                    <ExcelIcon className="left-col__file-icon" />
                    {wellFile?.name}
                    <button type="button" onClick={deleteWellFile}>
                      <DeleteIcon className="left-col__delete-icon" />
                    </button>
                  </div>
                )}
                <span className="left-col__post-label">В формате .xlsx</span>
              </div>
            </>
          )}
          {active === 'file' && (
            <div className="left-col__row">
              <p className="left-col__sub-title">Файл существующего проекта</p>
              {!file ? (
                <Dropzone
                  onDrop={onDropJSONFile}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xls, .json"
                  multiple={false}
                  error={dropzoneError}
                />
              ) : (
                <div className="left-col__file-name">
                  {['xls', 'xlsx'].includes(
                    file?.name.split('.').pop() as string,
                  ) && <ExcelIcon className="left-col__file-icon" />}
                  {file?.name.split('.').pop() === 'json' && (
                    <JsonIcon className="left-col__file-icon" />
                  )}
                  {file?.name}
                  <button type="button" onClick={deleteFile}>
                    <DeleteIcon className="left-col__delete-icon" />
                  </button>
                </div>
              )}
              <span className="left-col__post-label">
                В формате .json или .xlsx
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="left-col__buttons">
        <Button
          type="button"
          className="left-col__create-button"
          onClick={onCreateProject}
          disabled={progress}
        >
          {progress && (
            <img className="left-col__loader" src={loader} alt=".." />
          )}
          {progress ? 'Подготовка...' : 'Создать'}
        </Button>
        <Button
          type="button"
          className="left-col__cancel-button"
          mode="secondary"
          onClick={onCancelClick}
          disabled={progress}
        >
          Отмена
        </Button>
      </div>
    </div>
  );
};

export default LeftCol;
