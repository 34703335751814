import * as React from 'react';

import { usePutTableToStorage } from '../../../../../hooks/usePutTableToStorage';
import NodesTable from './NodesTable';
import { nodesMOColumnDefs } from './utils';

export const TABLE_NAME = 'PPD_MO_NODES';
const Table: React.FC = () => {
  usePutTableToStorage(TABLE_NAME, nodesMOColumnDefs);
  return (
    <>
      <NodesTable />
    </>
  );
};

export default Table;
