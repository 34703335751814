import * as React from 'react';

export const templateRowsNumber = 5;
export const layersUAColumnDefs = [
  {
    colId: '0',
    headerName: 'Название',
    field: 'name',
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '1',
    headerName: 'Расход нефти, т\u2060/\u2060сут',
    field: 'oil_flow_rate',
  },

  {
    colId: '2',
    headerName: 'Расход воды, м\u00B3\u2060/\u2060сут',
    field: 'water_flow_rate',
  },
  {
    colId: '3',
    headerName: 'Расход газа, тыс.м\u00B3\u2060/\u2060сут',
    field: 'gas_flow_rate',
  },
  {
    colId: '4',
    headerName: 'Расход закачки, м\u00B3\u2060/\u2060сут',
    field: 'water_injection',
  },
  { colId: '5', headerName: 'Пластовое давление, атм', field: 'pressure' },
];
export const layersTableHead = {
  name: 'Название',
  oil_flow_rate: {
    title: 'Расход нефти',
    component: (
      <>
        Расход нефти,
        <br /> т/сут
      </>
    ),
  },
  water_flow_rate: {
    title: 'Расход воды',
    component: (
      <>
        Расход воды,
        <br /> м<span className="sup">3</span>/сут
      </>
    ),
  },
  gas_flow_rate: {
    title: 'Расход газа',
    component: (
      <>
        Расход газа, <br />
        тыс.м<span className="sup">3</span>/сут
      </>
    ),
  },
  water_injection: {
    title: 'Расход закачки',
    component: (
      <>
        Расход закачки,
        <br /> м<span className="sup">3</span>/сут
      </>
    ),
  },
  pressure: {
    title: 'Пластовое давление',
    component: (
      <>
        Пластовое давление, <br />
        атм
      </>
    ),
  },
};
