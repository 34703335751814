import * as React from 'react';
import { useSelector } from 'react-redux';

import AgGridTable from 'Components/AgGridTable';
import { components } from 'generated/apiTypes';
import { selectSolution } from 'store/taskSlice';

import { useGetColumnDefs } from '../../../../hooks/useGetColumnDefs';
import { ISegmentsTableProps } from '../../../types';
import { TABLE_NAME } from './index';
import { segmentsMOColumnDefs, segmentsTableItems } from './utils';

const SegmentsAvgTable: React.FC<ISegmentsTableProps> = () => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOTechModeOptimizationProblem'];
  const [data, setData] = React.useState<any[]>([]);
  const columnDef = useGetColumnDefs(TABLE_NAME, segmentsMOColumnDefs);
  const getData = () => {
    const newData: any = [];
    solution?.solution?.segment_avg_table?.states.forEach(state => {
      newData.push(segmentsTableItems(state));
    });
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [solution]);

  return (
    <AgGridTable tableName={TABLE_NAME} columnDefs={columnDef} rowData={data} />
  );
};

export default SegmentsAvgTable;
