import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import classNames from 'classnames';
import { ReactComponent as FullScreenExitIcon } from 'images/Project/fullscreen-exit.svg';
import { ReactComponent as FullScreenOpenIcon } from 'images/Project/fullscreen-open.svg';
import {
  selectExperimentStatus,
  selectExperimentType,
  selectIsActual,
  selectSolution,
  setShowResults,
  toggleApplyBaseMode,
} from 'store/taskSlice';

import Button from '../../../Components/Button';
import { applyBaseSolutionResults } from '../../../services/apiRequests';
import { selectReservoir } from '../../../store/projectSlice';
import TaskStatus from '../ProjectHeader/TaskStatus';
import ApplyAFButton from './components/ApplyAFButton';
import ApplyOptimalButton from './components/ApplyOptimalButton';
import ErrorDetails from './components/ErrorDetails';
import AFDashboard from './ResultsPanel/Dashboard/AFDashboard';
import AFCalculationInfoDashboard from './ResultsPanel/Dashboard/AFDashboard/AFCalculationInfoDashboard';
import MODashboard from './ResultsPanel/Dashboard/MODashboard';
import MOCalculationInfoDashboard from './ResultsPanel/Dashboard/MODashboard/MOCalculationInfoDashboard';
import UADashboard from './ResultsPanel/Dashboard/UADashboard';
import UACalculationInfoDashboard from './ResultsPanel/Dashboard/UADashboard/UACalculationInfoDashboard';
import CompareUADashboard from './ResultsPanel/Dashboard/UADashboard/UACompareDashbord';
import AFResults from './ResultsPanel/Results/AFResults';
import MOResults from './ResultsPanel/Results/MOResults';
import UACompareResults from './ResultsPanel/Results/UACompareResults';
import UAResults from './ResultsPanel/Results/UAResults';
import MODashboardPPD from './ResultsPanelPPD/Dashboard/MODashboard';
import MOCalculationInfoDashboardPPD from './ResultsPanelPPD/Dashboard/MODashboard/MOCalculationInfoDashboard';
import UADashboardPPD from './ResultsPanelPPD/Dashboard/UADashboard';
import UACalculationInfoDashboardPPD from './ResultsPanelPPD/Dashboard/UADashboard/UACalculationInfoDashboard';
import MOResultsPPD from './ResultsPanelPPD/Results/MOResults';
import UAResultsPPD from './ResultsPanelPPD/Results/UAResults';

import './style.scss';

interface IResultsPanel {
  taskId: string | null;
}

export const ResultsPanel: React.FC<IResultsPanel> = () => {
  const solutionType = useSelector(selectSolution)?.type;
  const experimentType = useSelector(selectExperimentType);
  const status = useSelector(selectExperimentStatus);
  const isActual = useSelector(selectIsActual);
  const reservoir = useSelector(selectReservoir);
  const dispatch = useDispatch();
  const [compareResults, setCompareResults] = React.useState<boolean>(false);
  const [applyingInProgress, setApplyingInProgress] =
    React.useState<boolean>(false);
  const [fullScreen, setFullScreen] = React.useState<boolean>(false);
  const params = useParams() as { id: string };

  const onApplyBaseMode = async () => {
    setApplyingInProgress(true);
    await applyBaseSolutionResults(params.id).then();
    dispatch(toggleApplyBaseMode());
    setApplyingInProgress(false);
    dispatch(setShowResults(false));
  };

  if (['FAILED', 'VALIDATION_ERROR'].includes(status))
    return (
      <div className="project-results-panel">
        <div className="project-results-panel__title-wrapper">
          <p className="project-results-panel__title">{solutionType}</p>
          <TaskStatus className="with-status without-stop-btn error" />
        </div>
        <p className="project-results-panel__message error">
          Расчет завершился с ошибкой.
        </p>
        <ErrorDetails />
        <UACalculationInfoDashboard />
      </div>
    );

  if (status === 'NONE')
    return (
      <div className="project-results-panel">
        <div className="project-results-panel__title-wrapper initial">
          <p className="project-results-panel__title">{experimentType}</p>
        </div>
        <p className="project-results-panel__message error">
          Расчет еще не проводился.
        </p>
      </div>
    );

  if (status === 'INTERRUPTED')
    return (
      <div className="project-results-panel">
        <div className="project-results-panel__title-wrapper initial">
          <p className="project-results-panel__title">{experimentType}</p>
        </div>
        <p className="project-results-panel__message error">
          Расчет был прерван пользователем.
        </p>
      </div>
    );

  if (status === 'INITIAL')
    return (
      <div className="project-results-panel">
        <div className="project-results-panel__title-wrapper initial">
          <p className="project-results-panel__title">{experimentType}</p>
        </div>
      </div>
    );

  return (
    <div
      className={classNames(
        'project-results-panel',
        fullScreen && 'fullscreen',
      )}
    >
      <div className="project-results-panel__title-wrapper d-flex compare">
        {[
          'DRAFT',
          'SENT',
          'PREPARING',
          'PREPARED',
          'SOLVING',
          'INTERRUPTING',
        ].includes(status) ? (
          <>
            <p className="project-results-panel__title">{solutionType}</p>
            <TaskStatus className="with-status" />
          </>
        ) : (
          <div className="project-results-panel-header">
            <p className="project-results-panel__title">{solutionType}</p>
            {!isActual && (
              <div
                className={classNames(
                  'control-panel__progress-details',
                  'not-actual',
                )}
              >
                <div className="control-panel__progress-details-message">
                  НЕ АКТУАЛЕН
                </div>
              </div>
            )}
            <div className="project-results-panel__buttons">
              {solutionType === 'Базовый режим' && !reservoir?.uid && (
                <div className="project-results-panel__action-buttons">
                  {status === 'SOLVED' && isActual && (
                    <Button
                      type="button"
                      className="project-results-panel__well-shutdown-btn"
                      onClick={onApplyBaseMode}
                      disabled={applyingInProgress}
                    >
                      Применить откл. скважин
                    </Button>
                  )}
                  <button
                    className={classNames(
                      'project-results-panel__compare-btn',
                      compareResults && 'compare',
                    )}
                    type="button"
                    onClick={() => setCompareResults(!compareResults)}
                  >
                    Сравнить с факт.режимом
                  </button>
                </div>
              )}
              {solutionType === 'Оптимизация режима' && !reservoir?.uid && (
                <ApplyOptimalButton />
              )}
              {solutionType === 'Адаптация на факт' && <ApplyAFButton />}
              <button
                className="project-results-panel__full-size-btn"
                onClick={() => setFullScreen(!fullScreen)}
                type="button"
              >
                {fullScreen ? <FullScreenExitIcon /> : <FullScreenOpenIcon />}
              </button>
            </div>
          </div>
        )}
      </div>
      {solutionType === 'Базовый режим' && !reservoir && !compareResults && (
        <>
          <UADashboard />
          <UAResults />
          <UACalculationInfoDashboard />
        </>
      )}
      {solutionType === 'Базовый режим' && reservoir && (
        <>
          <UADashboardPPD />
          <UAResultsPPD />
          <UACalculationInfoDashboardPPD />
        </>
      )}
      {solutionType === 'Базовый режим' && !reservoir && compareResults && (
        <>
          <CompareUADashboard />
          <UACompareResults />
          <UACalculationInfoDashboard />
        </>
      )}
      {solutionType === 'Оптимизация режима' && !reservoir && (
        <>
          <MODashboard />
          <MOResults />
          <MOCalculationInfoDashboard />
        </>
      )}
      {solutionType === 'Оптимизация режима' && reservoir && (
        <>
          <MODashboardPPD />
          <MOResultsPPD />
          <MOCalculationInfoDashboardPPD />
        </>
      )}
      {solutionType === 'Адаптация на факт' && (
        <>
          <AFDashboard />
          <AFResults />
          <AFCalculationInfoDashboard />
        </>
      )}
      {/* <SegmentsScale /> */}
    </div>
  );
};

export default ResultsPanel;
