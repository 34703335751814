import * as React from 'react';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { components } from 'generated/apiTypes';
import StepInput from 'Pages/Project/ResultsPanel/components/StepInput';
import { selectSolution } from 'store/taskSlice';

import { usePutTableToStorage } from '../../../../../hooks/usePutTableToStorage';
import SegmentsAvgTable from './AvgTable';
import SegmentsByStepTable from './ByStepTable';
import { segmentsUAColumnDefs } from './utils';

export const TABLE_NAME = 'UA_SEGMENTS';
const Table: React.FC = () => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOPeriodicNodalAnalysisProblem'];
  const [tabIndex, setTabIndex] = React.useState(0);
  const initStep = '0.0';
  const [step, setStep] = React.useState<string>(initStep);
  usePutTableToStorage(TABLE_NAME, segmentsUAColumnDefs);
  return (
    <>
      <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
        <TabList className="results__tab-list tables-tab-list">
          <div className="results__table-tabs">
            <Tab className="results__tab">Средние</Tab>
            <Tab className="results__tab">По шагам</Tab>
            <StepInput
              value={step}
              setStep={setStep}
              tabIndex={tabIndex}
              initStep={initStep}
              max={
                solution?.solution?.well_step_table?.time2states
                  ? Object.keys(
                      solution?.solution?.well_step_table?.time2states,
                    )[
                      Object.entries(
                        solution?.solution?.well_step_table?.time2states,
                      )?.length - 1
                    ]?.toString()
                  : '0.0'
              }
              time2states={solution?.solution?.node_step_table?.time2states}
            />
          </div>
        </TabList>
        <div>
          <TabPanel>
            <SegmentsAvgTable />
          </TabPanel>
          <TabPanel>
            <SegmentsByStepTable keyD={step} />
          </TabPanel>
        </div>
      </Tabs>
    </>
  );
};

export default Table;
