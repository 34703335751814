import { WSURL } from '../api';
import { handleStorage } from '../services/handleStorage';
import RequestSocketActions from './appDB/RequestSocketActions';

const requestSocketActions = new RequestSocketActions();
// Socket manager
export default class Socket {
  socket: any;

  constructor(public receiveData: any, public setSocketConnectionStatus: any) {
    this.socket = null;
    this.receiveData = receiveData;
    this.setSocketConnectionStatus = setSocketConnectionStatus;
  }

  // User clicked connect button
  connect = async (project_uid: string) => {
    // Connect
    const token = handleStorage.getToken();
    const url: any = `${WSURL}/api/projects/${project_uid}/pipeline-network?token=${token}`;

    this.socket = new WebSocket(url);
    this.setSocketConnectionStatus({ isConnect: true });
    this.socket.onopen = () => {
      console.log('Соединение установлено');
    };

    this.socket.onmessage = async (e: any) => {
      this.receiveData(e);
      const requestsData = await requestSocketActions.getData();
      if (requestsData.length === 0) {
        this.disconnect();
      }
    };

    this.socket.onerror = (err: any) => {
      console.log('Socket_Error', err);
    };
  };

  waitForConnection = (callback: any, interval: any) => {
    if (this.socket.readyState === 1) {
      callback();
    } else {
      setTimeout(() => {
        this.waitForConnection(callback, interval);
      }, interval);
    }
  };

  sendData = (data?: any) => {
    this.waitForConnection(() => {
      this.socket.send(data);
    }, 1000);
  };

  // Close the socket
  disconnect = () => {
    this.socket.close(1000, 'Disconnect');
  };
}
