import {
  setChokes,
  setClusters,
  setGatheringCentersFactPressures,
  setGatheringCentersModels,
  setInjectionWellModels,
  setInjectionWells,
  setNodes,
  setPhysChemPackages,
  setProductionWellModels,
  setProductionWells,
  setProjectId,
  setProjectName,
  setPumps,
  setReservoir,
  setSegments,
  setSeparators,
  setSinks,
  setSources,
  setThreePhaseSeparators,
  setUnconnectedWellsModels,
  setWells,
  setWellsControlModels,
  setWellsHydroModels,
} from 'store/projectSlice';

import {
  getChokes,
  getClusters,
  getProject,
  getSeparators,
  getWells,
} from 'services/apiRequests';

const fetchProject = async (id: any, dispatch: any, callback?: any) => {
  /* const searchNodeName = (arr: any[], node_uid: string) => {
    return (
      (
        arr?.find(
          (item: any) =>
            item.node_uid === node_uid ||
            item.node_id === node_uid ||
            item.in_node_uid === node_uid ||
            item.out_node_uid === node_uid ||
            item.oil_out_node_uid === node_uid ||
            item.gas_out_node_uid === node_uid ||
            item.water_out_node_uid === node_uid,
        ) as any
      )?.name || ''
    );
  }; */

  try {
    const response = await getProject(id);
    const separatorsResponse = await getSeparators(id);
    const clustersResponse = await getClusters(id);
    const wells = await getWells(id);
    dispatch(setSeparators(separatorsResponse.data.separator_query_results));
    dispatch(setClusters(clustersResponse.data.clusters));
    /* const searchArr = [
      ...(response?.data?.sources || []),
      ...(response?.data?.sinks || []),
      ...(response?.data?.pumps || []),
      ...(response?.data?.injection_wells || []),
      ...(response?.data?.production_wells || []),
      ...(response?.data?.three_phase_separators || []),
    ];
    const renamedNodes = response?.data?.pipeline_network?.nodes?.map(node => {
      return {
        ...node,
        name: searchNodeName(searchArr, node.uid as string) || node.name,
      };
    }); */
    dispatch(setNodes(response?.data?.pipeline_network?.nodes));
    dispatch(setWells(wells?.data.wells));
    if (response?.data?.injection_wells) {
      dispatch(setInjectionWells(response?.data?.injection_wells));
    }
    if (response?.data?.production_wells) {
      dispatch(setProductionWells(response?.data?.production_wells));
    }
    if (response?.data?.injection_well_models) {
      dispatch(setInjectionWellModels(response?.data?.injection_well_models));
    }
    if (response?.data?.production_well_models) {
      dispatch(setProductionWellModels(response?.data?.production_well_models));
    }
    if (response?.data?.three_phase_separators) {
      dispatch(setThreePhaseSeparators(response?.data?.three_phase_separators));
    }
    if (response?.data?.sinks) {
      dispatch(setSinks(response?.data?.sinks));
    }
    if (response?.data?.pumps) {
      dispatch(setPumps(response?.data?.pumps));
    }
    if (response?.data?.sources) {
      dispatch(setSources(response?.data?.sources));
    }
    if (response?.data?.reservoir) {
      dispatch(setReservoir(response?.data?.reservoir));
    }
    const chokesResponse = await getChokes(id);
    dispatch(setChokes(chokesResponse.data.chokes));
    // dispatch(setWellsModels(response?.data?.wells_models));
    if (response?.data?.unconnected_wells_models)
      dispatch(
        setUnconnectedWellsModels(response?.data?.unconnected_wells_models),
      );
    dispatch(setWellsHydroModels(response?.data?.wells_hydro_models));
    dispatch(
      setGatheringCentersModels(response?.data?.gathering_centers_models),
    );
    dispatch(
      setGatheringCentersFactPressures(
        response?.data?.gathering_centers_fact_pressures,
      ),
    );
    dispatch(setSegments(response?.data?.pipeline_network?.segments));
    if (response?.data?.wells_control_models)
      dispatch(setWellsControlModels(response?.data?.wells_control_models));
    dispatch(setPhysChemPackages(response?.data?.phys_chem_packages));
    dispatch(setProjectName(response?.data?.name));
    dispatch(setProjectId(id));
    if (callback) callback(response.data);
  } catch (e) {
    //
  }

  // return response.data;
};

export default fetchProject;
