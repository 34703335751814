import React from 'react';

import { ReactComponent as CloseIcon } from 'images/Project/close-icon.svg';

import Button from '../Button';

import './style.scss';

interface ICancelTaskConfirmationWindowProps {
  closeWindow(): void;
  recoverFact(): void;
}

const DeleteConfirmationWindow: React.FC<
  ICancelTaskConfirmationWindowProps
> = ({ recoverFact, closeWindow }) => {
  const onBtnClick = () => {
    recoverFact();
    closeWindow();
  };

  React.useEffect(() => {
    const onKeyDownHandler = (e: React.KeyboardEvent) => {
      const { key } = e;
      if (key === 'Enter') {
        onBtnClick();
        closeWindow();
      }
    };
    document.addEventListener('keydown', onKeyDownHandler as any);
    return () => {
      document.removeEventListener('keydown', onKeyDownHandler as any);
    };
  }, []);
  return (
    <div className="dialog-window-background">
      <div className="dialog-window-container">
        <div className="dialog-window-wrapper">
          <button
            type="button"
            className="dialog-window-close-button"
            onClick={closeWindow}
          >
            <CloseIcon />
          </button>
          <div className="dialog-window-title">
            Восстановление фактического состояния
          </div>
          <div className="dialog-window-message cancel-task-message">
            Значения всех параметров текущего состояния центра сбора и скважин
            будут приравнены к значениям фактического состояния
          </div>
          <div className="dialog-window-buttons-wrapper">
            <Button
              type="button"
              className="dialog-window-delete-button"
              onClick={onBtnClick}
            >
              Применить
            </Button>
            <Button type="button" mode="secondary" onClick={closeWindow}>
              Отмена
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationWindow;
