import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { components } from 'generated/apiTypes';
import { selectSolution } from 'store/taskSlice';

import { IChartInfo, IInfoType, UseChartData } from '../../types';
import { seriesColors } from '../../utils';

const useChartData: UseChartData = info => {
  const solution = useSelector(selectSolution)
    .solution as unknown as components['schemas']['PNOPeriodicNodalAnalysisProblem'];
  const [chartData, setChartData] = useState<{ name: string; data: any[] }[]>(
    [],
  );
  const { time } = React.useMemo(() => {
    if (solution) {
      const statesByTime = solution.solution.well_step_table?.time2states;
      if (statesByTime) {
        return {
          time: Object.keys(statesByTime),
        };
      }
    }
    return { time: [] };
  }, [solution]);
  const getTimeToState = (type: IInfoType) => {
    switch (type) {
      case 'WELL':
        return solution.solution.well_step_table?.time2states;
      case 'SEPARATOR':
        return solution.solution.three_phase_separator_step_table?.time2States;
      case 'PUMP':
        return solution.solution.pump_step_table?.time2States;
      default:
        return {};
    }
  };
  useEffect(() => {
    if (time && info.length && solution) {
      const dataSource = time.reduce((prev: any, cur: any) => {
        prev[cur] = null;
        return prev;
      }, {});
      info
        .filter((i: IChartInfo) => i.isVisible)
        .forEach((inf: IChartInfo) => {
          const time2States = getTimeToState(inf.type);
          if (time2States)
            for (const data of Object.entries(dataSource)) {
              const state = (time2States[data[0]] as any).find(
                (item: any) =>
                  item.well_id === inf.id ||
                  item.pump_id === inf.id ||
                  item.separator_id === inf.id,
              );
              if (state) {
                dataSource[data[0]] +=
                  (state.power?.kW || state.power__kW || 0) * 24;
              }
            }
        });
      setChartData([
        {
          name: '',
          data: Object.values(dataSource),
        },
      ]);
    }
  }, [solution, info]);

  if (chartData.length) {
    return {
      labels: time,
      datasets: Array.from(chartData).map((el, index) => {
        return {
          label: el.name,
          data: el.data,
          fill: false,
          backgroundColor: seriesColors[index % 9],
          borderColor: seriesColors[index % 9],
          color: seriesColors[index % 9],
          borderWidth: 1,
          tension: 0.1,
          pointHoverBorderWidth: 0,
          pointRadius: 0,
          pointHitRadius: 0,
          pointBorderWidth: 1,
          pointHoverRadius: 1,
          hidden: index !== 0,
        };
      }),
    };
  }
  return { labels: [], datasets: [] };
};

export default useChartData;
