import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Button from 'Components/Button';
import { ReactComponent as Logo } from 'images/Projects/logo.svg';

import { login } from 'services/apiRequests';
import { handleStorage } from 'services/handleStorage';
import FormField from './FormField';

import './style.scss';

export type LoginPropsType = RouteComponentProps;

class Login extends React.Component<LoginPropsType> {
  state = {
    password: '',
    username: '',
    error: false,
  };

  tryToLogin = async () => {
    const { password, username } = this.state;
    if (this.validateFormValues(password, username)) {
      try {
        const formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);
        const {
          data: { access_token: token },
        } = await login(formData);
        handleStorage.setToken(token);
        handleStorage.setUsername(username);
        this.props.history.push('/');
      } catch (err) {
        this.setState({ error: true });
      }
    } else this.setState({ error: true });
  };

  submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();
    this.tryToLogin();
  };

  validateFormValues = (password: string, username: string): boolean =>
    !!(password && username);

  onChangeHandler = (
    e: React.FormEvent<HTMLInputElement>,
    formValue: 'password' | 'username',
  ) => {
    const { error } = this.state;
    if (error) this.setState({ error: false });
    this.setState({
      [formValue]: e.currentTarget.value,
    });
  };

  render() {
    const { error } = this.state;
    return (
      <div className="login-page-container">
        <form onSubmit={this.submitHandler} className="login-page-content">
          <div className="login-page-logo-wrapper">
            <div className="logo-img-wrapper">
              <Logo />
            </div>
            <div className="logo-text-wrapper">
              Оптимизация режимов работы добывающих скважин и&nbsp;нефтесборной
              сети
            </div>
          </div>
          <FormField
            onChangeHandler={this.onChangeHandler}
            formValue="username"
            placeholder="Логин"
          />
          <FormField
            onChangeHandler={this.onChangeHandler}
            formValue="password"
            type="password"
            placeholder="Пароль"
            onEnter={this.tryToLogin}
          />
          <div className="login-page-button-wrapper">
            <Button type="submit" className="login-page-button">
              Войти
            </Button>
          </div>
          {error && (
            <div className="login-page__error-message">
              Неверная комбинация логина и пароля
            </div>
          )}
        </form>
      </div>
    );
  }
}

export default withRouter(Login);
