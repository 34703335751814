import React from 'react';

import { ReactComponent as CloseIcon } from 'images/Project/close-icon.svg';

import Button from '../Button';
import RadioButton from '../RadioButton';

import './style.scss';

interface ICancelTaskConfirmationWindowProps {
  closeWindow(): void;
  exportProject(type: string): void;
}

const DeleteConfirmationWindow: React.FC<
  ICancelTaskConfirmationWindowProps
> = ({ exportProject, closeWindow }) => {
  const [type, setType] = React.useState<string>('JSON');

  const onBtnClick = () => {
    exportProject(type);
    closeWindow();
  };

  React.useEffect(() => {
    const onKeyDownHandler = (e: React.KeyboardEvent) => {
      const { key } = e;
      if (key === 'Enter') {
        onBtnClick();
        closeWindow();
      }
    };
    document.addEventListener('keydown', onKeyDownHandler as any);
    return () => {
      document.removeEventListener('keydown', onKeyDownHandler as any);
    };
  }, []);
  return (
    <div className="dialog-window-background">
      <div className="dialog-window-container">
        <div className="dialog-window-wrapper">
          <button
            type="button"
            className="dialog-window-close-button"
            onClick={closeWindow}
          >
            <CloseIcon />
          </button>
          <div className="dialog-window-title">
            Выберите формат экспорта проекта
          </div>
          <div className="dialog-window-options">
            <RadioButton
              id="export-radio-1"
              value="JSON"
              label=".json"
              name="export"
              setValue={setType}
              checked={type === 'JSON'}
            />
            <RadioButton
              id="export-radio-2"
              value="EXCEL"
              label=".xlsx"
              name="export"
              setValue={setType}
              checked={type === 'EXCEL'}
            />
          </div>
          <div className="dialog-window-buttons-wrapper">
            <Button
              type="button"
              className="dialog-window-delete-button"
              onClick={onBtnClick}
            >
              Экспортировать
            </Button>
            <Button type="button" mode="secondary" onClick={closeWindow}>
              Отмена
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationWindow;
