import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as CloseIcon } from '../../../images/Project/close-icon.svg';
import { selectActiveObject } from '../../../store/mapSlice';
import {
  selectShowDetailsPanel,
  selectUpdatedModel,
  selectWellModelById,
  setShowDetailsPanel,
} from '../../../store/projectSlice';
import HeightProfile from './HeightProfile';
import PipesCatalog from './PipesCatalog/Table';
import PumpModel from './PumpModel';
import WellModel from './WellModel';
import WellModelPPD from './WellModelPPD';

import './style.scss';

const DetailsPanel: React.FC = () => {
  const dispatch = useDispatch();
  const showDetailsPanel = useSelector(selectShowDetailsPanel);
  const activeObject = useSelector(selectActiveObject);
  const wellsModelsById = useSelector(selectWellModelById);
  const updatedModel = useSelector(selectUpdatedModel);
  const [updatedIndicator, setUpdatedIndicator] = React.useState<boolean>(
    updatedModel.includes(activeObject.object_uid as string),
  );

  React.useEffect(() => {
    if (showDetailsPanel === 'wellModel' && activeObject.type !== 'SOURCE')
      dispatch(setShowDetailsPanel(null));
    else if (showDetailsPanel === 'pumpModel' && activeObject.type !== 'PUMP')
      dispatch(setShowDetailsPanel(null));
    else if (
      showDetailsPanel === 'wellModelPPD' &&
      activeObject.type !== ('PROD_WELL_PPD' || 'INJ_WELL_PPD')
    )
      dispatch(setShowDetailsPanel(null));
  }, [activeObject.type]);

  React.useEffect(() => {
    setUpdatedIndicator(
      updatedModel.includes(activeObject.object_uid as string),
    );
    if (showDetailsPanel === 'heightProfile')
      dispatch(setShowDetailsPanel(null));
  }, [activeObject.object_uid, updatedModel]);

  const closePanel = () => {
    dispatch(setShowDetailsPanel(null));
  };

  const titleByType = () => {
    switch (showDetailsPanel) {
      case 'pipesCatalog':
        return 'Справочник трубопроводов';
      case 'wellModel':
      case 'wellModelPPD':
        return 'Модель скважины';
      case 'pumpModel':
        return 'Модель насоса';
      case 'heightProfile':
        return 'Высотный профиль';
      default:
        return '';
    }
  };

  if (!showDetailsPanel) {
    return null;
  }

  const isWellModelExists = wellsModelsById[activeObject.object_uid as string];

  return (
    <div className="details-panel__container">
      <div className="details-panel__header">
        <p className="project-task-settings__title">
          {titleByType()}
          {updatedIndicator && showDetailsPanel === 'wellModel' && (
            <div className="details-panel__updated-indicator" />
          )}
        </p>
        <button
          type="button"
          className="details-panel__close-button"
          onClick={closePanel}
        >
          <CloseIcon />
        </button>
      </div>
      {showDetailsPanel === 'pipesCatalog' && <PipesCatalog />}
      {showDetailsPanel === 'heightProfile' && <HeightProfile />}
      {showDetailsPanel === 'wellModel' && !isWellModelExists ? (
        <p className="details-panel__no-model-message">
          Модель скважины отсутствует в данных конфигурационного файла.
        </p>
      ) : (
        showDetailsPanel === 'wellModel' && <WellModel />
      )}
      {showDetailsPanel === 'pumpModel' && <PumpModel />}
      {showDetailsPanel === 'wellModelPPD' && <WellModelPPD />}
    </div>
  );
};

export default DetailsPanel;
