import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import classNames from 'classnames';
import { ReactComponent as AspoIcon } from 'images/Project/icn-aspo.svg';
import { ReactComponent as ProgressIcon } from 'images/Project/progress-icon.svg';

import { applyAFResults, getProject, getWells } from 'services/apiRequests';
import { setSegments, setWells } from '../../../../store/projectSlice';

const ApplyAFButton: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams() as { id: string };
  const [applyingAF, setApplyingAF] = React.useState<boolean>(false);

  const onApplyAspoClick = async () => {
    try {
      setApplyingAF(true);
      await applyAFResults(params.id);
      const response = await getProject(params.id);
      const wellsResponse = await getWells(params.id);
      dispatch(setWells(wellsResponse.data.wells));
      dispatch(setSegments(response?.data?.pipeline_network?.segments));
    } catch (e) {
      //
    }
    setApplyingAF(false);
  };
  const buttonByState = () => {
    if (applyingAF)
      return (
        <>
          <ProgressIcon className="project-results-panel__progress-icon" />
          <p>Применение...</p>
        </>
      );
    return (
      <>
        <AspoIcon className="project-results-panel__aspo-icon" />
        <p>Применить адаптацию</p>
      </>
    );
  };
  return (
    <button
      type="button"
      className={classNames('project-results-panel__aspo-btn af-btn', {
        applyingAF,
      })}
      onClick={onApplyAspoClick}
      disabled={applyingAF}
    >
      {buttonByState()}
    </button>
  );
};

export default ApplyAFButton;
