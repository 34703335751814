export const templateRowsNumber = 5;

export const segmentsAFColumnDefs = [
  {
    colId: '0',
    headerName: 'Начальный узел',
    field: 'start_node_name',
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '1',
    headerName: 'Конечный узел',
    field: 'end_node_name',
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '2',
    headerName: 'Поправка friction factor',
    field: 'friction_factor_correction',
  },
  {
    colId: '3',
    headerName: 'Поправка holdup factor',
    field: 'holdup_factor_correction',
  },
  {
    colId: '4',
    headerName: 'Поправка к давлению, атм',
    field: 'pressure_correction',
  },
];
export const segmentsAvgTableHead = {
  start_node_name: 'Начальный узел',
  end_node_name: 'Конечный узел',
  friction_factor_correction: 'Поправка friction factor',
  holdup_factor_correction: 'Поправка holdup factor',
  pressure_correction: 'Поправка к давлению, атм',
};
