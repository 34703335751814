import { components } from 'generated/apiTypes';
import update from 'immutability-helper';

import { oneDigitsFormatter, smartFormat } from '../../../../../utils';
import { ChartType } from '../types';

export const getTableHeaderByType = (
  type:
    | components['schemas']['PNOWellOperationType']
    | '5D_ЭЦН'
    | '5D_Газлифт'
    | 'Газлифт редуц.'
    | 'ЭЦН редуц.'
    | 'ФЧЗ'
    | 'ФЧЗ редуц.',
  targetParam?:
    | 'FREQ'
    | 'ANNULAR_PRESSURE_DROP'
    | 'WELL_HEAD_PRESSURE_DROP'
    | 'WORK_FREQ_5D'
    | 'WORK_GASLIFT_FLOWS_5D'
    | 'WORK_TIMES_5D'
    | 'ACCUMULATION_TIMES_5D',
) => {
  if (
    type === 'Газлифт' ||
    (type === '5D_Газлифт' && targetParam === 'WORK_GASLIFT_FLOWS_5D')
  ) {
    return 'Расход АГ работы, тыс. м3/сут';
  }
  if (type === 'Время') {
    return 'Интервалы работы, мин';
  }
  if (type === 'ЭЦН редуц.' || targetParam === 'WORK_TIMES_5D') {
    return 'Интервал работы, мин';
  }
  if (type === 'Газлифт редуц.' || targetParam === 'ACCUMULATION_TIMES_5D') {
    return 'Интервал накопления, мин';
  }
  if (type === 'ФЧЗ редуц.') {
    return 'Перепад затр. давления, атм';
  }
  if (
    (type === 'ФЧЗ' && targetParam === 'FREQ') ||
    (type === '5D_ЭЦН' && targetParam === 'WORK_FREQ_5D')
  ) {
    return 'Частота работы, Гц';
  }
  if (type === 'ФЧЗ' && targetParam === 'ANNULAR_PRESSURE_DROP') {
    return 'Перепад затр. давления, атм';
  }
  if (type === 'ФЧЗ' && targetParam === 'WELL_HEAD_PRESSURE_DROP') {
    return 'Перепад буф. давления, атм';
  }
  if (type === 'Фонтан') {
    return '';
  }
  return 'Частота, Гц';
};

export const getTableValueByType = (
  type: ChartType,
  wellModel: components['schemas']['GetWellModelQueryResult'],
  pressure_index: number,
  control_action_index: number,
  targetParam?:
    | 'FREQ'
    | 'ANNULAR_PRESSURE_DROP'
    | 'WELL_HEAD_PRESSURE_DROP'
    | 'WORK_FREQ_5D'
    | 'WORK_GASLIFT_FLOWS_5D'
    | 'WORK_TIMES_5D'
    | 'ACCUMULATION_TIMES_5D',
) => {
  switch (type) {
    case 'FlowRates': {
      let value;
      if (targetParam) {
        if (targetParam === 'FREQ') {
          value = (
            wellModel?.model as components['schemas']['GetPermanentAnnularFountainWellModelQueryResult']
          )?.flow_rates__m3_per_day?.[control_action_index][0][0][
            pressure_index
          ];
        } else if (targetParam === 'ANNULAR_PRESSURE_DROP') {
          value = (
            wellModel?.model as components['schemas']['GetPermanentAnnularFountainWellModelQueryResult']
          )?.flow_rates__m3_per_day?.[0][0][control_action_index][
            pressure_index
          ];
        } else if (targetParam === 'WELL_HEAD_PRESSURE_DROP') {
          value = (
            wellModel?.model as components['schemas']['GetPermanentAnnularFountainWellModelQueryResult']
          )?.flow_rates__m3_per_day?.[0][control_action_index][0][
            pressure_index
          ];
        } else if (
          targetParam === 'WORK_FREQ_5D' ||
          targetParam === 'WORK_GASLIFT_FLOWS_5D'
        ) {
          value =
            (
              wellModel?.model as components['schemas']['GetPump5dWellModelQueryResult']
            )?.flow_rates__thos_m3_per_day?.[pressure_index][
              control_action_index
            ][0][0][0] * 1000;
        } else if (targetParam === 'WORK_TIMES_5D') {
          value =
            (
              wellModel?.model as components['schemas']['GetGaslift5dWellModelQueryResult']
            )?.flow_rates__thos_m3_per_day?.[pressure_index][0][0][
              control_action_index
            ][0] * 1000;
        } /* if (targetParam === 'ACCUMULATION_TIMES_5D') */ else {
          value =
            (
              wellModel?.model as components['schemas']['GetGaslift5dWellModelQueryResult']
            )?.flow_rates__thos_m3_per_day?.[pressure_index][0][0][0][
              control_action_index
            ] * 1000;
        }
      } else
        value = (
          wellModel?.model as components['schemas']['GetPeriodicPumpWellModelQueryResult']
        )?.flow_rates__m3_per_day?.[control_action_index][pressure_index];

      if (value !== undefined) return oneDigitsFormatter.format(value);
      return null;
    }
    case 'Power': {
      let value;
      if (targetParam) {
        if (targetParam === 'FREQ') {
          value = (
            wellModel?.model as components['schemas']['GetPermanentAnnularFountainWellModelQueryResult']
          )?.powers__kW?.[control_action_index][0][0][pressure_index];
        } else if (targetParam === 'ANNULAR_PRESSURE_DROP') {
          value = (
            wellModel?.model as components['schemas']['GetPermanentAnnularFountainWellModelQueryResult']
          )?.powers__kW?.[0][0][control_action_index][pressure_index];
        } else if (targetParam === 'WELL_HEAD_PRESSURE_DROP') {
          value = (
            wellModel?.model as components['schemas']['GetPermanentAnnularFountainWellModelQueryResult']
          )?.powers__kW?.[0][control_action_index][0][pressure_index];
        } else if (targetParam === 'WORK_FREQ_5D') {
          value = (
            wellModel?.model as components['schemas']['GetPump5dWellModelQueryResult']
          )?.powers__kW?.[pressure_index][control_action_index][0][0][0];
        } else if (targetParam === 'WORK_TIMES_5D') {
          value = (
            wellModel?.model as components['schemas']['GetPump5dWellModelQueryResult']
          )?.powers__kW?.[pressure_index][0][0][control_action_index][0];
        } /* if (targetParam === 'ACCUMULATION_TIMES_5D') */ else {
          value = (
            wellModel?.model as components['schemas']['GetPump5dWellModelQueryResult']
          )?.powers__kW?.[pressure_index][0][0][0][control_action_index];
        }
      } else
        value = (
          wellModel?.model as components['schemas']['GetPeriodicPumpWellModelQueryResult']
        )?.powers__kW?.[control_action_index][pressure_index];
      if (value !== undefined) return smartFormat(value);
      return null;
    }
    case 'BottomHolePressures': {
      let value;
      if (targetParam) {
        if (targetParam === 'FREQ') {
          value = (
            wellModel?.model as components['schemas']['GetPermanentAnnularFountainWellModelQueryResult']
          )?.bottomhole_pressures__atmg?.[control_action_index][0][0][
            pressure_index
          ];
        } else if (targetParam === 'ANNULAR_PRESSURE_DROP') {
          value = (
            wellModel?.model as components['schemas']['GetPermanentAnnularFountainWellModelQueryResult']
          )?.bottomhole_pressures__atmg?.[0][0][control_action_index][
            pressure_index
          ];
        } else {
          value = (
            wellModel?.model as components['schemas']['GetPermanentAnnularFountainWellModelQueryResult']
          )?.bottomhole_pressures__atmg?.[0][control_action_index][0][
            pressure_index
          ];
        }
      } else
        value = (
          wellModel?.model as components['schemas']['GetPeriodicPumpWellModelQueryResult']
        )?.bottomhole_pressures__atmg?.[control_action_index][pressure_index];
      if (value !== undefined) return oneDigitsFormatter.format(value);
      return null;
    }
    case 'Amperage': {
      return null;
    }
    case 'Loading': {
      return null;
    }
    default:
      return '';
  }
};

export const getTableCaptionByType = (type: ChartType) => {
  switch (type) {
    case 'FlowRates':
      return 'Qж, м3/сут';
    case 'Power':
      return 'Мощность, кВт';
    case 'BottomHolePressures':
      return 'P заб, атм';
    case 'Amperage':
      return 'Сила тока, А';
    case 'Loading':
      return 'Загрузка, A/A';
    default:
      return '';
  }
};

export const getParameterByType = (
  type: ChartType,
  targetParam?:
    | 'FREQ'
    | 'ANNULAR_PRESSURE_DROP'
    | 'WELL_HEAD_PRESSURE_DROP'
    | 'WORK_FREQ_5D'
    | 'WORK_GASLIFT_FLOWS_5D'
    | 'WORK_TIMES_5D'
    | 'ACCUMULATION_TIMES_5D',
) => {
  switch (type) {
    case 'FlowRates': {
      if (
        [
          'WORK_FREQ_5D',
          'WORK_GASLIFT_FLOWS_5D',
          'WORK_TIMES_5D',
          'ACCUMULATION_TIMES_5D',
        ].includes(targetParam as string)
      ) {
        return 'flow_rates__thos_m3_per_day';
      }
      return 'flow_rates__m3_per_day';
    }
    case 'Power':
      return 'powers__kW';
    case 'BottomHolePressures':
      return 'bottomhole_pressures__atmg';
    default:
      return '';
  }
};

export const getUnitByType = (type: ChartType) => {
  switch (type) {
    case 'FlowRates':
      return 'm3_per_day';
    case 'Power':
      return 'joules';
    case 'BottomHolePressures':
      return 'atm';
    case 'Amperage':
      return 'amperes';
    case 'Loading':
      return 'amper_per_amper';
    default:
      return '';
  }
};

export const isModelExistByType = (
  type: ChartType,
  wellModel: components['schemas']['GetWellModelQueryResult'],
) => {
  switch (type) {
    case 'FlowRates': {
      return (
        ((
          wellModel?.model as components['schemas']['GetPeriodicGasliftWellModelQueryResult']
        ).flow_rates__m3_per_day?.length as number) > 0 ||
        ((
          wellModel?.model as components['schemas']['GetPump5dWellModelQueryResult']
        ).flow_rates__thos_m3_per_day?.length as number) > 0
      );
    }
    case 'Power': {
      return (
        ((
          wellModel?.model as components['schemas']['GetPeriodicPumpWellModelQueryResult']
        ).powers__kW?.length as number) > 0
      );
    }
    case 'BottomHolePressures': {
      return (
        ((
          wellModel?.model as components['schemas']['GetPeriodicGasliftWellModelQueryResult']
        ).bottomhole_pressures__atmg?.length as number) > 0
      );
    }
    case 'Amperage': {
      return false;
    }
    case 'Loading': {
      return false;
    }
    default:
      return false;
  }
};

export const getInitTableByType = (
  type: ChartType,
  wellModel: components['schemas']['GetWellModelQueryResult'],
): {
  model: components['schemas']['GetWellModelQueryResult'] | null;
  modified: boolean;
} => {
  if (isModelExistByType(type, wellModel))
    return { model: wellModel, modified: false };
  const initTable: number[][] = [];
  switch (wellModel.model.type) {
    case 'PeriodicPumpWell':
    case 'PermanentPumpWell': {
      (
        wellModel?.model as components['schemas']['GetPeriodicPumpWellModelQueryResult']
      ).frequencies__Hz.forEach((control_action, control_action_ix) => {
        initTable[control_action_ix] = [];
        wellModel?.model.pressures__atmg.forEach((pressure, pressure_ix) => {
          initTable[control_action_ix][pressure_ix] = 0;
        });
      });
      break;
    }
    case 'PeriodicGasliftWell':
    case 'PermanentGasliftWell': {
      (
        wellModel?.model as components['schemas']['GetPeriodicGasliftWellModelQueryResult']
      ).gaslift_flows__thos_m3_per_day.forEach(
        (control_action, control_action_ix) => {
          initTable[control_action_ix] = [];
          wellModel?.model.pressures__atmg.forEach((pressure, pressure_ix) => {
            initTable[control_action_ix][pressure_ix] = 0;
          });
        },
      );
      break;
    }
    case 'PermanentTimedWell': {
      (
        wellModel?.model as components['schemas']['GetPermanentTimedWellModelQueryResult']
      ).work_intervals__min.forEach((control_action, control_action_ix) => {
        initTable[control_action_ix] = [];
        wellModel?.model.pressures__atmg.forEach((pressure, pressure_ix) => {
          initTable[control_action_ix][pressure_ix] = 0;
        });
      });
      break;
    }
    default:
      break;
  }

  switch (type) {
    case 'FlowRates': {
      return {
        model: update(wellModel, {
          model: { flow_rates__m3_per_day: { $set: initTable } },
        }),
        modified: true,
      };
    }
    case 'Power': {
      return {
        model: update(wellModel, {
          model: { powers__kW: { $set: initTable } },
        }),
        modified: true,
      };
    }
    case 'BottomHolePressures': {
      return {
        model: update(wellModel, {
          model: { bottomhole_pressures__atmg: { $set: initTable } },
        }),
        modified: true,
      };
    }
    default:
      return { model: null, modified: false };
  }
};
