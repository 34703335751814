import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import classNames from 'classnames';
import {
  selectPumpById,
  selectShowDetailsPanel,
  setShowDetailsPanel,
  updatePump as updateStorePump,
} from 'store/projectSlice';

import { ReactComponent as DetailsIcon } from '../../../../images/Project/icn-details.svg';
import { ReactComponent as PumpIcon } from '../../../../images/Project/icn-pipe-pump.svg';
import { updatePump } from '../../../../services/apiRequests';
import { IObject } from '../../types';
import ObjectInput from '../components/ObjectInput';
import ObjectStringInput from '../components/ObjectStringInput';

const Pump: React.FC<IObject> = ({ objectUid }) => {
  const dispatch = useDispatch();
  const pumpById = useSelector(selectPumpById);
  const showDetailsPanel = useSelector(selectShowDetailsPanel);
  const params = useParams() as { id: string };

  const handleToggleDetails = () => {
    if (showDetailsPanel === 'pumpModel') dispatch(setShowDetailsPanel(null));
    else dispatch(setShowDetailsPanel('pumpModel'));
  };

  const savePumpValue = async (value: number, name: string) => {
    const oldValue = pumpById[objectUid as string];
    const newValue = { ...oldValue, [name]: value };
    dispatch(updateStorePump(newValue));
    try {
      await updatePump(params.id, objectUid as string, newValue);
    } catch (e) {
      dispatch(updateStorePump(oldValue));
    }
  };

  return (
    <div className="active-object__content full-width">
      <div className="active-object__top-row source-state-row">
        <p className="active-object__title">
          <PumpIcon />
          {pumpById[objectUid as string]?.name}
        </p>
        <button
          className={classNames(
            'active-object__well-model-button',
            showDetailsPanel === 'wellModel' && 'active',
          )}
          type="button"
          onClick={handleToggleDetails}
        >
          <DetailsIcon className="well-model-button-icon" />
        </button>
      </div>
      <div className="active-object__row">
        <ObjectStringInput
          label="Тип насоса"
          className="input active-object__row-input"
          initValue={pumpById[objectUid as string]?.pump_type}
          disabled
          name=""
          saveNewValue={() => {}}
        />
      </div>
      <div className="active-object__row">
        <div className="d-flex">
          <>
            <div className="active-object__input-with-unit-wrapper left">
              <ObjectInput
                className="input active-object__row-input short with-unit"
                label="Базовая скорость"
                name="base_velocity__rpm"
                initValue={pumpById[objectUid as string]?.base_velocity__rpm}
                saveNewValue={() => {}}
                disabled
              />
              <p className="active-object__row-input-unit">об/мин</p>
            </div>
          </>
          <div className="active-object__input-with-unit-wrapper">
            <>
              <ObjectInput
                className="input active-object__row-input short with-unit"
                label="Рабочая скорость"
                name="velocity__rpm"
                initValue={pumpById[objectUid as string]?.velocity__rpm}
                saveNewValue={savePumpValue}
              />
              <p className="active-object__row-input-unit">об/мин</p>
            </>
          </div>
        </div>
      </div>
      <p className="active-object__subtitle limitations">
        Индивидуальные ограничения
      </p>
      <div className="active-object__row">
        <div className="d-flex">
          <>
            <div className="active-object__input-with-unit-wrapper left">
              <ObjectInput
                className="input active-object__row-input short with-unit"
                label="Мин. рабочая скорость"
                name="min_velocity__rpm"
                initValue={pumpById[objectUid as string]?.min_velocity__rpm}
                saveNewValue={() => {}}
                disabled
              />
              <p className="active-object__row-input-unit">об/мин</p>
            </div>
          </>
          <div className="active-object__input-with-unit-wrapper">
            <>
              <ObjectInput
                className="input active-object__row-input short with-unit"
                label="Макс. рабочая скорость"
                name="max_velocity__rpm"
                initValue={pumpById[objectUid as string]?.max_velocity__rpm}
                saveNewValue={() => {}}
                disabled
              />
              <p className="active-object__row-input-unit">об/мин</p>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pump;
