import { useEffect } from 'react';

import { ColDef } from 'ag-grid-community';

export const usePutTableToStorage = (tableName: string, colDefs: ColDef[]) => {
  useEffect(() => {
    const isDevelop = window.location.pathname.split('/').includes('develop')
      ? '_develop'
      : '';
    const isMaster = window.location.pathname.split('/').includes('master')
      ? '_master'
      : '';
    const columns = localStorage.getItem(tableName + isDevelop + isMaster);
    if (!columns) {
      localStorage.setItem(
        tableName + isDevelop + isMaster,
        JSON.stringify(colDefs.map(def => [def.colId, true])),
      );
    }
  }, []);
};
