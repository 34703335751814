import * as React from 'react';

import { usePutTableToStorage } from '../../../../../hooks/usePutTableToStorage';
import SourcesTable from './SourceTable';
import { wellfieldsMOColumnDefs } from './utils';

export const TABLE_NAME = 'PPD_MO_SOURCES';
const Table: React.FC = () => {
  usePutTableToStorage(TABLE_NAME, wellfieldsMOColumnDefs);
  return <SourcesTable />;
};

export default Table;
