import * as React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from 'react-tabs';

import AgGridTable from 'Components/AgGridTable';
import { components } from 'generated/apiTypes';
import { selectWellCompareStateTable } from 'store/taskSlice';

import { formatThreeDigits, formatTwoDigits } from 'utils';
import { useGetColumnDefs } from '../../../hooks/useGetColumnDefs';
import { compareWellTableColumnDefs } from './utils';

const TABLE_NAME = 'UA_COMPARE';
const CompareWellTable: React.FC = () => {
  const compareWellsTable = useSelector(selectWellCompareStateTable);
  const [data, setData] = React.useState<any[]>([]);
  useEffect(() => {
    const columns = localStorage.getItem(TABLE_NAME);
    if (!columns) {
      localStorage.setItem(
        TABLE_NAME,
        JSON.stringify(
          compareWellTableColumnDefs.map(def => [def.colId, true]),
        ),
      );
    }
  }, []);
  const columnDef = useGetColumnDefs(TABLE_NAME, compareWellTableColumnDefs);
  React.useEffect(() => {
    const _data: any[] = [];
    compareWellsTable?.states.forEach(state => {
      _data.push({
        calc_current_state: state.calc_current_state,
        preset_current_state: state.preset_current_state,
        well_name: state.well_name,
        operation_type: state.operation_type,
        control_type: state.control_type,
        fact_offset_time: formatTwoDigits(state.preset_offset_time),
        base_offset_time: {
          value: formatTwoDigits(state.calc_offset_time),
          abs: formatTwoDigits(state.offset_time_abs_error),
          rel: formatTwoDigits(state.offset_time_rel_error),
          unit: 'мин',
        },
        fact_pressure: formatThreeDigits(state.preset_avg_pressure.atm),
        base_pressure: {
          value:
            state.calc_avg_pressure &&
            formatTwoDigits(state.calc_avg_pressure.atm),
          abs:
            state.pressure_abs_error &&
            formatTwoDigits(state.pressure_abs_error.atm),
          rel:
            state.pressure_rel_error &&
            formatTwoDigits(state.pressure_rel_error),
          unit: 'атм',
        },
        fact_fluid_flow_rate: formatThreeDigits(
          state.preset_fluid_avg_flow_rate.m3_per_day,
        ),
        base_fluid_flow_rate: {
          value: formatThreeDigits(state.calc_fluid_avg_flow_rate.m3_per_day),
          abs: formatThreeDigits(state.fluid_flow_rate_abs_error.m3_per_day),
          rel: formatThreeDigits(state.fluid_flow_rate_rel_error),
          unit: 'м3/сут',
        },
        fact_gas_flow_rate: formatThreeDigits(
          state.preset_gas_avg_flow_rate.thousand_m3_per_day,
        ),
        base_gas_flow_rate: {
          value: formatThreeDigits(
            state.calc_gas_avg_flow_rate.thousand_m3_per_day,
          ),
          abs: formatThreeDigits(
            state.gas_flow_rate_abs_error.thousand_m3_per_day,
          ),
          rel: formatThreeDigits(state.gas_flow_rate_rel_error),
          unit: 'тыс. м3/сут',
        },
        fact_oil_flow_rate: formatThreeDigits(
          state.preset_oil_avg_flow_rate.t_per_day,
        ),
        base_oil_flow_rate: {
          value: formatThreeDigits(state.calc_oil_avg_flow_rate.t_per_day),
          abs: formatThreeDigits(state.oil_flow_rate_abs_error.t_per_day),
          rel: formatThreeDigits(state.oil_flow_rate_rel_error),
          unit: 'т/сут',
        },
        fact_control_freq:
          state.operation_type === 'ЭЦН' && state.preset_control
            ? formatThreeDigits(
                (state.preset_control as components['schemas']['PNOFrequency'])!
                  .hertz,
              )
            : null,
        base_control_freq: {
          value:
            state.operation_type === 'ЭЦН' && state.calc_control
              ? formatThreeDigits(
                  (state.calc_control as components['schemas']['PNOFrequency'])
                    .hertz,
                )
              : null,
          abs:
            state.operation_type === 'ЭЦН' && state.control_abs_error
              ? formatTwoDigits(
                  (
                    state.control_abs_error as components['schemas']['PNOFrequency']
                  ).hertz,
                )
              : 0,
          rel:
            state.operation_type === 'ЭЦН' && state.control_rel_error
              ? formatTwoDigits(state.control_rel_error)
              : 0,
          unit: 'Гц',
        },
        fact_control_flow:
          state.operation_type === 'Газлифт' && state.preset_control
            ? formatThreeDigits(
                (state.preset_control as components['schemas']['PNOVolumeFlow'])
                  .thousand_m3_per_day,
              )
            : null,
        base_control_flow: {
          value:
            state.operation_type === 'Газлифт' && state.calc_control
              ? formatThreeDigits(
                  (state.calc_control as components['schemas']['PNOVolumeFlow'])
                    .thousand_m3_per_day,
                )
              : null,
          abs:
            state.operation_type === 'Газлифт' && state.control_abs_error
              ? formatTwoDigits(
                  (
                    state.control_abs_error as components['schemas']['PNOVolumeFlow']
                  ).thousand_m3_per_day,
                )
              : 0,
          rel:
            state.operation_type === 'Газлифт' && state.control_rel_error
              ? formatTwoDigits(state.control_rel_error)
              : 0,
          unit: 'тыс. м3/сут',
        },
        fact_work_time: formatTwoDigits(state.preset_work_time__min),
        base_work_time: {
          value: formatTwoDigits(state.calc_work_time__min),
          abs: formatTwoDigits(state.work_time_abs_error__min),
          rel: formatTwoDigits(state.work_time_rel_error),
          unit: 'мин',
        },
        fact_accumulation_time: formatTwoDigits(
          state.preset_accumulation_time__min,
        ),
        base_accumulation_time: {
          value: formatTwoDigits(state.calc_accumulation_time__min),
          abs: formatTwoDigits(state.accumulation_time_abs_error__min),
          rel: formatTwoDigits(state.accumulation_time_rel_error),
          unit: 'мин',
        },
      });
    });
    setData(_data);
  }, [compareWellsTable]);

  return (
    <AgGridTable
      style={{ height: '55vh' }}
      columnDefs={columnDef}
      rowData={data}
      tableName={TABLE_NAME}
    />
  );
};
const UACompareResults: React.FC = () => {
  const [tabIndex, setTabIndex] = React.useState(1);

  return (
    <Tabs
      className="results__tabs"
      selectedIndex={tabIndex}
      onSelect={index => setTabIndex(index)}
    >
      <div
        className="results__tab-list underline"
        style={{ marginBottom: '40px' }}
      >
        <div className="results__tab compare">Скважины</div>
      </div>
      <div className="results-tables__container">
        <CompareWellTable />
      </div>
    </Tabs>
  );
};

export default UACompareResults;
