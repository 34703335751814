import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { ReactComponent as IconLineChart } from 'images/Project/icn-line-chart.svg';

interface IElectricityConsumptionButton
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  checked: boolean;
}
export const ElectricityConsumptionButton: React.FC<
  IElectricityConsumptionButton
> = ({ checked = false, ...props }) => {
  const [active, setActive] = useState(false);
  useEffect(() => {
    setActive(checked);
  }, [checked]);
  const onClickHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setActive(!active);
    props.onClick && props.onClick(event);
  };
  return (
    <button
      type="button"
      className={classNames(
        'project-results-panel__electricity-consumption-btn',
        { active },
      )}
      onClick={onClickHandler}
    >
      <IconLineChart />
      Расход ЭЭ
    </button>
  );
};
