import * as React from 'react';
import { useSelector } from 'react-redux';

import AgGridTable from 'Components/AgGridTable';
import { components } from 'generated/apiTypes';
import { selectSolution } from 'store/taskSlice';

import { formatThreeDigits, formatTwoDigits } from 'utils';
import { useGetColumnDefs } from '../../../../../hooks/useGetColumnDefs';
import { nodesUAColumnDefs } from '../../../Nodes/UA/Table/utils';
import { TABLE_NAME } from './Table';

export interface ISinksTableProps {
  keyD: string;
  sinksUID: any;
}
const SinksByStepTable: React.FC<ISinksTableProps> = ({ keyD, sinksUID }) => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOPeriodicNodalAnalysisProblem'];
  const [data, setData] = React.useState<any[]>([]);
  const columnDef = useGetColumnDefs(TABLE_NAME, nodesUAColumnDefs);
  const getData = () => {
    const newData: any[] = [];
    solution?.solution?.node_step_table?.time2states[keyD]
      ?.filter(state => sinksUID?.includes(state.uid))
      .forEach(state => {
        newData.push({
          name: state.name,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          oil_flow_rate: formatThreeDigits(state.oil_flow_rate_mass.t_per_day),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          fluid_flow_rate: formatThreeDigits(
            state.fluid_flow_rate_volume.m3_per_day,
          ),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          gas_flow_rate: formatThreeDigits(
            state.gas_flow_rate_volume.thousand_m3_per_day,
          ),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          pressure: formatTwoDigits(state.pressure.atm),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          temperature: formatTwoDigits(state.temperature.celsius),
        });
      });
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [solution, keyD, sinksUID]);

  return (
    <AgGridTable tableName={TABLE_NAME} columnDefs={columnDef} rowData={data} />
  );
};

export default SinksByStepTable;
