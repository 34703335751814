import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectExperimentStatus, selectExperimentType } from 'store/taskSlice';

import Button from '../../../Components/Button';
import { setShowDialog } from '../../../store/commonSlice';
import { ITaskSettings } from '../types';
import AFSettings from './AFSettings';
import MOSettings from './MOSettings';
import PNASettings from './PNASettings';

const TaskSettings: React.FC<ITaskSettings> = ({ calcTask }) => {
  const dispatch = useDispatch();
  const type = useSelector(selectExperimentType);
  const status = useSelector(selectExperimentStatus);
  const [experimentSettingsSaved, setExperimentSettingsSaved] =
    React.useState<boolean>(true);
  const [waitingForSaving, setWaitingForSaving] =
    React.useState<boolean>(false);
  const [UATask, setUATask] = React.useState<
    'SOLVED' | 'NONE' | 'NOT_ACTUAL'
  >();
  const [incompleteConstraints, setIncompleteConstraints] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (['Оптимизация режима', 'Базовый режим'].includes(type))
      setUATask(undefined);
  }, [type]);

  React.useEffect(() => {
    if (waitingForSaving && experimentSettingsSaved) {
      setWaitingForSaving(false);
      calcTask();
    }
  }, [waitingForSaving, experimentSettingsSaved]);

  const onCancelTaskClick = () => {
    dispatch(
      setShowDialog({
        dialogType: 'CANCEL_TASK_CONFIRM',
      }),
    );
  };

  const calcTaskHandle = () => {
    if (experimentSettingsSaved) {
      calcTask();
    } else {
      setWaitingForSaving(true);
    }
  };

  return (
    <>
      <div className="active-object__content full-width">
        <p className="project-task-settings__title">Параметры расчета</p>
        {type === 'Базовый режим' && (
          <PNASettings
            setExperimentSettingsSaved={setExperimentSettingsSaved}
          />
        )}
        {type === 'Адаптация на факт' && (
          <AFSettings setExperimentSettingsSaved={setExperimentSettingsSaved} />
        )}
        {type === 'Оптимизация режима' && (
          <MOSettings
            setUATask={setUATask}
            setExperimentSettingsSaved={setExperimentSettingsSaved}
            setIncompleteConstraints={setIncompleteConstraints}
          />
        )}
      </div>
      <div className="active-object__content">
        {type === 'Оптимизация режима' && UATask === 'NONE' && (
          <div className="project-task-settings__hint">
            Для расчета оптимизации режима необходимо рассчитать базовый режим
          </div>
        )}
        <div className="project-task-settings__hint">
          {type === 'Оптимизация режима' && UATask === 'NOT_ACTUAL'
            ? 'Для расчета оптимизации режима с новыми данными необходимо пересчитать базовый режим'
            : ''}
        </div>
        <Button
          type="submit"
          onClick={calcTaskHandle}
          disabled={
            [
              'DRAFT',
              'SENT',
              'PREPARING',
              'PREPARED',
              'SOLVING',
              'INTERRUPTING',
            ].includes(status) ||
            (type === 'Оптимизация режима' && UATask !== 'SOLVED') ||
            incompleteConstraints
          }
        >
          {[
            'DRAFT',
            'SENT',
            'PREPARING',
            'PREPARED',
            'SOLVING',
            'INTERRUPTING',
          ].includes(status)
            ? 'Идет расчёт…'
            : 'Запустить расчёт'}
        </Button>
        {['DRAFT', 'SENT', 'PREPARING', 'PREPARED', 'SOLVING'].includes(
          status,
        ) && (
          <Button
            type="button"
            mode="secondary"
            onClick={onCancelTaskClick}
            className="project-task-settings__cancel-btn"
          >
            Остановить расчёт
          </Button>
        )}
      </div>
    </>
  );
};

export default TaskSettings;
