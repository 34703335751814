import {
  CompareCellComparator,
  CompareCellFilterGetter,
  CompareCellRenderer,
} from 'Components/AgGridTable/CompareCellRenderer/compareCellRenderer';
import { StatusRenderer } from 'Components/AgGridTable/StatusRenderer/statusRenderer';

import { formatThreeDigits, formatTwoDigits } from 'utils';
import { isNumber } from '../../../../utils';

export const templateRowsNumber = 5;
const compareCellColumnDefs = {
  cellRenderer: CompareCellRenderer,
  filterValueGetter: CompareCellFilterGetter,
  comparator: CompareCellComparator,
};
export const wellfieldsMOColumnDefs = [
  {
    colId: '0',
    headerName: 'Статус',
    field: 'current_state',
    cellRenderer: StatusRenderer,
    initialWidth: 120,
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '1',
    headerName: 'Название',
    field: 'well_name',
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '2',
    headerName: 'Способ эксплуатации',
    field: 'operation_type',
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '3',
    headerName: 'Дебит нефти, т\u2060/\u2060сут',
    field: 'oil_flow_rate',
    ...compareCellColumnDefs,
  },
  {
    colId: '4',
    headerName: 'Дебит жидкости, м\u00B3\u2060/\u2060сут',
    field: 'fluid_flow_rate',
    ...compareCellColumnDefs,
  },
  {
    colId: '5',
    headerName: 'Дебит газа, тыс.м\u00B3\u2060/\u2060сут',
    field: 'gas_flow_rate',
    ...compareCellColumnDefs,
  },
  {
    colId: '6',
    headerName: 'Линейное давление, атм',
    field: 'pressure',
    ...compareCellColumnDefs,
  },
  {
    colId: '7',
    headerName: 'Забойное давление, атм',
    field: 'bottomhole_pressure',
    ...compareCellColumnDefs,
  },
  {
    colId: '9',
    headerName: 'Частота работы, Гц',
    field: 'frequency',
    ...compareCellColumnDefs,
  },
  {
    colId: '10',
    headerName: 'Диаметр штуцера, мм',
    field: 'diameter',
    ...compareCellColumnDefs,
  },
];

export const wellsTableItems = ({
  well_name,
  operation_type,
  current_state,
  oil_flow_rate,
  oil_flow_rate_mass_abs,
  oil_flow_rate_mass_rel,
  fluid_flow_rate,
  fluid_flow_rate_volume_abs,
  fluid_flow_rate_volume_rel,
  gas_flow_rate,
  gas_flow_rate_abs,
  gas_flow_rate_rel,
  pressure,
  pressure_abs,
  pressure_rel,
  bottomhole_pressure,
  bottomhole_pressure_abs,
  bottomhole_pressure_rel,
  control,
  control_abs,
  control_rel,
}: any = {}) => {
  return {
    current_state,
    well_name,
    operation_type,
    oil_flow_rate: {
      value: formatThreeDigits(oil_flow_rate?.t_per_day),
      abs: formatTwoDigits(oil_flow_rate_mass_abs?.t_per_day),
      rel: formatTwoDigits(oil_flow_rate_mass_rel),
      unit: 'т/сут',
      cssClass:
        // eslint-disable-next-line no-nested-ternary
        isNumber(oil_flow_rate_mass_abs?.t_per_day) &&
        oil_flow_rate_mass_abs?.t_per_day !== 0
          ? oil_flow_rate_mass_abs?.t_per_day > 0
            ? 'green'
            : 'red'
          : undefined,
    },
    fluid_flow_rate: {
      value: formatThreeDigits(fluid_flow_rate?.m3_per_day),
      abs: formatTwoDigits(fluid_flow_rate_volume_abs?.m3_per_day),
      rel: formatTwoDigits(fluid_flow_rate_volume_rel),
      unit: 'м\u00B3\u2060/\u2060сут',
    },
    gas_flow_rate: {
      value: formatThreeDigits(gas_flow_rate?.thousand_m3_per_day),
      abs: formatTwoDigits(gas_flow_rate_abs?.thousand_m3_per_day),
      rel: formatTwoDigits(gas_flow_rate_rel),
      unit: 'тыс.м\u00B3\u2060/\u2060сут',
    },
    pressure: {
      value: formatThreeDigits(pressure?.atm),
      abs: formatTwoDigits(pressure_abs?.atm),
      rel: formatTwoDigits(pressure_rel),
      unit: 'атм',
    },
    bottomhole_pressure: {
      value: formatThreeDigits(bottomhole_pressure.atm),
      abs: formatTwoDigits(bottomhole_pressure_abs?.atm),
      rel: formatTwoDigits(bottomhole_pressure_rel),
      unit: 'атм',
    },
    frequency: {
      value:
        operation_type === 'Добывающая'
          ? formatThreeDigits(control?.hertz)
          : null,
      abs:
        operation_type === 'Добывающая'
          ? formatThreeDigits(control_abs?.hertz)
          : null,
      rel:
        operation_type === 'Добывающая' ? formatTwoDigits(control_rel) : null,
      unit: 'Гц',
    },
    diameter: {
      value:
        operation_type === 'Нагнетательная'
          ? formatThreeDigits(control?.mm)
          : null,
      abs:
        operation_type === 'Нагнетательная'
          ? formatTwoDigits(control_abs?.mm)
          : null,
      rel:
        operation_type === 'Нагнетательная'
          ? formatTwoDigits(control_rel)
          : null,
      unit: 'мм',
    },
  };
};
