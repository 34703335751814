import React, { useEffect, useRef, useState } from 'react';
import { MapContext } from 'react-mapbox-gl';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { setGeojsonLine } from 'Pages/Project/utils';
import { setShowDialog } from 'store/commonSlice';
import {
  setActiveObject,
  setLoopingsData,
  updateDrawState,
} from 'store/mapSlice';
import {
  setGatheringCentersModels,
  setNodes,
  setSegments,
  setWells,
  updateNodesNetwork,
  updateSegmentsNetwork,
} from 'store/projectSlice';

import { components } from '../../../../generated/apiTypes';
import { getTasks, updateNetwork } from '../../../../services/apiRequests';
import { RootState } from '../../../../store/store';
import { selectTaskId, setIsActual } from '../../../../store/taskSlice';
import {
  ICommonSlice,
  IMapSlice,
  IProjectSlice,
  ISocketSlice,
  ITaskSlice,
  SelectWellById,
} from '../../../../store/types';
import { ObjectType } from '../../types';
import {
  composeObjectsById,
  isJunctionGuard,
  isNodeGuard,
  isSinkGuard,
  isSourceGuard,
  isStoppedGuard,
} from '../../utils';
import AddLayer from '../MapMethods/AddLayer';
import AddSource from '../MapMethods/AddSource';
import { disableElementSelected } from '../MapMethods/functions';
import MapDraw from '../MapMethods/MapDraw';
import {
  chokePaint,
  colorsLinePaint,
  hoverLinePaint,
  layerProps,
  lineDraw,
  linePaint,
  loopingsLinePaint,
  pumpPaint,
  selectedLinePaint,
  separatorGasPaint,
  separatorOilPaint,
  separatorPaint,
  separatorWaterPaint,
} from '../utils';

interface Props {
  nodes: IProjectSlice['nodes'];
  segments: IProjectSlice['segments'];
  separators: IProjectSlice['separators'];
  activeObject: IMapSlice['activeObject'];
  drawState: IMapSlice['drawState'];
  wells: IProjectSlice['wells'];
  loopingsState: IMapSlice['loopingsState'];
  socketState: ISocketSlice['socketState'];
  showDialog: ICommonSlice['showDialog'];
  task: ITaskSlice['solution'];
  linesColor: IMapSlice['linesColor'];
  showResults: ITaskSlice['showResults'];
  reservoir: IProjectSlice['reservoir'];
  prodWells: IProjectSlice['production_wells']['items'];
  injWells: IProjectSlice['injection_wells']['items'];
  sinks: IProjectSlice['sinks']['items'];
  sources: IProjectSlice['sources']['items'];
  pumps: IProjectSlice['pumps']['items'];
  chokes: IProjectSlice['chokes']['items'];
  threePhaseSeparators: IProjectSlice['three_phase_separators']['items'];
}

function Features(props: Props) {
  const {
    segments,
    separators,
    activeObject,
    drawState,
    wells,
    loopingsState,
    socketState,
    showDialog,
    showResults,
    task,
    linesColor,
    nodes,
    reservoir,
    prodWells,
    injWells,
    sinks,
    sources,
    pumps,
    chokes,
    threePhaseSeparators,
  } = props;
  const dispatch = useDispatch();
  const params = useParams() as { id: string };
  const [mapInit, setMapInit] = useState(false);
  const [mapDraw, setMapDraw] = useState<MapDraw>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [mapIsDraw, setMapIsDraw] = useState(false);
  const mapContext = React.useContext(MapContext);
  const [updateLines, setUpdateLines] = useState<any>(false);
  const [updateSeparators, setUpdateSeparators] = useState<any>(false);
  const [updatePumps, setUpdatePumps] = useState<any>(false);
  const [updateChoke, setUpdateChoke] = useState<any>(false);
  const [updateThreePhaseSeparatorOil, setUpdateThreePhaseSeparatorOil] =
    useState<any>(false);
  const [updateThreePhaseSeparatorWater, setUpdateThreePhaseSeparatorWater] =
    useState<any>(false);
  const [updateThreePhaseSeparatorGas, setUpdateThreePhaseSeparatorGas] =
    useState<any>(false);
  const drawStateCurrent = useRef<any>(null);
  const socketStateCurrent = useRef<any>(null);
  const [isLinesLoaded, setIsLinesLoaded] = useState(false);
  const [isSeparatorsLoaded, setIsSeparatorsLoaded] = useState(false);
  const [isPumpsLoaded, setIsPumpsLoaded] = useState(false);
  const [isChokeLoaded, setIsChokeLoaded] = useState(false);
  const [isThreePhaseSeparatorOilLoaded, setIsThreePhaseSeparatorOilLoaded] =
    useState(false);
  const [
    isThreePhaseSeparatorWaterLoaded,
    setIsThreePhaseSeparatorWaterLoaded,
  ] = useState(false);
  const [isThreePhaseSeparatorGasLoaded, setIsThreePhaseSeparatorGasLoaded] =
    useState(false);
  const nodesItems = props.nodes.items.filter(isNodeGuard);
  const nodesRef: any = useRef(null);
  const segmentsRef: any = useRef(null);
  const wellsById = wells.items.reduce<SelectWellById>(composeObjectsById, {});
  const isWellWorking = isStoppedGuard(wellsById, wells.items);
  const workingWells = nodesItems?.filter(isWellWorking('IN_WORK'));
  const stoppedWells = nodesItems?.filter(isWellWorking('STOPPED'));
  // Скважина
  const source = workingWells?.filter(isSourceGuard);
  // Добывающая скважина
  const prodSource = nodesItems?.filter(node =>
    prodWells.find(well => well.node_uid === node.uid),
  );
  // Нагнетательная скважина
  const injSource = nodesItems?.filter(node =>
    injWells.find(well => well.node_uid === node.uid),
  );
  // Сток
  const sinkPPD = nodesItems?.filter(node =>
    sinks.find(sink => sink.node_id === node.uid),
  );
  // Исток
  const sourcePPD = nodesItems?.filter(node =>
    sources.find(sour => sour.node_id === node.uid),
  );
  // Трехфазный сепаратор
  /* const separatorPPD = nodesItems?.filter(node =>
    separatorsPPD.find(sepItem => sepItem.in_node_uid === node.uid),
  ); */
  // Соединение
  const junction = nodesItems?.filter(isJunctionGuard);
  // Центр сбора
  const sink = nodesItems?.filter(isSinkGuard);
  // Лупинги
  const loopingSegments = (
    task.solution
      ?.solution as components['schemas']['PNOTechNetworkOptimizationSolution']
  )?.looping_segments;

  const taskId = useSelector(selectTaskId);

  const lineProps = (param: IProjectSlice['segments']['items']) => {
    const arr = param.reduce<
      { coords: number[][]; props: { [key: string]: string | undefined } }[]
    >((acc, cur) => {
      if (cur.coordinates) {
        acc.push({
          coords: cur.coordinates,
          props: {
            uid: cur.uid,
            start_node_name: cur.start_node_name,
            end_node_name: cur.end_node_name,
            start_node_uid: cur.start_uid || undefined,
            end_node_uid: cur.end_uid || undefined,
          },
        });
      }

      return acc;
    }, []);

    return arr;
  };

  const pumpLineProps = (param: IProjectSlice['pumps']['items']) => {
    const arr = param.reduce<
      { coords: number[][]; props: { [key: string]: string | undefined } }[]
    >((acc, cur) => {
      const inNodeCoords = nodesItems?.find(
        node => cur.in_node_uid === node.uid,
      )?.coordinates;
      const outNodeCoords = nodesItems?.find(
        node => cur.out_node_uid === node.uid,
      )?.coordinates;
      if (inNodeCoords && outNodeCoords) {
        acc.push({
          coords: [inNodeCoords, outNodeCoords],
          props: {
            uid: cur.uid,
            start_node_uid: cur.in_node_uid || undefined,
            end_node_uid: cur.out_node_uid || undefined,
          },
        });
      }

      return acc;
    }, []);

    return arr;
  };

  const chokeLineProps = (param: IProjectSlice['chokes']['items']) => {
    const arr = param.reduce<
      { coords: number[][]; props: { [key: string]: string | undefined } }[]
    >((acc, cur) => {
      const inNodeCoords = nodesItems?.find(
        node => cur.start_node_id === node.uid,
      )?.coordinates;
      const outNodeCoords = nodesItems?.find(
        node => cur.end_node_id === node.uid,
      )?.coordinates;
      if (inNodeCoords && outNodeCoords) {
        acc.push({
          coords: [inNodeCoords, outNodeCoords],
          props: {
            uid: cur.id,
            start_node_uid: cur.start_node_id || undefined,
            end_node_uid: cur.end_node_id || undefined,
          },
        });
      }
      return acc;
    }, []);
    return arr;
  };

  const threePhaseSeparatorsOilProps = (
    param: IProjectSlice['three_phase_separators']['items'],
  ) => {
    const arr = param.reduce<
      { coords: number[][]; props: { [key: string]: string | undefined } }[]
    >((acc, cur) => {
      const inNodeCoords = nodesItems?.find(
        node => cur.in_node_uid === node.uid,
      )?.coordinates;
      const outNodeCoords = nodesItems?.find(
        node => cur.oil_out_node_uid === node.uid,
      )?.coordinates;
      if (inNodeCoords && outNodeCoords) {
        acc.push({
          coords: [inNodeCoords, outNodeCoords],
          props: {
            uid: cur.uid,
            start_node_uid: cur.in_node_uid || undefined,
            end_node_uid: cur.oil_out_node_uid || undefined,
          },
        });
      }

      return acc;
    }, []);

    return arr;
  };

  const threePhaseSeparatorsWaterProps = (
    param: IProjectSlice['three_phase_separators']['items'],
  ) => {
    const arr = param.reduce<
      { coords: number[][]; props: { [key: string]: string | undefined } }[]
    >((acc, cur) => {
      const inNodeCoords = nodesItems?.find(
        node => cur.in_node_uid === node.uid,
      )?.coordinates;
      const outNodeCoords = nodesItems?.find(
        node => cur.water_out_node_uid === node.uid,
      )?.coordinates;
      if (inNodeCoords && outNodeCoords) {
        acc.push({
          coords: [inNodeCoords, outNodeCoords],
          props: {
            uid: cur.uid,
            start_node_uid: cur.in_node_uid || undefined,
            end_node_uid: cur.water_out_node_uid || undefined,
          },
        });
      }

      return acc;
    }, []);

    return arr;
  };

  const threePhaseSeparatorsGasProps = (
    param: IProjectSlice['three_phase_separators']['items'],
  ) => {
    const arr = param.reduce<
      { coords: number[][]; props: { [key: string]: string | undefined } }[]
    >((acc, cur) => {
      const inNodeCoords = nodesItems?.find(
        node => cur.in_node_uid === node.uid,
      )?.coordinates;
      const outNodeCoords = nodesItems?.find(
        node => cur.gas_out_node_uid === node.uid,
      )?.coordinates;
      if (inNodeCoords && outNodeCoords) {
        acc.push({
          coords: [inNodeCoords, outNodeCoords],
          props: {
            uid: cur.uid,
            start_node_uid: cur.in_node_uid || undefined,
            end_node_uid: cur.gas_out_node_uid || undefined,
          },
        });
      }

      return acc;
    }, []);

    return arr;
  };

  const separatorLineProps = (param: IProjectSlice['separators']) => {
    const arr = param.reduce<
      { coords: number[][]; props: { [key: string]: string | undefined } }[]
    >((acc, cur) => {
      if (cur.coordinates) {
        acc.push({
          coords: cur.coordinates,
          props: {
            uid: cur.id,
            start_node_uid: cur.start_node_id,
            end_node_uid: cur.end_node_id,
          },
        });
      }

      return acc;
    }, []);

    return arr;
  };

  if (!lineProps) return null;
  const iconsObj: any = {
    ...(!reservoir?.uid && { source }),
    ...(reservoir?.uid && { prodSource }),
    ...(reservoir?.uid && { injSource }),
    ...(reservoir?.uid && { sinkPPD }),
    ...(reservoir?.uid && { sourcePPD }),
    junction,
    ...(!reservoir?.uid && { sink }),
    ...(!reservoir?.uid && { sourceStopped: stoppedWells }),
  };
  const segmentsGeojson = setGeojsonLine(lineProps(segments.items));
  const separatorsGeojson = setGeojsonLine(separatorLineProps(separators));
  const pumpsGeojson = setGeojsonLine(pumpLineProps(pumps));
  const chokeGeojson = setGeojsonLine(chokeLineProps(chokes));
  const threePhaseSeparatorsOilGeojson = setGeojsonLine(
    threePhaseSeparatorsOilProps(threePhaseSeparators),
  );
  const threePhaseSeparatorsWaterGeojson = setGeojsonLine(
    threePhaseSeparatorsWaterProps(threePhaseSeparators),
  );
  const threePhaseSeparatorsGasGeojson = setGeojsonLine(
    threePhaseSeparatorsGasProps(threePhaseSeparators),
  );

  const updateNodesFeatures = async () => {
    const elem: any = nodesItems.find(
      (item: any) => item.uid === activeObject?.node_uid,
    );
    if (elem) {
      mapDraw?.updateFeatures(
        'Point',
        { uid: elem.uid, type: elem.type.toLowerCase(), name: elem.name },
        elem.coordinates,
      );
    }
    if (nodesRef.current.isUpdate) {
      dispatch(updateNodesNetwork(false));

      const response = await updateNetwork({
        project_id: params.id,
        nodes: props.nodes.items,
        segments: props.segments.items,
      });

      const res = await getTasks(params.id);
      if (res?.data?.length > 0) {
        const currTask = res?.data?.find(item => item.task_uid === taskId);
        if (currTask && !currTask.is_actual) {
          dispatch(setIsActual(currTask.is_actual!));
        }
      }

      if (elem) {
        if (elem.type === 'SOURCE') {
          dispatch(setWells(response.data.wells));
        } else if (elem.type === 'SINK') {
          dispatch(
            setGatheringCentersModels(response.data.gathering_centers_models),
          );
        }
      }
    }
  };

  const handleNodesOnUp = (uid: any, coordinates: [number, number]) => {
    const node = nodesRef.current.items.find((item: any) => item.uid === uid);
    if (node) {
      const newNodesArr = nodesRef.current.items.filter(
        (item: any) => item.uid !== uid,
      );
      const newNode = {
        coordinates,
        uid: node.uid,
        name: node.name,
        type: node.type,
      };
      newNodesArr.push(newNode);
      const segment = segmentsRef.current.items.filter(
        (item: any) => item.start_uid === node.uid || item.end_uid === node.uid,
      );
      if (segment.length) {
        const copy = JSON.parse(JSON.stringify(segmentsRef.current.items));
        const newSegmentsArr = copy.map((item: any) => {
          if (item.start_uid === node.uid) {
            let newCoordinates = item.coordinates.slice(1);
            newCoordinates = [coordinates, ...newCoordinates];
            item.coordinates = newCoordinates;
          }
          if (item.end_uid === node.uid) {
            let newCoordinates = item.coordinates.slice(0, -1);
            newCoordinates = [...newCoordinates, coordinates];
            item.coordinates = newCoordinates;
          }
          return item;
        });
        dispatch(setSegments(newSegmentsArr));
      }
      dispatch(setNodes(newNodesArr));
      dispatch(updateSegmentsNetwork(true));
    }
  };

  const updateSegmentsFeatures = async () => {
    if (props.segments.isUpdate) {
      const response = await updateNetwork({
        project_id: params.id,
        nodes: props.nodes.items,
        segments: props.segments.items,
      });
      console.log('RESPONSE', response);
      dispatch(updateSegmentsNetwork(false));
      const res = await getTasks(params.id);
      if (res?.data?.length > 0) {
        const currTask = res?.data?.find(item => item.task_uid === taskId);
        if (currTask && !currTask.is_actual) {
          dispatch(setIsActual(currTask.is_actual!));
        }
      }
    }
  };

  const handleSegment = async (data: any) => {
    if (
      data &&
      drawState.activeElement !== drawStateCurrent.current.activeElement
    ) {
      // TODO настроить перехват ответа с бэка
      const obj: any = {
        uid: data.uid,
        start_uid: data.start_uid,
        end_uid: data.end_uid,
        start_node_name: '',
        end_node_name: '',
        diameter: { m: 0.2, mm: 200 },
        wall_thickness: { m: 0.01, mm: 10 },
        roughness: { m: 0.0001, mm: 0.1 },
        length: { m: 100, mm: 100000 },
        aspo: { m: 0, mm: 0 },
        temperature_out: { kelvins: 293.15, celsius: 20 },
        heat_transfer_coef: 0,
        height_profile: [],
        coordinates: data.coordinates,
      };
      const segmentsArr = [...segments.items];
      segmentsArr.push(obj);
      dispatch(setSegments(segmentsArr));
      dispatch(
        setActiveObject({
          object_name: '',
          object_uid: data.uid,
          node_uid: data.uid,
          type: 'PIPELINE',
        }),
      );
      dispatch(
        updateDrawState({
          isActive: false,
          activeElement: null,
          params: null,
        }),
      );
      dispatch(updateSegmentsNetwork(true));
    }
  };

  const handleMouseOnClick = (e: any) => {
    if (!drawStateCurrent.current.isActive) {
      const { properties, layer } = e.features[0];
      let type = layer.id.toUpperCase();
      if (layer.id === 'lines') type = 'PIPELINE';
      else if (layer.id === 'separators') type = 'EQUIPMENT';
      else if (layer.id === 'injSource') type = 'INJ_WELL_PPD';
      else if (layer.id === 'prodSource') type = 'PROD_WELL_PPD';
      else if (layer.id === 'sinkPPD') type = 'SINK_PPD';
      else if (layer.id === 'sourcePPD') type = 'SOURCE_PPD';
      else if (layer.id === 'pump') type = 'PUMP';
      else if (
        [
          'threePhaseSeparatorOil',
          'threePhaseSeparatorWater',
          'threePhaseSeparatorGas',
        ].includes(layer.id)
      )
        type = 'THREE_PHASE_SEPARATOR';

      const getObjId = () => {
        switch (layer.id) {
          case 'source':
            return wellsById[properties.uid]?.id;
          case 'choke':
            return chokes.find(chokeItem => chokeItem.id === properties.uid)
              ?.id;
          default:
            return properties.uid;
        }
      };

      dispatch(
        setActiveObject({
          type,
          node_uid:
            type === 'THREE_PHASE_SEPARATOR'
              ? properties.end_node_uid
              : properties.uid,
          object_uid: getObjId(),
          object_name: properties.name,
        }),
      );
    }
    mapDraw?.handleMouseOnClick(e, handleSegment);
  };

  const deleteNode = async () => {
    let newNodesArr = JSON.parse(JSON.stringify(nodesItems));
    let newSegmentsArr = JSON.parse(JSON.stringify(segments.items));
    const node = newNodesArr.find(
      (item: any) => item.uid === activeObject?.node_uid,
    );

    const segment = newSegmentsArr.find(
      (item: any) => item.uid === activeObject?.node_uid,
    );

    if (node) {
      newNodesArr = newNodesArr.filter(
        (item: any) => item.uid !== activeObject?.node_uid,
      );
      dispatch(setNodes(newNodesArr));
      dispatch(updateNodesNetwork(true));
    } else if (segment) {
      newSegmentsArr = newSegmentsArr.filter(
        (item: any) => item.uid !== activeObject?.node_uid,
      );
      dispatch(setSegments(newSegmentsArr));
      dispatch(updateSegmentsNetwork(true));
    }
    mapDraw?.deleteFeature(activeObject?.node_uid);
    dispatch(
      setShowDialog({
        dialogType: null,
        objectType: null,
        objectName: null,
        objectId: null,
        isConfirm: null,
        isDiscard: null,
      }),
    );
    dispatch(
      setActiveObject({
        node_uid: null,
        type: null,
        object_uid: null,
        object_name: null,
      }),
    );
  };

  const resizeMap = () => {
    const resultsPanel: any = document.querySelector('.project-results-panel');
    const windowWidth = window.innerWidth;
    const mapContainer: any = document.querySelector('.map-container');
    const mapCanvasContainer: any = document.querySelector('.mapboxgl-canvas');
    const currentWidth = windowWidth - resultsPanel;

    mapCanvasContainer.style.width = `${currentWidth}px`;
    mapContainer.style.width = `${currentWidth}px`;
    mapContext?.resize();
  };

  useEffect(() => {
    if (linesColor && linesColor.length) {
      const linesSource = mapDraw?.getSources('lines');

      const linesColorCoords = linesColor.map((item: any) => {
        return {
          ...item,
          properties: linesSource?._data.features.find(
            (property: any) => property.properties.uid === item.uid,
          )?.properties,
          coordinates: linesSource?._data.features.find(
            (line: any) => line.properties.uid === item.uid,
          )?.geometry.coordinates,
        };
      });

      linesColorCoords.forEach((item: any) => {
        mapDraw?.updateFeatures(
          'LineString',
          {
            ...item.properties,
            name: '',
            type: 'lines_color',
            uid: item.uid,
            color: `#${item.color}`,
          },
          item.coordinates,
        );
      });
    }

    if (!linesColor) {
      mapDraw?.deleteAllFeatures('lines_color');
    }
  }, [linesColor]);

  useEffect(() => {
    nodesRef.current = nodes;
    if (nodesRef.current.isUpdate) {
      updateNodesFeatures();
    }
  }, [nodesItems, mapIsDraw, nodes.isUpdate]);

  useEffect(() => {
    segmentsRef.current = segments;
    if (segmentsRef.current.isUpdate) {
      updateSegmentsFeatures();
    }
  }, [segments.items, segments.isUpdate]);

  useEffect(() => {
    if (!mapInit && mapContext) {
      const newMapDraw: MapDraw = new MapDraw(handleNodesOnUp);
      newMapDraw.init(mapContext);
      setMapDraw(newMapDraw);
      setMapInit(true);
    }
  }, [mapInit, mapContext]);

  useEffect(() => {
    resizeMap();

    if (loopingSegments?.length) {
      dispatch(setLoopingsData(loopingSegments));
      if (showResults) {
        loopingSegments.forEach((item: any) => {
          mapDraw?.updateFeatures(
            'LineString',
            { uid: item.uid, name: '', type: 'lines_loopings' },
            item.coordinates,
          );
        });
      } else {
        loopingSegments.forEach((item: any) => {
          mapDraw?.deleteFeature(item.uid);
        });
      }
    } else if (loopingsState.items.length > 0) {
      loopingsState?.items?.forEach((item: any) => {
        mapDraw?.deleteFeature(item.uid);
      });
      dispatch(setLoopingsData([]));
    }
  }, [loopingSegments, showResults]);

  useEffect(() => {
    drawStateCurrent.current = drawState;
    socketStateCurrent.current = socketState;

    if (drawState.isActive && mapContext) {
      mapContext.getCanvasContainer().style.cursor = 'crosshair';
    }
    if (activeObject.object_uid) {
      disableElementSelected(mapContext);
      mapDraw?.setElementSelected(activeObject);
    } else if (!activeObject.object_uid && !drawState.isActive) {
      disableElementSelected(mapContext);
    } else {
      disableElementSelected(mapContext);
    }

    const handleKeyPress = (e: React.KeyboardEvent) => {
      if (mapContext) {
        const { key } = e;
        // mapContext.getCanvasContainer().style.cursor = 'grab';
        if (key === 'Escape') {
          mapDraw?.disableElementSelected();
          dispatch(
            updateDrawState({
              isActive: false,
              activeElement: null,
              params: null,
            }),
          );
          mapContext.getCanvasContainer().style.cursor = 'grab';
          mapContext.off('mousemove', mapDraw?.onMove as () => void);
        } else if (['Delete', 'Backspace'].includes(key)) {
          if (['PIPELINE', 'JUNCTION'].includes(activeObject.type as string)) {
            dispatch(
              setShowDialog({
                dialogType: 'DELETE_CONFIRM',
                objectType:
                  activeObject.type === 'LINES'
                    ? 'PIPELINE'
                    : (activeObject.type as ObjectType),
                objectName: activeObject.object_name as string,
                objectId: activeObject.node_uid as string,
              }),
            );
          }
        }
      }
    };

    if (showDialog.isConfirm && showDialog.dialogType === 'DELETE_CONFIRM') {
      deleteNode();
    }

    document.addEventListener('keydown', handleKeyPress as any);
    return () => {
      document.removeEventListener('keydown', handleKeyPress as any);
    };
  }, [
    activeObject,
    drawState.isActive,
    drawState.params,
    loopingsState.items.length,
    socketState.isConnect,
    showDialog.isConfirm,
  ]);

  const setIcons = () => {
    const icons: any = [];

    // TODO нужно после отрефакторить
    Object.keys(iconsObj).forEach((layerKey: any) => {
      const data = iconsObj[layerKey as any].map((icon: any, i: any) => {
        const obj = {
          type: 'Feature',
          id: i,
          properties: {
            name: icon.name,
            uid: icon.uid,
          },
          geometry: {
            type: 'Point',
            coordinates: icon.coordinates,
          },
        };

        return obj;
      });

      const obj: any = {
        layerKey,
        data,
      };
      icons.push(obj);
    });
    return icons;
  };

  const sourceStoppedSource = (icon: any) => {
    const sourceStopped = mapDraw?.getSources('sourceStopped');
    if (sourceStopped) {
      if (icon.data.length > 0) {
        // const sourceStoppedFeatures = sourceStopped._data.features;
        mapDraw?.addPrepareFeatures('sourceStopped', icon.data);
      } else {
        [
          'sourceStopped',
          'sourceStopped_selected',
          'sourceStopped_hovered',
        ].forEach((item: any) => {
          mapDraw?.deleteAllFeatures(item);
        });
      }
    }

    return (
      <AddSource
        empty
        update
        before={`${icon.layerKey}_hovered`}
        features={icon.data}
        source_id={icon.layerKey}
        source_type="geojson"
        layer_name={icon.layerKey}
        layer_type="symbol"
        layer_layout={{
          'icon-image': icon.layerKey,
          'text-radial-offset': 1,
          ...layerProps,
        }}
        handleMouseMove={(e: any) => mapDraw?.handleMouseMove(e, 'nodes')}
        handleMouseLeave={() => mapDraw?.handleMouseLeave('nodes')}
        handleMouseDown={mapDraw?.handleNodeMouseDown}
        handleMouseTouchStart={mapDraw?.handleNodesMouseTouchStart}
        handleMouseClick={handleMouseOnClick}
      />
    );
  };

  const iconsFeaturesSources = (icon: any) => {
    return (
      <AddSource
        update
        before={`${icon.layerKey}_hovered`}
        features={icon.data}
        source_id={icon.layerKey}
        source_type="geojson"
        layer_name={icon.layerKey}
        layer_type="symbol"
        layer_layout={{
          'icon-image': icon.layerKey,
          'text-radial-offset': 1,
          ...layerProps,
        }}
        handleMouseMove={(e: any) => mapDraw?.handleMouseMove(e, 'nodes')}
        handleMouseLeave={() => mapDraw?.handleMouseLeave('nodes')}
        handleMouseDown={
          ![/* 'pump', */ 'separatorPPD', 'choke'].includes(icon.layerKey)
            ? mapDraw?.handleNodeMouseDown
            : undefined
        }
        handleMouseTouchStart={mapDraw?.handleNodesMouseTouchStart}
        handleMouseClick={handleMouseOnClick}
      />
    );
  };

  return (
    <>
      <AddSource
        hovered
        features={segmentsGeojson.features}
        source_id="lines"
        source_type="geojson"
        layer_type="line"
        layer_paint={linePaint}
        update={updateLines}
        setUpdate={setUpdateLines}
        setIsLoaded={setIsLinesLoaded}
        handleMouseMove={(e: any) => mapDraw?.handleMouseMove(e, 'lines')}
        handleMouseLeave={() => mapDraw?.handleMouseLeave('lines')}
        handleMouseClick={handleMouseOnClick}
      />
      <AddSource
        hovered
        features={separatorsGeojson.features}
        source_id="separators"
        source_type="geojson"
        layer_type="line"
        layer_paint={separatorPaint}
        update={updateSeparators}
        setUpdate={setUpdateSeparators}
        setIsLoaded={setIsSeparatorsLoaded}
        handleMouseMove={(e: any) => mapDraw?.handleMouseMove(e, 'separators')}
        handleMouseLeave={() => mapDraw?.handleMouseLeave('separators')}
        handleMouseClick={handleMouseOnClick}
      />
      <AddSource
        hovered
        features={pumpsGeojson.features}
        source_id="pump"
        source_type="geojson"
        layer_type="line"
        layer_paint={pumpPaint}
        update={updatePumps}
        setUpdate={setUpdatePumps}
        setIsLoaded={setIsPumpsLoaded}
        handleMouseMove={(e: any) => mapDraw?.handleMouseMove(e, 'pump')}
        handleMouseLeave={() => mapDraw?.handleMouseLeave('pump')}
        handleMouseClick={handleMouseOnClick}
      />
      <AddSource
        hovered
        features={chokeGeojson.features}
        source_id="choke"
        source_type="geojson"
        layer_type="line"
        layer_paint={chokePaint}
        update={updateChoke}
        setUpdate={setUpdateChoke}
        setIsLoaded={setIsChokeLoaded}
        handleMouseMove={(e: any) => mapDraw?.handleMouseMove(e, 'choke')}
        handleMouseLeave={() => mapDraw?.handleMouseLeave('choke')}
        handleMouseClick={handleMouseOnClick}
      />
      <AddSource
        hovered
        features={threePhaseSeparatorsOilGeojson.features}
        source_id="threePhaseSeparatorOil"
        source_type="geojson"
        layer_type="line"
        layer_paint={separatorOilPaint}
        update={updateThreePhaseSeparatorOil}
        setUpdate={setUpdateThreePhaseSeparatorOil}
        setIsLoaded={setIsThreePhaseSeparatorOilLoaded}
        handleMouseMove={(e: any) =>
          mapDraw?.handleMouseMove(e, 'threePhaseSeparatorOil')
        }
        handleMouseLeave={() =>
          mapDraw?.handleMouseLeave('threePhaseSeparatorOil')
        }
        handleMouseClick={handleMouseOnClick}
      />
      <AddSource
        hovered
        features={threePhaseSeparatorsWaterGeojson.features}
        source_id="threePhaseSeparatorWater"
        source_type="geojson"
        layer_type="line"
        layer_paint={separatorWaterPaint}
        update={updateThreePhaseSeparatorWater}
        setUpdate={setUpdateThreePhaseSeparatorWater}
        setIsLoaded={setIsThreePhaseSeparatorWaterLoaded}
        handleMouseMove={(e: any) =>
          mapDraw?.handleMouseMove(e, 'threePhaseSeparatorWater')
        }
        handleMouseLeave={() =>
          mapDraw?.handleMouseLeave('threePhaseSeparatorWater')
        }
        handleMouseClick={handleMouseOnClick}
      />
      <AddSource
        hovered
        features={threePhaseSeparatorsGasGeojson.features}
        source_id="threePhaseSeparatorGas"
        source_type="geojson"
        layer_type="line"
        layer_paint={separatorGasPaint}
        update={updateThreePhaseSeparatorGas}
        setUpdate={setUpdateThreePhaseSeparatorGas}
        setIsLoaded={setIsThreePhaseSeparatorGasLoaded}
        handleMouseMove={(e: any) =>
          mapDraw?.handleMouseMove(e, 'threePhaseSeparatorGas')
        }
        handleMouseLeave={() =>
          mapDraw?.handleMouseLeave('threePhaseSeparatorGas')
        }
        handleMouseClick={handleMouseOnClick}
      />
      {isLinesLoaded && (
        <AddSource
          empty
          features={[]}
          source_id="lines_selected"
          source_type="geojson"
          layer_type="line"
          layer_paint={selectedLinePaint}
        />
      )}
      {isSeparatorsLoaded && (
        <AddSource
          empty
          features={[]}
          source_id="separators_selected"
          source_type="geojson"
          layer_type="line"
          layer_paint={selectedLinePaint}
        />
      )}
      {isPumpsLoaded && (
        <AddSource
          empty
          features={[]}
          source_id="pump_selected"
          source_type="geojson"
          layer_type="line"
          layer_paint={selectedLinePaint}
        />
      )}
      {isChokeLoaded && (
        <AddSource
          empty
          features={[]}
          source_id="choke_selected"
          source_type="geojson"
          layer_type="line"
          layer_paint={selectedLinePaint}
        />
      )}
      {isThreePhaseSeparatorOilLoaded && (
        <AddSource
          empty
          features={[]}
          source_id="threePhaseSeparatorOil_selected"
          source_type="geojson"
          layer_type="line"
          layer_paint={selectedLinePaint}
        />
      )}
      {isThreePhaseSeparatorWaterLoaded && (
        <AddSource
          empty
          features={[]}
          source_id="threePhaseSeparatorWater_selected"
          source_type="geojson"
          layer_type="line"
          layer_paint={selectedLinePaint}
        />
      )}
      {isThreePhaseSeparatorGasLoaded && (
        <AddSource
          empty
          features={[]}
          source_id="threePhaseSeparatorGas_selected"
          source_type="geojson"
          layer_type="line"
          layer_paint={selectedLinePaint}
        />
      )}
      {isLinesLoaded && (
        <AddSource
          empty
          features={[]}
          source_id="lines_draw"
          source_type="geojson"
          layer_type="line"
          layer_paint={lineDraw}
        />
      )}
      {isLinesLoaded && (
        <AddSource
          empty
          features={[]}
          source_id="lines_loopings"
          source_type="geojson"
          layer_type="line"
          layer_paint={loopingsLinePaint}
        />
      )}
      {isLinesLoaded && (
        <AddSource
          empty
          features={[]}
          source_id="lines_color"
          source_type="geojson"
          layer_type="line"
          layer_paint={colorsLinePaint}
          getColor={['get', 'color']}
        />
      )}
      {isLinesLoaded && (
        <AddLayer
          source_id="lines"
          layer_name="lines_hovered"
          layer_type="line"
          layer_paint={hoverLinePaint}
        />
      )}
      {isSeparatorsLoaded && (
        <AddLayer
          source_id="separators"
          layer_name="separators_hovered"
          layer_type="line"
          layer_paint={hoverLinePaint}
        />
      )}
      {isPumpsLoaded && (
        <AddLayer
          source_id="pump"
          layer_name="pump_hovered"
          layer_type="line"
          layer_paint={hoverLinePaint}
        />
      )}
      {isChokeLoaded && (
        <AddLayer
          source_id="choke"
          layer_name="choke_hovered"
          layer_type="line"
          layer_paint={hoverLinePaint}
        />
      )}
      {isThreePhaseSeparatorOilLoaded && (
        <AddLayer
          source_id="threePhaseSeparatorOil"
          layer_name="threePhaseSeparatorOil_hovered"
          layer_type="line"
          layer_paint={hoverLinePaint}
        />
      )}
      {isThreePhaseSeparatorWaterLoaded && (
        <AddLayer
          source_id="threePhaseSeparatorWater"
          layer_name="threePhaseSeparatorWater_hovered"
          layer_type="line"
          layer_paint={hoverLinePaint}
        />
      )}
      {isThreePhaseSeparatorGasLoaded && (
        <AddLayer
          source_id="threePhaseSeparatorGas"
          layer_name="threePhaseSeparatorGas_hovered"
          layer_type="line"
          layer_paint={hoverLinePaint}
        />
      )}
      {isLinesLoaded &&
        setIcons().map((icon: any, i: any) => (
          <div key={i}>
            <AddSource
              empty
              features={[]}
              source_id={`${icon.layerKey}_edit`}
              source_type="geojson"
              layer_name={`${icon.layerKey}_edit`}
              layer_type="symbol"
              layer_layout={{
                'icon-image': `${icon.layerKey}_hover`,
                'text-allow-overlap': true,
                'icon-allow-overlap': true,
                'icon-offset': [0, 0],
              }}
            />
            <AddSource
              empty
              features={[]}
              source_id={`${icon.layerKey}_hovered`}
              source_type="geojson"
              layer_name={`${icon.layerKey}_hovered`}
              layer_type="symbol"
              layer_layout={{
                'icon-image': `${icon.layerKey}_hover`,
                'text-allow-overlap': true,
                'icon-allow-overlap': true,
              }}
            />
            <AddSource
              empty
              features={[]}
              source_id={`${icon.layerKey}_selected`}
              source_type="geojson"
              layer_name={`${icon.layerKey}_selected`}
              layer_type="symbol"
              layer_layout={{
                'icon-image': `${icon.layerKey}_selected`,
                'text-allow-overlap': true,
                'icon-allow-overlap': true,
              }}
            />
            <AddSource
              empty
              features={[]}
              source_id={`${icon.layerKey}_no_edit_selected`}
              source_type="geojson"
              layer_name={`${icon.layerKey}_no_edit_selected`}
              layer_type="symbol"
              layer_layout={{
                'icon-image': `${icon.layerKey}_no_edit_selected`,
                'text-allow-overlap': true,
                'icon-allow-overlap': true,
              }}
            />
            {icon.layerKey === 'sourceStopped'
              ? sourceStoppedSource(icon)
              : iconsFeaturesSources(icon)}
          </div>
        ))}
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  nodes: state.project.nodes,
  wells: state.project.wells,
  segments: state.project.segments,
  separators: state.project.separators,
  activeObject: state.map.activeObject,
  drawState: state.map.drawState,
  loopingsState: state.map.loopingsState,
  socketState: state.socket.socketState,
  showDialog: state.common.showDialog,
  task: state.task.solution,
  linesColor: state.map.linesColor,
  showResults: state.task.showResults,
  reservoir: state.project.reservoir,
  prodWells: state.project.production_wells.items,
  injWells: state.project.injection_wells.items,
  sinks: state.project.sinks.items,
  sources: state.project.sources.items,
  pumps: state.project.pumps.items,
  chokes: state.project.chokes.items,
  threePhaseSeparators: state.project.three_phase_separators.items,
});

export default connect(mapStateToProps)(Features);
