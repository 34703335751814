import { StatusRenderer } from 'Components/AgGridTable/StatusRenderer/statusRenderer';

export const templateRowsNumber = 5;

export const wellfieldsUAColumnDefs = [
  {
    colId: '0',
    headerName: 'Статус',
    field: 'current_state',
    cellRenderer: StatusRenderer,
    initialWidth: 120,
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '1',
    headerName: 'Название',
    field: 'well_name',
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '2',
    headerName: 'Способ эксплуатации',
    field: 'operation_type',
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '3',
    headerName: 'Дебит нефти, т\u2060/\u2060сут',
    field: 'oil_flow_rate',
  },
  {
    colId: '4',
    headerName: 'Дебит жидкости, м\u00B3\u2060/\u2060сут',
    field: 'fluid_flow_rate',
  },
  {
    colId: '5',
    headerName: 'Дебит газа, тыс.м\u00B3\u2060/\u2060сут',
    field: 'gas_flow_rate',
  },
  { colId: '6', headerName: 'Линейное давление, атм', field: 'pressure' },
  {
    colId: '7',
    headerName: 'Забойное давление, атм',
    field: 'bottomhole_pressure',
  },
  { colId: '9', headerName: 'Частота работы, Гц', field: 'frequency' },
  {
    colId: '10',
    headerName: 'Диаметр штуцера, мм',
    field: 'diameter',
  },
];
