import * as React from 'react';
import { useSelector } from 'react-redux';

import AgGridTable from 'Components/AgGridTable';
import { PNOAFSolution } from 'Pages/Project/types';
import { selectSolution } from 'store/taskSlice';

import { formatThreeDigits } from 'utils';
import { useGetColumnDefs } from '../../../../hooks/useGetColumnDefs';
import { TABLE_NAME } from './index';
import { wellsAFColumnDefs } from './utils';

const AvgTable: React.FC = () => {
  const solution = useSelector(selectSolution);
  const [data, setData] = React.useState<any[]>([]);

  const columnDef = useGetColumnDefs(TABLE_NAME, wellsAFColumnDefs);
  const getData = () => {
    const newData: any = [];
    (solution.solution as PNOAFSolution)?.solution?.well_states?.forEach(
      state => {
        newData.push({
          well_name: state.well_name,
          fact_pressure__atm: formatThreeDigits(state.fact_pressure__atm),
          calculated_pressure__atm: formatThreeDigits(
            state.calculated_pressure__atm,
          ),
          absolute_pressure_error__atm: formatThreeDigits(
            state.absolute_pressure_error__atm,
          ),
          relative_pressure_error: formatThreeDigits(
            state.relative_pressure_error,
          ),
        });
      },
    );
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [(solution.solution as PNOAFSolution)?.solution?.well_states]);

  return (
    <AgGridTable tableName={TABLE_NAME} columnDefs={columnDef} rowData={data} />
  );
};

export default AvgTable;
