import * as React from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import Skeleton from 'Components/Skeleton';
import { components } from 'generated/apiTypes';
import { selectWellCompareStateTable } from 'store/taskSlice';

import { dashboardItems } from './utils';

const CompareUADashboard: React.FC = () => {
  const wellCompareStateTable = useSelector(
    selectWellCompareStateTable,
  ) as components['schemas']['PNOWellCompareStateTable'];
  const getCommonError = () => {
    const commonError = {
      pressure: {
        abs: { avg: 0, max: 0 },
        rel: { avg: 0, max: 0 },
      },
      oil_flow_rate: {
        abs: { avg: 0, max: 0 },
        rel: { avg: 0, max: 0 },
      },
      fluid_flow_rate: {
        abs: { avg: 0, max: 0 },
        rel: { avg: 0, max: 0 },
      },
      gas_flow_rate: {
        abs: { avg: 0, max: 0 },
        rel: { avg: 0, max: 0 },
      },
    };
    const numStates = wellCompareStateTable?.states.length;
    wellCompareStateTable?.states.forEach(state => {
      commonError.oil_flow_rate.abs.max = Math.max(
        commonError.oil_flow_rate.abs.max,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.oil_flow_rate_abs_error.t_per_day,
      );
      commonError.oil_flow_rate.abs.avg +=
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.oil_flow_rate_abs_error.t_per_day;
      commonError.oil_flow_rate.rel.max = Math.max(
        commonError.oil_flow_rate.rel.max,
        state.oil_flow_rate_rel_error,
      );
      commonError.oil_flow_rate.rel.avg += state.oil_flow_rate_rel_error;

      commonError.fluid_flow_rate.abs.max = Math.max(
        commonError.fluid_flow_rate.abs.max,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.fluid_flow_rate_abs_error.m3_per_day,
      );
      commonError.fluid_flow_rate.abs.avg +=
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.fluid_flow_rate_abs_error.m3_per_day;
      commonError.fluid_flow_rate.rel.max = Math.max(
        commonError.fluid_flow_rate.rel.max,
        state.fluid_flow_rate_rel_error,
      );
      commonError.fluid_flow_rate.rel.avg += state.fluid_flow_rate_rel_error;

      commonError.gas_flow_rate.abs.max = Math.max(
        commonError.gas_flow_rate.abs.max,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.gas_flow_rate_abs_error.thousand_m3_per_day,
      );
      commonError.gas_flow_rate.abs.avg +=
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.gas_flow_rate_abs_error.thousand_m3_per_day;
      commonError.gas_flow_rate.rel.max = Math.max(
        commonError.gas_flow_rate.rel.max,
        state.gas_flow_rate_rel_error,
      );
      commonError.gas_flow_rate.rel.avg += state.gas_flow_rate_rel_error;

      commonError.pressure.abs.max = Math.max(
        commonError.pressure.abs.max,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.pressure_abs_error.atm,
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      commonError.pressure.abs.avg += state.pressure_abs_error.atm;
      commonError.pressure.rel.max = Math.max(
        commonError.pressure.rel.max,
        state.pressure_rel_error,
      );
      commonError.pressure.rel.avg += state.pressure_rel_error;
    });
    if (numStates) {
      commonError.oil_flow_rate.abs.avg /= numStates;
      commonError.oil_flow_rate.rel.avg /= numStates;
      commonError.fluid_flow_rate.abs.avg /= numStates;
      commonError.fluid_flow_rate.rel.avg /= numStates;
      commonError.gas_flow_rate.abs.avg /= numStates;
      commonError.gas_flow_rate.rel.avg /= numStates;
      commonError.pressure.abs.avg /= numStates;
      commonError.pressure.rel.avg /= numStates;
    }
    return commonError;
  };
  return (
    <div className="dashboard">
      {dashboardItems(getCommonError()).map((el, key) => {
        return (
          <div key={key} className={classNames('dashboard__item', el.style)}>
            <p className="dashboard__item-dem">{el.dem}</p>
            {key < 4 && (
              <div className="error-value">
                <div>
                  <p>
                    Δср&emsp;&ensp;
                    <text>
                      {el.absAvg === undefined ? (
                        <Skeleton className="skeleton-dashboard min-max" />
                      ) : (
                        el.absAvg
                      )}{' '}
                      {el.unit}
                    </text>
                  </p>
                  <p>
                    {' '}
                    δср&emsp;&ensp;
                    <text>
                      {el.relAvg === undefined ? (
                        <Skeleton className="skeleton-dashboard min-max" />
                      ) : (
                        el.relAvg
                      )}{' '}
                      %
                    </text>
                  </p>
                </div>
                <div>
                  <p>
                    {' '}
                    Δмакс&ensp;
                    <text>
                      {el.absMax === undefined ? (
                        <Skeleton className="skeleton-dashboard min-max" />
                      ) : (
                        el.absMax
                      )}{' '}
                      {el.unit}
                    </text>
                  </p>
                  <p>
                    {' '}
                    δмакс&ensp;
                    <text>
                      {el.relMax === undefined ? (
                        <Skeleton className="skeleton-dashboard min-max" />
                      ) : (
                        el.relMax
                      )}{' '}
                      %
                    </text>
                  </p>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default CompareUADashboard;
