import React from 'react';
import { useSelector } from 'react-redux';

import { selectNodes } from 'store/projectSlice';

import { UseChartData } from '../types';
import { seriesColors } from '../utils';

const useChartData: UseChartData = (projectSolution, option) => {
  const nodes = useSelector(selectNodes);
  const { time } = React.useMemo(() => {
    const statesByTime =
      projectSolution?.solution?.node_step_table?.time2states;
    if (statesByTime) {
      return {
        time: Object.keys(statesByTime).map(item =>
          parseFloat(item).toFixed(0),
        ),
      };
    }
    return {};
  }, [projectSolution?.solution]);

  return React.useMemo(() => {
    if (time && option) {
      const chartData: { name: string; data: any[] }[] = [];
      nodes.items
        ?.filter(node => node.type === 'SINK')
        ?.map(node => {
          return chartData.push({
            name: node.name,
            data: projectSolution?.solution?.node_step_table?.time2states
              ? Object.values(
                  projectSolution?.solution?.node_step_table?.time2states,
                ).map((state: any) => {
                  const nodeStateByNodeId = state.find(
                    (val: any) => val.uid === node.uid,
                  );
                  if (option.value === 'pressure') {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    return nodeStateByNodeId?.[option.value]?.atm || 0;
                  }
                  if (option.value === 'temperature') {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    return nodeStateByNodeId?.[option.value]?.celsius || 0;
                  }
                  if (option.value === 'gas_flow_rate_volume') {
                    return (
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      nodeStateByNodeId?.[option.value]?.thousand_m3_per_day ||
                      0
                    );
                  }
                  if (option.value === 'fluid_flow_rate_volume') {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    return nodeStateByNodeId?.[option.value]?.m3_per_day || 0;
                  }
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  return nodeStateByNodeId?.[option.value]?.t_per_day || 0;
                })
              : [],
          });
        });

      return {
        labels: time,
        datasets: Array.from(chartData).map((el, index) => {
          return {
            label: el.name,
            data: el.data,
            fill: false,
            backgroundColor: seriesColors[index % 9],
            borderColor: seriesColors[index % 9],
            color: seriesColors[index % 9],
            borderWidth: 1,
            tension: 0.1,
            pointHoverBorderWidth: 0,
            pointRadius: 0,
            pointHitRadius: 0,
            pointBorderWidth: 1,
            pointHoverRadius: 1,
          };
        }),
      };
    }
    return { labels: [], datasets: [] };
  }, [time, option?.value]);
};

export default useChartData;
