import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectPhysChemPackages, selectReservoir } from 'store/projectSlice';

import { ReactComponent as LayerPPDIcon } from '../../../../images/Project/layers.svg';
import { IObject } from '../../types';
import ObjectInput from '../components/ObjectInput';
import ObjectSelect, { OptionType } from '../components/ObjectSelect';

const LayerPPD: React.FC<IObject> = () => {
  const reservoir = useSelector(selectReservoir);
  const physChemPackages = useSelector(selectPhysChemPackages);
  const PCPackages: OptionType<string>[] = physChemPackages.map(value => {
    return {
      value: value?.uid as string,
      label: value?.name as string,
    };
  });

  const saveValue = () => {};

  return (
    <div className="active-object__content full-width">
      <div className="active-object__top-row">
        <p className="active-object__title">
          <LayerPPDIcon />
          {reservoir?.name}
        </p>
      </div>
      <div className="active-object__row">
        <div className="d-flex">
          <div className="active-object__input-with-unit-wrapper short">
            <ObjectSelect
              label="Пакет ФХС"
              classNames="active-object__row-input"
              classNamesWrapper="active-object__row-input-wrapper wide"
              name="phys_chem_package_id"
              options={PCPackages}
              value={PCPackages.find(
                item => item.value === reservoir?.phys_chem__uid,
              )}
              saveNewValue={saveValue}
              disabled
            />
          </div>
        </div>
      </div>
      <div className="active-object__row">
        <div className="d-flex">
          <div className="active-object__input-with-unit-wrapper left">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Кол-во добывающих скв."
              name=""
              initValue={reservoir?.production_well_ids?.length}
              saveNewValue={saveValue}
              disabled
            />
            <p className="active-object__row-input-unit">шт</p>
          </div>
          <div className="active-object__input-with-unit-wrapper">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Кол-во нагнетател. скв."
              name=""
              initValue={reservoir?.injection_well_ids?.length}
              saveNewValue={saveValue}
              disabled
            />
            <p className="active-object__row-input-unit">шт</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayerPPD;
