import {
  CompareCellComparator,
  CompareCellFilterGetter,
  CompareCellRenderer,
} from 'Components/AgGridTable/CompareCellRenderer/compareCellRenderer';

import { formatThreeDigits, formatTwoDigits } from 'utils';

export const templateRowsNumber = 5;
const compareCellColumnDefs = {
  cellRenderer: CompareCellRenderer,
  filterValueGetter: CompareCellFilterGetter,
  comparator: CompareCellComparator,
};
export const layersMOColumnDefs = [
  {
    colId: '0',
    headerName: 'Название',
    field: 'name',
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '1',
    headerName: 'Расход нефти, т\u2060/\u2060сут',
    field: 'oil_flow_rate',
    ...compareCellColumnDefs,
  },

  {
    colId: '2',
    headerName: 'Расход воды, м\u00B3\u2060/\u2060сут',
    field: 'water_flow_rate',
    ...compareCellColumnDefs,
  },
  {
    colId: '3',
    headerName: 'Расход газа, тыс.м\u00B3\u2060/\u2060сут',
    field: 'gas_flow_rate',
    ...compareCellColumnDefs,
  },
  {
    colId: '4',
    headerName: 'Расход закачки, м\u00B3\u2060/\u2060сут',
    field: 'water_injection',
    ...compareCellColumnDefs,
  },
  {
    colId: '5',
    headerName: 'Пластовое давление, атм',
    field: 'pressure',
    ...compareCellColumnDefs,
  },
];

export const layersTableItems = ({
  name,
  oil_production__t_per_day,
  oil_production_abs__t_per_day,
  oil_production_rel,
  water_production__m3_per_day,
  water_production_abs__m3_per_day,
  water_production_rel,
  gas_production__m3_per_day,
  gas_production_abs__m3_per_day,
  gas_production_rel,
  water_injection__m3_per_day,
  water_injection_abs__m3_per_day,
  water_injection_rel,
  pressure__atm,
  pressure_abs__atm,
  pressure_rel,
}: any = {}) => {
  return {
    name,
    oil_flow_rate: {
      value: formatThreeDigits(oil_production__t_per_day),
      abs: formatTwoDigits(oil_production_abs__t_per_day),
      rel: formatTwoDigits(oil_production_rel),
      unit: 'т/сут',
    },
    water_flow_rate: {
      value: formatThreeDigits(water_production__m3_per_day),
      abs: formatTwoDigits(water_production_abs__m3_per_day),
      rel: formatTwoDigits(water_production_rel),
      unit: 'м\u00B3\u2060/\u2060сут',
    },
    gas_flow_rate: {
      value: formatThreeDigits(gas_production__m3_per_day / 1000),
      abs: formatTwoDigits(gas_production_abs__m3_per_day / 1000),
      rel: formatTwoDigits(gas_production_rel),
      unit: 'тыс.м\u00B3\u2060/\u2060сут',
    },
    water_injection: {
      value: formatThreeDigits(water_injection__m3_per_day),
      abs: formatTwoDigits(water_injection_abs__m3_per_day),
      rel: formatTwoDigits(water_injection_rel),
      unit: 'м\u00B3\u2060/\u2060сут',
    },
    pressure: {
      value: formatThreeDigits(pressure__atm),
      abs: formatTwoDigits(pressure_abs__atm),
      rel: formatTwoDigits(pressure_rel),
      unit: 'атм',
    },
  };
};
