import { formatThreeDigits, formatTwoDigits } from 'utils';
import {
  CompareCellComparator,
  CompareCellFilterGetter,
  CompareCellRenderer,
} from '../../../../../../Components/AgGridTable/CompareCellRenderer/compareCellRenderer';

export const templateRowsNumber = 5;
const compareCellColumnDefs = {
  cellRenderer: CompareCellRenderer,
  filterValueGetter: CompareCellFilterGetter,
  comparator: CompareCellComparator,
};
export const segmentsMOColumnDefs = [
  {
    colId: '0',
    headerName: 'Начальный узел',
    field: 'start_node_name',
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '1',
    headerName: 'Конечный узел',
    field: 'end_node_name',
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '2',
    headerName: 'Расход нефти, т\u2060/\u2060сут',
    field: 'oil_flow_rate',
    ...compareCellColumnDefs,
  },
  {
    colId: '3',
    headerName: 'Расход жидкости, м\u00B3\u2060/\u2060сут',
    field: 'fluid_flow_rate',
    ...compareCellColumnDefs,
  },
  {
    colId: '4',
    headerName: 'Расход газа, тыс.м\u00B3\u2060/\u2060сут',
    field: 'gas_flow_rate',
    ...compareCellColumnDefs,
  },
  {
    colId: '5',
    headerName: 'Давление на входе, атм',
    field: 'p_in',
    ...compareCellColumnDefs,
  },
  {
    colId: '6',
    headerName: 'Перепад давлений, атм/км',
    field: 'dp_dl',
    ...compareCellColumnDefs,
  },
  {
    colId: '7',
    headerName: 'Перепад температур, ℃\u2060/\u2060км',
    field: 'dt_dl',
    ...compareCellColumnDefs,
  },
  {
    colId: '8',
    headerName: 'Макс. скорость жидкости, м\u2060/\u2060с',
    field: 'fluid_velocity',
    ...compareCellColumnDefs,
  },
  {
    colId: '9',
    headerName: 'Макс. скорость газа, м\u2060/\u2060с',
    field: 'gas_velocity',
    ...compareCellColumnDefs,
  },
];

export const segmentsTableItems = ({
  start_node_name,
  end_node_name,
  oil_flow_rate_mass,
  oil_flow_rate_mass_abs,
  oil_flow_rate_mass_rel,
  fluid_flow_rate_volume,
  fluid_flow_rate_volume_abs,
  fluid_flow_rate_volume_rel,
  gas_flow_rate_volume,
  gas_flow_rate_volume_abs,
  gas_flow_rate_volume_rel,
  pressure_in,
  pressure_in_abs,
  pressure_in_rel,
  dp_dl,
  dp_dl_abs,
  dp_dl_rel,
  dt_dl,
  dt_dl_abs,
  dt_dl_rel,
  fluid_velocity,
  fluid_velocity_abs,
  fluid_velocity_rel,
  gas_velocity,
  gas_velocity_abs,
  gas_velocity_rel,
}: any = {}) => {
  return {
    start_node_name,
    end_node_name,
    oil_flow_rate: {
      value: formatThreeDigits(oil_flow_rate_mass.t_per_day),
      abs: formatTwoDigits(oil_flow_rate_mass_abs?.t_per_day),
      rel: formatTwoDigits(oil_flow_rate_mass_rel),
      unit: 'т/сут',
    },
    fluid_flow_rate: {
      value: formatThreeDigits(fluid_flow_rate_volume.m3_per_day),
      abs: formatTwoDigits(fluid_flow_rate_volume_abs?.m3_per_day),
      rel: formatTwoDigits(fluid_flow_rate_volume_rel),
      unit: 'м\u00B3\u2060/\u2060сут',
    },
    gas_flow_rate: {
      value: formatThreeDigits(gas_flow_rate_volume?.thousand_m3_per_day),
      abs: formatTwoDigits(gas_flow_rate_volume_abs?.thousand_m3_per_day),
      rel: formatTwoDigits(gas_flow_rate_volume_rel),
      unit: 'тыс.м\u00B3\u2060/\u2060сут',
    },
    p_in: {
      value: formatThreeDigits(pressure_in.atm),
      abs: formatTwoDigits(pressure_in_abs?.atm),
      rel: formatTwoDigits(pressure_in_rel),
      unit: 'атм',
    },
    dp_dl: {
      value: formatThreeDigits(dp_dl.atm_per_km),
      abs: formatTwoDigits(dp_dl_abs?.atm_per_km),
      rel: formatTwoDigits(dp_dl_rel),
      unit: 'атм/км',
    },
    dt_dl: {
      value: formatThreeDigits(dt_dl.celsius_per_km),
      abs: formatTwoDigits(dt_dl_abs?.celsius_per_km),
      rel: formatTwoDigits(dt_dl_rel),
      unit: '℃/км',
    },
    fluid_velocity: {
      value: formatThreeDigits(fluid_velocity.m_per_second),
      abs: formatTwoDigits(fluid_velocity_abs?.m_per_second),
      rel: formatTwoDigits(fluid_velocity_rel),
      unit: 'м/с',
    },
    gas_velocity: {
      value: formatThreeDigits(gas_velocity.m_per_second),
      abs: formatTwoDigits(gas_velocity_abs?.m_per_second),
      rel: formatTwoDigits(gas_velocity_rel),
      unit: 'м/с',
    },
  };
};
