import * as React from 'react';

import UATable from './Table/Table';

const Nodes: React.FC = () => {
  return (
    <>
      <UATable />
    </>
  );
};

export default Nodes;
