import * as React from 'react';
import { useSelector } from 'react-redux';

import AgGridTable from 'Components/AgGridTable';
import { components } from 'generated/apiTypes';
import {
  selectChokesSolution,
  selectSeparatorsSolution,
  selectSolution,
} from 'store/taskSlice';

import { useGetColumnDefs } from '../../../../../hooks/useGetColumnDefs';
import { chokeTableItems } from '../../../../ResultsPanel/Equipments/MO/Table/utils';
import { TABLE_NAME } from './Table';
import {
  equipmentMOColumnDefs,
  pumpTableItems,
  separatorTableItems,
} from './utils';

const EquipmentsTable: React.FC = () => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOReservoirOptimizationProblem'];
  const separatorsSolution = useSelector(selectSeparatorsSolution);
  const chokesSolution = useSelector(selectChokesSolution);
  const [data, setData] = React.useState<any[]>([]);
  const columnDefs = useGetColumnDefs(TABLE_NAME, equipmentMOColumnDefs);

  const getData = () => {
    const newData: any[] = [];
    solution?.solution?.pump_states.forEach(state => {
      newData.push(pumpTableItems(state));
    });
    (
      separatorsSolution?.solution as components['schemas']['GetTechModeOptimizationSolutionSeparatorAverageStateQueryResult'][]
    )?.forEach(state => {
      newData.push(separatorTableItems(state));
    });
    solution?.solution?.three_phase_separator_states.forEach(state => {
      newData.push(separatorTableItems(state));
    });
    (
      chokesSolution?.solution as components['schemas']['GetTechModeOptimizationSolutionChokeAverageStateQueryResult'][]
    )?.forEach(state => {
      newData.push(chokeTableItems(state));
    });
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [
    solution?.solution?.three_phase_separator_states,
    solution?.solution?.pump_states,
    separatorsSolution.solution,
    chokesSolution.solution,
  ]);

  return (
    <AgGridTable
      tableName={TABLE_NAME}
      columnDefs={columnDefs}
      rowData={data}
    />
  );
};

export default EquipmentsTable;
