import * as React from 'react';
import { useSelector } from 'react-redux';

import AgGridTable from 'Components/AgGridTable';
import { components } from 'generated/apiTypes';
import { selectSolution } from 'store/taskSlice';

import { useGetColumnDefs } from '../../../../../hooks/useGetColumnDefs';
import { TABLE_NAME } from './Table';
import { wellfieldsMOColumnDefs, wellsTableItems } from './utils';

const SourcesAvgTable: React.FC = () => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOReservoirOptimizationProblem'];
  const [data, setData] = React.useState<any[]>([]);
  const columnDef = useGetColumnDefs(TABLE_NAME, wellfieldsMOColumnDefs);

  const getData = () => {
    const newData: any = [];
    solution?.solution?.well_states.forEach(state => {
      newData.push(wellsTableItems(state));
    });
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [solution]);

  return (
    <AgGridTable tableName={TABLE_NAME} columnDefs={columnDef} rowData={data} />
  );
};

export default SourcesAvgTable;
