export const AG_GRID_LOCALE_RU = {
  filterOoo: 'Фильтрация...',
  equals: 'Равно',
  notEqual: 'Не равно',
  blank: 'Пусто',
  notBlank: 'Не пусто',
  empty: 'Выбрать один',

  selectAll: '(Select All)',
  selectAllSearchResults: '(Select All Search Results)',
  addCurrentSelectionToFilter: 'Add current selection to filter',
  searchOoo: 'Search...',
  blanks: '(Blanks)',
  noMatches: 'No matches',
  // Number Filter
  lessThan: 'Меньше',
  greaterThan: 'Больше',
  lessThanOrEqual: 'Меньше или равно',
  greaterThanOrEqual: 'Больше или равно',
  inRange: 'Между',
  inRangeStart: 'С',
  inRangeEnd: 'До',

  // Text Filter
  contains: 'Содержит',
  notContains: 'Не содержит',
  startsWith: 'Начинается с',
  endsWith: 'Заканчивается на',

  // Date Filter
  dateFormatOoo: 'yyyy-mm-dd',
  before: 'Before',
  after: 'After',

  // Filter Conditions
  andCondition: 'И',
  orCondition: 'ИЛИ',

  // Filter Buttons
  applyFilter: 'Применить',
  resetFilter: 'Сбросить',
  clearFilter: 'Очистить',
  cancelFilter: 'Отменить',

  // Filter Titles
  textFilter: 'Text Filter',
  numberFilter: 'Number Filter',
  dateFilter: 'Date Filter',
  setFilter: 'Set Filter',
};

export const DEFAULT_COL_DEF = {
  flex: 1,
  filter: true,
  wrapHeaderText: true,
  autoHeaderHeight: true,
  resizable: true,
  wrapText: true,
  // cellDataType: false,
  hide: false,
  minWidth: 150,
};
