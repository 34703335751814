import * as React from 'react';

import { formatThreeDigits, formatTwoDigits } from 'utils';
import {
  CompareCellComparator,
  CompareCellFilterGetter,
  CompareCellRenderer,
} from '../../../../../../Components/AgGridTable/CompareCellRenderer/compareCellRenderer';
import { isNumber } from '../../../utils';

export const templateRowsNumber = 5;
const compareCellColumnDefs = {
  cellRenderer: CompareCellRenderer,
  filterValueGetter: CompareCellFilterGetter,
  comparator: CompareCellComparator,
};
export const segmentsMOColumnDefs = [
  {
    colId: '0',
    headerName: 'Начальный узел',
    field: 'start_node_name',
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '1',
    headerName: 'Конечный узел',
    field: 'end_node_name',
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '2',
    headerName: 'Расход нефти, т\u2060/\u2060сут',
    field: 'oil_flow_rate',
    ...compareCellColumnDefs,
  },
  {
    colId: '3',
    headerName: 'Расход жидкости, м\u00B3\u2060/\u2060сут',
    field: 'fluid_flow_rate',
    ...compareCellColumnDefs,
  },
  {
    colId: '4',
    headerName: 'Расход газа, тыс.м\u00B3\u2060/\u2060сут',
    field: 'gas_flow_rate',
    ...compareCellColumnDefs,
  },
  {
    colId: '5',
    headerName: 'Давление на входе, атм',
    field: 'p_in',
    ...compareCellColumnDefs,
  },
  {
    colId: '6',
    headerName: 'Перепад давлений, атм/км',
    field: 'dp_dl',
    ...compareCellColumnDefs,
  },
  {
    colId: '7',
    headerName: 'Перепад температур, ℃\u2060/\u2060км',
    field: 'dt_dl',
    ...compareCellColumnDefs,
  },
  {
    colId: '8',
    headerName: 'Макс. скорость жидкости, м\u2060/\u2060с',
    field: 'fluid_velocity',
    ...compareCellColumnDefs,
  },
  {
    colId: '9',
    headerName: 'Макс. скорость газа, м\u2060/\u2060с',
    field: 'gas_velocity',
    ...compareCellColumnDefs,
  },
];
export const segmentsTableHead = {
  start_node_name: {
    title: 'Начальный узел',
    component: <>Начальный узел</>,
  },
  end_node_name: {
    title: 'Конечный узел',
    component: <>Конечный узел</>,
  },
  oil_flow_rate: {
    title: 'Расход нефти',
    component: (
      <>
        Расход нефти, <br />
        т/сут
      </>
    ),
  },
  fluid_flow_rate: {
    title: 'Расход жидкости',
    component: (
      <>
        Расход жидкости,
        <br />
        м3/сут
      </>
    ),
  },
  gas_flow_rate: {
    title: 'Расход газа',
    component: (
      <>
        Расход газа,
        <br />
        тыс.м3/сут
      </>
    ),
  },
  p_in: {
    title: 'Давление на входе',
    component: (
      <>
        Давление на входе,
        <br />
        атм
      </>
    ),
  },
  dp_dl: {
    title: 'Перепад давлений',
    component: (
      <>
        Перепад давлений,
        <br />
        атм/км
      </>
    ),
  },
  dt_dl: {
    title: 'Перепад температур',
    component: (
      <>
        Перепад температур, <br />
        ℃/км
      </>
    ),
  },
  fluid_velocity: {
    title: 'Макс. скорость жидкости',
    component: (
      <>
        Макс. скорость жидкости <br />
        м/с
      </>
    ),
  },
  gas_velocity: {
    title: 'Макс. скорость газа',
    component: (
      <>
        Макс. скорость газа <br />
        м/с
      </>
    ),
  },
};

export const segmentsTableItems = ({
  start_node_name,
  end_node_name,
  compare_oil_flow_rate,
  compare_fluid_flow_rate,
  compare_gas_flow_rate,
  compare_pressure_in,
  compare_pressure_drop,
  compare_temperature_drop,
  compare_fluid_velocity,
  compare_gas_velocity,
}: any = {}) => {
  return {
    start_node_name,
    end_node_name,
    oil_flow_rate: {
      dem: 'oil_flow_rate',
      value:
        compare_oil_flow_rate?.fact &&
        formatThreeDigits(compare_oil_flow_rate.fact.t_per_day),
      abs: formatTwoDigits(compare_oil_flow_rate?.abs?.t_per_day),
      rel: formatTwoDigits(compare_oil_flow_rate?.rel),
      unit: 'т/сут',
      cssClass:
        // eslint-disable-next-line no-nested-ternary
        isNumber(compare_oil_flow_rate?.abs?.t_per_day) &&
        compare_oil_flow_rate?.abs?.t_per_day !== 0
          ? compare_oil_flow_rate?.abs?.t_per_day > 0
            ? 'green'
            : 'red'
          : undefined,
    },
    fluid_flow_rate: {
      value:
        compare_fluid_flow_rate?.fact &&
        formatThreeDigits(compare_fluid_flow_rate.fact.m3_per_day),
      abs: formatTwoDigits(compare_fluid_flow_rate?.abs?.m3_per_day),
      rel: formatTwoDigits(compare_fluid_flow_rate?.rel),
      unit: 'м3/сут',
    },
    gas_flow_rate: {
      value:
        compare_gas_flow_rate?.fact &&
        formatThreeDigits(compare_gas_flow_rate?.fact?.thousand_m3_per_day),
      abs: formatTwoDigits(compare_gas_flow_rate?.abs?.thousand_m3_per_day),
      rel: formatTwoDigits(compare_gas_flow_rate?.rel),
      unit: 'тыс. м3/сут',
    },
    p_in: {
      value:
        compare_pressure_in?.fact &&
        formatThreeDigits(compare_pressure_in.fact.atm),
      abs: formatTwoDigits(compare_pressure_in?.abs?.atm),
      rel: formatTwoDigits(compare_pressure_in?.rel),
      unit: 'атм',
      cssClass:
        // eslint-disable-next-line no-nested-ternary
        isNumber(compare_pressure_in?.abs?.atm) &&
        compare_pressure_in?.abs?.atm !== 0
          ? compare_pressure_in?.abs?.atm < 0
            ? 'green'
            : 'red'
          : undefined,
    },
    dp_dl: {
      value:
        compare_pressure_drop?.fact &&
        formatThreeDigits(compare_pressure_drop.fact.atm_per_km),
      abs: formatTwoDigits(compare_pressure_drop?.abs?.atm_per_km),
      rel: formatTwoDigits(compare_pressure_drop?.rel),
      unit: 'атм/км',
    },
    dt_dl: {
      value:
        compare_temperature_drop?.fact &&
        formatThreeDigits(compare_temperature_drop.fact.celsius_per_km),
      abs: formatTwoDigits(compare_temperature_drop?.abs?.celsius_per_km),
      rel: formatTwoDigits(compare_temperature_drop?.rel),
      unit: '℃/км',
    },
    fluid_velocity: {
      value:
        compare_fluid_velocity?.fact &&
        formatThreeDigits(compare_fluid_velocity.fact.m_per_second),
      abs: formatTwoDigits(compare_fluid_velocity?.abs?.m_per_second),
      rel: formatTwoDigits(compare_fluid_velocity?.rel),
      unit: 'м/с',
    },
    gas_velocity: {
      value:
        compare_gas_velocity?.fact &&
        formatThreeDigits(compare_gas_velocity.fact.m_per_second),
      abs: formatTwoDigits(compare_gas_velocity?.abs?.m_per_second),
      rel: formatTwoDigits(compare_gas_velocity?.rel),
      unit: 'м/с',
    },
  };
};
