import * as React from 'react';

import { components } from '../../../../generated/apiTypes';
import { FountainSourceViewAF } from './FountainSourceViewAF';
import { PeriodGasSourceViewAF } from './PeriodGasSourceViewAF';
import { PeriodPumpSourceViewAF } from './PeriodPumpSourceViewAF';
import { PermAnnularFountainSourceViewAF } from './PermAnnularFountainSourceViewAF';
import { PermGasSourceViewAF } from './PermGasSourceViewAF';
import { PermPumpSourceViewAF } from './PermPumpSourceViewAF';
import { ReducedPeriodicGasliftSourceViewAF } from './ReducedPeriodicGasliftSourceViewAF';
import { ReducedPeriodicPumpSourceViewAF } from './ReducedPeriodicPumpSourceViewAF';
import { ReducedPermAnnularFountainSourceViewAF } from './ReducedPermAnnularFountainSourceViewAF';
import { TimedSourceViewAF } from './TimedSourceViewAF';

interface ISourceViewAF {
  wellFactState?: components['schemas']['GetWellFactStateQueryResult']['well_fact_state_query_result'];
  saveWellFactValue(value: number, name: string): void;
}

const SourceViewAF: React.FC<ISourceViewAF> = ({
  wellFactState,
  saveWellFactValue,
}) => {
  // const physChemPackages = useSelector(selectPhysChemPackages);
  // const PCPackages: OptionType<string>[] = physChemPackages.map(value => {
  //   return {
  //     value: value?.uid as string,
  //     label: value?.name as string,
  //   };
  // });
  //
  // const getControlType = (type: string | undefined) => {
  //   switch (type) {
  //     case 'PermanentFountainWell':
  //     case 'PermanentGasliftWell':
  //     case 'PermanentPumpWell':
  //     case 'PermanentTimedWell':
  //       return 'Постоянный';
  //     case 'PeriodicGasliftWell':
  //     case 'PeriodicPumpWell':
  //       return 'Периодический';
  //     default:
  //       return '';
  //   }
  // };
  return (
    <>
      {wellFactState?.type === 'PermanentFountainWellFactState' && (
        <FountainSourceViewAF
          props={wellFactState}
          saveWellFactValue={saveWellFactValue}
        />
      )}
      {wellFactState?.type === 'PermanentGasliftWellFactState' && (
        <PermGasSourceViewAF
          props={wellFactState}
          saveWellFactValue={saveWellFactValue}
        />
      )}
      {wellFactState?.type === 'PeriodicGasliftWellFactState' && (
        <PeriodGasSourceViewAF
          props={wellFactState}
          saveWellFactValue={saveWellFactValue}
        />
      )}
      {wellFactState?.type === 'PermanentPumpWellFactState' && (
        <PermPumpSourceViewAF
          props={wellFactState}
          saveWellFactValue={saveWellFactValue}
        />
      )}
      {wellFactState?.type === 'PeriodicPumpWellFactState' && (
        <PeriodPumpSourceViewAF
          props={wellFactState}
          saveWellFactValue={saveWellFactValue}
        />
      )}
      {wellFactState?.type === 'PermanentTimedWellFactState' && (
        <TimedSourceViewAF
          props={wellFactState}
          saveWellFactValue={saveWellFactValue}
        />
      )}
      {wellFactState?.type === 'PermanentAnnularFountainWellFactState' && (
        <PermAnnularFountainSourceViewAF
          props={wellFactState}
          saveWellFactValue={saveWellFactValue}
        />
      )}
      {wellFactState?.type ===
        'ReducedPermanentAnnularFountainWellFactState' && (
        <ReducedPermAnnularFountainSourceViewAF
          props={wellFactState}
          saveWellFactValue={saveWellFactValue}
        />
      )}
      {wellFactState?.type === 'ReducedPeriodicPumpWellFactState' && (
        <ReducedPeriodicPumpSourceViewAF
          props={wellFactState}
          saveWellFactValue={saveWellFactValue}
        />
      )}
      {wellFactState?.type === 'ReducedPeriodicGasliftWellFactState' && (
        <ReducedPeriodicGasliftSourceViewAF
          props={wellFactState}
          saveWellFactValue={saveWellFactValue}
        />
      )}
    </>
  );
};

export default SourceViewAF;
