import { sklonenie } from './sklonenie';

export const scientificFormatter = new Intl.NumberFormat('ru', {
  notation: 'scientific',
});

export const formatter = new Intl.NumberFormat('ru', {
  maximumFractionDigits: 2,
});

export const accurateFormatter = new Intl.NumberFormat('ru', {
  maximumFractionDigits: 6,
});

export const oneDigitsFormatter = new Intl.NumberFormat('ru', {
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
});

export const twoDigitsFormatter = new Intl.NumberFormat('ru', {
  maximumFractionDigits: 2,
});

export const threeDigitsFormatter = new Intl.NumberFormat('ru', {
  maximumFractionDigits: 3,
});

export const fourDigitsFormatter = new Intl.NumberFormat('ru', {
  maximumFractionDigits: 4,
});

export const fiveDigitsFormatter = new Intl.NumberFormat('ru', {
  maximumFractionDigits: 5,
});

export const coordsFormatter = new Intl.NumberFormat('ru', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 8,
});

export const smartFormat = (num: number) => {
  if (num === 0) return 0;
  if (Math.abs(num) < 0.001 || Math.abs(num) > 9999)
    return scientificFormatter.format(num);
  if (Math.abs(num) < 1 && Math.abs(num) >= 0.001)
    return threeDigitsFormatter.format(num);
  if (Math.abs(num) >= 1 && Math.abs(num) < 100)
    return twoDigitsFormatter.format(num);
  if (Math.abs(num) >= 100 && Math.abs(num) <= 9999)
    return oneDigitsFormatter.format(num);
  return num;
};

export const shortenTitle = (title: string, maxLength: number): string => {
  if (title.length > maxLength) {
    return `${title.slice(0, maxLength - 1)} ...`;
  }
  return title;
};

export const timeFormatter = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const min = Math.floor((seconds - hours * 3600) / 60);
  const sec = seconds - hours * 3600 - min * 60;
  const dateList = [];

  hours &&
    dateList.push(`${hours} ${sklonenie(hours, ['час', 'часа', 'часов'])}`);
  min && dateList.push(`${min} мин`);
  sec && dateList.push(`${oneDigitsFormatter.format(sec)} сек`);
  return dateList.join(' ');
};

export const formatTwoDigits = (value: number | undefined | null) => {
  return value !== null && value !== undefined
    ? parseFloat(value.toFixed(2))
    : null;
};
export const formatThreeDigits = (value: number | undefined | null) => {
  return value !== null && value !== undefined
    ? parseFloat(value.toFixed(3))
    : null;
};
