import React from 'react';

import { ReactComponent as CloseIcon } from 'images/Project/close-icon.svg';

import Button from '../Button';

import './style.scss';

interface ICancelTaskConfirmationWindowProps {
  closeWindow(): void;
  cancelTask(): void;
}

const DeleteConfirmationWindow: React.FC<
  ICancelTaskConfirmationWindowProps
> = ({ cancelTask, closeWindow }) => {
  const onStopClick = () => {
    cancelTask();
    closeWindow();
  };

  React.useEffect(() => {
    const onKeyDownHandler = (e: React.KeyboardEvent) => {
      const { key } = e;
      if (key === 'Enter') {
        onStopClick();
      }
    };
    document.addEventListener('keydown', onKeyDownHandler as any);
    return () => {
      document.removeEventListener('keydown', onKeyDownHandler as any);
    };
  }, []);
  return (
    <div className="dialog-window-background">
      <div className="dialog-window-container">
        <div className="dialog-window-wrapper">
          <button
            type="button"
            className="dialog-window-close-button"
            onClick={closeWindow}
          >
            <CloseIcon />
          </button>
          <div className="dialog-window-title">Остановка расчёта</div>
          <div className="dialog-window-message cancel-task-message">
            Вы действительно хотите остановить текущий расчет?
          </div>
          <div className="dialog-window-message message-details">
            Состояние расчета будет потеряно.
          </div>
          <div className="dialog-window-buttons-wrapper">
            <Button
              type="button"
              className="dialog-window-delete-button"
              onClick={onStopClick}
            >
              Остановить
            </Button>
            <Button type="button" mode="secondary" onClick={closeWindow}>
              Отмена
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationWindow;
