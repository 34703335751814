import { AxiosResponse } from 'axios';
import { components } from 'generated/apiTypes';

import { api } from '../api';
//

type CreateProjectDto = {
  file: FormData;
  project_name: string;
};

type CreateProjectFromFilesDto = {
  files: FormData;
  project_name: string;
  iskra_project_url: string;
};

type CreateProjectFromFileDto = {
  file: FormData;
  project_name: string;
  iskra_project_url: string;
  gathering_center_id?: string;
};

type CalcTaskDto = {
  project_id: string;
};

type CancelTaskDto = {
  task_id: string;
};

type SetPackageDto = {
  project_id: string;
  phys_chem_id: string;
};

export const login = (formData: any): Promise<AxiosResponse> => {
  return api.post(`/api/token`, formData);
};

export const getProjects = (): Promise<
  AxiosResponse<components['schemas']['PNOProjectData'][]>
> => {
  return api.get('/api/projects');
};

export const createProject = ({ file, project_name }: CreateProjectDto) => {
  return api.post('/api/projects', file, { params: { project_name } });
};

// files - скважины, тех режим
export const createProjectFromFiles = ({
  files,
  project_name,
  iskra_project_url,
}: CreateProjectFromFilesDto) => {
  return api.post('/api/projects/files', files, {
    params: { project_name, iskra_project_url },
  });
};

// только скважины
export const createProjectFromFile = ({
  file,
  project_name,
  iskra_project_url,
  gathering_center_id,
}: CreateProjectFromFileDto): Promise<
  AxiosResponse<
    | components['schemas']['SubNetworksResponse']
    | components['schemas']['PNOProjectData']
  >
> => {
  let params: {
    project_name: string;
    iskra_project_url: string;
    gathering_center_id?: string;
  } = { project_name, iskra_project_url };
  if (gathering_center_id)
    params = { project_name, iskra_project_url, gathering_center_id };
  return api.post('/api/projects/file', file, {
    params,
  });
};

export const getSeparators = (
  id: string,
): Promise<
  AxiosResponse<components['schemas']['GetSeparatorsQueryResult']>
> => {
  return api.get(`/api/separators`, { params: { project_id: id } });
};

export const getProject = (
  id: string,
): Promise<AxiosResponse<components['schemas']['PNOProject']>> => {
  return api.get(`/api/projects/${id}`);
};

export const getTasks = (
  project_uid: string,
): Promise<AxiosResponse<components['schemas']['TaskData'][]>> => {
  return api.get(`/api/projects/${project_uid}/tasks`);
};

export const calcNodalAnalysisTask = ({
  project_id,
}: CalcTaskDto): Promise<
  AxiosResponse<components['schemas']['TaskStatusTracer']>
> => {
  return api.post(
    `/api/projects/${project_id}/calc/periodic-nodal-analysis-task`,
  );
};

export const calcReservoirBaseTask = ({
  project_id,
}: CalcTaskDto): Promise<
  AxiosResponse<components['schemas']['TaskStatusTracer']>
> => {
  return api.post(`/api/projects/${project_id}/calc/reservoir-base-task`);
};

export const calcReservoirOptimizationTask = ({
  project_id,
}: CalcTaskDto): Promise<
  AxiosResponse<components['schemas']['TaskStatusTracer']>
> => {
  return api.post(
    `/api/projects/${project_id}/calc/reservoir-optimization-task`,
  );
};

export const calcHydroTask = ({
  project_id,
}: CalcTaskDto): Promise<
  AxiosResponse<components['schemas']['TaskStatusTracer']>
> => {
  return api.post(`/api/projects/${project_id}/calc/pipeline-network-task`);
};

export const calcFactAdaptationTask = ({
  project_id,
}: CalcTaskDto): Promise<
  AxiosResponse<components['schemas']['TaskStatusTracer']>
> => {
  return api.post(
    `/api/projects/${project_id}/calc/network-fact-adaptation-task`,
  );
};

export const calcModeOptimizationTask = ({
  project_id,
}: CalcTaskDto): Promise<
  AxiosResponse<components['schemas']['TaskStatusTracer']>
> => {
  return api.post(
    `/api/projects/${project_id}/calc/tech-mode-optimization-task`,
  );
};

export const calcNetworkOptimizationTask = ({
  project_id,
}: CalcTaskDto): Promise<
  AxiosResponse<components['schemas']['TaskStatusTracer']>
> => {
  return api.post(
    `/api/projects/${project_id}/calc/tech-network-optimization-task`,
  );
};

export const cancelTask = ({ task_id }: CancelTaskDto): Promise<null> => {
  return api.post(`/api/tasks/${task_id}/cancel`);
};

export const getSolution = (
  task_id: string,
): Promise<
  AxiosResponse<
    | components['schemas']['PNOPeriodicNodalAnalysisProblem']
    | components['schemas']['PNOPipelineNetworkProblem']
    | components['schemas']['PNONetworkFactAdaptationProblem']
  >
> => {
  return api.get(`/api/get_solution`, {
    params: {
      task_id,
    },
  });
};

export const getIntegralCharacteristics = (
  task_id: string,
): Promise<
  AxiosResponse<
    | components['schemas']['PNOPeriodicNodalAnalysisSolutionIntegralCharacteristic']
    | components['schemas']['PNOPipelineNetworkSolutionIntegralCharacteristic']
  >
> => {
  return api.get(`/api/solutions/${task_id}/short`);
};

export const getAFIntegralCharacteristics = (
  task_id: string,
): Promise<
  AxiosResponse<
    components['schemas']['GetFactAdaptationSolutionIntegralCharacteristicQueryResult']
  >
> => {
  return api.get(`/api/fact-adaptation-solution-integral-characteristics`, {
    params: { task_id },
  });
};

export const getAFWellStates = (
  task_id: string,
): Promise<
  AxiosResponse<
    components['schemas']['GetFactAdaptationSolutionWellStatesQueryResult']
  >
> => {
  return api.get(`/api/fact-adaptation-solution-well-states`, {
    params: { task_id },
  });
};

export const getAFPipelineStates = (
  task_id: string,
): Promise<
  AxiosResponse<
    components['schemas']['GetFactAdaptationSolutionPipelineStatesQueryResult']
  >
> => {
  return api.get(`/api/fact-adaptation-solution-pipeline-states`, {
    params: { task_id },
  });
};

export const getExperimentSettings = (
  project_id: string,
): Promise<AxiosResponse<components['schemas']['ExperimentSettings']>> => {
  return api.get(`/api/projects/${project_id}/experiment-settings`);
};

export const getTraces = (
  task_id: string,
): Promise<AxiosResponse<components['schemas']['TaskStatusTracer']>> => {
  return api.get(`/api/traces`, { params: { task_id } });
};

export const setExperimentSettings = (
  project_id: string,
  settings: components['schemas']['ExperimentSettings'],
): Promise<AxiosResponse<components['schemas']['ExperimentSettings']>> => {
  return api.put(`/api/projects/${project_id}/experiment-settings`, settings);
};

export const validateProject = (project_uid: string) =>
  api.post<components['schemas']['PNOProjectValidationResult']>(
    `/api/projects/${project_uid}/validate`,
  );

export const getWells = (
  project_id: string,
): Promise<AxiosResponse<components['schemas']['GetWellsQueryResult']>> => {
  return api.get(`/api/wells`, { params: { project_id } });
};

export const updateWell = (
  well_id: string,
  data: components['schemas']['UpdateWellCommand'],
) => {
  return api.put(`/api/wells/${well_id}`, data);
};

export const getWellModel = (
  well_id: string,
): Promise<AxiosResponse<components['schemas']['GetWellModelQueryResult']>> => {
  return api.get(`/api/well-models`, { params: { well_id } });
};

export const updatePump = (
  project_uid: string,
  pump_uid: string,
  data: components['schemas']['PNOPump'],
): Promise<AxiosResponse<components['schemas']['PNOPump']>> => {
  return api.put(`/api/projects/${project_uid}/pumps/${pump_uid}`, data);
};

export const updateWellModel = (
  well_id: string,
  data: components['schemas']['UpdateWellModelCommand'],
) => {
  return api.put(`/api/well-models`, data, { params: { well_id } });
};

export const setWellHydroModel = (
  project_id: string,
  well_id: string,
  well_hydro_model: components['schemas']['PNOWellHydroModel'],
) => {
  return api.put(
    `/api/projects/${project_id}/well-hydro-models/${well_id}`,
    well_hydro_model,
  );
};

export const setWellControlModel = (
  project_id: string,
  well_id: string,
  well_control_model: components['schemas']['PNOWellControlModel'],
) => {
  return api.put(
    `/api/projects/${project_id}/well-control-models/${well_id}`,
    well_control_model,
  );
};

export const setGatheringCentersModel = (
  project_id: string,
  node_id: string,
  gathering_center_model: components['schemas']['PNOGatheringCenterModel'],
) => {
  return api.put(
    `/api/projects/${project_id}/gathering-center-models/${node_id}`,
    gathering_center_model,
  );
};

export const setGatheringCenterFactPressure = (
  project_id: string,
  node_id: string,
  data: { [x: string]: number },
): Promise<
  AxiosResponse<components['schemas']['PNOGatheringCenterFactPressure'][]>
> => {
  return api.put(
    `/api/projects/${project_id}/gathering-center-fact-pressure/${node_id}`,
    data,
  );
};

// @deprecate
// export const setSegment = (
//   project_id: string,
//   segment_id: string,
//   segment: components['schemas']['PNOPipelineSegment'],
// ) => {
//   return api.put(`/api/projects/${project_id}/segments/${segment_id}`, segment);
// };

export const getExcelProjectFile = (id: string) => {
  return api.get(`/api/projects/${id}/excel`, {
    responseType: 'blob',
  });
};

export const getJSONProjectFile = (id: string) => {
  return api.get(`/api/projects/${id}/json`, {
    responseType: 'blob',
  });
};

export const getExcelUASolution = (task_uid: string) => {
  return api.get(
    `/api/periodic-nodal-analysis-tasks/${task_uid}/solution/excel`,
    {
      responseType: 'blob',
    },
  );
};

export const getExcelAFSolution = (task_uid: string) => {
  return api.get(
    `/api/network-fact-adaptation-tasks/${task_uid}/solution/excel`,
    {
      responseType: 'blob',
    },
  );
};

export const getExcelGRSolution = (task_uid: string) => {
  return api.get(`/api/pipeline-network-tasks/${task_uid}/solution/excel`, {
    responseType: 'blob',
  });
};

export const setPhysChemPackage = (
  project_id: string,
  package_id: string,
  pack: components['schemas']['PNOPhysChemPackage'],
) => {
  return api.put(
    `/api/projects/${project_id}/phys-chem-packages/${package_id}`,
    pack,
  );
};

export const addPhysChemPackage = (
  project_id: string,
  pack: components['schemas']['PNOPhysChemPackage'],
): Promise<AxiosResponse<components['schemas']['PNOPhysChemPackage'][]>> => {
  return api.post(`/api/projects/${project_id}/phys-chem-packages`, pack);
};

export const deleteProject = (
  project_id: string,
): Promise<AxiosResponse<components['schemas']['PNOProjectData'][]>> => {
  return api.delete(`/api/projects/${project_id}`);
};

export const deletePhysChemPackage = (
  project_id: string,
  package_id: string,
): Promise<AxiosResponse<components['schemas']['PNOPhysChemPackage'][]>> => {
  return api.delete(
    `/api/projects/${project_id}/phys-chem-packages/${package_id}`,
  );
};

// @deprecate
// export const deleteNode = (
//   project_id: string,
//   node_id: string,
// ): Promise<AxiosResponse<components['schemas']['PNOPipelineNetwork']>> => {
//   return api.delete(`/api/projects/${project_id}/nodes/${node_id}`);
// };

// @deprecate
// export const deleteSegment = (
//   project_id: string,
//   segment_id: string,
// ): Promise<AxiosResponse<components['schemas']['PNOPipelineNetwork']>> => {
//   return api.delete(`/api/projects/${project_id}/segments/${segment_id}`);
// };

// @deprecate
// export const deleteWell = (
//   project_id: string,
//   well_id: string,
// ): Promise<AxiosResponse<components['schemas']['WellResponse']>> => {
//   return api.delete(`/api/projects/${project_id}/wells/${well_id}`);
// };

// @deprecate
// export const deleteSink = (
//   project_id: string,
//   node_id: string,
// ): Promise<AxiosResponse<components['schemas']['GatheringCenterResponse']>> => {
//   return api.delete(
//     `/api/projects/${project_id}/gathering-center-models/${node_id}`,
//   );
// };

// @deprecate
// export const setNode = (
//   project_id: string,
//   node_id: string,
//   node: components['schemas']['PNOPipelineNode'],
// ) => {
//   return api.put(`/api/projects/${project_id}/nodes/${node_id}`, node);
// };

export const getAvgWellsTableUA = (
  task_id: string,
): Promise<AxiosResponse<components['schemas']['PNOWellAvgStateTable']>> => {
  return api.get(
    `/api/periodic-nodal-analysis-tasks/${task_id}/solution/avg-well-table`,
  );
};

export const getAvgWellsTableMO = (
  task_id: string,
): Promise<AxiosResponse<components['schemas']['PNOWellAvgStateTable']>> => {
  return api.get(
    `/api/tech-mode-optimization-tasks/${task_id}/solution/avg-well-table`,
  );
};

export const getWellsStepTableMO = (
  task_id: string,
): Promise<
  AxiosResponse<components['schemas']['PNOTechModeOptimizationWellStepTable']>
> => {
  return api.get(
    `/api/tech-mode-optimization-tasks/${task_id}/solution/step-well-table`,
  );
};

export const getWellsStepTableUA = (
  task_id: string,
): Promise<AxiosResponse<components['schemas']['PNOWellStateStepTable']>> => {
  return api.get(
    `/api/periodic-nodal-analysis-tasks/${task_id}/solution/step-well-table`,
  );
};

export const getAvgWellsTableNO = (
  task_id: string,
): Promise<AxiosResponse<components['schemas']['PNOWellAvgStateTable']>> => {
  return api.get(
    `/api/tech-network-optimization-tasks/${task_id}/solution/avg-well-table`,
  );
};

export const getAvgSegmentsTableUA = (
  task_id: string,
): Promise<
  AxiosResponse<
    components['schemas']['PNOPeriodicNodalAnalysisSegmentAvgTable']
  >
> => {
  return api.get(
    `/api/periodic-nodal-analysis-tasks/${task_id}/solution/avg-segment-table`,
    {
      params: {
        task_id,
      },
    },
  );
};

export const getAvgNodesTableUA = (
  task_id: string,
): Promise<
  AxiosResponse<components['schemas']['PNOPeriodicNodalAnalysisNodeAvgTable']>
> => {
  return api.get(
    `/api/periodic-nodal-analysis-tasks/${task_id}/solution/avg-node-table`,
    {
      params: {
        task_id,
      },
    },
  );
};

export const setPackageForAllWells = ({
  project_id,
  phys_chem_id,
}: SetPackageDto): Promise<
  AxiosResponse<components['schemas']['PNOWell'][]>
> => {
  return api.post(
    `/api/projects/${project_id}/phys-chem-packages/${phys_chem_id}/set-for-all-well-models`,
  );
};

// Обновление сети
interface UpdateNetwork {
  project_id: string;
  nodes: components['schemas']['PNOPipelineNetwork']['nodes'];
  segments: components['schemas']['PNOPipelineNetwork']['segments'];
}

export const updateNetwork = async ({
  project_id,
  nodes,
  segments,
}: UpdateNetwork) => {
  return api.put(`/api/projects/${project_id}/pipeline-network`, {
    nodes,
    segments,
  });
};

// Добавление Узла
// @deprecate
// export const addNode = async ({
//   project_id,
//   uid,
//   name,
//   type,
//   coordinates,
// }: AddNode) => {
//   return api.post(`/api/projects/${project_id}/nodes`, {
//     uid,
//     name,
//     type,
//     coordinates,
//   });
// };

// Добавление Скважины
// @deprecate
// export const addWell = async ({ project_id, name, coordinates }: AddWell) => {
//   return api.post(`/api/projects/${project_id}/wells`, {
//     name,
//     coordinates,
//   });
// };

// Добавление ЦС
// @deprecate
// export const addGatheringCenter = async ({
//   project_id,
//   name,
//   pressure,
//   coordinates,
// }: AddGatheringCenter) => {
//   return api.post(`/api/projects/${project_id}/gathering-center`, {
//     name,
//     pressure,
//     coordinates,
//   });
// };

// Добавление Трубы
// @deprecate
// export const addSegment = async ({ project_id, data }: AddSegment) => {
//   return api.post(`/api/projects/${project_id}/segments`, data);
// };

export const applyAspo = (
  project_id: string,
  task_id: string,
): Promise<AxiosResponse<components['schemas']['PNOPipelineNetwork']>> => {
  return api.post(`/api/projects/${project_id}/aspo/${task_id}`);
};

export const applyAFResults = (
  project_id: string,
): Promise<
  AxiosResponse<components['schemas']['ApplyFactAdaptationCommandResult']>
> => {
  return api.post(`/api/projects/${project_id}/apply-fact-adaptation-solution`);
};

export const applyBaseSolutionResults = (
  project_id: string,
): Promise<AxiosResponse<components['schemas']['PNOProject']>> => {
  return api.post(`/api/projects/${project_id}/apply-base-solution`);
};

export const resetAspo = (
  project_id: string,
): Promise<AxiosResponse<components['schemas']['PNOPipelineNetwork']>> => {
  return api.post(`/api/projects/${project_id}/zero-aspo`);
};

export const getSegmentsCatalog = (
  project_id: string,
): Promise<
  AxiosResponse<components['schemas']['PNOSegmentTypeCharacteristic'][]>
> => {
  return api.get(`/api/projects/${project_id}/segment-catalog`);
};

export const randomizeOffsets = async (
  project_id: string,
): Promise<AxiosResponse<components['schemas']['PNOProject']>> => {
  return api.put(`/api/projects/${project_id}/randomize-offsets`);
};

export const getExcelSolution = (project_id: string, dynamics: boolean) => {
  return api.get(`/api/projects/${project_id}/solution/excel`, {
    responseType: 'blob',
    params: { dynamics },
  });
};

export const applyOptimalTechMode = (
  project_id: string,
): Promise<AxiosResponse<components['schemas']['PNOProject']>> => {
  return api.post(`/api/projects/${project_id}/apply-optimal-tech-mode`);
};

export const recoverFactState = (
  project_id: string,
): Promise<AxiosResponse<components['schemas']['PNOProject']>> => {
  return api.post(`/api/projects/${project_id}/set-current-values-from-fact`);
};

export const getUASeparatorsAvg = (
  task_id: string,
): Promise<
  AxiosResponse<
    components['schemas']['GetBaseSolutionSeparatorAverageStatesQueryResult']
  >
> => {
  return api.get(`/api/base-solution-separator-average-states`, {
    params: { task_id },
  });
};

export const getUAChokesAvg = (
  task_id: string,
): Promise<
  AxiosResponse<
    components['schemas']['GetBaseSolutionChokeAverageStatesQueryResult']
  >
> => {
  return api.get(`/api/base-solution-choke-average-states`, {
    params: { task_id },
  });
};

export const getMOSeparatorsAvg = (
  task_id: string,
): Promise<
  AxiosResponse<
    components['schemas']['GetTechModeOptimizationSolutionSeparatorAverageStatesQueryResult']
  >
> => {
  return api.get(
    `/api/tech-mode-optimization-solution-separator-average-states`,
    {
      params: { task_id },
    },
  );
};

export const getMOChokesAvg = (
  task_id: string,
): Promise<
  AxiosResponse<
    components['schemas']['GetTechModeOptimizationSolutionChokeAverageStatesQueryResult']
  >
> => {
  return api.get(`/api/tech-mode-optimization-solution-choke-average-states`, {
    params: { task_id },
  });
};

export const getClusters = (
  project_id: string,
): Promise<AxiosResponse<components['schemas']['GetClustersQueryResult']>> => {
  return api.get(`/api/clusters`, {
    params: { project_id },
  });
};

export const getGlobalEqualityOptimizationConstraints = (
  project_id: string,
  optimization_constraint_type: components['schemas']['EqualityConstraintType'],
): Promise<
  AxiosResponse<
    components['schemas']['GlobalEqualityNetworkOptimizationExperimentSettingsConstraints']
  >
> => {
  return api.get(`/api/global-equality-optimization-constraints/default`, {
    params: { project_id, optimization_constraint_type },
  });
};

export const getGlobalInequalityOptimizationConstraints = (
  project_id: string,
  optimization_constraint_type: components['schemas']['InequalityConstraintType'],
): Promise<
  AxiosResponse<
    components['schemas']['GlobalInequalityNetworkOptimizationExperimentSettingsConstraints']
  >
> => {
  return api.get(`/api/global-inequality-optimization-constraints/default`, {
    params: { project_id, optimization_constraint_type },
  });
};

export const getClusterEqualityOptimizationConstraints = (
  project_id: string,
  optimization_constraint_type: components['schemas']['EqualityConstraintType'],
): Promise<
  AxiosResponse<
    components['schemas']['ClusterEqualityNetworkOptimizationExperimentSettingsConstraints']
  >
> => {
  return api.get(`/api/cluster-equality-optimization-constraints/default`, {
    params: { project_id, optimization_constraint_type },
  });
};

export const getClusterInequalityOptimizationConstraints = (
  project_id: string,
  optimization_constraint_type: components['schemas']['InequalityConstraintType'],
): Promise<
  AxiosResponse<
    components['schemas']['ClusterInequalityNetworkOptimizationExperimentSettingsConstraints']
  >
> => {
  return api.get(`/api/cluster-inequality-optimization-constraints/default`, {
    params: { project_id, optimization_constraint_type },
  });
};

export const getWellFactStates = (
  well_id: string,
): Promise<
  AxiosResponse<components['schemas']['GetWellFactStateQueryResult']>
> => {
  return api.get('/api/well-fact-states', { params: { well_id } });
};

export const updateWellFactStates = (
  data: components['schemas']['UpdateWellFactStateCommand'],
): Promise<
  AxiosResponse<components['schemas']['GetWellFactStateQueryResult']>
> => {
  return api.put(`/api/well-fact-states`, data);
};

export const getChokes = (
  project_id: string,
): Promise<AxiosResponse<components['schemas']['GetChokesQueryResult']>> => {
  return api.get('/api/chokes', { params: { project_id } });
};

export const setChoke = (
  data: components['schemas']['UpdateChokeCommand'],
): Promise<AxiosResponse<components['schemas']['GetChokesQueryResult']>> => {
  return api.put(`/api/chokes/${data.choke_id}`, data);
};
