import React from 'react';

import { components } from 'generated/apiTypes';

import { UseChartData } from '../types';
import { seriesColors } from '../utils';

const useChartData: UseChartData = (wellStepTable, option) => {
  const { time } = React.useMemo(() => {
    const statesByTime = wellStepTable?.time2states;
    if (statesByTime) {
      return {
        time: Object.keys(statesByTime).map(item =>
          parseFloat(item).toFixed(0),
        ),
      };
    }
    return {};
  }, [wellStepTable?.time2states]);

  return React.useMemo(() => {
    if (time && option) {
      const chartData: { name: string; data: any[] }[] = [];
      wellStepTable?.time2states &&
        Object.values(
          wellStepTable?.time2states as components['schemas']['PNOWellStateStepTable']['time2states'],
        )[0]?.map(well => {
          return chartData.push({
            name: well.well_name,
            data: wellStepTable.time2states
              ? Object.values(wellStepTable?.time2states).map((state: any) => {
                  const wellStateByWellId = state.find(
                    (val: any) => val.well_id === well.well_id,
                  );
                  if (option.value === 'pressure') {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    return wellStateByWellId?.[option.value]?.atm || 0;
                  }
                  if (option.value === 'power') {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    return wellStateByWellId?.[option.value]?.kW || 0;
                  }
                  if (option.value === 'gas_flow_rate') {
                    return (
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      wellStateByWellId?.[option.value]?.thousand_m3_per_day
                    );
                  }
                  if (option.value === 'fluid_flow_rate') {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    return wellStateByWellId?.[option.value]?.m3_per_day;
                  }
                  if (option.value === 'control') {
                    return (
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      wellStateByWellId?.[option.value]?.hertz ||
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      wellStateByWellId?.[option.value]?.thousand_m3_per_day
                    );
                  }
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  return wellStateByWellId?.[option.value]?.t_per_day;
                })
              : [],
          });
        });
      return {
        labels: time,
        datasets: Array.from(chartData).map((el, index) => {
          return {
            label: el.name,
            data: el.data,
            fill: false,
            backgroundColor: seriesColors[index % 9],
            borderColor: seriesColors[index % 9],
            color: seriesColors[index % 9],
            borderWidth: 1,
            tension: 0.1,
            pointHoverBorderWidth: 0,
            pointRadius: 0,
            pointHitRadius: 0,
            pointBorderWidth: 1,
            pointHoverRadius: 1,
            hidden: index !== 0,
          };
        }),
      };
    }
    return { labels: [], datasets: [] };
  }, [time, option?.value]);
};

export default useChartData;
