import React from 'react';

import { UseChartData } from '../types';
import { seriesColors } from '../utils';

const useChartData: UseChartData = (wellStepTable, option, name) => {
  const { time } = React.useMemo(() => {
    if (wellStepTable?.time2states) {
      return {
        time: Object.entries(wellStepTable?.time2states).map(states =>
          parseFloat(states[0]),
        ),
      };
    }
    return {};
  }, [wellStepTable?.time2states]);

  return React.useMemo(() => {
    if (time && option && name) {
      const chartData: { name: string; data: any[] }[] = [
        { name: 'Базовый режим', data: [] },
        { name: 'Оптимизированный режим', data: [] },
      ];
      wellStepTable?.time2states &&
        Object.values(wellStepTable?.time2states).forEach((states: any) => {
          const wellState = states.find(
            (state: any) => state.well_name === name,
          );
          if (option.value === 'pressure') {
            chartData[0].data.push(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              wellState?.compare_pressure?.base?.atm || 0,
            );
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            chartData[1].data.push(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              wellState?.compare_pressure?.fact?.atm || 0,
            );
          }
          if (option.value === 'oil_flow_rate') {
            chartData[0].data.push(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              wellState?.compare_oil_flow_rate?.base?.t_per_day || 0,
            );
            chartData[1].data.push(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              wellState?.compare_oil_flow_rate?.fact?.t_per_day || 0,
            );
          }
          if (option.value === 'gas_flow_rate') {
            chartData[0].data.push(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              wellState?.compare_gas_flow_rate?.base?.thousand_m3_per_day || 0,
            );
            chartData[1].data.push(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              wellState?.compare_gas_flow_rate?.fact?.thousand_m3_per_day || 0,
            );
          }
          if (option.value === 'fluid_flow_rate') {
            chartData[0].data.push(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              wellState?.compare_fluid_flow_rate?.base?.m3_per_day || 0,
            );
            chartData[1].data.push(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              wellState?.compare_fluid_flow_rate?.fact?.m3_per_day || 0,
            );
          }
          if (option.value === 'power') {
            chartData[0].data.push(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              wellState?.compare_power?.base?.kW || 0,
            );
            chartData[1].data.push(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              wellState?.compare_power?.fact?.kW || 0,
            );
          }
          if (option.value === 'control') {
            if (wellState?.operation_type === 'Газлифт') {
              chartData[0].data.push(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                wellState?.compare_control?.base?.m3_per_day || 0,
              );
              chartData[1].data.push(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                wellState?.compare_control?.fact?.m3_per_day || 0,
              );
            } else if (wellState?.operation_type === 'ЭЦН') {
              chartData[0].data.push(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                wellState?.compare_control?.base?.hertz || 0,
              );
              chartData[1].data.push(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                wellState?.compare_control?.fact?.hertz || 0,
              );
            }
          }
        });
      return {
        labels: time,
        datasets: Array.from(chartData).map((el, index) => {
          return {
            label: el.name,
            data: el.data,
            fill: false,
            backgroundColor: seriesColors[index % 9],
            borderColor: seriesColors[index % 9],
            color: seriesColors[index % 9],
            borderWidth: 1,
            tension: 0.1,
            pointHoverBorderWidth: 0,
            pointRadius: 0,
            pointHitRadius: 0,
            pointBorderWidth: 1,
            pointHoverRadius: 1,
          };
        }),
      };
    }
    return { labels: [], datasets: [] };
  }, [time, option?.value, name]);
};

export default useChartData;
