import * as React from 'react';

export const templateRowsNumber = 5;
export const wellsAFColumnDefs = [
  {
    colId: '0',
    headerName: 'Название',
    field: 'well_name',
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '1',
    headerName: 'Фактическое давление, атм',
    field: 'fact_pressure__atm',
  },
  {
    colId: '2',
    headerName: 'Расчетное давление, атм',
    field: 'calculated_pressure__atm',
  },
  {
    colId: '3',
    headerName: 'Абсолютное отклонение давлений, атм',
    field: 'absolute_pressure_error__atm',
  },
  {
    colId: '4',
    headerName: 'Относительное отклонение давлений, %',
    field: 'absolute_pressure_error__atm',
  },
];
export const wellsAvgTableHead = {
  well_name: 'Название',
  fact_pressure__atm: {
    title: 'Фактическое давление',
    component: (
      <>
        Фактическое давление, <br />
        атм
      </>
    ),
  },
  calculated_pressure__atm: {
    title: 'Расчетное давление',
    component: (
      <>
        Расчетное давление, <br />
        атм
      </>
    ),
  },
  absolute_pressure_error__atm: {
    title: 'Абсолютное отклонение давлений',
    component: (
      <>
        Абсолютное отклонение <br />
        давлений, атм
      </>
    ),
  },
  relative_pressure_error: {
    title: 'Относительное отклонение давлений',
    component: (
      <>
        Относительное отклонение <br />
        давлений, %
      </>
    ),
  },
};

export const wellsAvgColumns = [
  {
    headerName: (
      <>
        Фактическое давление, <br />
        атм
      </>
    ),
    field: 'fact_pressure__atm',
    filter: true,
  },
];
