import * as React from 'react';

export const templateRowsNumber = 5;
export const nodesUAColumnDefs = [
  {
    colId: '0',
    headerName: 'Название',
    field: 'name',
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '1',
    headerName: 'Тип',
    field: 'type',
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '2',
    headerName: 'Расход нефти, т\u2060/\u2060сут',
    field: 'oil_flow_rate',
  },
  {
    colId: '3',
    headerName: 'Расход жидкости, м\u00B3\u2060/\u2060сут',
    field: 'fluid_flow_rate',
  },
  {
    colId: '4',
    headerName: 'Расход газа, тыс.м\u00B3\u2060/\u2060сут',
    field: 'gas_flow_rate',
  },
  { colId: '5', headerName: 'Давление, атм', field: 'pressure' },
  { colId: '6', headerName: 'Температура, ˚C', field: 'temperature' },
];
export const nodesTableHead = {
  name: 'Название',
  type: {
    title: 'Тип',
    component: <>Тип</>,
  },
  oil_flow_rate: {
    title: 'Расход нефти',
    component: (
      <>
        Расход нефти,
        <br /> т/сут
      </>
    ),
  },
  fluid_flow_rate: {
    title: 'Расход жидкости',
    component: (
      <>
        Расход жидкости,
        <br /> м<span className="sup">3</span>/сут
      </>
    ),
  },
  gas_flow_rate: {
    title: 'Расход газа',
    component: (
      <>
        Расход газа, <br />
        тыс.м<span className="sup">3</span>/сут
      </>
    ),
  },
  pressure: {
    title: 'Давление',
    component: (
      <>
        Давление, <br />
        атм
      </>
    ),
  },
  temperature: {
    title: 'Температура',
    component: (
      <>
        Температура,
        <br />
        ˚C
      </>
    ),
  },
};
