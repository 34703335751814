import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectSinkById } from 'store/projectSlice';

import { ReactComponent as GCIcon } from '../../../../images/Project/icn-gathering-center.svg';
import { IObject } from '../../types';

const SinkPPD: React.FC<IObject> = ({ objectUid }) => {
  const sinkById = useSelector(selectSinkById);

  return (
    <div className="active-object__content">
      <div className="active-object__top-row">
        <p className="active-object__title">
          <GCIcon />
          {sinkById[objectUid as string]?.name}
        </p>
      </div>
    </div>
  );
};

export default SinkPPD;
