import React from 'react';

import classNames from 'classnames';

import { ReactComponent as GCIcon } from '../../images/NewProject/icn-gathering-center.svg';

import './style.scss';

interface IRadioButtonProps {
  value: string;
  setValue?(value: string): void;
  label: string;
  disabled?: boolean;
  name: string;
  id: string;
  checked?: boolean;
}

const RadioButton: React.FC<IRadioButtonProps> = ({
  value,
  setValue,
  disabled,
  name,
  label,
  id,
  checked,
  ...inputProps
}) => {
  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (setValue) setValue(e.target.value);
  };

  return (
    <div className="radio-input-row">
      <input
        type="radio"
        aria-checked={false}
        className="radio-input"
        value={value}
        onChange={onChangeValue}
        disabled={disabled}
        name={name}
        id={id}
        checked={checked}
        {...inputProps}
      />
      {name === 'subnet' && (
        <GCIcon className={classNames('radio-label-icon', { disabled })} />
      )}
      <label htmlFor={id} className="radio-label">
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
