import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectExperimentType,
  selectSolution,
  setAFIntergalCharacteristics,
  setAFPipelinesAvgTable,
  setAFWellsAvgTable,
  setChokesSolution,
  setIntergalCharacteristics,
  setIsActual,
  setSeparatorsSolution,
  setSolution,
  setWellsStepTable,
} from 'store/taskSlice';

import {
  getAFIntegralCharacteristics,
  getAFPipelineStates,
  getAFWellStates,
  getIntegralCharacteristics,
  getMOChokesAvg,
  getMOSeparatorsAvg,
  getSolution,
  getTasks,
  getUAChokesAvg,
  getUASeparatorsAvg,
  getWellsStepTableMO,
  getWellsStepTableUA,
} from 'services/apiRequests';
import { components } from '../../../generated/apiTypes';
import { selectReservoir } from '../../../store/projectSlice';
import { ExperimentStatus, ExperimentType } from '../types';

const useGetSolution = (
  project_id: string | null,
  task_id: string | null,
  status: ExperimentStatus,
) => {
  const dispatch = useDispatch();
  const storeSolutionType = useSelector(selectSolution)?.type;
  const experimentType = useSelector(selectExperimentType);
  const reservoirUID = useSelector(selectReservoir)?.uid;

  React.useEffect(() => {
    let isSubscribed = true;
    const getData = async () => {
      if (task_id) {
        try {
          switch (experimentType) {
            case 'Базовый режим': {
              if (reservoirUID) {
                let solution;
                if (isSubscribed) solution = await getSolution(task_id);
                isSubscribed &&
                  solution &&
                  dispatch(
                    setSolution({
                      type: storeSolutionType as ExperimentType,
                      solution: solution.data,
                    }),
                  );
              } else {
                const integralCharacteristics =
                  await getIntegralCharacteristics(task_id);
                isSubscribed &&
                  dispatch(
                    setIntergalCharacteristics(
                      integralCharacteristics.data as components['schemas']['PNOPeriodicNodalAnalysisSolutionIntegralCharacteristic'],
                    ),
                  );
                let wellsStepTable;
                if (isSubscribed)
                  wellsStepTable = await getWellsStepTableUA(task_id);
                isSubscribed &&
                  wellsStepTable &&
                  dispatch(setWellsStepTable(wellsStepTable.data));
                let solution;
                if (isSubscribed) solution = await getSolution(task_id);
                isSubscribed &&
                  solution &&
                  dispatch(
                    setSolution({
                      type: storeSolutionType as ExperimentType,
                      solution: solution.data,
                    }),
                  );
                let separatorsSolution;
                if (isSubscribed)
                  separatorsSolution = await getUASeparatorsAvg(task_id);
                isSubscribed &&
                  separatorsSolution &&
                  dispatch(
                    setSeparatorsSolution({
                      type: storeSolutionType as ExperimentType,
                      solution: separatorsSolution.data.separator_states,
                    }),
                  );
                let chokesSolution;
                if (isSubscribed)
                  chokesSolution = await getUAChokesAvg(task_id);
                isSubscribed &&
                  chokesSolution &&
                  dispatch(
                    setChokesSolution({
                      type: storeSolutionType as ExperimentType,
                      solution: chokesSolution.data.states,
                    }),
                  );
              }
              break;
            }
            case 'Оптимизация режима': {
              if (reservoirUID) {
                let solution;
                if (isSubscribed) solution = await getSolution(task_id);
                isSubscribed &&
                  solution &&
                  dispatch(
                    setSolution({
                      type: storeSolutionType as ExperimentType,
                      solution: solution.data,
                    }),
                  );
              } else {
                const integralCharacteristics =
                  await getIntegralCharacteristics(task_id);
                isSubscribed &&
                  dispatch(
                    setIntergalCharacteristics(
                      integralCharacteristics.data as components['schemas']['PNOPeriodicNodalAnalysisSolutionIntegralCharacteristic'],
                    ),
                  );
                let wellsStepTable;
                if (isSubscribed)
                  wellsStepTable = await getWellsStepTableMO(task_id);
                isSubscribed &&
                  wellsStepTable &&
                  dispatch(setWellsStepTable(wellsStepTable.data));
                let response;
                if (isSubscribed) response = await getSolution(task_id);
                isSubscribed &&
                  response &&
                  dispatch(
                    setSolution({
                      type: storeSolutionType as ExperimentType,
                      solution: response.data,
                    }),
                  );
                let separatorsSolution;
                if (isSubscribed)
                  separatorsSolution = await getMOSeparatorsAvg(task_id);
                isSubscribed &&
                  separatorsSolution &&
                  dispatch(
                    setSeparatorsSolution({
                      type: storeSolutionType as ExperimentType,
                      solution: separatorsSolution.data.separator_states,
                    }),
                  );
                let chokesSolution;
                if (isSubscribed)
                  chokesSolution = await getMOChokesAvg(task_id);
                isSubscribed &&
                  chokesSolution &&
                  dispatch(
                    setChokesSolution({
                      type: storeSolutionType as ExperimentType,
                      solution: chokesSolution.data.states,
                    }),
                  );
              }
              break;
            }
            case 'Адаптация на факт': {
              const integralCharacteristics =
                await getAFIntegralCharacteristics(task_id);
              isSubscribed &&
                dispatch(
                  setAFIntergalCharacteristics(integralCharacteristics.data),
                );
              let wellStates;
              if (isSubscribed) wellStates = await getAFWellStates(task_id);
              isSubscribed &&
                wellStates &&
                dispatch(setAFWellsAvgTable(wellStates.data.well_states));
              let pipelineStates;
              if (isSubscribed)
                pipelineStates = await getAFPipelineStates(task_id);
              isSubscribed &&
                pipelineStates &&
                dispatch(
                  setAFPipelinesAvgTable(pipelineStates.data.pipeline_states),
                );
              break;
            }
            default: {
              break;
            }
          }
        } catch (e) {
          //
        }
      }
    };
    const getTaskIsActual = async () => {
      const response = await getTasks(project_id!);
      if (response?.data?.length > 0) {
        const task = response?.data?.find(item => item.task_uid === task_id);
        if (task) {
          dispatch(setIsActual(task.is_actual!));
        }
      }
    };
    // const getSettings = async () => {
    //   const response = await getExperimentSettings(project_id!);
    //   dispatch(setExperimentSettings(response?.data));
    // };
    if (status === 'SOLVED') {
      getTaskIsActual();
      getData();
    }
    // if (status === 'SOLVED' || status === 'FAILED') getSettings();

    return () => {
      isSubscribed = false;
    };
  }, [task_id, status, project_id]);
};

export default useGetSolution;
