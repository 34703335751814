import * as React from 'react';

import { components } from '../../../../generated/apiTypes';
import { ReactComponent as LinkedIcon } from '../../../../images/Project/linked-icon.svg';
import ObjectInput from '../../ActiveObject/components/ObjectInput';

interface IPoint {
  index: number;
  saveStoreHeightProfile(
    pointIx: number,
    value: components['schemas']['PNOSegmentInternalCoordinate'],
  ): void;
  saveHeightProfile(
    pointIx: number,
    value: components['schemas']['PNOSegmentInternalCoordinate'],
  ): void;
  item: components['schemas']['PNOSegmentInternalCoordinate'];
  deletePoint(pointIx: number): void;
  isCoordinateError(value: number): boolean;
}

const Point: React.FC<IPoint> = ({
  index,
  saveStoreHeightProfile,
  saveHeightProfile,
  item,
  isCoordinateError,
}) => {
  const [itemState, setItemState] =
    React.useState<components['schemas']['PNOSegmentInternalCoordinate']>();

  const [error, setError] = React.useState<boolean>(false);

  React.useEffect(() => {
    setItemState(item);
  }, [item]);

  const savePoint = (value: any, name: string) => {
    setError(false);
    if (name === 'coordinate') {
      if (!isCoordinateError(value?.m)) {
        const newValue = { ...item, [name]: value };
        saveHeightProfile(index, newValue);
      } else {
        const newValue = { ...item, [name]: value };
        saveStoreHeightProfile(index, newValue);
        setError(true);
      }
    } else {
      const newValue = { ...item, [name]: value };
      saveHeightProfile(index, newValue);
    }
  };

  if (index === 0) {
    return (
      <div className="d-flex flex-end" key={index}>
        <div className="active-object__input-with-unit-wrapper">
          <ObjectInput
            className="input active-object__row-input short with-unit"
            label="Координата вдоль трубы"
            name=""
            initValue={itemState?.coordinate.m}
            saveNewValue={() => {}}
            disabled
          />
          <p className="active-object__row-input-unit">м</p>
        </div>
        <LinkedIcon className="active-object__linked-icon" />
        <div className="active-object__input-with-unit-wrapper">
          <ObjectInput
            className="input active-object__row-input short with-unit"
            label="Высота"
            name="height"
            unit="m"
            initValue={itemState?.height.m}
            saveNewValue={savePoint}
            disabled
          />
          <p className="active-object__row-input-unit">м</p>
        </div>
      </div>
    );
  }
  return (
    <div className="height-profile-panel__point d-flex flex-end" key={index}>
      <div className="active-object__input-with-unit-wrapper">
        <ObjectInput
          className="input active-object__row-input short with-unit"
          label=""
          name="coordinate"
          unit="m"
          initValue={itemState?.coordinate.m}
          saveNewValue={savePoint}
          error={error}
          disabled
        />
        <p className="active-object__row-input-unit">м</p>
      </div>
      <LinkedIcon className="active-object__linked-icon" />
      <div className="active-object__input-with-unit-wrapper">
        <ObjectInput
          className="input active-object__row-input short with-unit"
          label=""
          name="height"
          unit="m"
          initValue={itemState?.height.m}
          saveNewValue={savePoint}
          disabled /* (itemState?.coordinate.m === 0 && index === 0) || error */
        />
        <p className="active-object__row-input-unit">м</p>
      </div>
      {/* index > 1 && (
        <button
          type="button"
          className="active-object__delete-point-btn"
          onClick={() => {
            deletePoint(index);
          }}
        >
          <DeleteIcon />
        </button>
      ) */}
    </div>
  );
};

export default Point;
