import { components } from 'generated/apiTypes';

import { formatThreeDigits, formatTwoDigits } from 'utils';
import {
  CompareCellComparator,
  CompareCellFilterGetter,
  CompareCellRenderer,
} from '../../../../../../../Components/AgGridTable/CompareCellRenderer/compareCellRenderer';

export const templateRowsNumber = 5;
const compareCellColumnDefs = {
  cellRenderer: CompareCellRenderer,
  filterValueGetter: CompareCellFilterGetter,
  comparator: CompareCellComparator,
};
export const equipmentMOColumnDefs = [
  {
    colId: '0',
    headerName: 'Название',
    field: 'name',
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '1',
    headerName: 'Тип',
    field: 'type',
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '2',
    headerName: 'Расход нефти (Вх.), т\u2060/\u2060сут',
    field: 'in_oil_flow_rate',
    ...compareCellColumnDefs,
  },
  {
    colId: '3',
    headerName: 'Расход нефти (Вых.), т\u2060/\u2060сут',
    field: 'out_oil_flow_rate',
    ...compareCellColumnDefs,
  },
  {
    colId: '4',
    headerName: 'Расход жидкости (Вх.), м\u00B3\u2060/\u2060сут',
    field: 'in_fluid_flow_rate',
    ...compareCellColumnDefs,
  },
  {
    colId: '5',
    headerName: 'Расход жидкости (Вых.), м\u00B3\u2060/\u2060сут',
    field: 'out_fluid_flow_rate',
    ...compareCellColumnDefs,
  },
  {
    colId: '6',
    headerName: 'Расход газа (Вх.), тыс.м\u00B3\u2060/\u2060сут',
    field: 'in_gas_flow_rate',
    ...compareCellColumnDefs,
  },
  {
    colId: '7',
    headerName: 'Расход газа (Вых.), тыс.м\u00B3\u2060/\u2060сут',
    field: 'out_gas_flow_rate',
    ...compareCellColumnDefs,
  },
  {
    colId: '8',
    headerName: 'Давление (Вх.), атм',
    field: 'in_pressure',
    ...compareCellColumnDefs,
  },
  {
    colId: '9',
    headerName: 'Давление (Вых.), атм',
    field: 'out_pressure',
    ...compareCellColumnDefs,
  },
];

export const pumpTableItems = ({
  pump_name,
  compare_in_oil_flow,
  compare_out_oil_flow,
  compare_in_fluid_flow,
  compare_out_fluid_flow,
  compare_in_gas_flow,
  compare_out_gas_flow,
  compare_in_pressure,
  compare_out_pressure,
}: any = {}) => {
  return {
    name: pump_name,
    type: 'Насос',

    in_oil_flow_rate: {
      value: formatThreeDigits(
        (compare_in_oil_flow as components['schemas']['CompareOilFlowRate'])
          ?.base?.t_per_day,
      ),
      abs: formatTwoDigits(
        (compare_in_oil_flow as components['schemas']['CompareOilFlowRate'])
          ?.abs?.t_per_day,
      ),
      rel: formatTwoDigits(
        (compare_in_oil_flow as components['schemas']['CompareOilFlowRate'])
          ?.rel,
      ),
      unit: 'т/сут',
    },
    out_oil_flow_rate: {
      value: formatThreeDigits(
        (compare_out_oil_flow as components['schemas']['CompareOilFlowRate'])
          ?.base?.t_per_day,
      ),
      abs: formatTwoDigits(
        (compare_out_oil_flow as components['schemas']['CompareOilFlowRate'])
          ?.abs?.t_per_day,
      ),
      rel: formatTwoDigits(
        (compare_out_oil_flow as components['schemas']['CompareOilFlowRate'])
          ?.rel,
      ),
      unit: 'т/сут',
    },
    in_fluid_flow_rate: {
      value: formatThreeDigits(
        (compare_in_fluid_flow as components['schemas']['CompareFluidFlowRate'])
          ?.base?.m3_per_day,
      ),
      abs: formatTwoDigits(
        (compare_in_fluid_flow as components['schemas']['CompareFluidFlowRate'])
          ?.abs?.m3_per_day,
      ),
      rel: formatThreeDigits(
        (compare_in_fluid_flow as components['schemas']['CompareFluidFlowRate'])
          ?.rel,
      ),
      unit: 'м\u00B3\u2060/\u2060сут',
    },
    out_fluid_flow_rate: {
      value: formatThreeDigits(
        (
          compare_out_fluid_flow as components['schemas']['CompareFluidFlowRate']
        )?.base?.m3_per_day,
      ),
      abs: formatTwoDigits(
        (
          compare_out_fluid_flow as components['schemas']['CompareFluidFlowRate']
        )?.abs?.m3_per_day,
      ),
      rel: formatTwoDigits(
        (
          compare_out_fluid_flow as components['schemas']['CompareFluidFlowRate']
        )?.rel,
      ),
      unit: 'м\u00B3\u2060/\u2060сут',
    },
    in_gas_flow_rate: {
      value: formatThreeDigits(
        (compare_in_gas_flow as components['schemas']['CompareGasFlowRate'])
          ?.base?.thousand_m3_per_day,
      ),
      abs: formatTwoDigits(
        (compare_in_gas_flow as components['schemas']['CompareGasFlowRate'])
          ?.abs?.thousand_m3_per_day,
      ),
      rel: formatTwoDigits(
        (compare_in_gas_flow as components['schemas']['CompareGasFlowRate'])
          ?.rel,
      ),
      unit: 'тыс.м\u00B3\u2060/\u2060сут',
    },
    out_gas_flow_rate: {
      value: formatThreeDigits(
        (compare_out_gas_flow as components['schemas']['CompareGasFlowRate'])
          ?.base?.thousand_m3_per_day,
      ),
      abs: formatTwoDigits(
        (compare_out_gas_flow as components['schemas']['CompareGasFlowRate'])
          ?.abs?.thousand_m3_per_day,
      ),
      rel: formatTwoDigits(
        (compare_out_gas_flow as components['schemas']['CompareGasFlowRate'])
          ?.rel,
      ),
      unit: 'тыс.м\u00B3\u2060/\u2060сут',
    },
    in_pressure: {
      value: formatThreeDigits(
        (compare_in_pressure as components['schemas']['ComparePressure'])?.base
          ?.atm,
      ),
      abs: formatTwoDigits(
        (compare_in_pressure as components['schemas']['ComparePressure'])?.abs
          ?.atm,
      ),
      rel: formatTwoDigits(
        (compare_in_pressure as components['schemas']['ComparePressure'])?.rel,
      ),
      unit: 'атм',
    },
    out_pressure: {
      value: formatThreeDigits(
        (compare_out_pressure as components['schemas']['ComparePressure'])?.base
          ?.atm,
      ),
      abs: formatTwoDigits(
        (compare_out_pressure as components['schemas']['ComparePressure'])?.abs
          ?.atm,
      ),
      rel: formatTwoDigits(
        (compare_out_pressure as components['schemas']['ComparePressure'])?.rel,
      ),
      unit: 'атм',
    },
  };
};

export const threePhaseSeparatorTableItems = ({
  separator_name,
  compare_in_oil_flow,
  compare_out_oil_flow,
  compare_in_fluid_flow,
  compare_out_fluid_flow,
  compare_in_gas_flow,
  compare_out_gas_flow,
  compare_in_pressure,
  compare_out_pressure,
}: any = {}) => {
  return {
    name: separator_name,
    type: 'Трехфазный сепаратор',

    in_oil_flow_rate: {
      value: formatThreeDigits(
        (compare_in_oil_flow as components['schemas']['CompareOilFlowRate'])
          ?.base?.t_per_day,
      ),
      abs: formatTwoDigits(
        (compare_in_oil_flow as components['schemas']['CompareOilFlowRate'])
          ?.abs?.t_per_day,
      ),
      rel: formatTwoDigits(
        (compare_in_oil_flow as components['schemas']['CompareOilFlowRate'])
          ?.rel,
      ),
      unit: 'т/сут',
    },
    out_oil_flow_rate: {
      value: formatThreeDigits(
        (compare_out_oil_flow as components['schemas']['CompareOilFlowRate'])
          ?.base?.t_per_day,
      ),
      abs: formatTwoDigits(
        (compare_out_oil_flow as components['schemas']['CompareOilFlowRate'])
          ?.abs?.t_per_day,
      ),
      rel: formatTwoDigits(
        (compare_out_oil_flow as components['schemas']['CompareOilFlowRate'])
          ?.rel,
      ),
      unit: 'т/сут',
    },
    in_fluid_flow_rate: {
      value: formatThreeDigits(
        (compare_in_fluid_flow as components['schemas']['CompareFluidFlowRate'])
          ?.base?.m3_per_day,
      ),
      abs: formatTwoDigits(
        (compare_in_fluid_flow as components['schemas']['CompareFluidFlowRate'])
          ?.abs?.m3_per_day,
      ),
      rel: formatThreeDigits(
        (compare_in_fluid_flow as components['schemas']['CompareFluidFlowRate'])
          ?.rel,
      ),
      unit: 'м\u00B3\u2060/\u2060сут',
    },
    out_fluid_flow_rate: {
      value: formatThreeDigits(
        (
          compare_out_fluid_flow as components['schemas']['CompareFluidFlowRate']
        )?.base?.m3_per_day,
      ),
      abs: formatTwoDigits(
        (
          compare_out_fluid_flow as components['schemas']['CompareFluidFlowRate']
        )?.abs?.m3_per_day,
      ),
      rel: formatTwoDigits(
        (
          compare_out_fluid_flow as components['schemas']['CompareFluidFlowRate']
        )?.rel,
      ),
      unit: 'м\u00B3\u2060/\u2060сут',
    },
    in_gas_flow_rate: {
      value: formatThreeDigits(
        (compare_in_gas_flow as components['schemas']['CompareGasFlowRate'])
          ?.base?.thousand_m3_per_day,
      ),
      abs: formatTwoDigits(
        (compare_in_gas_flow as components['schemas']['CompareGasFlowRate'])
          ?.abs?.thousand_m3_per_day,
      ),
      rel: formatTwoDigits(
        (compare_in_gas_flow as components['schemas']['CompareGasFlowRate'])
          ?.rel,
      ),
      unit: 'тыс.м\u00B3\u2060/\u2060сут',
    },
    out_gas_flow_rate: {
      value: formatThreeDigits(
        (compare_out_gas_flow as components['schemas']['CompareGasFlowRate'])
          ?.base?.thousand_m3_per_day,
      ),
      abs: formatTwoDigits(
        (compare_out_gas_flow as components['schemas']['CompareGasFlowRate'])
          ?.abs?.thousand_m3_per_day,
      ),
      rel: formatTwoDigits(
        (compare_out_gas_flow as components['schemas']['CompareGasFlowRate'])
          ?.rel,
      ),
      unit: 'тыс.м\u00B3\u2060/\u2060сут',
    },
    in_pressure: {
      value: formatThreeDigits(
        (compare_in_pressure as components['schemas']['ComparePressure'])?.base
          ?.atm,
      ),
      abs: formatTwoDigits(
        (compare_in_pressure as components['schemas']['ComparePressure'])?.abs
          ?.atm,
      ),
      rel: formatTwoDigits(
        (compare_in_pressure as components['schemas']['ComparePressure'])?.rel,
      ),
      unit: 'атм',
    },
    out_pressure: {
      value: formatThreeDigits(
        (compare_out_pressure as components['schemas']['ComparePressure'])?.base
          ?.atm,
      ),
      abs: formatTwoDigits(
        (compare_out_pressure as components['schemas']['ComparePressure'])?.abs
          ?.atm,
      ),
      rel: formatTwoDigits(
        (compare_out_pressure as components['schemas']['ComparePressure'])?.rel,
      ),
      unit: 'атм',
    },
  };
};
export const separatorTableItems = ({
  separator_name,
  start_oil_mass_flow__t_per_day,
  start_oil_mass_flow_abs_error__t_per_day,
  start_oil_mass_flow_rel_error,
  end_oil_mass_flow__t_per_day,
  end_oil_mass_flow_abs_error__t_per_day,
  end_oil_mass_flow_rel_error,
  start_fluid_volume_flow__m3_per_day,
  start_fluid_volume_flow_abs_error__m3_per_day,
  start_fluid_volume_flow_rel_error,
  end_fluid_volume_flow__m3_per_day,
  end_fluid_volume_flow_abs_error__m3_per_day,
  end_fluid_volume_flow_rel_error,
  start_gas_volume_flow__thos_m3_per_day,
  start_gas_volume_flow_abs_error__thos_m3_per_day,
  start_gas_volume_flow_rel_error,
  end_gas_volume_flow__thos_m3_per_day,
  end_gas_volume_flow_abs_error__thos_m3_per_day,
  end_gas_volume_flow_rel_error,
  start_pressure__atm,
  start_pressure_abs_error__atm,
  start_pressure_rel_error,
  end_pressure__atm,
  end_pressure_abs_error__atm,
  end_pressure_rel_error,
}: any = {}) => {
  return {
    name: separator_name,
    type: 'Сепаратор',
    in_oil_flow_rate: {
      value: formatThreeDigits(start_oil_mass_flow__t_per_day),
      abs: formatTwoDigits(start_oil_mass_flow_abs_error__t_per_day),
      rel: formatTwoDigits(start_oil_mass_flow_rel_error),
      unit: 'т/сут',
    },
    out_oil_flow_rate: {
      value: formatThreeDigits(end_oil_mass_flow__t_per_day),
      abs: formatTwoDigits(end_oil_mass_flow_abs_error__t_per_day),
      rel: formatTwoDigits(end_oil_mass_flow_rel_error),
      unit: 'т/сут',
    },
    in_fluid_flow_rate: {
      value: formatThreeDigits(start_fluid_volume_flow__m3_per_day),
      abs: formatTwoDigits(start_fluid_volume_flow_abs_error__m3_per_day),
      rel: formatTwoDigits(start_fluid_volume_flow_rel_error),
      unit: 'м\u00B3\u2060/\u2060сут',
    },
    out_fluid_flow_rate: {
      value: formatThreeDigits(end_fluid_volume_flow__m3_per_day),
      abs: formatTwoDigits(end_fluid_volume_flow_abs_error__m3_per_day),
      rel: formatTwoDigits(end_fluid_volume_flow_rel_error),
      unit: 'м\u00B3\u2060/\u2060сут',
    },
    in_gas_flow_rate: {
      value: formatThreeDigits(start_gas_volume_flow__thos_m3_per_day),
      abs: formatTwoDigits(start_gas_volume_flow_abs_error__thos_m3_per_day),
      rel: formatTwoDigits(start_gas_volume_flow_rel_error),
      unit: 'тыс.м\u00B3\u2060/\u2060сут',
    },
    out_gas_flow_rate: {
      value: formatThreeDigits(end_gas_volume_flow__thos_m3_per_day),
      abs: formatTwoDigits(end_gas_volume_flow_abs_error__thos_m3_per_day),
      rel: formatTwoDigits(end_gas_volume_flow_rel_error),
      unit: 'тыс.м\u00B3\u2060/\u2060сут',
    },
    in_pressure: {
      value: formatThreeDigits(start_pressure__atm),
      abs: formatTwoDigits(start_pressure_abs_error__atm),
      rel: formatTwoDigits(start_pressure_rel_error),
      unit: 'атм',
    },
    out_pressure: {
      value: formatThreeDigits(end_pressure__atm),
      abs: formatTwoDigits(end_pressure_abs_error__atm),
      rel: formatTwoDigits(end_pressure_rel_error),
      unit: 'атм',
    },
  };
};

export const chokeTableItems = ({
  name,
  mass_oil_flow__t_per_day,
  mass_oil_flow_abs_error__t_per_day,
  mass_oil_flow_rel_error__t_per_day,
  volume_fluid_flow__m3_per_day,
  volume_fluid_flow_abs_error__m3_per_day,
  volume_fluid_flow_rel_error__m3_per_day,
  volume_gas_flow__thos_m3_per_day,
  volume_gas_flow_abs_error__thos_m3_per_day,
  volume_gas_flow_rel_error__thos_m3_per_day,
  start_pressure__atmg,
  start_pressure_abs_error__atmg,
  start_pressure_rel_error__atmg,
  end_pressure__atmg,
  end_pressure_abs_error__atmg,
  end_pressure_rel_error__atmg,
}: any = {}) => {
  return {
    name,
    type: 'Штуцер',
    in_oil_flow_rate: {
      value: formatThreeDigits(mass_oil_flow__t_per_day),
      abs: formatTwoDigits(mass_oil_flow_abs_error__t_per_day),
      rel: formatTwoDigits(mass_oil_flow_rel_error__t_per_day),
      unit: 'т/сут',
    },
    out_oil_flow_rate: {
      value: formatThreeDigits(mass_oil_flow__t_per_day),
      abs: formatTwoDigits(mass_oil_flow_abs_error__t_per_day),
      rel: formatTwoDigits(mass_oil_flow_rel_error__t_per_day),
      unit: 'т/сут',
    },
    in_fluid_flow_rate: {
      value: formatThreeDigits(volume_fluid_flow__m3_per_day),
      abs: formatTwoDigits(volume_fluid_flow_abs_error__m3_per_day),
      rel: formatTwoDigits(volume_fluid_flow_rel_error__m3_per_day),
      unit: 'м\u00B3\u2060/\u2060сут',
    },
    out_fluid_flow_rate: {
      value: formatThreeDigits(volume_fluid_flow__m3_per_day),
      abs: formatTwoDigits(volume_fluid_flow_abs_error__m3_per_day),
      rel: formatTwoDigits(volume_fluid_flow_rel_error__m3_per_day),
      unit: 'м\u00B3\u2060/\u2060сут',
    },
    in_gas_flow_rate: {
      value: formatThreeDigits(volume_gas_flow__thos_m3_per_day),
      abs: formatTwoDigits(volume_gas_flow_abs_error__thos_m3_per_day),
      rel: formatTwoDigits(volume_gas_flow_rel_error__thos_m3_per_day),
      unit: 'тыс.м\u00B3\u2060/\u2060сут',
    },
    out_gas_flow_rate: {
      value: formatThreeDigits(volume_gas_flow__thos_m3_per_day),
      abs: formatTwoDigits(volume_gas_flow_abs_error__thos_m3_per_day),
      rel: formatTwoDigits(volume_gas_flow_rel_error__thos_m3_per_day),
      unit: 'тыс.м\u00B3\u2060/\u2060сут',
    },
    in_pressure: {
      value: formatThreeDigits(start_pressure__atmg),
      abs: formatTwoDigits(start_pressure_abs_error__atmg),
      rel: formatTwoDigits(start_pressure_rel_error__atmg),
      unit: 'атм',
    },
    out_pressure: {
      value: formatThreeDigits(end_pressure__atmg),
      abs: formatTwoDigits(end_pressure_abs_error__atmg),
      rel: formatTwoDigits(end_pressure_rel_error__atmg),
      unit: 'атм',
    },
  };
};
