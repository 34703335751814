import * as React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { ReactComponent as ChartIcon } from 'images/Project/icn-line-chart.svg';
import { ReactComponent as TableIcon } from 'images/Project/icn-table.svg';

import SourcesChart from './Chart';
import Table from './Table/Table';

const Sources: React.FC = () => {
  const [tabIndex, setTabIndex] = React.useState(0);
  return (
    <>
      <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
        <TabList className="results__tab-list">
          <Tab className="results__icon">
            <ChartIcon />
          </Tab>
          <Tab className="results__icon">
            <TableIcon />
          </Tab>
        </TabList>
        <div>
          <TabPanel>
            <SourcesChart />
          </TabPanel>
          <TabPanel>
            <Table />
          </TabPanel>
        </div>
      </Tabs>
    </>
  );
};

export default Sources;
