import * as React from 'react';
import { Line } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import Button from '../../../../../Components/Button';
import { components } from '../../../../../generated/apiTypes';
import {
  getTasks,
  getWellModel,
  updateWellModel as saveNewWellModel,
} from '../../../../../services/apiRequests';
import { selectActiveObject } from '../../../../../store/mapSlice';
import {
  removeUpdatedModel,
  selectWellModelById,
  updateWellModal,
} from '../../../../../store/projectSlice';
import { selectTaskId, setIsActual } from '../../../../../store/taskSlice';
import { IChart } from './types';
import { getOptions } from './utils';

import '../../../ResultsPanel/chartStyle.scss';

const Chart: React.FC<IChart> = ({
  chartType,
  wellOperationType,
  chartData,
  tableState,
  setTableState,
  targetParam,
}) => {
  const dispatch = useDispatch();
  const wellsModelsById = useSelector(selectWellModelById);
  const activeObject = useSelector(selectActiveObject);
  const params = useParams() as { id: string };
  const taskId = useSelector(selectTaskId);
  const [wellModel, setWellModel] =
    React.useState<components['schemas']['GetWellModelQueryResult']>();

  React.useEffect(() => {
    setWellModel(wellsModelsById[activeObject.object_uid as string]);
  }, [
    activeObject.object_uid,
    wellsModelsById[activeObject.object_uid as string],
  ]);

  const getServerWellModel = async () => {
    try {
      const response = await getWellModel(activeObject.object_uid as string);
      if (response.data) {
        dispatch(removeUpdatedModel(activeObject.object_uid as string));
        dispatch(updateWellModal(response.data));
      }
    } catch (e) {
      //
    }
  };

  const saveWellModel = async () => {
    if (wellModel) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { cluster_id, ...modelToSend } =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        wellModel as components['schemas']['GetWellModelQueryResult'];
      try {
        const response = await saveNewWellModel(
          activeObject.object_uid as string,
          modelToSend as {
            model:
              | components['schemas']['UpdatePeriodicGasliftWellModelCommand']
              | components['schemas']['UpdatePeriodicPumpWellModelCommand']
              | components['schemas']['UpdatePermanentFountainWellModelCommand']
              | components['schemas']['UpdatePermanentGasliftWellModelCommand']
              | components['schemas']['UpdatePermanentPumpWellModelCommand']
              | components['schemas']['UpdatePermanentTimedWellModelCommand']
              | components['schemas']['UpdatePump5dWellModelCommand']
              | components['schemas']['UpdateGaslift5dWellModelCommand'];
          },
        );
        if (response.data) {
          dispatch(removeUpdatedModel(activeObject.object_uid as string));
        }
        const res = await getTasks(params.id);
        if (res?.data?.length > 0) {
          const task = res?.data?.find(item => item.task_uid === taskId);
          if (task && !task.is_actual) {
            dispatch(setIsActual(task.is_actual!));
          }
        }
      } catch (e) {
        //
      }
    }
  };

  const onCancelClick = () => {
    getServerWellModel();
    setTableState('view');
  };

  const onSaveClick = () => {
    saveWellModel();
    setTableState('view');
  };

  return (
    <>
      <div className="results__single-chart">
        <div className="well-model-panel__chart-container">
          <Line
            data={chartData}
            options={
              getOptions(
                chartType,
                wellOperationType as any,
                targetParam,
              ) as any
            }
          />
        </div>
      </div>
      <div className="well-model-panel__buttons">
        {tableState === 'view' && (
          <Button
            type="button"
            mode="secondary"
            onClick={() => {
              setTableState('edit');
            }}
          >
            Редактировать
          </Button>
        )}
        {tableState === 'edit' && (
          <>
            <Button
              type="button"
              className="well-model-panel__save-btn"
              onClick={onSaveClick}
            >
              Сохранить
            </Button>
            <Button type="button" mode="secondary" onClick={onCancelClick}>
              Отменить
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default Chart;
