import * as React from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Map from 'Components/Map';

import DialogWindow from '../../Components/DialogWindow';
import { components } from '../../generated/apiTypes';
import {
  createProject,
  createProjectFromFile,
} from '../../services/apiRequests';
import { setShowDialog } from '../../store/commonSlice';
import LeftCol from './LeftCol';
import { NewProjectPropsType } from './types';

import './style.scss';

const NewProject: React.FC<NewProjectPropsType> = ({ history }) => {
  const dispatch = useDispatch();
  const [inProgress, setInProgress] = React.useState<boolean>(false);

  React.useEffect(() => {
    !localStorage.username && history.push('/login');
  }, []);

  const onCancelClick = () => {
    history.push('/');
  };

  const createProjectfromFile = async (file: File, projectName: string) => {
    const fileToSend = new FormData();
    fileToSend.append('file', file);
    try {
      setInProgress(true);
      const { data } = await createProject({
        project_name: projectName,
        file: fileToSend,
      });
      setInProgress(false);
      const id = data.project_uid;
      history.push(`/project/${id}`);
    } catch (e: any) {
      setInProgress(false);
      if (e?.response?.status === 422) {
        dispatch(
          setShowDialog({
            dialogType: 'ERROR',
            objectName: 'Невозможно создать проект',
            message: e?.response?.data?.detail[0]?.msg,
          }),
        );
      } else {
        dispatch(
          setShowDialog({
            dialogType: 'ERROR',
            objectName: 'Невозможно создать проект',
            message: e?.response?.data?.user_message,
          }),
        );
      }
    }
  };

  const createProjectfromDetails = async (
    well_model_file: File,
    projectName: string,
    url: string,
  ) => {
    const fileToSend = new FormData();
    fileToSend.append('well_model_file', well_model_file);
    setInProgress(true);
    try {
      const response = await createProjectFromFile({
        project_name: projectName,
        iskra_project_url: url,
        file: fileToSend,
      });
      setInProgress(false);
      if (response.status === 201) {
        const id = (response.data as components['schemas']['PNOProjectData'])
          ?.project_uid;
        if (id) history.push(`/project/${id}`);
      } else if (response.status === 200) {
        dispatch(
          setShowDialog({
            dialogType: 'CHOOSE_SUBNET',
            options: (
              response.data as components['schemas']['SubNetworksResponse']
            )?.subnetworks,
            objectName: projectName,
            objectId: url,
            file: fileToSend,
          }),
        );
        // history.push(`/project/${data}`);
      }
    } catch (e: any) {
      setInProgress(false);
      if (e?.response?.status === 422) {
        dispatch(
          setShowDialog({
            dialogType: 'ERROR',
            objectName: 'Невозможно создать проект',
            message: e?.response?.data?.detail[0]?.msg,
          }),
        );
      } else {
        dispatch(
          setShowDialog({
            dialogType: 'ERROR',
            objectName: 'Невозможно создать проект',
            message: e?.response?.data?.user_message,
          }),
        );
      }
    }
  };

  return (
    <div className="new-project-container">
      <DialogWindow />
      <LeftCol
        onCancelClick={onCancelClick}
        createProjectFromFile={createProjectfromFile}
        createProjectFromDetails={createProjectfromDetails}
        progress={inProgress}
      />
      <Map />
    </div>
  );
};

export default withRouter(NewProject);
