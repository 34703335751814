import * as React from 'react';

import { usePutTableToStorage } from '../../../../hooks/usePutTableToStorage';
import UATable from './Table/Table';
import { equipmentUAColumnDefs } from './Table/utils';

export const TABLE_NAME = 'PPD_UA_EQUIPMENTS';
const Equipments: React.FC = () => {
  usePutTableToStorage(TABLE_NAME, equipmentUAColumnDefs);

  return <UATable />;
};

export default Equipments;
