import * as React from 'react';

import classNames from 'classnames';

import Input from '../../../../Components/Input';
import { components } from '../../../../generated/apiTypes';

interface IStepInput {
  tabIndex: number;
  value: string;
  initStep: string;
  max: string;
  setStep(val: string): void;
  time2states: {
    [key: string]:
      | components['schemas']['PNOPeriodicNodalAnalysisNode'][]
      | components['schemas']['PNOSegmentState'][]
      | components['schemas']['PNOTechModeOptimizationNodeState'][]
      | components['schemas']['PNOTechModeOptimizationSegmentState'][]
      | components['schemas']['PNOTechModeOptimizationWellState'][]
      | components['schemas']['PNOWellState'][];
  };
}

const StepInput: React.FC<IStepInput> = ({
  tabIndex,
  value,
  setStep,
  max,
  time2states,
}) => {
  const [stateValue, setStateValue] = React.useState<string>(
    parseFloat(value).toFixed(0),
  );
  const [timeStep, setTimeStep] = React.useState<number>();

  React.useEffect(() => {
    if (time2states) {
      const step =
        parseFloat(
          Object.keys(time2states)[Object.entries(time2states)?.length - 1],
        ) -
        parseFloat(
          Object.keys(time2states)[Object.entries(time2states)?.length - 2],
        );
      if (step) setTimeStep(step);
    }
  }, [time2states]);

  const onChangeStep = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStateValue(e.target.value);
  };

  const saveValue = () => {
    if (
      parseFloat(stateValue) &&
      timeStep &&
      Math.round(parseFloat(stateValue) / timeStep) * timeStep <=
        parseFloat(max)
    ) {
      const val = Math.max(
        0.0,
        Math.round(parseFloat(stateValue) / timeStep) * timeStep,
      );
      setStateValue(Math.min(val, parseFloat(max)).toFixed(0));
      setStep(Math.min(val, parseFloat(max)).toFixed(1));
    } else {
      setStateValue(parseFloat(value).toFixed(0));
      setStep(parseFloat(value).toFixed(1));
    }
  };

  const onBlurHandler = () => {
    saveValue();
  };

  const onKeyUpHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'ArrowUp': {
        let newValue = 0;
        if (
          timeStep &&
          parseFloat(stateValue) !== undefined &&
          parseFloat(stateValue) < parseFloat(max)
        ) {
          newValue = Math.min(
            parseFloat(stateValue) + timeStep,
            parseFloat(max),
          );
        } else {
          newValue = 0;
        }
        setStateValue(newValue.toFixed(0));
        setStep(newValue.toFixed(1));
        break;
      }
      case 'ArrowDown': {
        let newValue = 0;
        if (
          timeStep &&
          parseFloat(stateValue) !== undefined &&
          parseFloat(stateValue) > 0
        ) {
          newValue = Math.max(parseFloat(stateValue) - timeStep, 0);
        } else {
          newValue = parseFloat(max);
        }
        setStateValue(newValue.toFixed(0));
        setStep(newValue.toFixed(1));
        break;
      }
      default:
        break;
    }
  };

  return (
    <div
      className={classNames(
        'results_tabs-step-row',
        tabIndex === 1 && 'visible',
      )}
    >
      <Input
        className={classNames('input results_tabs-step-input')}
        value={stateValue}
        onChange={onChangeStep}
        onBlur={onBlurHandler}
        onKeyUp={onKeyUpHandler}
      />
      <p className="results_tabs-step-input-unit">
        мин из {parseFloat(max).toFixed(0)}
      </p>
    </div>
  );
};

export default StepInput;
