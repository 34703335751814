import * as React from 'react';
import { useSelector } from 'react-redux';

import { components } from 'generated/apiTypes';
import { selectSolution } from 'store/taskSlice';

import AgGridTable from '../../../../../../../Components/AgGridTable';
import { useGetColumnDefs } from '../../../../../hooks/useGetColumnDefs';
import { TABLE_NAME } from './Table';
import { layersMOColumnDefs, layersTableItems } from './utils';

const LayersTable: React.FC = () => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOReservoirOptimizationProblem'];
  const [data, setData] = React.useState<any[]>([]);
  const columnDefs = useGetColumnDefs(TABLE_NAME, layersMOColumnDefs);

  const getData = () => {
    const newData: any[] = [];
    newData.push(layersTableItems(solution?.solution?.reservoir_state));
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [solution]);

  return (
    <AgGridTable
      tableName={TABLE_NAME}
      columnDefs={columnDefs}
      rowData={data}
    />
  );
};

export default LayersTable;
