import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import update from 'immutability-helper';
import { selectActiveObject } from 'store/mapSlice';
import {
  addUpdatedModel,
  selectUpdatedModel,
  selectWellModelById,
  updateWellModal,
} from 'store/projectSlice';

import { oneDigitsFormatter } from '../../../../../utils';
import { ChartType } from '../types';
import { getParameterByType } from './utils';

interface IParameterInput {
  initValue: string;
  pressure_index: number;
  control_action_index: number;
  tableType: ChartType;
  targetParam?:
    | 'FREQ'
    | 'ANNULAR_PRESSURE_DROP'
    | 'WELL_HEAD_PRESSURE_DROP'
    | 'WORK_FREQ_5D'
    | 'WORK_GASLIFT_FLOWS_5D'
    | 'WORK_TIMES_5D'
    | 'ACCUMULATION_TIMES_5D';
}

const ParameterInput: React.FC<IParameterInput> = ({
  initValue,
  pressure_index,
  control_action_index,
  tableType,
  targetParam,
}) => {
  const dispatch = useDispatch();
  const wellsModelsById = useSelector(selectWellModelById);
  const activeObject = useSelector(selectActiveObject);
  const updatedModel = useSelector(selectUpdatedModel);
  const wellModel = wellsModelsById[activeObject.object_uid as string];
  const [value, setValue] = React.useState<string | undefined>(undefined);
  const ref = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    setValue(initValue);
  }, [initValue]);

  const changeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueWithoutSpaces = e.target.value.replace(/\s/g, '');
    const digitOnly = new RegExp('^[-]?([0-9]*)*[.,]?([0-9]{0,8})?$', 'g');
    if (valueWithoutSpaces.match(digitOnly)) {
      setValue(e.target.value);
    }
  };

  const onKeyDownHandler = (e: React.KeyboardEvent) => {
    const { key } = e;
    if (['Delete', 'Backspace'].includes(key)) {
      e.stopPropagation();
    }
    if (['Enter', 'Escape'].includes(key)) {
      e.stopPropagation();
      ref?.current?.blur();
    }
  };

  const onBlur = () => {
    const commaToDotValue = value?.replace(',', '.');
    const valueWithoutSpaces = commaToDotValue?.replace(/\s/g, '');
    if (valueWithoutSpaces) {
      const newValue = parseFloat(valueWithoutSpaces);
      if (newValue) {
        setValue(oneDigitsFormatter.format(newValue));
        if (!updatedModel.includes(activeObject.object_uid as string)) {
          dispatch(addUpdatedModel(activeObject.object_uid as string));
        }
        if (targetParam) {
          if (targetParam === 'FREQ') {
            dispatch(
              updateWellModal(
                update(wellModel, {
                  model: {
                    [getParameterByType(tableType)]: {
                      [control_action_index]: {
                        0: {
                          0: {
                            [pressure_index]: {
                              $set: newValue,
                            },
                          },
                        },
                      },
                    },
                  },
                }),
              ),
            );
          } else if (targetParam === 'ANNULAR_PRESSURE_DROP') {
            dispatch(
              updateWellModal(
                update(wellModel, {
                  model: {
                    [getParameterByType(tableType)]: {
                      0: {
                        0: {
                          [control_action_index]: {
                            [pressure_index]: {
                              $set: newValue,
                            },
                          },
                        },
                      },
                    },
                  },
                }),
              ),
            );
          } else if (targetParam === 'WELL_HEAD_PRESSURE_DROP') {
            dispatch(
              updateWellModal(
                update(wellModel, {
                  model: {
                    [getParameterByType(tableType)]: {
                      0: {
                        [control_action_index]: {
                          0: {
                            [pressure_index]: {
                              $set: newValue,
                            },
                          },
                        },
                      },
                    },
                  },
                }),
              ),
            );
          } else if (
            targetParam === 'WORK_FREQ_5D' ||
            targetParam === 'WORK_GASLIFT_FLOWS_5D'
          ) {
            dispatch(
              updateWellModal(
                update(wellModel, {
                  model: {
                    [getParameterByType(tableType, targetParam)]: {
                      [pressure_index]: {
                        [control_action_index]: {
                          0: {
                            0: {
                              0: {
                                $set:
                                  tableType === 'FlowRates'
                                    ? newValue / 1000
                                    : newValue,
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                }),
              ),
            );
          } else if (targetParam === 'WORK_TIMES_5D') {
            dispatch(
              updateWellModal(
                update(wellModel, {
                  model: {
                    [getParameterByType(tableType, targetParam)]: {
                      [pressure_index]: {
                        0: {
                          0: {
                            [control_action_index]: {
                              0: {
                                $set:
                                  tableType === 'FlowRates'
                                    ? newValue / 1000
                                    : newValue,
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                }),
              ),
            );
          } /* if (targetParam === 'ACCUMULATION_TIMES_5D') */ else {
            dispatch(
              updateWellModal(
                update(wellModel, {
                  model: {
                    [getParameterByType(tableType, targetParam)]: {
                      [pressure_index]: {
                        0: {
                          0: {
                            0: {
                              [control_action_index]: {
                                $set:
                                  tableType === 'FlowRates'
                                    ? newValue / 1000
                                    : newValue,
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                }),
              ),
            );
          }
        } else
          dispatch(
            updateWellModal(
              update(wellModel, {
                model: {
                  [getParameterByType(tableType)]: {
                    [control_action_index]: {
                      [pressure_index]: {
                        $set: newValue,
                      },
                    },
                  },
                },
              }),
            ),
          );
      }
    }
  };

  return (
    <input
      ref={ref}
      className="well-model-panel__parameter-input"
      value={value}
      onChange={changeValue}
      onKeyDown={onKeyDownHandler}
      onBlur={onBlur}
    />
  );
};

export default ParameterInput;
