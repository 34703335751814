import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import { selectChokeById, updateChoke } from 'store/projectSlice';

import { ReactComponent as ChokeIcon } from '../../../../images/Project/icn-choke.svg';
import { setChoke } from '../../../../services/apiRequests';
import { IObject } from '../../types';
import ObjectInput from '../components/ObjectInput';

const Choke: React.FC<IObject> = ({ objectUid }) => {
  const dispatch = useDispatch();
  const chokeById = useSelector(selectChokeById);

  const saveChokeValue = async (value: number, name: string) => {
    const oldValue = chokeById[objectUid as string];
    const { id, start_node_id, end_node_id, project_id, ...rest } =
      chokeById[objectUid as string];
    const newValue = { ...rest, choke_id: id, [name]: value };
    dispatch(updateChoke(newValue));
    try {
      await setChoke(newValue);
    } catch (e) {
      dispatch(updateChoke({ ...newValue, [name]: (oldValue as any)?.[name] }));
    }
  };

  return (
    <div className="active-object__content full-width">
      <div className="active-object__top-row source-state-row">
        <p className="active-object__title">
          <ChokeIcon />
          {chokeById[objectUid as string]?.name}
        </p>
      </div>
      <div className="active-object__row">
        <div className="d-flex">
          <div className="active-object__input-with-unit-wrapper left">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Крит. отн. давлений"
              name="critical_pressure_ratio__atmg_per_atmg"
              initValue={
                chokeById[objectUid as string]
                  ?.critical_pressure_ratio__atmg_per_atmg
              }
              saveNewValue={saveChokeValue}
            />
            <p className="active-object__row-input-unit">атм/атм</p>
          </div>
        </div>
      </div>
      <div className="project-task-settings__separator" />
      <p className="active-object__subtitle limitations">Диаметр штуцера</p>
      <div className="active-object__row">
        <div className="d-flex">
          <div className="active-object__input-with-unit-wrapper left">
            <ObjectInput
              className={classNames(
                'input active-object__row-input',
                'with-unit',
              )}
              label="Минимальный"
              name="min_diameter__mm"
              initValue={chokeById[objectUid as string]?.min_diameter__mm}
              saveNewValue={saveChokeValue}
            />
            <p className="active-object__row-input-unit">мм</p>
          </div>
          <div className="active-object__input-with-unit-wrapper">
            <ObjectInput
              className={classNames(
                'input active-object__row-input',
                'with-unit',
              )}
              label="Максимальный"
              name="max_diameter__mm"
              initValue={chokeById[objectUid as string]?.max_diameter__mm}
              saveNewValue={saveChokeValue}
            />
            <p className="active-object__row-input-unit">мм</p>
          </div>
        </div>
      </div>
      <div className="active-object__row">
        <div className="active-object__input-with-unit-wrapper short">
          <ObjectInput
            className="input active-object__row-input short with-unit"
            label="Текущий"
            name="diameter__mm"
            initValue={chokeById[objectUid as string]?.diameter__mm}
            saveNewValue={saveChokeValue}
          />
          <p className="active-object__row-input-unit">мм</p>
        </div>
      </div>
    </div>
  );
};

export default Choke;
