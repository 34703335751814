import * as React from 'react';

import { usePutTableToStorage } from '../../../../hooks/usePutTableToStorage';
import SegmentsAvgTable from './SegmentsTable';
import { segmentsMOColumnDefs } from './utils';

export const TABLE_NAME = 'PPD_MO_SEGMENTS';
const Table: React.FC = () => {
  usePutTableToStorage(TABLE_NAME, segmentsMOColumnDefs);
  return <SegmentsAvgTable />;
};

export default Table;
