import * as React from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import { components } from 'generated/apiTypes';

import { ReactComponent as InjWellIcon } from '../../../../images/Project/icn-inj-well-ppd.svg';
import { ReactComponent as ProdWellIcon } from '../../../../images/Project/icn-prod-well-ppd.svg';
import { ReactComponent as WellIcon } from '../../../../images/Project/icn-well.svg';
import { selectActiveObject } from '../../../../store/mapSlice';

type StateType = components['schemas']['WellCurrentState'];

interface IWellState {
  saveNewValue(value: number | string, name: string): void;
  state: StateType | undefined;
  wellName: string;
  disabled?: boolean;
}

const SourceState: React.FC<IWellState> = ({
  saveNewValue,
  state,
  wellName,
  disabled,
}) => {
  const activeObject = useSelector(selectActiveObject);
  const [value, setValue] = React.useState<StateType>();

  React.useEffect(() => {
    if (state) setValue(state);
  }, [state]);

  const changeValue = () => {
    if (value === 'IN_WORK') {
      setValue('STOPPED');
      saveNewValue('STOPPED', 'current_state');
    } else {
      setValue('IN_WORK');
      saveNewValue('IN_WORK', 'current_state');
    }
  };

  const getIcon = () => {
    switch (activeObject?.type as string) {
      case 'PROD_WELL_PPD':
      case 'PROD_WELL_PPDSTOPPED':
        return <ProdWellIcon />;
      case 'INJ_WELL_PPD':
      case 'INJ_WELL_PPDSTOPPED':
        return <InjWellIcon />;
      default:
        return <WellIcon />;
    }
  };

  return (
    <div className="source-state__container">
      <p className="active-object__title">
        {getIcon()}
        {wellName}
      </p>
      <button
        className={classNames(
          'active-object__status',
          value === 'IN_WORK' && 'progress',
        )}
        type="button"
        onClick={changeValue}
        disabled={disabled}
      >
        {value === 'IN_WORK' ? 'Работает' : 'Остановлена'}
      </button>
    </div>
  );
};

export default SourceState;
