import { formatThreeDigits, formatTwoDigits } from 'utils';
import {
  CompareCellComparator,
  CompareCellFilterGetter,
  CompareCellRenderer,
} from '../../../../../../../Components/AgGridTable/CompareCellRenderer/compareCellRenderer';
import { isNumber } from '../../../../utils';

export const templateRowsNumber = 5;
const compareCellColumnDefs = {
  cellRenderer: CompareCellRenderer,
  filterValueGetter: CompareCellFilterGetter,
  comparator: CompareCellComparator,
};
export const sinksMOColumnDefs = [
  {
    colId: '0',
    headerName: 'Название',
    field: 'name',
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '1',
    headerName: 'Расход нефти, т\u2060/\u2060сут',
    field: 'oil_flow_rate',
    ...compareCellColumnDefs,
  },
  {
    colId: '2',
    headerName: 'Расход жидкости, м\u00B3\u2060/\u2060сут',
    field: 'fluid_flow_rate',
    ...compareCellColumnDefs,
  },
  {
    colId: '3',
    headerName: 'Расход газа, тыс.м\u00B3\u2060/\u2060сут',
    field: 'gas_flow_rate',
    ...compareCellColumnDefs,
  },
  {
    colId: '4',
    headerName: 'Давление, атм',
    field: 'pressure',
    ...compareCellColumnDefs,
  },
  {
    colId: '5',
    headerName: 'Температура, ˚C',
    field: 'temperature',
    ...compareCellColumnDefs,
  },
];
export const sinksTableItems = ({
  node_name,
  compare_oil_flow_rate,
  compare_fluid_flow_rate,
  compare_gas_flow_rate,
  compare_pressure,
  compare_temperature,
}: any = {}) => {
  return {
    name: node_name,
    oil_flow_rate: {
      value: compare_oil_flow_rate?.fact
        ? formatThreeDigits(compare_oil_flow_rate.fact.t_per_day)
        : null,
      abs:
        compare_oil_flow_rate?.abs?.t_per_day &&
        formatTwoDigits(compare_oil_flow_rate?.abs?.t_per_day),
      rel:
        compare_oil_flow_rate?.rel &&
        formatTwoDigits(compare_oil_flow_rate?.rel),
      unit: 'т/сут',
      cssClass:
        // eslint-disable-next-line no-nested-ternary
        isNumber(compare_oil_flow_rate?.abs?.t_per_day) &&
        compare_oil_flow_rate?.abs?.t_per_day !== 0
          ? compare_oil_flow_rate?.abs?.t_per_day > 0
            ? 'green'
            : 'red'
          : undefined,
    },
    fluid_flow_rate: {
      value:
        compare_fluid_flow_rate?.fact &&
        formatThreeDigits(compare_fluid_flow_rate.fact.m3_per_day),
      abs: formatTwoDigits(compare_fluid_flow_rate?.abs?.m3_per_day),
      rel: formatTwoDigits(compare_fluid_flow_rate?.rel),
      unit: 'м3/сут',
    },
    gas_flow_rate: {
      value:
        compare_gas_flow_rate?.fact &&
        formatThreeDigits(compare_gas_flow_rate?.fact?.thousand_m3_per_day),
      abs: formatTwoDigits(compare_gas_flow_rate?.abs?.thousand_m3_per_day),
      rel: formatTwoDigits(compare_gas_flow_rate?.rel),
      unit: 'тыс. м3/сут',
    },
    pressure: {
      value:
        compare_pressure?.fact && formatThreeDigits(compare_pressure.fact.atm),
      abs: formatTwoDigits(compare_pressure?.abs?.atm),
      rel: formatTwoDigits(compare_pressure?.rel),
      unit: 'атм',
      cssClass:
        // eslint-disable-next-line no-nested-ternary
        isNumber(compare_pressure?.abs?.atm) && compare_pressure?.abs?.atm !== 0
          ? compare_pressure?.abs?.atm < 0
            ? 'green'
            : 'red'
          : undefined,
    },
    temperature: {
      value:
        compare_temperature?.fact &&
        formatThreeDigits(compare_temperature.fact.celsius),
      abs: formatTwoDigits(compare_temperature?.abs?.celsius),
      rel: formatTwoDigits(compare_temperature?.rel),
      unit: '℃',
    },
  };
};
