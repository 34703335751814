import * as React from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import Skeleton from 'Components/Skeleton';
import { components } from 'generated/apiTypes';
import { selectIntegralCharacteristics } from 'store/taskSlice';

import { formatter } from 'utils';
import { dashboardItems } from './utils';

const Dashboard: React.FC = () => {
  const integralCharacteristics = useSelector(
    selectIntegralCharacteristics,
  ) as components['schemas']['PNOTechModeOptimizationSolutionIntegralCharacteristic'];
  const getColor = (item: any) => {
    if (
      [
        'Затраты электроэнергии',
        'Расход активного газа',
        'Сбор воды',
        'УРЭ',
        'УРГГ',
      ].includes(item.dem) &&
      item.abs > 0
    ) {
      return 'red';
    }
    if (item.dem === 'Сбор нефти' && item.abs < 0) {
      return 'red';
    }
    if (['Сбор жидкости', 'Сбор ПНГ'].includes(item.dem)) {
      return 'gray';
    }
    if (item.abs === 0) return 'gray';
    return 'green';
  };
  const prepareTableValue = (value: number) => {
    if (value > 0) {
      return `+${formatter.format(value)}`;
    }
    return formatter.format(value);
  };
  return (
    <div className="dashboard">
      {dashboardItems(integralCharacteristics).map((el, key) => {
        return (
          <div
            key={key}
            className={classNames('dashboard__item', 'grow', 'optimal-item')}
          >
            <p className="dashboard__item-dem">{el.dem}</p>
            <div className="dashboard__bottom">
              <p className="dashboard__item-value">
                {el.value === undefined ? (
                  <Skeleton className={classNames('skeleton-dashboard')} />
                ) : (
                  <>
                    {`${formatter.format(el.value)} `}
                    {el.unit}
                  </>
                )}
              </p>
            </div>
            <div className={classNames('color-indication', getColor(el))}>
              <p>
                {el.rel && prepareTableValue(el.abs)}{' '}
                {el.rel && <span className="small">{el.unit}</span>}
              </p>
              <p>
                {el.rel && prepareTableValue(el.rel)}
                {el.rel && '%'}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Dashboard;
