import * as React from 'react';

import classNames from 'classnames';

import Input from '../../../Components/Input';
import { accurateFormatter } from '../../../utils';
import { ITaskSettingsInputRow } from '../types';

const TaskSettingsInputRow: React.FC<ITaskSettingsInputRow> = ({
  value,
  label,
  unit,
  saveNewValue,
  name,
  subName,
  disabled,
  className,
  containerClassName,
}) => {
  const [inputValue, setInputValue] = React.useState<string>('');

  React.useEffect(() => {
    if (value !== undefined) setInputValue(accurateFormatter.format(value));
  }, [value]);

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueWithoutSpaces = e.target.value.replace(/\s/g, '');
    const digitOnly = new RegExp('^[+]?([0-9]*)*[.,]?([0-9]{0,3})?$', 'g');
    if (valueWithoutSpaces.match(digitOnly)) {
      setInputValue(e.target.value);
    }
  };

  const onBlur = () => {
    const commaToDotValue = inputValue.replace(',', '.');
    const valueWithoutSpaces = commaToDotValue.replace(/\s/g, '');
    let newValue: any = parseFloat(valueWithoutSpaces);
    if (newValue !== undefined) {
      setInputValue(accurateFormatter.format(newValue));
      if (subName) {
        newValue = { [subName]: newValue };
      }
      saveNewValue(newValue, name);
    }
  };

  return (
    <label
      className={classNames(
        'project-task-settings__row-input',
        containerClassName,
      )}
    >
      {label && (
        <span className="project-task-settings__row-title">{label}</span>
      )}
      <Input
        className={classNames('input project-task-settings__input', className)}
        value={inputValue}
        onChange={onChangeValue}
        onBlur={onBlur}
        name={name}
        disabled={disabled}
      />
      <p className="project-task-settings__input-unit">{unit}</p>
    </label>
  );
};

export default TaskSettingsInputRow;
