import React, { useMemo } from 'react';

import classNames from 'classnames';
import { ReactComponent as IconChecked } from 'images/Project/checked.svg';
import { ReactComponent as IconClose } from 'images/Project/close-icon.svg';

import { IChartInfo } from './types';

interface ILegend {
  info: IChartInfo[];
  setInfo(value: IChartInfo[]): void;
}
export const Legend: React.FC<ILegend> = ({ info, setInfo }) => {
  const equipmentIndexStart = useMemo(() => {
    return info.filter(i => i.type === 'WELL').length;
  }, [info]);
  const changeAll = (isWell: boolean, isVisible: boolean) => {
    const wells = info.filter(i => i.type === 'WELL');
    const equipment = info.filter(i => i.type !== 'WELL');
    if (isWell) {
      setInfo([
        ...wells.map(w => {
          return {
            ...w,
            isVisible,
          };
        }),
        ...equipment,
      ]);
    } else {
      setInfo([
        ...wells,
        ...equipment.map(e => {
          return {
            ...e,
            isVisible,
          };
        }),
      ]);
    }
  };
  return (
    <div className="legend-container">
      <div className="legend-title">
        <div className="title">Скважины</div>
        <button type="button" onClick={() => changeAll(true, true)}>
          <IconChecked /> Выбрать все
        </button>{' '}
        <button type="button" onClick={() => changeAll(true, false)}>
          <IconClose /> Сбросить
        </button>
      </div>
      {info
        .filter(i => i.type === 'WELL')
        .map((item, index) => (
          <button
            className={classNames('legend-btn', item.isVisible && 'active')}
            type="button"
            onClick={() => {
              setInfo([
                ...info.slice(0, index),
                {
                  ...info[index],
                  isVisible: !info[index].isVisible,
                },
                ...info.slice(index + 1),
              ]);
            }}
          >
            {item.name}
          </button>
        ))}
      {info.filter(i => i.type !== 'WELL').length ? (
        <div className="legend-title">
          <div className="title">Оборудование</div>{' '}
          <button type="button" onClick={() => changeAll(false, true)}>
            <IconChecked /> Выбрать все
          </button>{' '}
          <button type="button" onClick={() => changeAll(false, false)}>
            <IconClose />
            Сбросить
          </button>
        </div>
      ) : (
        <></>
      )}
      {info
        .filter(i => i.type !== 'WELL')
        .map((item, index) => (
          <button
            className={classNames('legend-btn', item.isVisible && 'active')}
            type="button"
            onClick={() => {
              setInfo([
                ...info.slice(0, index + equipmentIndexStart),
                {
                  ...info[index + equipmentIndexStart],
                  isVisible: !info[index + equipmentIndexStart].isVisible,
                },
                ...info.slice(index + equipmentIndexStart + 1),
              ]);
            }}
          >
            {item.name}
          </button>
        ))}
    </div>
  );
};
