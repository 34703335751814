import * as React from 'react';

import { ViscosityType } from '../../types';
import ObjectInput from '../components/ObjectInput';
import DeleteButton from './DeleteButton';

interface IPoints {
  saveNewValue(value: number[], name: string): void;
  type: ViscosityType;
  temperatures: any[];
  values: any[];
}

const Points: React.FC<IPoints> = ({
  type,
  temperatures,
  values,
  saveNewValue,
}) => {
  const [pointsValues, setPointsValues] = React.useState<any[]>(values);
  const [pointsTemperatures, setPointsTemperatures] =
    React.useState<any[]>(temperatures);
  const [deletedIx, setDeletedIx] = React.useState<number | undefined>(
    undefined,
  );

  const usePrevious = <T,>(value: T): T | undefined => {
    const ref = React.useRef<T>();
    React.useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevTemperatures = usePrevious({ temperatures });

  React.useEffect(() => {
    setPointsValues(values);
  }, [values]);

  React.useEffect(() => {
    if (
      prevTemperatures?.temperatures?.length &&
      prevTemperatures?.temperatures?.length < temperatures?.length
    ) {
      setPointsValues([...pointsValues, { centipoise: 0 }]);
      saveNewValue(
        [...pointsValues, { centipoise: 0 }],
        'oil_viscosity_values',
      );
    } else if (
      prevTemperatures?.temperatures?.length &&
      prevTemperatures?.temperatures?.length > temperatures?.length
    ) {
      if (deletedIx) {
        const newValues = [...pointsValues];
        newValues.splice(deletedIx, 1);
        setPointsValues(newValues);
        saveNewValue(newValues, 'oil_viscosity_values');
      }
    } else setPointsTemperatures(temperatures);
  }, [temperatures]);

  const saveTemperatures = (value: number | string, name: string) => {
    if (pointsTemperatures) {
      const newValues = [...pointsTemperatures];
      newValues[parseInt(name, 10)] = value as number;
      setPointsTemperatures(newValues);
      saveNewValue(newValues, 'oil_viscosity_temperatures');
    }
  };

  const saveValues = (value: number | string, name: string) => {
    const newValues = [...pointsValues];
    newValues[parseInt(name, 10)] = value as number;
    setPointsValues(newValues);
    saveNewValue(newValues, 'oil_viscosity_values');
  };

  // const addPoint = () => {
  //   setPointsTemperatures([...pointsTemperatures, { celsius: 0 }]);
  //   saveNewValue(
  //     [...pointsTemperatures, { celsius: 0 }],
  //     'oil_viscosity_temperatures',
  //   );
  // };

  const deletePoint = (ix: number) => {
    if (pointsTemperatures) {
      const newValues = [...pointsTemperatures];
      newValues.splice(ix, 1);
      setPointsTemperatures(newValues);
      setDeletedIx(ix);
      saveNewValue(newValues, 'oil_viscosity_temperatures');
    }
  };

  return (
    <>
      {/* type === 'TABLE' && (
        <button
          type="button"
          className="active-object__add-point-btn"
          onClick={addPoint}
        >
          <AddIcon className="active-object__add-icon" />
          Добавить точку
        </button>
      ) */}
      {temperatures?.map((value, index) => {
        if (type === 'TABLE' || (type === 'TWO_POINTS' && index < 2)) {
          return (
            <div className="active-object__row points-row">
              <div className="d-flex">
                <div>
                  {index === 0 && (
                    <label className="input-label">Температура</label>
                  )}
                  <div className="active-object__input-with-unit-wrapper left">
                    <div className="active-object__row-input-prefix">
                      {index + 1}
                    </div>
                    <ObjectInput
                      className="input active-object__row-input short with-prefix with-unit"
                      label=""
                      name={index.toString()}
                      unit="celsius"
                      initValue={value?.celsius}
                      saveNewValue={saveTemperatures}
                      disabled={type === 'TWO_POINTS'}
                    />
                    <p className="active-object__row-input-unit">˚C</p>
                  </div>
                </div>
                <div>
                  {index === 0 && (
                    <label className="input-label">Вязкость</label>
                  )}
                  <div className="active-object__input-with-unit-wrapper">
                    <div className="active-object__row-input-prefix">
                      {index + 1}
                    </div>
                    <ObjectInput
                      className="input active-object__row-input short with-prefix"
                      label=""
                      name={index.toString()}
                      unit="centipoise"
                      initValue={values[index]?.centipoise}
                      saveNewValue={saveValues}
                      disabled
                    />
                    <p className="active-object__row-input-unit">сП</p>
                  </div>
                </div>
                {index > 1 && (
                  <DeleteButton index={index} deletePoint={deletePoint} />
                )}
              </div>
            </div>
          );
        }
        return <></>;
      })}
    </>
  );
};

export default Points;
