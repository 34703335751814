import * as React from 'react';
import { useSelector } from 'react-redux';

import AgGridTable from 'Components/AgGridTable';
import { components } from 'generated/apiTypes';
import { selectSolution } from 'store/taskSlice';

import { formatThreeDigits, formatTwoDigits } from 'utils';
import { useGetColumnDefs } from '../../../../../hooks/useGetColumnDefs';
import { TABLE_NAME } from '../index';
import { layersUAColumnDefs } from './utils';

const LayersTable: React.FC = () => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOReservoirBaseProblem'];
  const [data, setData] = React.useState<any[]>([]);
  const columnDef = useGetColumnDefs(TABLE_NAME, layersUAColumnDefs);

  const getData = () => {
    const newData: any[] = [];
    const state = solution?.solution?.reservoir_state;
    newData.push({
      name: state.name,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      oil_flow_rate: formatThreeDigits(state?.oil_production__t_per_day),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      water_flow_rate: formatThreeDigits(state?.water_production__m3_per_day),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      gas_flow_rate: formatThreeDigits(
        state?.gas_production__m3_per_day / 1000,
      ),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      pressure: formatTwoDigits(state?.pressure__atm),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      water_injection: formatThreeDigits(state.water_injection__m3_per_day),
    });
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [solution]);

  return (
    <AgGridTable tableName={TABLE_NAME} columnDefs={columnDef} rowData={data} />
  );
};

export default LayersTable;
