import * as React from 'react';

import { usePutTableToStorage } from '../../../../../hooks/usePutTableToStorage';
import SegmentsTable from './Table';
import { segmentsUAColumnDefs } from './utils';

export const TABLE_NAME = 'PPD_UA_SEGMENTS';
const Table: React.FC = () => {
  usePutTableToStorage(TABLE_NAME, segmentsUAColumnDefs);
  return (
    <>
      <SegmentsTable />
    </>
  );
};

export default Table;
