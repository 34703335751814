import * as React from 'react';
import { useSelector } from 'react-redux';

import AgGridTable from 'Components/AgGridTable';
import { components } from 'generated/apiTypes';
import {
  selectChokesSolution,
  selectSeparatorsSolution,
  selectSolution,
} from 'store/taskSlice';

import { formatThreeDigits, formatTwoDigits } from 'utils';
import { useGetColumnDefs } from '../../../../../hooks/useGetColumnDefs';
import { TABLE_NAME } from '../index';
import { equipmentUAColumnDefs } from './utils';

const EquipmentsTable: React.FC = () => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOPeriodicNodalAnalysisProblem'];
  const separatorsSolution = useSelector(selectSeparatorsSolution);
  const chokesSolution = useSelector(selectChokesSolution);
  const [data, setData] = React.useState<any[]>([]);
  const columnDef = useGetColumnDefs(TABLE_NAME, equipmentUAColumnDefs);

  const getData = () => {
    const newData: any[] = [];
    solution?.solution?.pump_avg_table?.states?.forEach(state => {
      newData.push({
        name: state.pump_name,
        type: 'Насос',
        in_oil_flow_rate: formatThreeDigits(state.in_oil_flow__t_per_day),
        in_fluid_flow_rate: formatThreeDigits(state.in_fluid_flow__m3_per_day),
        in_gas_flow_rate: formatThreeDigits(
          state.in_gas_flow__thousand_m3_per_day,
        ),
        out_oil_flow_rate: formatThreeDigits(state.out_oil_flow__t_per_day),
        out_fluid_flow_rate: formatThreeDigits(
          state.out_fluid_flow__m3_per_day,
        ),
        out_gas_flow_rate: formatThreeDigits(
          state.out_gas_flow__thousand_m3_per_day,
        ),
        in_pressure: formatTwoDigits(state.in_pressure__atm),
        out_pressure: formatTwoDigits(state.out_pressure__atm),
      });
    });
    separatorsSolution?.solution?.forEach(state => {
      newData.push({
        name: state.separator_name,
        type: 'Сепаратор',
        in_oil_flow_rate: formatThreeDigits(
          state.start_oil_mass_flow__t_per_day,
        ),
        in_fluid_flow_rate: formatThreeDigits(
          state.start_fluid_volume_flow__m3_per_day,
        ),
        in_gas_flow_rate: formatThreeDigits(
          state.start_gas_volume_flow__thos_m3_per_day,
        ),
        out_oil_flow_rate: formatThreeDigits(
          state.end_oil_mass_flow__t_per_day,
        ),
        out_fluid_flow_rate: formatThreeDigits(
          state.end_fluid_volume_flow__m3_per_day,
        ),
        out_gas_flow_rate: formatThreeDigits(
          state.end_gas_volume_flow__thos_m3_per_day,
        ),
        in_pressure: formatTwoDigits(state.start_pressure__atm),
        out_pressure: formatTwoDigits(state.end_pressure__atm),
      });
    });
    solution?.solution?.three_phase_separator_avg_table?.states?.forEach(
      state => {
        newData.push({
          name: state.separator_name,
          type: 'Трёхфазный сепаратор',
          in_oil_flow_rate: formatThreeDigits(state.in_oil_flow__t_per_day),
          in_fluid_flow_rate: formatThreeDigits(
            state.in_fluid_flow__m3_per_day,
          ),
          in_gas_flow_rate: formatThreeDigits(
            state.in_gas_flow__thousand_m3_per_day,
          ),
          out_oil_flow_rate: formatThreeDigits(state.out_oil_flow__t_per_day),
          out_fluid_flow_rate: formatThreeDigits(
            state.out_fluid_flow__m3_per_day,
          ),
          out_gas_flow_rate: formatThreeDigits(
            state.out_gas_flow__thousand_m3_per_day,
          ),
          in_pressure: formatTwoDigits(state.in_pressure__atm),
          out_pressure: formatTwoDigits(state.out_pressure__atm),
        });
      },
    );
    chokesSolution?.solution?.forEach(state => {
      newData.push({
        name: state.name,
        type: 'Штуцер',
        in_oil_flow_rate: formatThreeDigits(state.mass_oil_flow__t_per_day),
        in_fluid_flow_rate: formatThreeDigits(
          state.volume_fluid_flow__m3_per_day,
        ),
        in_gas_flow_rate: formatThreeDigits(
          state.volume_gas_flow__thos_m3_per_day,
        ),
        out_oil_flow_rate: formatThreeDigits(state.mass_oil_flow__t_per_day),
        out_fluid_flow_rate: formatThreeDigits(
          state.volume_fluid_flow__m3_per_day,
        ),
        out_gas_flow_rate: formatThreeDigits(
          state.volume_gas_flow__thos_m3_per_day,
        ),
        in_pressure: formatTwoDigits(state.start_pressure__atmg),
        out_pressure: formatTwoDigits(state.end_pressure__atmg),
      });
    });
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [
    solution?.solution?.three_phase_separator_avg_table?.states,
    solution?.solution?.pump_avg_table?.states,
    separatorsSolution.solution,
    chokesSolution.solution,
  ]);

  return (
    <AgGridTable
      style={{ marginTop: '44px' }}
      tableName={TABLE_NAME}
      columnDefs={columnDef}
      rowData={data}
    />
  );
};

export default EquipmentsTable;
