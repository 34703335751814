import { useEffect, useState } from 'react';

import { ColDef } from 'ag-grid-community';
import { IStoreColDef } from 'Components/AgGridTable/types';

export const useGetColumnDefs = (tableName: string, colDefs: ColDef[]) => {
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  useEffect(() => {
    if (!tableName || !colDefs) return;
    const isDevelop = window.location.pathname.split('/').includes('develop')
      ? '_develop'
      : '';
    const isMaster = window.location.pathname.split('/').includes('master')
      ? '_master'
      : '';
    const columnsToRender = localStorage.getItem(
      tableName + isDevelop + isMaster,
    );
    if (columnsToRender) {
      const columns: any[] = [];
      JSON.parse(columnsToRender).forEach((params: IStoreColDef) => {
        const def = colDefs.find(
          (d: ColDef) => d.colId === params[0],
        ) as ColDef;
        if (def) {
          def.hide = !params[1];
        }
        columns.push(def);
      });
      setColumnDefs(columns);
    } else {
      setColumnDefs(colDefs);
    }
  }, [tableName, colDefs]);
  return columnDefs;
};
