import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { selectExperimentSettings, selectIsActual } from 'store/taskSlice';

import { components } from '../../../generated/apiTypes';
import {
  getTasks,
  getTraces,
  setExperimentSettings,
} from '../../../services/apiRequests';
import { selectReservoir, selectWells } from '../../../store/projectSlice';
import { setExperimentSettings as setStoreExperimentSettings } from '../../../store/taskSlice';
import ObjectSelect from '../ActiveObject/components/ObjectSelect';
import { taskByExperimentType } from '../utils';
import MOConstraints from './MOConstraints/MOConstraints';
import {
  getTargetControlTypeOptions,
  getTargetMetricsTypeOptions,
} from './options';
import TaskSettingsInputRow from './TaskSettingInputRow';

type SettingsType = components['schemas']['ExperimentSettings'];

interface IMOSettingsProps {
  setUATask(status: 'SOLVED' | 'NONE' | 'NOT_ACTUAL'): void;
  setExperimentSettingsSaved(val: boolean): void;
  setIncompleteConstraints(val: boolean): void;
}

const MOSettings: React.FC<IMOSettingsProps> = ({
  setUATask,
  setExperimentSettingsSaved,
  setIncompleteConstraints,
}) => {
  const params = useParams() as { id: string };
  // useGetExperimentSettings(params.id);
  const settings = useSelector(selectExperimentSettings);
  const isActual = useSelector(selectIsActual);
  const reservoir = useSelector(selectReservoir);
  const wells = useSelector(selectWells);
  const dispatch = useDispatch();
  const getUAStatus = async () => {
    const response = await getTasks(params.id);
    if (response?.data?.length > 0) {
      const task = response?.data
        ?.filter(
          item =>
            item.task_type ===
            taskByExperimentType('Базовый режим', reservoir?.uid !== undefined),
        )
        .slice(-1)[0];
      if (task?.task_uid) {
        const res = await getTraces(task.task_uid);
        if (!task?.is_actual) {
          setUATask('NOT_ACTUAL');
        } else if (res.data.status === 'SOLVED') setUATask('SOLVED');
        else setUATask('NONE');
      } else {
        setUATask('NONE');
      }
    }
  };

  React.useEffect(() => {
    getUAStatus();
  }, [isActual]);

  const saveNewValue = async (value: number, name: string) => {
    dispatch(
      setStoreExperimentSettings({
        ...(settings as SettingsType),
        [name]: value,
      }),
    );
    setExperimentSettingsSaved(false);
    await setExperimentSettings(params.id, {
      ...(settings as SettingsType),
      [name]: value,
    });
    setExperimentSettingsSaved(true);
  };

  const saveNewTargetMetricValue = async (value: string) => {
    if (settings) {
      const newValue = {
        ...settings,
        optimization_experiment_settings: {
          ...settings.optimization_experiment_settings,
          target_metric: value,
          constraints: {
            type: 'EmptyNetworkOptimizationExperimentSettingsConstraints',
          },
        },
      };
      dispatch(setStoreExperimentSettings(newValue as any));
      setExperimentSettingsSaved(false);
      const newSettings = await setExperimentSettings(
        params.id,
        newValue as any,
      );
      if (newSettings.data)
        // renew setting as constraints may be reset
        dispatch(setStoreExperimentSettings(newSettings.data));
      setExperimentSettingsSaved(true);
    }
  };

  const saveNewManagedWellsNumberValue = async (value: number) => {
    if (settings) {
      const newValue = {
        ...settings,
        optimization_experiment_settings: {
          ...settings.optimization_experiment_settings,
          max_number_of_managed_wells: value,
        },
      };
      dispatch(setStoreExperimentSettings(newValue as any));
      setExperimentSettingsSaved(false);
      const newSettings = await setExperimentSettings(
        params.id,
        newValue as any,
      );
      if (newSettings.data)
        // renew setting as constraints may be reset
        dispatch(setStoreExperimentSettings(newSettings.data));
      setExperimentSettingsSaved(true);
    }
  };

  const saveNewTargetControlType = async (value: string) => {
    if (settings) {
      const newValue = {
        ...settings,
        optimization_experiment_settings: {
          ...settings.optimization_experiment_settings,
          variable_type:
            value as components['schemas']['PNOOptimizationVariableType'],
        },
      };
      dispatch(setStoreExperimentSettings(newValue));
      setExperimentSettingsSaved(false);
      await setExperimentSettings(params.id, newValue);
      setExperimentSettingsSaved(true);
    }
  };

  return (
    <>
      <div>
        {!reservoir?.uid && wells?.items.length > 0 && (
          <div className="project-task-settings__row">
            <TaskSettingsInputRow
              label="Длительность"
              value={settings?.period}
              unit="мин"
              saveNewValue={saveNewValue}
              name="period"
              disabled
            />
            <TaskSettingsInputRow
              label="Шаг"
              value={settings?.time_step}
              unit="мин"
              saveNewValue={saveNewValue}
              name="time_step"
              disabled
            />
          </div>
        )}
        <div className="project-task-settings__row">
          <TaskSettingsInputRow
            label="Точность расчетов"
            value={settings?.accuracy}
            unit="aтм"
            saveNewValue={saveNewValue}
            name="accuracy"
            disabled
          />
        </div>
        <p className="project-task-settings__post-settings-hint">
          Данные параметры заданы в базовом расчете
        </p>
        <p className="project-task-settings__title">Параметры оптимизации</p>
        {/* <div className="project-task-settings__options">
          <Checkbox
            label="Варьировать отступы по времени"
            checkedValue={
              settings?.optimization_experiment_settings
                ?.allow_to_vary_offset_time as boolean
            }
            id="allow_to_vary_offset_time"
            setCheckedValue={saveNewOptimizationValue}
            disabled={[
              'DRAFT',
              'SENT',
              'PREPARING',
              'PREPARED',
              'SOLVING',
            ].includes(status)}
          />
          <Checkbox
            label="Варьировать частоты ПДФ"
            checkedValue={
              settings?.optimization_experiment_settings
                ?.allow_to_vary_control_action as boolean
            }
            id="allow_to_vary_control_action"
            setCheckedValue={saveNewOptimizationValue}
            disabled={[
              'DRAFT',
              'SENT',
              'PREPARING',
              'PREPARED',
              'SOLVING',
            ].includes(status)}
          />
          <Checkbox
            label="Варьировать статусы скважин"
            checkedValue={
              settings?.optimization_experiment_settings
                ?.allow_to_vary_current_state as boolean
            }
            id="allow_to_vary_current_state"
            setCheckedValue={saveNewOptimizationValue}
            disabled={[
              'DRAFT',
              'SENT',
              'PREPARING',
              'PREPARED',
              'SOLVING',
            ].includes(status)}
          />
        </div> */}
        {!reservoir?.uid && (
          <div className="project-task-settings__row">
            <TaskSettingsInputRow
              label="Кол-во управляемых скважин"
              value={
                settings?.optimization_experiment_settings
                  ?.max_number_of_managed_wells
              }
              unit="шт"
              saveNewValue={saveNewManagedWellsNumberValue}
              name="max_number_of_managed_wells"
              disabled={false}
              containerClassName="full-width"
            />
          </div>
        )}
        <div className="project-task-settings__row">
          <ObjectSelect
            label="Критерий оптимизации"
            classNamesWrapper="full-width"
            classNames="active-object__row-input"
            name=""
            options={getTargetMetricsTypeOptions(reservoir?.uid !== undefined)}
            value={getTargetMetricsTypeOptions(
              reservoir?.uid !== undefined,
            )?.find(
              el =>
                el.value ===
                settings?.optimization_experiment_settings?.target_metric,
            )}
            saveNewValue={saveNewTargetMetricValue}
            disabled={!!reservoir?.uid}
          />
        </div>
        <div className="project-task-settings__row">
          <ObjectSelect
            label="Управляющее воздействие"
            classNamesWrapper="full-width"
            classNames="active-object__row-input"
            name=""
            options={getTargetControlTypeOptions()}
            value={getTargetControlTypeOptions()?.find(
              el =>
                el.value ===
                settings?.optimization_experiment_settings?.variable_type,
            )}
            saveNewValue={saveNewTargetControlType}
            disabled={!!reservoir?.uid}
          />
        </div>
        <MOConstraints
          setExperimentSettingsSaved={setExperimentSettingsSaved}
          setIncompleteConstraints={setIncompleteConstraints}
          disabled={!!reservoir?.uid}
        />
      </div>
    </>
  );
};

export default MOSettings;
