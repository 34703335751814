import * as React from 'react';
import { useSelector } from 'react-redux';

import {
  selectErrors,
  selectNodeById,
  selectSegmentById,
  selectShowErrors,
  selectUnconnectedWellModelById,
  selectWellById,
} from 'store/projectSlice';
import { selectExperimentType } from 'store/taskSlice';

import IssuesIcon from './IssuesIcon';
import { composeIssues } from './utils';

/* const setType = (type?: components['schemas']['PipelineNodeType']) => {
  if (type === 'INTERNAL') {
    return 'Внутренней точки сегмента';
  }
  if (type === 'JUNCTION') {
    return 'Соединения';
  }
  if (type === 'SINK') {
    return 'Центра сбора';
  }
  if (type === 'SOURCE') {
    return 'Источника';
  }
  return 'Скважины';
}; */

const Issues: React.FC = () => {
  const showErrors = useSelector(selectShowErrors);
  const errorsObj = useSelector(selectErrors);
  const nodesById = useSelector(selectNodeById);
  const segmentsByID = useSelector(selectSegmentById);
  const wellsById = useSelector(selectWellById);
  const unconnectedWellModelById = useSelector(selectUnconnectedWellModelById);
  const experimentType = useSelector(selectExperimentType);

  if (!showErrors) return null;

  const errorsArray = composeIssues(experimentType)(errorsObj);

  return (
    <div className="project-issues">
      <div className="project-issues__top-row">
        <p className="project-issues__title">Валидация проекта</p>
      </div>
      <div className="project-issues__container">
        <div className="project-issues__validation-section">
          {errorsArray?.map(el => {
            if (!el.object_uid) return null;
            const object: any =
              nodesById[el.object_uid] || wellsById[el.object_uid];
            const index = el.message?.indexOf(`id=${el.object_uid}`);
            if (index && index > -1) {
              const message1 = el.message?.replace(
                el.message?.slice(index),
                '',
              );
              const message2 = el.message
                ?.replace(el.message?.slice(0, index), '')
                .replace(`id=${el.object_uid}`, '');

              return (
                <div className="project-issues__issue" key={el.object_uid}>
                  <IssuesIcon type={el.criticality} />
                  <p className="project-issues__issue-text">
                    {message1}
                    <span className="project-issues__issue-place">
                      {object?.name ||
                        unconnectedWellModelById[el.object_uid]?.name ||
                        (segmentsByID[el.object_uid] &&
                          `${segmentsByID[el.object_uid].start_node_name} -> ${
                            segmentsByID[el.object_uid].end_node_name
                          }`)}
                    </span>{' '}
                    {message2}
                  </p>
                </div>
              );
            }
            return (
              <div className="project-issues__issue" key={el.object_uid}>
                <IssuesIcon type={el.criticality} />
                <p className="project-issues__issue-text">{el.message}</p>
              </div>
            );
          })}
          {!errorsArray.length && (
            <div className="project-issues__issue">
              {/* <p className="project-issues__issue-date">13.04.2021 15:36</p> */}
              <IssuesIcon type="Success" />
              <p className="project-issues__issue-text">
                Ошибок в проекте не обнаружено
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Issues;
