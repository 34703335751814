import * as React from 'react';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { selectProject } from '../../../../../store/projectSlice';
import { ElectricityConsumptionButton } from '../../components/ElectricityConsumptionButton';
import UAECChart from '../ElectricityConsumptionChart/UA';
import Equipment from '../Equipments';
import Nodes from '../Nodes';
import Segments from '../Segments';
import Sink from '../Sink';
import Sources from '../Sources';

const UAResults: React.FC = () => {
  const project = useSelector(selectProject);
  const [tabIndex, setTabIndex] = React.useState(0);

  const isEquipmentExists =
    project.three_phase_separators.items.length > 0 ||
    project.pumps.items.length > 0 ||
    project.chokes.items.length > 0 ||
    project.separators.length > 0;

  const lastTabIndex = isEquipmentExists ? 5 : 4;
  return (
    <Tabs
      className="results__tabs"
      selectedIndex={tabIndex}
      onSelect={index => setTabIndex(index)}
    >
      <TabList className="results__tab-list underline">
        <Tab className="results__tab">Центр сбора</Tab>
        <Tab className="results__tab">Скважины</Tab>
        <Tab className="results__tab">Узлы</Tab>
        {isEquipmentExists && <Tab className="results__tab">Оборудование</Tab>}
        <Tab className="results__tab">Трубы</Tab>
        <ElectricityConsumptionButton
          checked={tabIndex === lastTabIndex}
          onClick={() =>
            setTabIndex(tabIndex === lastTabIndex ? 0 : lastTabIndex)
          }
        />
      </TabList>
      <div className="results-tables__container">
        <TabPanel>
          <Sink />
        </TabPanel>
        <TabPanel>
          <Sources />
        </TabPanel>
        <TabPanel>
          <Nodes />
        </TabPanel>
        {isEquipmentExists && (
          <TabPanel>
            <Equipment />
          </TabPanel>
        )}
        <TabPanel>
          <Segments />
        </TabPanel>
        <TabPanel>
          <UAECChart />
        </TabPanel>
      </div>
    </Tabs>
  );
};

export default UAResults;
