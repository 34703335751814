import React from 'react';

import { components } from '../../../../../generated/apiTypes';
import { formatter, oneDigitsFormatter } from '../../../../../utils';
import { seriesColors } from '../../WellModel/Chart/utils';

const useChartData = (
  wellModel:
    | components['schemas']['PNOInjectionWellModel']
    | components['schemas']['PNOProductionWellModel']
    | undefined,
  targetParameter: 'PRESSURE' | 'FLOW' | 'FREQ/DIAMETER',
  targetParameterIndex: number,
) => {
  const { axisX } = React.useMemo(() => {
    switch (targetParameter) {
      case 'PRESSURE': {
        if (wellModel?.liquid_flows__m3_per_day) {
          return {
            axisX: wellModel?.liquid_flows__m3_per_day?.map((flow: any) => {
              return oneDigitsFormatter.format(flow);
            }),
          };
        }
        break;
      }
      case 'FLOW':
      case 'FREQ/DIAMETER': {
        if (wellModel?.linear_pressures__atm) {
          return {
            axisX: wellModel?.linear_pressures__atm?.map((flow: any) => {
              return oneDigitsFormatter.format(flow);
            }),
          };
        }
        break;
      }
      default:
        return {};
    }
    return {};
  }, [
    wellModel?.liquid_flows__m3_per_day,
    wellModel?.linear_pressures__atm,
    targetParameter,
  ]);

  const getProdWellBottomHolePressure = (item_ix: number, serie_ix: number) => {
    switch (targetParameter) {
      case 'PRESSURE':
        return (wellModel as components['schemas']['PNOProductionWellModel'])
          ?.bottomhole_pressures__atm[targetParameterIndex][item_ix][serie_ix];
      case 'FLOW':
        return (wellModel as components['schemas']['PNOProductionWellModel'])
          ?.bottomhole_pressures__atm[item_ix][targetParameterIndex][serie_ix];
      case 'FREQ/DIAMETER':
        return (wellModel as components['schemas']['PNOProductionWellModel'])
          ?.bottomhole_pressures__atm[item_ix][serie_ix][targetParameterIndex];
      default:
        return undefined;
    }
  };

  const getInjWellBottomHolePressure = (item_ix: number, serie_ix: number) => {
    switch (targetParameter) {
      case 'PRESSURE':
        return (
          wellModel as components['schemas']['PNOInjectionWellModel'] as any
        )?.bottom_hole_pressures__atm[targetParameterIndex][item_ix][serie_ix];
      case 'FLOW':
        return (
          wellModel as components['schemas']['PNOInjectionWellModel'] as any
        )?.bottom_hole_pressures__atm[item_ix][targetParameterIndex][serie_ix];
      case 'FREQ/DIAMETER':
        return (
          wellModel as components['schemas']['PNOInjectionWellModel'] as any
        )?.bottom_hole_pressures__atm[item_ix][serie_ix][targetParameterIndex];
      default:
        return undefined;
    }
  };

  return React.useMemo(() => {
    if (axisX && wellModel) {
      const chartData: { name: string; data: any[] }[] = [];
      let series: any[] = [];
      switch (targetParameter) {
        case 'PRESSURE':
        case 'FLOW':
          series =
            (wellModel as components['schemas']['PNOProductionWellModel'])
              ?.frequency__Hz ||
            /* (wellModel as components['schemas']['PNOInjectionWellModel'])
              ?.choke_diameters__m */ 0;
          break;
        case 'FREQ/DIAMETER':
          series = wellModel?.liquid_flows__m3_per_day;
          break;
        default:
          break;
      }

      series.map((serie, serie_ix) => {
        let legendName = '';
        switch (targetParameter) {
          case 'PRESSURE':
          case 'FLOW':
            legendName =
              (wellModel as components['schemas']['PNOProductionWellModel'])
                ?.frequency__Hz && `${formatter.format(serie)} Гц` /* ||
              ((wellModel as components['schemas']['PNOInjectionWellModel'])
                ?.choke_diameters__m &&
                `${formatter.format(serie * 1000)} мм`) */;
            break;
          case 'FREQ/DIAMETER':
            legendName = `${formatter.format(serie)} м3/сут`;
            break;
          default:
            break;
        }
        chartData.push({
          name: legendName,
          data: axisX.map((item: any, item_ix: number) => {
            return (
              ((wellModel as components['schemas']['PNOProductionWellModel'])
                ?.bottomhole_pressures__atm &&
                getProdWellBottomHolePressure(item_ix, serie_ix)) ||
              ((wellModel as components['schemas']['PNOInjectionWellModel'])
                ?.bottom_hole_pressures__atm &&
                getInjWellBottomHolePressure(item_ix, serie_ix))
            );
          }),
        });
        return null;
      });

      return {
        labels: axisX,
        datasets: Array.from(chartData).map((el, index) => {
          return {
            label: el.name,
            data: el.data,
            fill: false,
            backgroundColor: seriesColors[index % 9],
            borderColor: seriesColors[index % 9],
            color: seriesColors[index % 9],
            borderWidth: 1,
            tension: 0.1,
            pointHoverBorderWidth: 0,
            pointRadius: 0,
            pointHitRadius: 0,
            pointBorderWidth: 1,
            pointHoverRadius: 1,
          };
        }),
      };
    }
    return { labels: [], datasets: [] };
  }, [axisX, targetParameter, targetParameterIndex]);
};

export default useChartData;
