import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectThreePhaseSeparatorById } from 'store/projectSlice';

import { ReactComponent as SeparatorGasIcon } from '../../../../images/Project/icn-three-phase-separator-gas.svg';
import { ReactComponent as SeparatorOilIcon } from '../../../../images/Project/icn-three-phase-separator-oil.svg';
import { ReactComponent as SeparatorWaterIcon } from '../../../../images/Project/icn-three-phase-separator-water.svg';
import { IObject } from '../../types';
import ObjectInput from '../components/ObjectInput';
import ObjectStringInput from '../components/ObjectStringInput';

const ThreePhaseSeparator: React.FC<IObject> = ({ objectUid, nodeUid }) => {
  const separatorById = useSelector(selectThreePhaseSeparatorById);

  const saveValue = () => {};

  const getIconByNodeId = (): JSX.Element => {
    if (separatorById[objectUid as string]?.oil_out_node_uid === nodeUid)
      return <SeparatorOilIcon />;
    if (separatorById[objectUid as string]?.water_out_node_uid === nodeUid)
      return <SeparatorWaterIcon />;
    if (separatorById[objectUid as string]?.gas_out_node_uid === nodeUid)
      return <SeparatorGasIcon />;
    return <></>;
  };

  const getOutTypeByNodeId = (): string => {
    if (separatorById[objectUid as string]?.oil_out_node_uid === nodeUid)
      return 'Нефть';
    if (separatorById[objectUid as string]?.water_out_node_uid === nodeUid)
      return 'Вода';
    if (separatorById[objectUid as string]?.gas_out_node_uid === nodeUid)
      return 'Газ';
    return '';
  };

  return (
    <div className="active-object__content">
      <div className="active-object__top-row">
        <p className="active-object__title">
          {getIconByNodeId()}
          {separatorById[objectUid as string]?.name}
        </p>
      </div>
      <div className="active-object__row">
        <ObjectStringInput
          label="Тип выхода"
          className="input active-object__row-input"
          initValue={getOutTypeByNodeId()}
          disabled
          name=""
          saveNewValue={() => {}}
        />
      </div>
      <div className="active-object__row">
        <div className="d-flex">
          <div className="active-object__input-with-unit-wrapper left">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Эфф. сепарации газа"
              name=""
              initValue={
                separatorById[objectUid as string]
                  ?.gas_separation_efficiency__part * 100
              }
              saveNewValue={saveValue}
              disabled
            />
            <p className="active-object__row-input-unit">%</p>
          </div>
          <div className="active-object__input-with-unit-wrapper">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Эфф. сепарации воды"
              name=""
              initValue={
                separatorById[objectUid as string]?.water_sep_efficiency__part *
                100
              }
              saveNewValue={saveValue}
              disabled
            />
            <p className="active-object__row-input-unit">%</p>
          </div>
        </div>
      </div>
      <div className="active-object__row">
        <div className="d-flex">
          <div className="active-object__input-with-unit-wrapper">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Давление на входе"
              name=""
              initValue={separatorById[objectUid as string]?.pressure__atm}
              saveNewValue={saveValue}
              disabled
            />
            <p className="active-object__row-input-unit">атм</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreePhaseSeparator;
