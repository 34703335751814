import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectPhysChemPackagesById,
  updatePhysChemPackages,
} from 'store/projectSlice';

import { ReactComponent as FHSIcon } from '../../../../images/Project/icn-fhs.svg';
import { setPhysChemPackage as savePhysChemPackage } from '../../../../services/apiRequests';
import { IObject, ViscosityType } from '../../types';
import ObjectInput from '../components/ObjectInput';
import ObjectSelect from '../components/ObjectSelect';
import { getViscosityTypeOptions } from '../components/options';
import Points from './Points';

const Package: React.FC<IObject> = ({ objectUid, projectUid }) => {
  const packageById = useSelector(selectPhysChemPackagesById);
  const dispatch = useDispatch();

  const savePackageValue = async (
    value: number | string | number[],
    name: string,
  ) => {
    let val: number | string | number[] = ['number', 'string'].includes(
      typeof value,
    )
      ? value
      : [...(value as number[])];
    if (name === 'watercut_cutoff_threshold') val = (val as number) / 100;
    try {
      const response = await savePhysChemPackage(
        projectUid,
        objectUid as string,
        {
          ...packageById[objectUid as string],
          [name]: val,
        },
      );
      if (response.data) dispatch(updatePhysChemPackages(response.data));
    } catch (e) {
      //
    }
  };

  return (
    <div className="active-object__content">
      <div className="active-object__top-row">
        <p className="active-object__title">
          <FHSIcon />
          {packageById[objectUid as string]?.name}
        </p>
      </div>
      <div className="active-object__row">
        <div className="d-flex">
          <div className="active-object__input-with-unit-wrapper left">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Плотность нефти"
              name="oil_density"
              initValue={packageById[objectUid as string]?.oil_density}
              saveNewValue={savePackageValue}
              disabled
            />
            <p className="active-object__row-input-unit">
              кг/
              <span className="relative">
                м<span className="index unit-small">3</span>
              </span>
            </p>
          </div>
          <div className="active-object__input-with-unit-wrapper">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Теплоемкость нефти"
              name="oil_heat_capacity"
              initValue={packageById[objectUid as string]?.oil_heat_capacity}
              saveNewValue={savePackageValue}
              disabled
            />
            <p className="active-object__row-input-unit">Дж/кг/К</p>
          </div>
        </div>
      </div>
      <div className="active-object__row">
        <div className="d-flex">
          <div className="active-object__input-with-unit-wrapper left">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Плотность воды"
              name="water_density"
              initValue={packageById[objectUid as string]?.water_density}
              saveNewValue={savePackageValue}
              disabled
            />
            <p className="active-object__row-input-unit">
              кг/
              <span className="relative">
                м<span className="index unit-small">3</span>
              </span>
            </p>
          </div>
          <div className="active-object__input-with-unit-wrapper">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Теплоемкость воды"
              name="water_heat_capacity"
              initValue={packageById[objectUid as string]?.water_heat_capacity}
              saveNewValue={savePackageValue}
              disabled
            />
            <p className="active-object__row-input-unit">Дж/кг/К</p>
          </div>
        </div>
      </div>
      <div className="active-object__row">
        <div className="d-flex">
          <div className="active-object__input-with-unit-wrapper left">
            <ObjectInput
              className="input active-object__row-input short"
              label="Отн. плотность газа"
              name="gas_rel_density"
              initValue={packageById[objectUid as string]?.gas_rel_density}
              saveNewValue={savePackageValue}
              disabled
            />
          </div>
          <div className="active-object__input-with-unit-wrapper">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Теплоемкость газа"
              name="gas_heat_capacity"
              initValue={packageById[objectUid as string]?.gas_heat_capacity}
              saveNewValue={savePackageValue}
              disabled
            />
            <p className="active-object__row-input-unit">Дж/кг/К</p>
          </div>
        </div>
      </div>
      <div className="active-object__row">
        <div className="active-object__input-with-unit-wrapper short">
          <ObjectInput
            className="input active-object__row-input short with-unit"
            label="Гран. обводненность"
            name="watercut_cutoff_threshold"
            initValue={
              (packageById[objectUid as string]
                ?.watercut_cutoff_threshold as number) * 100
            }
            saveNewValue={savePackageValue}
            disabled
          />
          <p className="active-object__row-input-unit">%</p>
        </div>
      </div>
      <div className="active-object__row">
        <div className="active-object__input-with-unit-wrapper">
          <ObjectSelect
            label="Модель вязкости"
            classNames="active-object__row-input long"
            classNamesWrapper="active-object__row-input-wrapper wide"
            name="viscosity_type"
            options={getViscosityTypeOptions()}
            value={getViscosityTypeOptions()?.find(
              el =>
                el.value === packageById[objectUid as string]?.viscosity_type,
            )}
            saveNewValue={savePackageValue}
            disabled
          />
        </div>
      </div>
      <Points
        type={packageById[objectUid as string]?.viscosity_type as ViscosityType}
        temperatures={
          packageById[objectUid as string]?.oil_viscosity_temperatures as any[]
        }
        values={packageById[objectUid as string]?.oil_viscosity_values as any[]}
        saveNewValue={savePackageValue}
      />
    </div>
  );
};

export default Package;
