import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import {
  selectCalculationTime,
  selectExperimentStatus,
  selectPercentage,
} from 'store/taskSlice';

import { ReactComponent as CancelTaskIcon } from '../../../images/Project/icn-cancel-task.svg';
import loader from '../../../images/Project/loader_blue_bg.gif';
import { setShowDialog } from '../../../store/commonSlice';
import { timeFormatter } from '../../../utils';
import { messageByStatus } from '../utils';

interface ITaskStatus {
  className?: string;
}

const TaskStatus: React.FC<ITaskStatus> = ({ className }) => {
  const dispatch = useDispatch();
  const status = useSelector(selectExperimentStatus);
  const percentage = useSelector(selectPercentage);
  const calcTime = useSelector(selectCalculationTime);
  const onCancelClick = () => {
    dispatch(
      setShowDialog({
        dialogType: 'CANCEL_TASK_CONFIRM',
      }),
    );
  };

  return (
    <div
      className={classNames(
        'control-panel__progress-details',
        className,
        status === 'INTERRUPTING' && 'without-stop-btn',
        status === 'SOLVING' && 'solving',
      )}
    >
      <div className="control-panel__progress-details-message">
        {messageByStatus(status)}
        {status === 'SOLVING' && percentage && ` ${percentage}%`}
        {status === 'SOLVING' && calcTime && <p>{timeFormatter(calcTime)}</p>}
      </div>
      {!['FAILED', 'VALIDATION_ERROR'].includes(status) && (
        <>
          <img
            className={classNames(
              'control-panel__loader',
              status === 'SOLVING' && 'solving',
              status === 'INTERRUPTING' && 'interrupting',
            )}
            src={loader}
            alt=".."
          />
          {status !== 'INTERRUPTING' && (
            <CancelTaskIcon
              className="control-panel__details-button-cancel-icon"
              onClick={onCancelClick}
            />
          )}
        </>
      )}
    </div>
  );
};
export default TaskStatus;
