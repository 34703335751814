import React from 'react';

import { oneDigitsFormatter } from '../../../../../utils';

interface IViewModel {
  pump: any;
}

const viewModel: React.FC<IViewModel> = ({ pump }) => {
  return (
    <>
      <div className="well-model-panel__tables-wrapper">
        <table className="well-model-panel__non-scrollable-table">
          <tbody>
            <tr>
              <th className="well-model-panel__table-cell well-model-panel__table-header-cell">
                Подача, м3/сут
              </th>
              {pump?.volume_fluid_flows__m3_per_day?.map((item: number) => (
                <th className="well-model-panel__table-cell value-cell pressure-cell right-aligned">
                  {item && oneDigitsFormatter.format(item)}
                </th>
              ))}
            </tr>
            <tr>
              <th className="well-model-panel__table-cell well-model-panel__table-header-cell">
                Напор, м
              </th>
              {pump?.volume_fluid_flows__m3_per_day?.map(
                (val: number, flows_index: number) => (
                  <td className="well-model-panel__table-cell value-cell pressure-cell">
                    {pump?.head__m[flows_index]}
                  </td>
                ),
              )}
            </tr>
            <tr>
              <th className="well-model-panel__table-cell well-model-panel__table-header-cell">
                Эффективность, %
              </th>
              {pump?.volume_fluid_flows__m3_per_day?.map(
                (val: number, flows_index: number) => (
                  <td className="well-model-panel__table-cell value-cell pressure-cell">
                    {pump?.efficiencies__percent[flows_index]}
                  </td>
                ),
              )}
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default viewModel;
