import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as AspoIcon } from 'images/Project/icn-aspo.svg';
import { setShowDialog } from 'store/commonSlice';
import { selectIsActual } from 'store/taskSlice';

const ApplyOptimalButton = () => {
  const isActual = useSelector(selectIsActual);
  const dispatch = useDispatch();
  const onApplyOptimalClick = () => {
    dispatch(
      setShowDialog({
        dialogType: 'APPLY_OPTIMAL',
      }),
    );
  };
  return (
    <button
      type="button"
      className="project-results-panel__optimal-btn"
      onClick={onApplyOptimalClick}
      disabled={!isActual}
    >
      <AspoIcon className="project-results-panel__optimal-icon" />
      <p>Применить оптимизацию</p>
    </button>
  );
};
export default ApplyOptimalButton;
