// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import {
  selectExperimentSettings,
  setExperimentSettings as setStoreExperimentSettings,
} from 'store/taskSlice';

import {
  getGlobalInequalityOptimizationConstraints,
  setExperimentSettings,
} from '../../../../services/apiRequests';
import ObjectInput from '../../ActiveObject/components/ObjectInput';
import ObjectSelect from '../../ActiveObject/components/ObjectSelect';
import { InequalityConstraint } from '../../types';
import { getInequalityConstraintsOptions } from '../options';

interface INonClusterConstraintsProps {
  setIncompleteConstraints(val: boolean): void;
  setExperimentSettingsSaved(val: boolean): void;
}

const NonClusterInequalityConstraint: React.FC<INonClusterConstraintsProps> = ({
  setIncompleteConstraints,
  setExperimentSettingsSaved,
}) => {
  const dispatch = useDispatch();
  const settings = useSelector(selectExperimentSettings);
  const params = useParams() as { id: string };
  const [constraint1Value, setConstraint1Value] = React.useState<
    InequalityConstraint | undefined
  >();
  const [constraint2Value, setConstraint2Value] = React.useState<
    InequalityConstraint | undefined
  >();
  const [constraint1Number, setConstraint1Number] = React.useState<
    number | undefined
  >();
  const [constraint1ValueChanged, setConstraint1ValueChanged] =
    React.useState<boolean>(false);
  const [constraint2Number, setConstraint2Number] = React.useState<
    number | undefined
  >();
  const [constraint2ValueChanged, setConstraint2ValueChanged] =
    React.useState<boolean>(false);

  const [constraintsOptionsCurrent, setConstraintsOptionsCurrent] =
    React.useState<
      {
        value: InequalityConstraint;
        label: string;
        disabled: boolean;
      }[]
    >();

  React.useEffect(() => {
    if (
      settings?.optimization_experiment_settings?.constraints?.constraints &&
      (
        settings?.optimization_experiment_settings?.constraints
          ?.constraints as any[]
      )[0]?.type !== constraint1Value
    ) {
      setConstraint1Value(
        (
          settings?.optimization_experiment_settings?.constraints
            ?.constraints as any[]
        )[0]?.type,
      );
    }
    if (
      settings?.optimization_experiment_settings?.constraints?.constraints &&
      settings?.optimization_experiment_settings?.constraints?.constraints
        ?.length === 1
    ) {
      setConstraint2Value(undefined);
      setConstraint2Number(undefined);
    }
  }, [
    settings?.optimization_experiment_settings?.constraints?.constraints &&
      (
        settings?.optimization_experiment_settings?.constraints
          ?.constraints as any[]
      )[0]?.type,
  ]);

  React.useEffect(() => {
    if (
      settings?.optimization_experiment_settings?.constraints?.constraints &&
      (
        settings?.optimization_experiment_settings?.constraints
          ?.constraints as any[]
      )[1]?.type !== constraint2Value
    ) {
      setConstraint2Value(
        (
          settings?.optimization_experiment_settings?.constraints
            ?.constraints as any[]
        )[1]?.type,
      );
    }
  }, [
    settings?.optimization_experiment_settings?.constraints?.constraints &&
      (
        settings?.optimization_experiment_settings?.constraints
          ?.constraints as any[]
      )[1]?.type,
  ]);

  React.useEffect(() => {
    if (
      settings?.optimization_experiment_settings?.constraints?.constraints &&
      (
        settings?.optimization_experiment_settings?.constraints
          ?.constraints as any[]
      )[0]?.type
    ) {
      const value = [
        'MaxSpecificPowerConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
        'MaxSpecificGasliftConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
      ].includes(
        (
          settings?.optimization_experiment_settings?.constraints
            ?.constraints as any[]
        )[0]?.type,
      )
        ? ((
            settings?.optimization_experiment_settings?.constraints
              ?.constraints as any[]
          )[0]?.value as any)
        : (
            (
              settings?.optimization_experiment_settings?.constraints
                ?.constraints as any[]
            )[0]?.value as any
          )?.[
            getUnitByConstraint(
              (
                settings?.optimization_experiment_settings?.constraints
                  ?.constraints as any[]
              )[0]?.type,
            )
          ];
      if (value !== constraint1Number) {
        setConstraint1Number(value);
      }
    }
  }, [
    settings?.optimization_experiment_settings?.constraints?.constraints &&
      (
        settings?.optimization_experiment_settings?.constraints
          ?.constraints as any[]
      )[0]?.value,
  ]);

  React.useEffect(() => {
    if (
      settings?.optimization_experiment_settings?.constraints?.constraints &&
      (
        settings?.optimization_experiment_settings?.constraints
          ?.constraints as any[]
      )[1]?.type
    ) {
      const value = [
        'MaxSpecificPowerConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
        'MaxSpecificGasliftConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
      ].includes(
        (
          settings?.optimization_experiment_settings?.constraints
            ?.constraints as any[]
        )[1]?.type,
      )
        ? ((
            settings?.optimization_experiment_settings?.constraints
              ?.constraints as any[]
          )[1]?.value as any)
        : (
            (
              settings?.optimization_experiment_settings?.constraints
                ?.constraints as any[]
            )[1]?.value as any
          )?.[
            getUnitByConstraint(
              (
                settings?.optimization_experiment_settings?.constraints
                  ?.constraints as any[]
              )[1]?.type,
            )
          ];
      if (value !== constraint2Number) {
        setConstraint2Number(value);
      }
    }
  }, [
    settings?.optimization_experiment_settings?.constraints?.constraints &&
      (
        settings?.optimization_experiment_settings?.constraints
          ?.constraints as any[]
      )[1]?.value,
  ]);

  React.useEffect(() => {
    let option1ToDisable:
      | {
          value: InequalityConstraint;
          label: string;
          disabled: boolean;
        }
      | undefined;
    let option2ToDisable:
      | {
          value: InequalityConstraint;
          label: string;
          disabled: boolean;
        }
      | undefined;
    switch (settings?.optimization_experiment_settings?.target_metric) {
      case 'Maximum oil debit':
        option1ToDisable = getInequalityConstraintsOptions().find(
          item =>
            item.value ===
            'MinOilDebitInequalityNetworkOptimizationExperimentSettingsConstraint',
        );
        break;
      case 'Minimum energy consumption':
        option1ToDisable = getInequalityConstraintsOptions().find(
          item =>
            item.value ===
            'MaxEnergyConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
        );
        break;
      case 'Minimum active gas consumption':
        option1ToDisable = getInequalityConstraintsOptions().find(
          item =>
            item.value ===
            'MaxActiveGasConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
        );
        break;
      case 'MIN_PRODUCED_FLUID_VOLUME':
        option1ToDisable = getInequalityConstraintsOptions().find(
          item =>
            item.value ===
            'MaxProducedFluidVolumeInequalityNetworkOptimizationExperimentSettingsConstraint',
        );
        break;
      case 'MIN_PRODUCED_ASSOCIATED_PETROLEUM_GAS_VOLUME':
      case 'MAX_PRODUCED_ASSOCIATED_PETROLEUM_GAS_VOLUME':
        option1ToDisable = getInequalityConstraintsOptions().find(
          item =>
            item.value ===
            'MaxProducedPetroleumGasVolumeInequalityNetworkOptimizationExperimentSettingsConstraint',
        );
        break;
      case 'MIN_PRODUCED_WATER_VOLUME':
        option1ToDisable = getInequalityConstraintsOptions().find(
          item =>
            item.value ===
            'MaxProducedWaterVolumeInequalityNetworkOptimizationExperimentSettingsConstraint',
        );
        break;
      case 'MIN_SPECIFIC_POWER_CONSUMPTION':
        option1ToDisable = getInequalityConstraintsOptions().find(
          item =>
            item.value ===
            'MaxSpecificPowerConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
        );
        break;
      case 'MIN_SPECIFIC_GASLIFT_CONSUMPTION':
        option1ToDisable = getInequalityConstraintsOptions().find(
          item =>
            item.value ===
            'MaxSpecificGasliftConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
        );
        break;
      case 'MIN_TOTAL_GAS':
      case 'MAX_TOTAL_GAS':
        option1ToDisable = getInequalityConstraintsOptions().find(
          item =>
            item.value ===
            'MaxTotalGasConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
        );
        option2ToDisable = getInequalityConstraintsOptions().find(
          item =>
            item.value ===
            'MinTotalGasConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
        );
        break;
      default:
        break;
    }
    setConstraintsOptionsCurrent(
      getInequalityConstraintsOptions().map(item => {
        if (item.value === option1ToDisable?.value) {
          return { ...option1ToDisable, disabled: true };
        }
        if (item.value === option2ToDisable?.value) {
          return { ...option2ToDisable, disabled: true };
        }
        if (item.value === constraint1Value) {
          const option = getInequalityConstraintsOptions().find(
            option1 => option1.value === constraint1Value,
          );
          if (option) return { ...option, disabled: true };
        } else if (item.value === constraint2Value) {
          const option = getInequalityConstraintsOptions().find(
            option2 => option2.value === constraint2Value,
          );
          if (option) return { ...option, disabled: true };
        }
        return item;
      }),
    );
  }, [
    settings?.optimization_experiment_settings?.target_metric,
    constraint1Value,
    constraint2Value,
  ]);

  const tryToSaveConstraint = async () => {
    if (
      settings?.optimization_experiment_settings &&
      constraint1Value &&
      constraint1Number !== undefined &&
      constraint2Value &&
      constraint2Number !== undefined
    ) {
      const newValue = {
        ...settings,
        optimization_experiment_settings: {
          ...settings.optimization_experiment_settings,
          constraints: {
            type: 'GlobalInequalityNetworkOptimizationExperimentSettingsConstraints' as any,
            constraints: [
              {
                type: constraint1Value as any,
                value: getNumberValueByConstraint(
                  constraint1Value,
                  constraint1Number,
                ) as any,
              },
              {
                type: constraint2Value as any,
                value: getNumberValueByConstraint(
                  constraint2Value,
                  constraint2Number,
                ) as any,
              },
            ],
          },
        },
      };
      dispatch(setStoreExperimentSettings(newValue as any));
      setExperimentSettingsSaved(false);
      await setExperimentSettings(params.id, newValue as any);
      setExperimentSettingsSaved(true);
      setIncompleteConstraints(false);
    } else if (
      settings?.optimization_experiment_settings &&
      constraint1Value &&
      constraint1Number !== undefined
    ) {
      const newValue = {
        ...settings,
        optimization_experiment_settings: {
          ...settings.optimization_experiment_settings,
          constraints: {
            type: 'GlobalInequalityNetworkOptimizationExperimentSettingsConstraints' as any,
            constraints: [
              {
                type: constraint1Value as any,
                value: getNumberValueByConstraint(
                  constraint1Value,
                  constraint1Number,
                ) as any,
              },
            ],
          },
        },
      };
      dispatch(setStoreExperimentSettings(newValue as any));
      setExperimentSettingsSaved(false);
      await setExperimentSettings(params.id, newValue as any);
      setExperimentSettingsSaved(true);
      setIncompleteConstraints(false);
    } else if (
      settings?.optimization_experiment_settings &&
      constraint2Value &&
      constraint2Number !== undefined
    ) {
      const newValue = {
        ...settings,
        optimization_experiment_settings: {
          ...settings.optimization_experiment_settings,
          constraints: {
            type: 'GlobalInequalityNetworkOptimizationExperimentSettingsConstraints' as any,
            constraints: [
              {
                type: constraint2Value as any,
                value: getNumberValueByConstraint(
                  constraint2Value,
                  constraint2Number,
                ) as any,
              },
            ],
          },
        },
      };
      dispatch(setStoreExperimentSettings(newValue as any));
      setExperimentSettingsSaved(false);
      await setExperimentSettings(params.id, newValue as any);
      setExperimentSettingsSaved(true);
      setIncompleteConstraints(false);
    }
  };

  const getNumberValueByConstraint = (
    constraint: InequalityConstraint,
    number: number,
  ) => {
    switch (constraint) {
      case 'MinOilDebitInequalityNetworkOptimizationExperimentSettingsConstraint':
        return { t_per_day: number };
      case 'MaxEnergyConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
        return { kW_h_per_day: number };
      case 'MaxActiveGasConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
      case 'MaxProducedPetroleumGasVolumeInequalityNetworkOptimizationExperimentSettingsConstraint':
      case 'MaxTotalGasConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
      case 'MinTotalGasConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
        return { thousand_m3_per_day: number };
      case 'MaxProducedFluidVolumeInequalityNetworkOptimizationExperimentSettingsConstraint':
      case 'MaxProducedWaterVolumeInequalityNetworkOptimizationExperimentSettingsConstraint':
        return { m3_per_day: number };
      case 'MaxSpecificPowerConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
        return number;
      case 'MaxSpecificGasliftConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
        return number;
      default:
        return {};
    }
  };

  const getUnitByConstraint = (constraint: InequalityConstraint) => {
    switch (constraint) {
      case 'MinOilDebitInequalityNetworkOptimizationExperimentSettingsConstraint':
        return 't_per_day';
      case 'MaxEnergyConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
        return 'kW_h_per_day';
      case 'MaxActiveGasConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
      case 'MaxProducedPetroleumGasVolumeInequalityNetworkOptimizationExperimentSettingsConstraint':
      case 'MaxTotalGasConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
      case 'MinTotalGasConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
        return 'thousand_m3_per_day';
      case 'MaxProducedFluidVolumeInequalityNetworkOptimizationExperimentSettingsConstraint':
      case 'MaxProducedWaterVolumeInequalityNetworkOptimizationExperimentSettingsConstraint':
        return 'm3_per_day';
      case 'MaxSpecificPowerConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
        return '';
      case 'MaxSpecificGasliftConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
        return '';
      default:
        return '';
    }
  };

  React.useEffect(() => {
    const fetchInequalityConstraints = async () => {
      let response;
      if (constraint1Value) {
        response = await getGlobalInequalityOptimizationConstraints(
          params.id,
          constraint1Value as any,
        );
      }
      return response;
    };
    if (
      (settings?.optimization_experiment_settings?.constraints?.constraints &&
        (
          settings?.optimization_experiment_settings?.constraints
            ?.constraints as any[]
        )[0]?.type) !== constraint1Value &&
      constraint1Value !== undefined
    ) {
      setIncompleteConstraints(true);
      fetchInequalityConstraints().then(response => {
        if (response?.data.constraints[0]?.type) {
          if (
            [
              'MaxSpecificPowerConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
              'MaxSpecificGasliftConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
            ].includes(response?.data.constraints[0]?.type)
          ) {
            if (constraint1Number !== response?.data.constraints[0]?.value)
              setConstraint1Number(response?.data.constraints[0]?.value);
            else setConstraint1ValueChanged(!constraint1ValueChanged);
          } else if (
            constraint1Number !==
            (response?.data.constraints[0]?.value as any)?.[
              getUnitByConstraint(response?.data.constraints[0].type)
            ]
          )
            setConstraint1Number(
              (response?.data.constraints[0]?.value as any)?.[
                getUnitByConstraint(response?.data.constraints[0].type)
              ],
            );
          else setConstraint1ValueChanged(!constraint1ValueChanged);
        }
        setIncompleteConstraints(false);
      });
    }
  }, [constraint1Value]);

  React.useEffect(() => {
    const fetchInequalityConstraints = async () => {
      let response;
      if (constraint2Value) {
        response = await getGlobalInequalityOptimizationConstraints(
          params.id,
          constraint2Value as any,
        );
      }
      return response;
    };
    if (
      (settings?.optimization_experiment_settings?.constraints?.constraints &&
        (
          settings?.optimization_experiment_settings?.constraints
            ?.constraints as any[]
        )[1]?.type) !== constraint2Value &&
      constraint2Value !== undefined
    ) {
      setIncompleteConstraints(true);
      fetchInequalityConstraints().then(response => {
        if (
          response?.data.constraints[0] &&
          response?.data.constraints[0]?.type
        ) {
          if (
            [
              'MaxSpecificPowerConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
              'MaxSpecificGasliftConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
            ].includes(response?.data.constraints[0]?.type)
          ) {
            if (constraint2Number !== response?.data.constraints[0]?.value)
              setConstraint2Number(response?.data.constraints[0]?.value);
            else setConstraint2ValueChanged(!constraint2ValueChanged);
          } else if (
            constraint2Number !==
            (response?.data.constraints[0]?.value as any)?.[
              getUnitByConstraint(response?.data.constraints[0].type)
            ]
          )
            setConstraint2Number(
              (response?.data.constraints[0]?.value as any)?.[
                getUnitByConstraint(response?.data.constraints[0].type)
              ],
            );
          else setConstraint2ValueChanged(!constraint2ValueChanged);
        }
        setIncompleteConstraints(false);
      });
    }
  }, [constraint2Value]);

  React.useEffect(() => {
    if (constraint1Value) {
      let valueToCompare;
      if (
        settings?.optimization_experiment_settings?.constraints?.constraints
      ) {
        valueToCompare = [
          'MaxSpecificPowerConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
          'MaxSpecificGasliftConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint',
        ].includes(constraint1Value)
          ? ((
              settings?.optimization_experiment_settings?.constraints
                ?.constraints as any[]
            )[0]?.value as any)
          : (
              (
                settings?.optimization_experiment_settings?.constraints
                  ?.constraints as any[]
              )[0]?.value as any
            )?.[getUnitByConstraint(constraint1Value)];
      }
      const value = !settings?.optimization_experiment_settings?.constraints
        ?.constraints
        ? undefined
        : valueToCompare;
      const constraintType = settings?.optimization_experiment_settings
        ?.constraints?.constraints
        ? settings?.optimization_experiment_settings?.constraints
            ?.constraints[0]?.type
        : undefined;
      if (value !== constraint1Number || constraintType !== constraint1Value) {
        tryToSaveConstraint().then();
      }
    }
  }, [constraint1Number, constraint1ValueChanged]);

  React.useEffect(() => {
    if (constraint2Value) {
      let valueToCompare;
      if (
        settings?.optimization_experiment_settings?.constraints?.constraints
      ) {
        valueToCompare = [
          'SpecificPowerConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint',
          'SpecificGasliftConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint',
        ].includes(constraint2Value)
          ? ((
              settings?.optimization_experiment_settings?.constraints
                ?.constraints as any[]
            )[1]?.value as any)
          : (
              (
                settings?.optimization_experiment_settings?.constraints
                  ?.constraints as any[]
              )[1]?.value as any
            )?.[getUnitByConstraint(constraint2Value)];
      }
      const value = !settings?.optimization_experiment_settings?.constraints
        ?.constraints
        ? undefined
        : valueToCompare;
      const constraintType = settings?.optimization_experiment_settings
        ?.constraints?.constraints
        ? settings?.optimization_experiment_settings?.constraints
            ?.constraints[1]?.type
        : undefined;
      if (value !== constraint2Number || constraintType !== constraint2Value) {
        tryToSaveConstraint().then();
      }
    }
  }, [constraint2Number, constraint2ValueChanged]);

  const onChangeConstraint1Value = (value: InequalityConstraint) => {
    setConstraint1Value(value);
  };

  const onChangeConstraint2Value = (value: InequalityConstraint) => {
    setConstraint2Value(value);
  };

  const saveConstraint1Number = (value: number) => {
    setConstraint1Number(value);
  };

  const saveConstraint2Number = (value: number) => {
    setConstraint2Number(value);
  };

  const onChangeConstraintNumber = () => {
    setIncompleteConstraints(true);
  };

  const renderUnitByConstraint = (
    constraint: InequalityConstraint | undefined,
  ) => {
    switch (constraint) {
      case 'MinOilDebitInequalityNetworkOptimizationExperimentSettingsConstraint':
        return (
          <p className="active-object__row-input-unit">
            <span className="relative">т/сут</span>
          </p>
        );
      case 'MaxEnergyConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
        return (
          <p className="active-object__row-input-unit">
            <span className="relative">кВт·ч/сут</span>
          </p>
        );
      case 'MaxActiveGasConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
      case 'MaxProducedPetroleumGasVolumeInequalityNetworkOptimizationExperimentSettingsConstraint':
      case 'MaxTotalGasConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
      case 'MinTotalGasConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
        return (
          <p className="active-object__row-input-unit">
            <span className="relative">
              тыс.м<span className="index unit-small">3</span>
            </span>
            &nbsp; /сут
          </p>
        );
      case 'MaxProducedFluidVolumeInequalityNetworkOptimizationExperimentSettingsConstraint':
      case 'MaxProducedWaterVolumeInequalityNetworkOptimizationExperimentSettingsConstraint':
        return (
          <p className="active-object__row-input-unit">
            <span className="relative">
              м<span className="index unit-small">3</span>
            </span>
            &nbsp; /сут
          </p>
        );
      case 'MaxSpecificPowerConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
        return (
          <p className="active-object__row-input-unit">
            <span className="relative">кВт·ч/т</span>
          </p>
        );
      case 'MaxSpecificGasliftConsumptionInequalityNetworkOptimizationExperimentSettingsConstraint':
        return (
          <p className="active-object__row-input-unit">
            <span className="relative">
              тыс.м<span className="index unit-small">3</span>
            </span>
            &nbsp; /т
          </p>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <div className="project-task-settings__separator" />
      <div className="active-object__row">
        <ObjectSelect
          label="Ограничение 1"
          classNamesWrapper="full-width"
          classNames="active-object__row-input"
          name="11"
          options={constraintsOptionsCurrent || []}
          value={constraintsOptionsCurrent?.find(
            item => item.value === constraint1Value,
          )}
          isOptionDisabled={(option: any) => (option as any)?.disabled}
          saveNewValue={onChangeConstraint1Value}
        />
      </div>
      <div className="active-object__row">
        <div className="project-task-settings__row long active-object__input-with-unit-wrapper">
          <ObjectInput
            className="input active-object__row-input with-long-unit"
            label=""
            name="gas_constraint"
            initValue={constraint1Number}
            minValue={0}
            saveNewValue={saveConstraint1Number}
            onChangeValue={onChangeConstraintNumber}
          />
          {renderUnitByConstraint(constraint1Value)}
        </div>
      </div>

      <div className="project-task-settings__separator" />
      <div className="active-object__row">
        <ObjectSelect
          label="Ограничение 2"
          classNamesWrapper="full-width"
          classNames="active-object__row-input"
          name=""
          options={constraintsOptionsCurrent || []}
          value={constraintsOptionsCurrent?.find(
            item => item.value === constraint2Value,
          )}
          isOptionDisabled={(option: any) => (option as any)?.disabled}
          saveNewValue={onChangeConstraint2Value}
        />
      </div>
      <div className="active-object__row">
        <div className="project-task-settings__row long active-object__input-with-unit-wrapper">
          <ObjectInput
            className="input active-object__row-input with-long-unit"
            label=""
            name="gas_constraint"
            initValue={constraint2Number}
            minValue={0}
            saveNewValue={saveConstraint2Number}
            onChangeValue={onChangeConstraintNumber}
          />
          {renderUnitByConstraint(constraint2Value)}
        </div>
      </div>
      <div />
    </>
  );
};

export default NonClusterInequalityConstraint;
