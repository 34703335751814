import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectWellById,
  setWellFactState,
  updateWellModal,
} from 'store/projectSlice';

import { getWellFactStates, getWellModel } from 'services/apiRequests';

const useGetWellModel = (projectUid: string, wellUid: string) => {
  const dispatch = useDispatch();
  const wellsById = useSelector(selectWellById);

  const fetchWellModel = async () => {
    try {
      await getWellModel(wellUid).then(res => {
        if (res.data.model.type === 'PermanentFountainWell') {
          dispatch(
            updateWellModal({
              ...res.data,
              model: {
                ...res.data.model,
                flow_rates__m3_per_day: [
                  res.data.model.flow_rates__m3_per_day,
                ] as any,
                bottomhole_pressures__atmg: [
                  res.data.model.bottomhole_pressures__atmg,
                ] as any,
              },
            }),
          );
        } else dispatch(updateWellModal(res.data));
      });
      if (
        !['Pump5dWell', 'Gaslift5dWell'].includes(
          wellsById[wellUid as string]?.type as string,
        )
      )
        await getWellFactStates(wellUid)
          .then(res =>
            dispatch(setWellFactState(res.data.well_fact_state_query_result)),
          )
          .catch(() => {
            dispatch(setWellFactState(undefined));
          });
    } catch (e) {
      //
    }
  };

  React.useEffect(() => {
    fetchWellModel();
  }, [projectUid, wellUid]);
};

export default useGetWellModel;
