import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import classNames from 'classnames';
import ToggleList from 'Components/ToggleList';
import { ReactComponent as DropIcon } from 'images/Project/drop.svg';
import { ReactComponent as FolderIcon } from 'images/Project/folder.svg';
import { ReactComponent as DeleteIcon } from 'images/Project/icn-delete-object.svg';
import { ReactComponent as AllIcon } from 'images/Project/icn-select-all.svg';
import { ReactComponent as LayersIcon } from 'images/Project/layers.svg';

import { setShowDialog } from '../../store/commonSlice';
import { setActiveObject } from '../../store/mapSlice';
import { RootState } from '../../store/store';
import { IMapSlice } from '../../store/types';
import { shortenTitle } from '../../utils';
import { ObjectType } from './types';

interface Props {
  activeObject: IMapSlice['activeObject'];
  title: string;
  data: any;
  type: ObjectType;
}

const NetworkObjectsToggle: React.FC<Props> = (props: Props) => {
  const { title, data, type, activeObject } = props;
  const dispatch = useDispatch();
  const [activeList, setActiveList] = useState<boolean>(false);
  const [allSources, setAllSources] = useState<boolean>(false);
  const [allPipelines, setAllPipelines] = useState<boolean>(false);
  const maxObjectNameLength = 18;

  useEffect(() => {
    if (activeObject.type !== 'ALLSOURCES') setAllSources(false);
    if (activeObject.type !== 'ALLPIPELINES') setAllPipelines(false);
    if (activeObject.type === type) {
      setActiveList(true);
    }
    if (activeObject.type === 'SOURCESTOPPED' && type === 'SOURCE') {
      setActiveList(true);
    } else if (
      activeObject.type === 'PROD_WELL_PPDSTOPPED' &&
      type === 'PROD_WELL_PPD'
    ) {
      setActiveList(true);
    } else if (
      activeObject.type === 'INJ_WELL_PPDSTOPPED' &&
      type === 'INJ_WELL_PPD'
    ) {
      setActiveList(true);
    } else if (activeObject.type !== type && activeList) {
      setActiveList(false);
    }
  }, [activeObject.type, activeObject.object_uid]);

  const toggleActive = (val: boolean) => {
    if (!val) {
      if (
        activeObject.type === type ||
        ['ALLSOURCES', 'ALLPIPELINES'].includes(activeObject.type as string)
      ) {
        dispatch(
          setActiveObject({
            node_uid: null,
            type: null,
            object_uid: null,
            object_name: null,
          }),
        );
      }
    }
    setActiveList(val);
  };

  const onObjectClick = (
    uid: string | undefined,
    node_uid: string | undefined,
    node_name: string | undefined,
    is_stopped: string | undefined,
  ) => {
    dispatch(
      setActiveObject({
        ...(node_uid && { node_uid }),
        type: is_stopped ? `${type}STOPPED` : type,
        object_name: node_name || null,
        object_uid: uid || null,
      }),
    );
  };

  const onDeleteBtnClick = async (
    e: React.MouseEvent,
    objectType: ObjectType,
    objectName: string,
    objectId: string,
  ) => {
    e.stopPropagation();
    dispatch(
      setShowDialog({
        dialogType: 'DELETE_CONFIRM',
        objectType,
        objectName,
        objectId,
      }),
    );
  };

  const onHoverBtnClick = async (e: React.MouseEvent) => {
    if (activeList) {
      e.stopPropagation();
    }
    if (type === 'SOURCE') {
      dispatch(
        setActiveObject({
          node_uid: null,
          type: !allSources ? 'ALLSOURCES' : null,
          object_uid: null,
          object_name: null,
        }),
      );
      setAllSources(!allSources);
    }
    /* if (type === 'PIPELINE') {
      dispatch(
        setActiveObject({
          node_uid: null,
          type: !allPipelines ? 'ALLPIPELINES' : null,
          object_uid: null,
          object_name: null,
        }),
      );
      setAllPipelines(!allPipelines);
    } */
    /* if (type === 'PACKAGE') {
      try {
        const response = await addPhysChemPackage(params.id, {});
        if (response?.data) {
          dispatch(setPhysChemPackages(response.data));
          const packageObj = response?.data?.find(
            (val, index, array) => index === array.length - 1,
          );
          dispatch(
            setActiveObject({
              node_uid: null,
              type: 'PACKAGE',
              object_uid: packageObj?.uid || null,
              object_name: packageObj?.name || null,
            }),
          );
        }
      } catch (err) {
        //
      }
    } */
  };

  const getGroupIcon = () => {
    switch (type) {
      case 'PACKAGE':
        return <DropIcon className="network-objects__icon" />;
      case 'LAYER_PPD':
        return <LayersIcon className="network-objects__icon" />;
      default:
        return <FolderIcon className="network-objects__icon" />;
    }
  };

  return (
    <div className="network-objects__row">
      <ToggleList
        activeList={activeList}
        setActiveList={toggleActive}
        topRow={
          <div
            className={classNames(
              'network-objects__title-wrapper',
              type?.toLowerCase(),
            )}
          >
            <span className="network-objects__title">
              {getGroupIcon()}
              <span>{title}</span>
            </span>
            <button
              type="button"
              className={classNames(
                'network-objects__hover-btn',
                (allSources || allPipelines) && 'active',
              )}
              onClick={onHoverBtnClick}
            >
              {['SOURCE' /* , 'PIPELINE' */].includes(type) && (
                <AllIcon
                  className={classNames(
                    'network-objects__icon button-icon',
                    (allSources || allPipelines) && 'active',
                  )}
                />
              )}
              {/* type === 'PACKAGE' && (
                <AddIcon
                  className={classNames('network-objects__icon button-icon')}
                />
              ) */}
            </button>
          </div>
        }
        body={
          <Body
            data={data}
            activeObject={activeObject}
            type={type}
            maxObjectNameLength={maxObjectNameLength}
            onDeleteBtnClick={onDeleteBtnClick}
            onObjectClick={onObjectClick}
          />
        }
      />
    </div>
  );
};

function Body(props: any) {
  const {
    data,
    activeObject,
    type,
    maxObjectNameLength,
    onDeleteBtnClick,
    onObjectClick,
  } = props;

  const bodyScrollRef = useRef<any>([]);

  const executeScroll = () => {
    const index = data
      .map((item: any) => {
        return type === 'SOURCE' ? item.node_uid : item.uid;
      })
      .indexOf(
        type === 'SOURCE' ? activeObject.node_uid : activeObject.object_uid,
      );
    if (index !== -1) {
      bodyScrollRef?.current[index].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  useEffect(() => {
    if (activeObject.type === type) {
      executeScroll();
    }
  }, [activeObject.type, activeObject.object_uid]);

  useEffect(() => {
    executeScroll();
  }, [data.length]);

  return (
    <div>
      {data?.map((item: any, i: any) => {
        return (
          <button
            ref={el => (bodyScrollRef.current[i] = el)}
            className={classNames(
              'network-objects__sub-title',
              (item?.uid === activeObject.object_uid ||
                item?.id === activeObject.object_uid ||
                (item?.node_uid && item?.node_uid === activeObject.node_uid) ||
                (item?.node_id && item?.node_id === activeObject.node_uid) ||
                (activeObject.type === 'ALLSOURCES' && type === 'SOURCE') ||
                (activeObject.type === 'ALLPIPELINES' &&
                  type === 'PIPELINE')) &&
                'active',
            )}
            key={item?.uid || i}
            onClick={() =>
              onObjectClick(
                item?.uid || item?.id,
                item?.node_uid || item?.node_id || item?.oil_out_node_uid,
                item?.name,
                item?.current_state === 'STOPPED',
              )
            }
            type="button"
          >
            {shortenTitle(item?.name, maxObjectNameLength)}
            {['PIPELINE', 'JUNCTION'].includes(type) && (
              <button
                type="button"
                className={classNames(
                  'network-object__hover-btn',
                  ['ALLSOURCES', 'ALLPIPELINES'].includes(
                    activeObject.type as string,
                  ) && 'all-objects',
                )}
                onClick={e =>
                  onDeleteBtnClick(
                    e,
                    type,
                    item.name,
                    (item.node_uid || item.uid) as string,
                  )
                }
              >
                <DeleteIcon
                  className={classNames('network-object__icon button-icon')}
                />
              </button>
            )}
          </button>
        );
      })}
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  activeObject: state.map.activeObject,
});
export default connect(mapStateToProps)(NetworkObjectsToggle);
