import React from 'react';

import { components } from '../../../../../../../../generated/apiTypes';
import { UseChartData } from '../types';
import { seriesColors } from '../utils';

const useChartData: UseChartData = (projectSolution, option, name) => {
  const { time } = React.useMemo(() => {
    if (projectSolution?.solution?.node_step_table?.time2states) {
      return {
        time: Object.entries(
          projectSolution?.solution?.node_step_table?.time2states,
        ).map(states => parseFloat(states[0])),
      };
    }
    return {};
  }, [projectSolution?.solution]);

  return React.useMemo(() => {
    if (time && option && name) {
      const chartData: { name: string; data: any[] }[] = [
        { name: 'Базовый режим', data: [] },
        { name: 'Оптимизированный режим', data: [] },
      ];
      const nodeUIDS = projectSolution?.task?.pipeline_network?.nodes?.map(
        (node: components['schemas']['PNOPipelineNode']) =>
          node.type === 'JUNCTION' && node.uid,
      );
      projectSolution?.solution?.node_step_table?.time2states &&
        Object.values(
          projectSolution?.solution?.node_step_table?.time2states,
        ).forEach((states: any) => {
          const nodeState = states.find(
            (state: any) =>
              nodeUIDS?.includes(state.node_uid) && state.node_name === name,
          );
          if (option.value === 'pressure') {
            chartData[0].data.push(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              nodeState?.compare_pressure?.base?.atm || 0,
            );
            chartData[1].data.push(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              nodeState?.compare_pressure?.fact?.atm || 0,
            );
          }
          if (option.value === 'oil_flow_rate') {
            chartData[0].data.push(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              nodeState?.compare_oil_flow_rate?.base?.t_per_day || 0,
            );
            chartData[1].data.push(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              nodeState?.compare_oil_flow_rate?.fact?.t_per_day || 0,
            );
          }
          if (option.value === 'gas_flow_rate') {
            chartData[0].data.push(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              nodeState?.compare_gas_flow_rate?.base?.thousand_m3_per_day || 0,
            );
            chartData[1].data.push(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              nodeState?.compare_gas_flow_rate?.fact?.thousand_m3_per_day || 0,
            );
          }
          if (option.value === 'fluid_flow_rate') {
            chartData[0].data.push(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              nodeState?.compare_fluid_flow_rate?.base?.m3_per_day || 0,
            );
            chartData[1].data.push(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              nodeState?.compare_fluid_flow_rate?.fact?.m3_per_day || 0,
            );
          }
          if (option.value === 'temperature') {
            chartData[0].data.push(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              nodeState?.compare_temperature?.base?.celsius || 0,
            );
            chartData[1].data.push(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              nodeState?.compare_temperature?.fact?.celsius || 0,
            );
          }
        });
      return {
        labels: time,
        datasets: Array.from(chartData).map((el, index) => {
          return {
            label: el.name,
            data: el.data,
            fill: false,
            backgroundColor: seriesColors[index % 9],
            borderColor: seriesColors[index % 9],
            color: seriesColors[index % 9],
            borderWidth: 1,
            tension: 0.1,
            pointHoverBorderWidth: 0,
            pointRadius: 0,
            pointHitRadius: 0,
            pointBorderWidth: 1,
            pointHoverRadius: 1,
          };
        }),
      };
    }
    return { labels: [], datasets: [] };
  }, [time, option?.value, name]);
};

export default useChartData;
