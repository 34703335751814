import React from 'react';

import { components } from '../../../../../generated/apiTypes';
import { oneDigitsFormatter } from '../../../../../utils';
import { seriesColors } from '../../WellModel/Chart/utils';

const useChartData = (pump: components['schemas']['PNOPump'] | undefined) => {
  const { flows } = React.useMemo(() => {
    if (pump?.volume_fluid_flows__m3_per_day) {
      return {
        flows: pump?.volume_fluid_flows__m3_per_day?.map((flow: any) => {
          return oneDigitsFormatter.format(flow);
        }),
      };
    }
    return {};
  }, [pump?.volume_fluid_flows__m3_per_day]);

  return React.useMemo(() => {
    if (flows) {
      const chartData: {
        headName: string;
        percentName: string;
        head: any[];
        percent: any[];
      } = {
        headName: 'Напор, м',
        percentName: 'Эффективность, %',
        head: flows.map((flow: any, flow_ix: number) => {
          return pump?.head__m && pump?.head__m[flow_ix];
        }),
        percent: flows.map((flow: any, flow_ix: number) => {
          return (
            pump?.efficiencies__percent && pump?.efficiencies__percent[flow_ix]
          );
        }),
      };

      return {
        labels: flows,
        datasets: [
          {
            yAxisID: 'y',
            label: chartData.headName,
            data: chartData.head,
            fill: false,
            backgroundColor: seriesColors[0 % 9],
            borderColor: seriesColors[0 % 9],
            color: seriesColors[0 % 9],
            borderWidth: 1,
            tension: 0.1,
            pointHoverBorderWidth: 0,
            pointRadius: 0,
            pointHitRadius: 0,
            pointBorderWidth: 1,
            pointHoverRadius: 1,
          },
          {
            yAxisID: 'y1',
            label: chartData.percentName,
            data: chartData.percent,
            fill: false,
            backgroundColor: seriesColors[1 % 9],
            borderColor: seriesColors[1 % 9],
            color: seriesColors[1 % 9],
            borderWidth: 1,
            tension: 0.1,
            pointHoverBorderWidth: 0,
            pointRadius: 0,
            pointHitRadius: 0,
            pointBorderWidth: 1,
            pointHoverRadius: 1,
          },
        ],
      };
    }
    return { labels: [], datasets: [] };
  }, [flows, pump?.head__m]);
};

export default useChartData;
