import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import update from 'immutability-helper';

import { components } from '../generated/apiTypes';
import { PNOAFSolution } from '../Pages/Project/types';
import { initialState } from './initialState';
import { RootState } from './store';
import { ITaskSlice } from './types';

const taskInitialState = initialState as ITaskSlice;

export const taskSlice = createSlice({
  name: 'task',
  initialState: {
    taskId: taskInitialState.taskId,
    experiment: taskInitialState.experiment,
    showResults: taskInitialState.showResults,
    solution: taskInitialState.solution,
    separators_solution: taskInitialState.separators_solution,
    chokes_solution: taskInitialState.chokes_solution,
    settings: taskInitialState.settings,
    applyBaseMode: taskInitialState.applyBaseMode,
  },
  reducers: {
    setIsActual: (
      state,
      action: PayloadAction<ITaskSlice['experiment']['is_actual']>,
    ) => {
      state.experiment.is_actual = action.payload;
    },
    setPercentage: (
      state,
      action: PayloadAction<ITaskSlice['experiment']['percentage']>,
    ) => {
      state.experiment.percentage = action.payload;
    },
    setCalculationTime: (
      state,
      action: PayloadAction<ITaskSlice['experiment']['ts_calculation_state']>,
    ) => {
      state.experiment.ts_calculation_state = action.payload;
    },
    setExperimentSettings: (
      state,
      action: PayloadAction<ITaskSlice['settings']>,
    ) => {
      state.settings = action.payload;
    },
    setExperimentType: (
      state,
      action: PayloadAction<ITaskSlice['experiment']['type']>,
    ) => {
      state.experiment.type = action.payload;
    },
    setExperimentMessage: (
      state,
      action: PayloadAction<ITaskSlice['experiment']['message']>,
    ) => {
      state.experiment.message = action.payload;
    },
    setExperimentStatus: (
      state,
      action: PayloadAction<ITaskSlice['experiment']['status']>,
    ) => {
      state.experiment.status = action.payload;
    },
    setShowResults: (
      state,
      action: PayloadAction<ITaskSlice['showResults']>,
    ) => {
      state.showResults = action.payload;
    },
    toggleApplyBaseMode: state => {
      state.applyBaseMode = !state.applyBaseMode;
    },
    setSolution: (state, action: PayloadAction<ITaskSlice['solution']>) => {
      state.solution = action.payload;
    },
    setSeparatorsSolution: (
      state,
      action: PayloadAction<ITaskSlice['separators_solution']>,
    ) => {
      state.separators_solution = action.payload;
    },
    setChokesSolution: (
      state,
      action: PayloadAction<ITaskSlice['chokes_solution']>,
    ) => {
      state.chokes_solution = action.payload;
    },
    setTaskId: (state, action: PayloadAction<ITaskSlice['taskId']>) => {
      state.taskId = action.payload;
    },
    setIntergalCharacteristics: (
      state,
      action: PayloadAction<
        components['schemas']['PNOPeriodicNodalAnalysisSolutionIntegralCharacteristic']
      >,
    ) => {
      if (state.solution.solution) {
        if (state.solution.solution?.solution?.integral_characteristic) {
          state.solution.solution = update(state.solution.solution, {
            solution: { integral_characteristic: { $set: action.payload } },
          });
        } else if (state.solution.solution.solution) {
          state.solution.solution.solution = update(
            state.solution.solution.solution,
            { integral_characteristic: { $set: action.payload } },
          );
        }
      } else {
        state.solution.solution = update(state.solution.solution as any, {
          $set: { solution: { integral_characteristic: action.payload } },
        });
      }
    },
    setAFIntergalCharacteristics: (
      state,
      action: PayloadAction<
        components['schemas']['GetFactAdaptationSolutionIntegralCharacteristicQueryResult']
      >,
    ) => {
      if (state.solution.solution) {
        if (state.solution.solution?.solution?.integral_characteristic) {
          state.solution.solution = update(state.solution.solution, {
            solution: { integral_characteristic: { $set: action.payload } },
          });
        } else if (state.solution.solution.solution) {
          state.solution.solution.solution = update(
            state.solution.solution.solution,
            { integral_characteristic: { $set: action.payload } },
          );
        }
      } else {
        state.solution.solution = update(state.solution.solution as any, {
          $set: { solution: { integral_characteristic: action.payload } },
        });
      }
    },
    setAFWellsAvgTable: (
      state,
      action: PayloadAction<
        components['schemas']['GetFactAdaptationSolutionWellStateQueryResult'][]
      >,
    ) => {
      if (state.solution.solution) {
        if ((state.solution.solution as PNOAFSolution).solution?.well_states) {
          state.solution.solution = update(state.solution.solution, {
            solution: { well_states: { $set: action.payload } },
          });
        } else if (state.solution.solution.solution) {
          state.solution.solution.solution = update(
            state.solution.solution.solution,
            { well_states: { $set: action.payload } },
          );
        }
      } else {
        state.solution.solution = update(state.solution.solution as any, {
          $set: { solution: { well_states: action.payload } },
        });
      }
    },
    setAFPipelinesAvgTable: (
      state,
      action: PayloadAction<
        components['schemas']['GetFactAdaptationSolutionPipelineStateQueryResult'][]
      >,
    ) => {
      if (state.solution.solution) {
        if (
          (state.solution.solution as PNOAFSolution).solution?.pipeline_states
        ) {
          state.solution.solution = update(state.solution.solution, {
            solution: { pipeline_states: { $set: action.payload } },
          });
        } else if (state.solution.solution.solution) {
          state.solution.solution.solution = update(
            state.solution.solution.solution,
            { pipeline_states: { $set: action.payload } },
          );
        }
      } else {
        state.solution.solution = update(state.solution.solution as any, {
          $set: { solution: { pipeline_states: action.payload } },
        });
      }
    },
    setAvgWellsTable: (
      state,
      action: PayloadAction<components['schemas']['PNOWellAvgStateTable']>,
    ) => {
      if (state.solution.solution) {
        if (
          (
            state.solution.solution
              .solution as components['schemas']['PNOPeriodicNodalAnalysisSolution']
          )?.well_avg_state_table
        ) {
          state.solution.solution = update(state.solution.solution, {
            solution: { well_avg_state_table: { $set: action.payload } },
          });
        } else if (state.solution.solution.solution) {
          state.solution.solution.solution = update(
            state.solution.solution.solution,
            { well_avg_state_table: { $set: action.payload } },
          );
        }
      } else {
        state.solution.solution = update(state.solution.solution as any, {
          $set: { solution: { well_avg_table: action.payload } },
        });
      }
    },
    setWellsStepTable: (
      state,
      action: PayloadAction<
        | components['schemas']['PNOWellStateStepTable']
        | components['schemas']['PNOTechModeOptimizationWellStepTable']
      >,
    ) => {
      if (state.solution.solution) {
        if (
          (
            state.solution.solution
              .solution as components['schemas']['PNOPeriodicNodalAnalysisSolution']
          )?.well_avg_state_table
        ) {
          state.solution.solution = update(state.solution.solution, {
            solution: {
              well_step_table: {
                $set: action.payload as components['schemas']['PNOWellStateStepTable'],
              },
            },
          });
        } else if (state.solution.solution.solution) {
          state.solution.solution.solution = update(
            state.solution.solution.solution,
            {
              well_step_table: {
                $set: action.payload as components['schemas']['PNOTechModeOptimizationWellStepTable'],
              },
            },
          );
        }
      } else {
        state.solution.solution = update(state.solution.solution as any, {
          $set: { solution: { well_step_table: action.payload } },
        });
      }
    },
    resetTaskSliceState: state => {
      state.taskId = taskInitialState.taskId;
      state.experiment.status = taskInitialState.experiment.status;
      state.experiment.message = taskInitialState.experiment.message;
      state.experiment.type = taskInitialState.experiment.type;
      state.showResults = taskInitialState.showResults;
      state.solution = taskInitialState.solution;
      state.separators_solution = initialState.separators_solution;
      state.chokes_solution = initialState.chokes_solution;
    },
  },
});

export const {
  setPercentage,
  setIsActual,
  setCalculationTime,
  setExperimentSettings,
  setExperimentType,
  setExperimentMessage,
  setExperimentStatus,
  toggleApplyBaseMode,
  setShowResults,
  setSolution,
  setSeparatorsSolution,
  setChokesSolution,
  setTaskId,
  setIntergalCharacteristics,
  setAFIntergalCharacteristics,
  setAvgWellsTable,
  setWellsStepTable,
  setAFPipelinesAvgTable,
  setAFWellsAvgTable,
  resetTaskSliceState,
} = taskSlice.actions;

const selectSelf = (state: RootState) => state.task;

export const selectShowResults = createSelector(
  selectSelf,
  task => task.showResults,
);

export const selectApplyBaseMode = createSelector(
  selectSelf,
  task => task.applyBaseMode,
);

export const selectExperimentType = createSelector(
  selectSelf,
  task => task.experiment.type,
);

export const selectExperimentSettings = createSelector(
  selectSelf,
  task => task.settings,
);
export const selectExperimentStatus = createSelector(
  selectSelf,
  task => task.experiment.status,
);
export const selectIsActual = createSelector(
  selectSelf,
  task => task.experiment.is_actual,
);
export const selectPercentage = createSelector(
  selectSelf,
  task => task.experiment.percentage,
);

export const selectCalculationTime = createSelector(
  selectSelf,
  task => task.experiment.ts_calculation_state,
);

export const selectExperimentMessage = createSelector(
  selectSelf,
  task => task.experiment.message,
);

export const selectTaskId = createSelector(selectSelf, task => task.taskId);

export const selectSolution = createSelector(selectSelf, task => task.solution);

export const selectSeparatorsSolution = createSelector(
  selectSelf,
  task => task.separators_solution,
);

export const selectChokesSolution = createSelector(
  selectSelf,
  task => task.chokes_solution,
);

export const selectAvgWellsTableUA = createSelector(
  selectSelf,
  task =>
    (
      task.solution
        ?.solution as components['schemas']['PNOPeriodicNodalAnalysisProblem']
    )?.solution?.well_avg_state_table,
);
export const selectWellsStepTableUA = createSelector(
  selectSelf,
  task =>
    (
      task.solution
        ?.solution as components['schemas']['PNOPeriodicNodalAnalysisProblem']
    )?.solution?.well_step_table,
);
export const selectWellsStepTableMO = createSelector(
  selectSelf,
  task =>
    (
      task.solution
        ?.solution as components['schemas']['PNOTechModeOptimizationProblem']
    )?.solution?.well_step_table,
);
export const selectSeparatorStepTableUA = createSelector(
  selectSelf,
  task =>
    (
      task.solution
        ?.solution as components['schemas']['PNOPeriodicNodalAnalysisProblem']
    )?.solution?.three_phase_separator_step_table,
);
export const selectPumpStepTableUA = createSelector(
  selectSelf,
  task =>
    (
      task.solution
        ?.solution as components['schemas']['PNOPeriodicNodalAnalysisProblem']
    )?.solution?.pump_step_table,
);
export const selectIntegralCharacteristics = createSelector(
  selectSelf,
  task => task.solution.solution?.solution.integral_characteristic,
);
export const selectWellCompareStateTable = createSelector(
  selectSelf,
  task =>
    (
      task.solution
        ?.solution as components['schemas']['PNOPeriodicNodalAnalysisProblem']
    )?.solution?.well_compare_state_table,
);
export default taskSlice.reducer;
