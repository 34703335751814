import * as React from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import { components } from 'generated/apiTypes';
import { selectIntegralCharacteristics } from 'store/taskSlice';

import { dashboardItems } from './utils';

const Dashboard: React.FC = () => {
  const integralCharacteristics = useSelector(
    selectIntegralCharacteristics,
  ) as components['schemas']['GetFactAdaptationSolutionIntegralCharacteristicQueryResult'];

  return (
    <div className="dashboard af-dashboard">
      {dashboardItems(integralCharacteristics).map((el, key) => {
        return (
          <div key={key} className={classNames('dashboard__item grow')}>
            <p className="dashboard__item-dem">{el.dem}</p>
            <div className="dashboard__bottom">
              <p className="dashboard__item-value">
                {el.value}
                &nbsp;{el.unit}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Dashboard;
