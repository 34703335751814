// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import {
  selectExperimentSettings,
  setExperimentSettings as setStoreExperimentSettings,
} from 'store/taskSlice';

import {
  getGlobalEqualityOptimizationConstraints,
  setExperimentSettings,
} from '../../../../services/apiRequests';
import ObjectInput from '../../ActiveObject/components/ObjectInput';
import ObjectSelect from '../../ActiveObject/components/ObjectSelect';
import { EqualityConstraint } from '../../types';
import { getEqualityConstraintsOptions } from '../options';

interface INonClusterConstraintsProps {
  setIncompleteConstraints(val: boolean): void;
  setExperimentSettingsSaved(val: boolean): void;
}

const NonClusterEqualityConstraint: React.FC<INonClusterConstraintsProps> = ({
  setIncompleteConstraints,
  setExperimentSettingsSaved,
}) => {
  const dispatch = useDispatch();
  const settings = useSelector(selectExperimentSettings);
  const params = useParams() as { id: string };
  const [constraintValue, setConstraintValue] = React.useState<
    EqualityConstraint | undefined
  >();
  const [constraintNumber, setConstraintNumber] = React.useState<
    number | undefined
  >();

  const [constraintsOptionsCurrent, setConstraintsOptionsCurrent] =
    React.useState<
      {
        value: EqualityConstraint;
        label: string;
        disabled: boolean;
      }[]
    >();

  React.useEffect(() => {
    if (
      settings?.optimization_experiment_settings?.constraints?.constraint
        ?.type &&
      settings?.optimization_experiment_settings?.constraints?.constraint
        ?.type !== constraintValue
    ) {
      setConstraintValue(
        settings?.optimization_experiment_settings?.constraints?.constraint
          ?.type,
      );
    }
  }, [
    settings?.optimization_experiment_settings?.constraints?.constraint?.type,
  ]);

  React.useEffect(() => {
    if (
      settings?.optimization_experiment_settings?.constraints?.constraint?.type
    ) {
      const value = [
        'SpecificPowerConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint',
        'SpecificGasliftConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint',
      ].includes(
        settings?.optimization_experiment_settings?.constraints?.constraint
          ?.type,
      )
        ? (settings?.optimization_experiment_settings?.constraints?.constraint
            ?.value as any)
        : (
            settings?.optimization_experiment_settings?.constraints?.constraint
              ?.value as any
          )?.[
            getUnitByConstraint(
              settings?.optimization_experiment_settings?.constraints
                ?.constraint?.type,
            )
          ];
      if (value !== constraintNumber) {
        setConstraintNumber(value);
      }
    }
  }, [
    settings?.optimization_experiment_settings?.constraints?.constraint?.value,
  ]);

  React.useEffect(() => {
    let optionToDisable:
      | {
          value: EqualityConstraint;
          label: string;
          disabled: boolean;
        }
      | undefined;
    switch (settings?.optimization_experiment_settings?.target_metric) {
      case 'Maximum oil debit':
        optionToDisable = getEqualityConstraintsOptions().find(
          item =>
            item.value ===
            'OilDebitEqualityNetworkOptimizationExperimentSettingsConstraint',
        );
        break;
      case 'Minimum energy consumption':
        optionToDisable = getEqualityConstraintsOptions().find(
          item =>
            item.value ===
            'EnergyConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint',
        );
        break;
      case 'Minimum active gas consumption':
        optionToDisable = getEqualityConstraintsOptions().find(
          item =>
            item.value ===
            'ActiveGasConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint',
        );
        break;
      case 'MIN_PRODUCED_FLUID_VOLUME':
        optionToDisable = getEqualityConstraintsOptions().find(
          item =>
            item.value ===
            'ProducedFluidVolumeEqualityNetworkOptimizationExperimentSettingsConstraint',
        );
        break;
      case 'MIN_PRODUCED_ASSOCIATED_PETROLEUM_GAS_VOLUME':
      case 'MAX_PRODUCED_ASSOCIATED_PETROLEUM_GAS_VOLUME':
        optionToDisable = getEqualityConstraintsOptions().find(
          item =>
            item.value ===
            'ProducedPetroleumGasVolumeEqualityNetworkOptimizationExperimentSettingsConstraint',
        );
        break;
      case 'MIN_PRODUCED_WATER_VOLUME':
        optionToDisable = getEqualityConstraintsOptions().find(
          item =>
            item.value ===
            'ProducedWaterVolumeEqualityNetworkOptimizationExperimentSettingsConstraint',
        );
        break;
      case 'MIN_SPECIFIC_POWER_CONSUMPTION':
        optionToDisable = getEqualityConstraintsOptions().find(
          item =>
            item.value ===
            'SpecificPowerConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint',
        );
        break;
      case 'MIN_SPECIFIC_GASLIFT_CONSUMPTION':
        optionToDisable = getEqualityConstraintsOptions().find(
          item =>
            item.value ===
            'SpecificGasliftConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint',
        );
        break;
      case 'MIN_TOTAL_GAS':
      case 'MAX_TOTAL_GAS':
        optionToDisable = getEqualityConstraintsOptions().find(
          item =>
            item.value ===
            'TotalGasConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint',
        );
        break;
      default:
        break;
    }
    setConstraintsOptionsCurrent(
      getEqualityConstraintsOptions().map(item => {
        if (item.value === optionToDisable?.value) {
          return { ...optionToDisable, disabled: true };
        }
        if (item.value === constraintValue) {
          const option = getEqualityConstraintsOptions().find(
            option1 => option1.value === constraintValue,
          );
          if (option) return { ...option, disabled: true };
        }
        return item;
      }),
    );
  }, [
    settings?.optimization_experiment_settings?.target_metric,
    constraintValue,
  ]);

  const tryToSaveConstraint = async () => {
    if (
      settings?.optimization_experiment_settings &&
      constraintValue &&
      constraintNumber !== undefined
    ) {
      const newValue = {
        ...settings,
        optimization_experiment_settings: {
          ...settings.optimization_experiment_settings,
          constraints: {
            type: 'GlobalEqualityNetworkOptimizationExperimentSettingsConstraints' as any,
            constraint: {
              type: constraintValue as any,
              value: getNumberValueByConstraint(
                constraintValue,
                constraintNumber,
              ) as any,
            },
          },
        },
      };
      dispatch(setStoreExperimentSettings(newValue));
      setExperimentSettingsSaved(false);
      await setExperimentSettings(params.id, newValue);
      setExperimentSettingsSaved(true);
      setIncompleteConstraints(false);
    }
  };

  const getNumberValueByConstraint = (
    constraint: EqualityConstraint,
    number: number,
  ) => {
    switch (constraint) {
      case 'OilDebitEqualityNetworkOptimizationExperimentSettingsConstraint':
        return { t_per_day: number };
      case 'EnergyConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint':
        return { kW_h_per_day: number };
      case 'ActiveGasConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint':
      case 'ProducedPetroleumGasVolumeEqualityNetworkOptimizationExperimentSettingsConstraint':
      case 'TotalGasConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint':
        return { thousand_m3_per_day: number };
      case 'ProducedFluidVolumeEqualityNetworkOptimizationExperimentSettingsConstraint':
      case 'ProducedWaterVolumeEqualityNetworkOptimizationExperimentSettingsConstraint':
        return { m3_per_day: number };
      case 'SpecificPowerConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint':
        return number;
      case 'SpecificGasliftConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint':
        return number;
      default:
        return {};
    }
  };

  const getUnitByConstraint = (constraint: EqualityConstraint) => {
    switch (constraint) {
      case 'OilDebitEqualityNetworkOptimizationExperimentSettingsConstraint':
        return 't_per_day';
      case 'EnergyConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint':
        return 'kW_h_per_day';
      case 'ActiveGasConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint':
      case 'ProducedPetroleumGasVolumeEqualityNetworkOptimizationExperimentSettingsConstraint':
      case 'TotalGasConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint':
        return 'thousand_m3_per_day';
      case 'ProducedFluidVolumeEqualityNetworkOptimizationExperimentSettingsConstraint':
      case 'ProducedWaterVolumeEqualityNetworkOptimizationExperimentSettingsConstraint':
        return 'm3_per_day';
      case 'SpecificPowerConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint':
        return '';
      case 'SpecificGasliftConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint':
        return '';
      default:
        return '';
    }
  };

  React.useEffect(() => {
    const fetchEqualityConstraints = async () => {
      let response;
      if (constraintValue) {
        response = await getGlobalEqualityOptimizationConstraints(
          params.id,
          constraintValue,
        );
      }
      return response;
    };
    if (
      settings?.optimization_experiment_settings?.constraints?.constraint
        ?.type !== constraintValue &&
      constraintValue !== undefined
    ) {
      setIncompleteConstraints(true);
      fetchEqualityConstraints().then(response => {
        if (response?.data.constraint?.type) {
          if (
            [
              'SpecificPowerConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint',
              'SpecificGasliftConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint',
            ].includes(response?.data.constraint?.type)
          ) {
            setConstraintNumber(response?.data.constraint?.value);
          } else
            setConstraintNumber(
              (response?.data.constraint?.value as any)?.[
                getUnitByConstraint(response?.data.constraint?.type)
              ],
            );
        }
        setIncompleteConstraints(false);
      });
    }
  }, [constraintValue]);

  React.useEffect(() => {
    if (constraintValue) {
      const value = [
        'SpecificPowerConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint',
        'SpecificGasliftConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint',
      ].includes(constraintValue)
        ? (settings?.optimization_experiment_settings?.constraints?.constraint
            ?.value as any)
        : (
            settings?.optimization_experiment_settings?.constraints?.constraint
              ?.value as any
          )?.[getUnitByConstraint(constraintValue)];
      if (value !== constraintNumber) {
        tryToSaveConstraint().then();
      }
    }
  }, [constraintNumber]);

  const onChangeConstraintValue = (value: EqualityConstraint) => {
    setConstraintValue(value);
  };

  const saveConstraintNumber = (value: number) => {
    setConstraintNumber(value);
  };

  const onChangeConstraintNumber = () => {
    setIncompleteConstraints(true);
  };

  const renderUnitByConstraint = (
    constraint: EqualityConstraint | undefined,
  ) => {
    switch (constraint) {
      case 'OilDebitEqualityNetworkOptimizationExperimentSettingsConstraint':
        return (
          <p className="active-object__row-input-unit">
            <span className="relative">т/сут</span>
          </p>
        );
      case 'EnergyConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint':
        return (
          <p className="active-object__row-input-unit">
            <span className="relative">кВт·ч/сут</span>
          </p>
        );
      case 'ActiveGasConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint':
      case 'ProducedPetroleumGasVolumeEqualityNetworkOptimizationExperimentSettingsConstraint':
      case 'TotalGasConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint':
        return (
          <p className="active-object__row-input-unit">
            <span className="relative">
              тыс.м<span className="index unit-small">3</span>
            </span>
            &nbsp; /сут
          </p>
        );
      case 'ProducedFluidVolumeEqualityNetworkOptimizationExperimentSettingsConstraint':
      case 'ProducedWaterVolumeEqualityNetworkOptimizationExperimentSettingsConstraint':
        return (
          <p className="active-object__row-input-unit">
            <span className="relative">
              м<span className="index unit-small">3</span>
            </span>
            &nbsp; /сут
          </p>
        );
      case 'SpecificPowerConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint':
        return (
          <p className="active-object__row-input-unit">
            <span className="relative">кВт·ч/т</span>
          </p>
        );
      case 'SpecificGasliftConsumptionEqualityNetworkOptimizationExperimentSettingsConstraint':
        return (
          <p className="active-object__row-input-unit">
            <span className="relative">
              тыс.м<span className="index unit-small">3</span>
            </span>
            &nbsp; /т
          </p>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <div className="project-task-settings__separator" />
      <div className="active-object__row">
        <ObjectSelect
          label="Ограничение"
          classNamesWrapper="full-width"
          classNames="active-object__row-input"
          name="11"
          options={constraintsOptionsCurrent || []}
          value={constraintsOptionsCurrent?.find(
            item => item.value === constraintValue,
          )}
          isOptionDisabled={(option: any) => (option as any)?.disabled}
          saveNewValue={onChangeConstraintValue}
        />
      </div>
      <div className="active-object__row">
        <div className="project-task-settings__row long active-object__input-with-unit-wrapper">
          <ObjectInput
            className="input active-object__row-input with-long-unit"
            label=""
            name="gas_constraint"
            initValue={constraintNumber}
            minValue={0}
            saveNewValue={saveConstraintNumber}
            onChangeValue={onChangeConstraintNumber}
          />
          {renderUnitByConstraint(constraintValue)}
        </div>
      </div>
    </>
  );
};

export default NonClusterEqualityConstraint;
