import * as React from 'react';
import { useSelector } from 'react-redux';
import { ValueType } from 'react-select';

import classNames from 'classnames';

import { components } from '../../../../generated/apiTypes';
import {
  selectClusters,
  selectPhysChemPackages,
} from '../../../../store/projectSlice';
import { getWellOperationType } from '../../utils';
import ObjectInput from '../components/ObjectInput';
import ObjectSelect, { OptionType } from '../components/ObjectSelect';
import ObjectStringInput from '../components/ObjectStringInput';

interface ISourceView {
  well: components['schemas']['GetWellQueryResult'] | undefined;
  wellModel: components['schemas']['GetWellModelQueryResult'] | undefined;
  wellControlModel: components['schemas']['PNOWellControlModel'] | undefined;
  saveNewWellValue(value: number | string, name: string): void;
  saveNewWellModelValue(value: number | string, name: string): void;
  waterFractionType: ValueType<OptionType<string>, false>;
  gasFractionType: ValueType<OptionType<string>, false>;
  saveNewWaterFractionType(value: ValueType<OptionType<string>, false>): void;
  saveNewGasFractionType(value: ValueType<OptionType<string>, false>): void;
}

const SourceView: React.FC<ISourceView> = ({
  well,
  wellModel,
  saveNewWellValue,
  saveNewWellModelValue,
}) => {
  const physChemPackages = useSelector(selectPhysChemPackages);
  const clusters = useSelector(selectClusters);
  const PCPackages: OptionType<string>[] = physChemPackages.map(value => {
    return {
      value: value?.uid as string,
      label: value?.name as string,
    };
  });

  const cluster = clusters?.find(el => el.id === well?.cluster_id);

  const getControlType = (type: string | undefined) => {
    switch (type) {
      case 'PermanentFountainWell':
      case 'PermanentGasliftWell':
      case 'PermanentPumpWell':
      case 'PermanentTimedWell':
      case 'ReducedPermanentAnnularFountainWell':
      case 'PermanentAnnularFountainWell':
        return 'Постоянный';
      case 'PeriodicGasliftWell':
      case 'ReducedPeriodicGasliftWell':
      case 'PeriodicPumpWell':
      case 'ReducedPeriodicPumpWell':
        return 'Периодический';
      default:
        return '';
    }
  };

  const timedWellFields = () => {
    return (
      <>
        <div className="active-object__row">
          <div className="d-flex">
            <div className="active-object__input-with-unit-wrapper left">
              <ObjectInput
                className="input active-object__row-input short with-unit"
                label="Интервал работы"
                name="work_interval__min"
                maxValue={
                  (
                    wellModel?.model as components['schemas']['GetPermanentTimedWellModelQueryResult']
                  )?.maximum_work_interval__min
                }
                minValue={
                  (
                    wellModel?.model as components['schemas']['GetPermanentTimedWellModelQueryResult']
                  )?.minimum_work_interval__min
                }
                initValue={
                  (
                    wellModel?.model as components['schemas']['GetPermanentTimedWellModelQueryResult']
                  )?.work_interval__min
                }
                saveNewValue={saveNewWellModelValue}
              />
              <p className="active-object__row-input-unit">мин</p>
            </div>
          </div>
        </div>
        <p className="active-object__subtitle limitations">
          Индивидуальные ограничения
        </p>
        <div className="active-object__row">
          <div className="d-flex">
            <div className="active-object__input-with-unit-wrapper left">
              <ObjectInput
                className={classNames(
                  'input active-object__row-input',
                  'with-unit',
                )}
                label="Мин. интервал работы"
                name="minimum_work_interval__min"
                initValue={
                  (
                    wellModel?.model as components['schemas']['GetPermanentTimedWellModelQueryResult']
                  )?.minimum_work_interval__min
                }
                saveNewValue={saveNewWellModelValue}
              />
              <p className="active-object__row-input-unit">мин</p>
            </div>
            <div className="active-object__input-with-unit-wrapper">
              <ObjectInput
                className={classNames(
                  'input active-object__row-input',
                  'with-unit',
                )}
                label="Макс. интервал работы"
                name="maximum_work_interval__min"
                initValue={
                  (
                    wellModel?.model as components['schemas']['GetPermanentTimedWellModelQueryResult']
                  )?.maximum_work_interval__min
                }
                saveNewValue={saveNewWellModelValue}
              />
              <p className="active-object__row-input-unit">мин</p>
            </div>
          </div>
        </div>
      </>
    );
  };

  const pumpGasliftWellFields = () => {
    return (
      <>
        <div className="active-object__row">
          <ObjectStringInput
            label="Режим работы"
            className="input active-object__row-input"
            initValue={getControlType(well?.type)}
            disabled
            name=""
            saveNewValue={() => {}}
          />
        </div>
        {getControlType(well?.type) !== 'Постоянный' && (
          <div className="active-object__row">
            <div className="d-flex">
              <div className="active-object__input-with-unit-wrapper left">
                <ObjectInput
                  className="input active-object__row-input short with-unit"
                  label="Интервал работы"
                  name="pumping_time__min"
                  initValue={
                    getControlType(well?.type) === 'Периодический'
                      ? (
                          wellModel?.model as components['schemas']['GetPeriodicPumpWellModelQueryResult']
                        )?.pumping_time__min
                      : -1
                  }
                  disabled
                  saveNewValue={saveNewWellModelValue}
                />
                <p className="active-object__row-input-unit">мин</p>
              </div>
              <div className="active-object__input-with-unit-wrapper">
                <ObjectInput
                  className="input active-object__row-input short with-unit"
                  label="Интервал накопления"
                  name="accumulation_time__min"
                  initValue={
                    getControlType(well?.type) === 'Периодический'
                      ? (
                          wellModel?.model as components['schemas']['GetPeriodicPumpWellModelQueryResult']
                        )?.accumulation_time__min
                      : -1
                  }
                  saveNewValue={saveNewWellModelValue}
                  disabled
                />
                <p className="active-object__row-input-unit">мин</p>
              </div>
            </div>
          </div>
        )}
        {getWellOperationType(well?.type) === 'ЭЦН' && (
          <div className="active-object__row">
            <div className="d-flex">
              <div className="active-object__input-with-unit-wrapper left">
                <ObjectInput
                  className="input active-object__row-input short with-unit"
                  label="Частота работы"
                  name="frequency__Hz"
                  maxValue={
                    (
                      wellModel?.model as components['schemas']['GetPermanentPumpWellModelQueryResult']
                    )?.maximum_frequency__Hz
                  }
                  minValue={
                    (
                      wellModel?.model as components['schemas']['GetPermanentPumpWellModelQueryResult']
                    )?.minimum_frequency__Hz
                  }
                  initValue={
                    (
                      wellModel?.model as components['schemas']['GetPermanentPumpWellModelQueryResult']
                    )?.frequency__Hz
                  }
                  saveNewValue={saveNewWellModelValue}
                  disabled={getControlType(well?.type) === 'Периодический'}
                />
                <p className="active-object__row-input-unit">Гц</p>
              </div>
              {getControlType(well?.type) !== 'Постоянный' && (
                <div className="active-object__input-with-unit-wrapper short">
                  <ObjectInput
                    className="input active-object__row-input with-unit"
                    label="Частота накопления"
                    name="accumulation_control_action"
                    unit="hertz"
                    initValue={-1}
                    saveNewValue={() => {}}
                    disabled
                  />
                  <p className="active-object__row-input-unit">Гц</p>
                </div>
              )}
            </div>
          </div>
        )}
        {getWellOperationType(well?.type) === 'Газлифт' && (
          <div className="active-object__row">
            <div className="d-flex">
              <div className="active-object__input-with-unit-wrapper left">
                <ObjectInput
                  className="input active-object__row-input short with-long-unit"
                  label="Расход АГ работы"
                  name="gaslift_flow__thos_m3_per_day"
                  maxValue={
                    (
                      wellModel?.model as components['schemas']['GetPermanentGasliftWellModelQueryResult']
                    )?.maximum_gaslift_flow__thos_m3_per_day
                  }
                  minValue={
                    (
                      wellModel?.model as components['schemas']['GetPermanentGasliftWellModelQueryResult']
                    )?.minimum_gaslift_flow__thos_m3_per_day
                  }
                  initValue={
                    (
                      wellModel?.model as components['schemas']['GetPermanentGasliftWellModelQueryResult']
                    )?.gaslift_flow__thos_m3_per_day
                  }
                  saveNewValue={saveNewWellModelValue}
                  disabled={getControlType(well?.type) === 'Периодический'}
                />
                <p className="active-object__row-input-unit">
                  <span className="relative">
                    тыс.м<span className="index unit-small">3</span>
                  </span>
                  &nbsp; /сут
                </p>
              </div>
              {getControlType(well?.type) !== 'Постоянный' && (
                <div className="active-object__input-with-unit-wrapper short">
                  <ObjectInput
                    className="input active-object__row-input with-long-unit"
                    label="Расход АГ накопления"
                    name=""
                    initValue={-1}
                    saveNewValue={() => {}}
                    disabled
                  />
                  <p className="active-object__row-input-unit">
                    <span className="relative">
                      тыс.м<span className="index unit-small">3</span>
                    </span>
                    &nbsp; /сут
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
        {getControlType(well?.type) !== 'Постоянный' && (
          <div className="active-object__row">
            <div className="active-object__input-with-unit-wrapper short">
              <ObjectInput
                className="input active-object__row-input short with-unit"
                label="Офсет"
                name="offset_time__min"
                initValue={
                  getControlType(well?.type) === 'Периодический'
                    ? (
                        wellModel?.model as components['schemas']['GetPeriodicGasliftWellModelQueryResult']
                      )?.offset_time__min
                    : -1
                }
                saveNewValue={saveNewWellModelValue}
                disabled={getControlType(well?.type) !== 'Периодический'}
              />
              <p className="active-object__row-input-unit">мин</p>
            </div>
          </div>
        )}
        <p className="active-object__subtitle limitations">
          Индивидуальные ограничения
        </p>
        <div className="active-object__row">
          <div className="d-flex">
            <div className="active-object__input-with-unit-wrapper left">
              <ObjectInput
                className={classNames(
                  'input active-object__row-input',
                  getWellOperationType(well?.type) === 'ЭЦН'
                    ? 'with-unit'
                    : 'with-long-unit',
                )}
                label={
                  getWellOperationType(well?.type) === 'ЭЦН'
                    ? 'Мин. частота'
                    : 'Мин. расход АГ'
                }
                name={
                  getWellOperationType(well?.type) === 'ЭЦН'
                    ? 'minimum_frequency__Hz'
                    : 'minimum_gaslift_flow__thos_m3_per_day'
                }
                initValue={
                  getWellOperationType(well?.type) === 'ЭЦН'
                    ? (
                        wellModel?.model as components['schemas']['GetPeriodicPumpWellModelQueryResult']
                      )?.minimum_frequency__Hz
                    : (
                        wellModel?.model as components['schemas']['GetPeriodicGasliftWellModelQueryResult']
                      )?.minimum_gaslift_flow__thos_m3_per_day
                }
                saveNewValue={saveNewWellModelValue}
              />
              <p className="active-object__row-input-unit">
                {getWellOperationType(well?.type) === 'ЭЦН' ? (
                  <>Гц</>
                ) : (
                  <>
                    <span className="relative">
                      тыс.м<span className="index unit-small">3</span>
                    </span>
                    &nbsp; /сут
                  </>
                )}
              </p>
            </div>
            <div className="active-object__input-with-unit-wrapper">
              <ObjectInput
                className={classNames(
                  'input active-object__row-input',
                  getWellOperationType(well?.type) === 'ЭЦН'
                    ? 'with-unit'
                    : 'with-long-unit',
                )}
                label={
                  getWellOperationType(well?.type) === 'ЭЦН'
                    ? 'Макс. частота'
                    : 'Макс. расход АГ'
                }
                name={
                  getWellOperationType(well?.type) === 'ЭЦН'
                    ? 'maximum_frequency__Hz'
                    : 'maximum_gaslift_flow__thos_m3_per_day'
                }
                initValue={
                  getWellOperationType(well?.type) === 'ЭЦН'
                    ? (
                        wellModel?.model as components['schemas']['GetPeriodicPumpWellModelQueryResult']
                      )?.maximum_frequency__Hz
                    : (
                        wellModel?.model as components['schemas']['GetPeriodicGasliftWellModelQueryResult']
                      )?.maximum_gaslift_flow__thos_m3_per_day
                }
                saveNewValue={saveNewWellModelValue}
              />
              <p className="active-object__row-input-unit">
                {getWellOperationType(well?.type) === 'ЭЦН' ? (
                  <>Гц</>
                ) : (
                  <>
                    <span className="relative">
                      тыс.м<span className="index unit-small">3</span>
                    </span>
                    &nbsp; /сут
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
      </>
    );
  };

  const pumpGaslift5dWellFields = () => {
    return (
      <>
        <div className="active-object__row">
          <div className="active-object__input-with-unit-wrapper short">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Офсет"
              name="offset__min"
              initValue={
                (
                  wellModel?.model as components['schemas']['GetPump5dWellModelQueryResult']
                )?.offset__min
              }
              saveNewValue={saveNewWellModelValue}
              disabled
            />
            <p className="active-object__row-input-unit">мин</p>
          </div>
        </div>
        {getWellOperationType(well?.type) === '5D_ЭЦН' && (
          <>
            <p className="active-object__subtitle limitations">
              Частота работы
            </p>
            <div className="active-object__row">
              <div className="d-flex">
                <div className="active-object__input-with-unit-wrapper left">
                  <ObjectInput
                    className={classNames(
                      'input active-object__row-input',
                      'with-unit',
                    )}
                    label="Минимальная"
                    name="min_work_frequency__Hz"
                    initValue={
                      (
                        wellModel?.model as components['schemas']['GetPump5dWellModelQueryResult']
                      )?.min_work_frequency__Hz
                    }
                    saveNewValue={saveNewWellModelValue}
                    disabled
                  />
                  <p className="active-object__row-input-unit">Гц</p>
                </div>
                <div className="active-object__input-with-unit-wrapper">
                  <ObjectInput
                    className={classNames(
                      'input active-object__row-input',
                      'with-unit',
                    )}
                    label="Максимальная"
                    name="max_work_frequency__Hz"
                    initValue={
                      (
                        wellModel?.model as components['schemas']['GetPump5dWellModelQueryResult']
                      )?.max_work_frequency__Hz
                    }
                    disabled
                    saveNewValue={saveNewWellModelValue}
                  />
                  <p className="active-object__row-input-unit">Гц</p>
                </div>
              </div>
            </div>
            <div className="active-object__row">
              <div className="active-object__input-with-unit-wrapper short">
                <ObjectInput
                  className="input active-object__row-input short with-unit"
                  label="Текущая"
                  name="work_frequency__Hz"
                  initValue={
                    (
                      wellModel?.model as components['schemas']['GetPump5dWellModelQueryResult']
                    )?.work_frequency__Hz
                  }
                  saveNewValue={saveNewWellModelValue}
                />
                <p className="active-object__row-input-unit">Гц</p>
              </div>
            </div>
            <div className="project-task-settings__separator" />
            <p className="active-object__subtitle limitations">
              Частота накопления
            </p>
            <div className="active-object__row">
              <div className="d-flex">
                <div className="active-object__input-with-unit-wrapper left">
                  <ObjectInput
                    className={classNames(
                      'input active-object__row-input',
                      'with-unit',
                    )}
                    label="Минимальная"
                    name="min_accumulation_frequency__Hz"
                    initValue={
                      (
                        wellModel?.model as components['schemas']['GetPump5dWellModelQueryResult']
                      )?.min_accumulation_frequency__Hz
                    }
                    saveNewValue={saveNewWellModelValue}
                    disabled
                  />
                  <p className="active-object__row-input-unit">Гц</p>
                </div>
                <div className="active-object__input-with-unit-wrapper">
                  <ObjectInput
                    className={classNames(
                      'input active-object__row-input',
                      'with-unit',
                    )}
                    label="Максимальная"
                    name="max_accumulation_frequency__Hz"
                    initValue={
                      (
                        wellModel?.model as components['schemas']['GetPump5dWellModelQueryResult']
                      )?.max_accumulation_frequency__Hz
                    }
                    disabled
                    saveNewValue={saveNewWellModelValue}
                  />
                  <p className="active-object__row-input-unit">Гц</p>
                </div>
              </div>
            </div>
            <div className="active-object__row">
              <div className="active-object__input-with-unit-wrapper short">
                <ObjectInput
                  className="input active-object__row-input short with-unit"
                  label="Текущая"
                  name="accumulation_frequency__Hz"
                  initValue={
                    (
                      wellModel?.model as components['schemas']['GetPump5dWellModelQueryResult']
                    )?.accumulation_frequency__Hz
                  }
                  saveNewValue={saveNewWellModelValue}
                />
                <p className="active-object__row-input-unit">Гц</p>
              </div>
            </div>
            <div className="project-task-settings__separator" />{' '}
          </>
        )}
        {getWellOperationType(well?.type) === '5D_Газлифт' && (
          <>
            <p className="active-object__subtitle limitations">
              Расход АГ работы
            </p>
            <div className="active-object__row">
              <div className="d-flex">
                <div className="active-object__input-with-unit-wrapper left">
                  <ObjectInput
                    className={classNames(
                      'input active-object__row-input',
                      'with-unit',
                    )}
                    label="Минимальный"
                    name="min_work_gaslift_flow__thos_m3_per_day"
                    initValue={
                      (
                        wellModel?.model as components['schemas']['GetGaslift5dWellModelQueryResult']
                      )?.min_work_gaslift_flow__thos_m3_per_day
                    }
                    saveNewValue={saveNewWellModelValue}
                    disabled
                  />
                  <p className="active-object__row-input-unit">
                    <span className="relative">
                      тыс.м<span className="index unit-small">3</span>
                    </span>
                    &nbsp; /сут
                  </p>
                </div>
                <div className="active-object__input-with-unit-wrapper">
                  <ObjectInput
                    className={classNames(
                      'input active-object__row-input',
                      'with-unit',
                    )}
                    label="Максимальный"
                    name="max_work_gaslift_flow__thos_m3_per_day"
                    initValue={
                      (
                        wellModel?.model as components['schemas']['GetGaslift5dWellModelQueryResult']
                      )?.max_work_gaslift_flow__thos_m3_per_day
                    }
                    saveNewValue={saveNewWellModelValue}
                    disabled
                  />
                  <p className="active-object__row-input-unit">
                    <span className="relative">
                      тыс.м<span className="index unit-small">3</span>
                    </span>
                    &nbsp; /сут
                  </p>
                </div>
              </div>
            </div>
            <div className="active-object__row">
              <div className="active-object__input-with-unit-wrapper short">
                <ObjectInput
                  className="input active-object__row-input short with-unit"
                  label="Текущий"
                  name="work_gaslift_flow__thos_m3_per_day"
                  initValue={
                    (
                      wellModel?.model as components['schemas']['GetGaslift5dWellModelQueryResult']
                    )?.work_gaslift_flow__thos_m3_per_day
                  }
                  saveNewValue={saveNewWellModelValue}
                />
                <p className="active-object__row-input-unit">
                  <span className="relative">
                    тыс.м<span className="index unit-small">3</span>
                  </span>
                  &nbsp; /сут
                </p>
              </div>
            </div>
            <div className="project-task-settings__separator" />
            <p className="active-object__subtitle limitations">
              Расход АГ накопления
            </p>
            <div className="active-object__row">
              <div className="d-flex">
                <div className="active-object__input-with-unit-wrapper left">
                  <ObjectInput
                    className={classNames(
                      'input active-object__row-input',
                      'with-unit',
                    )}
                    label="Минимальный"
                    name="min_accumulation_gaslift_flow__thos_m3_per_day"
                    initValue={
                      (
                        wellModel?.model as components['schemas']['GetGaslift5dWellModelQueryResult']
                      )?.min_accumulation_gaslift_flow__thos_m3_per_day
                    }
                    saveNewValue={saveNewWellModelValue}
                    disabled
                  />
                  <p className="active-object__row-input-unit">
                    <span className="relative">
                      тыс.м<span className="index unit-small">3</span>
                    </span>
                    &nbsp; /сут
                  </p>
                </div>
                <div className="active-object__input-with-unit-wrapper">
                  <ObjectInput
                    className={classNames(
                      'input active-object__row-input',
                      'with-unit',
                    )}
                    label="Максимальный"
                    name="max_accumulation_gaslift_flow__thos_m3_per_day"
                    initValue={
                      (
                        wellModel?.model as components['schemas']['GetGaslift5dWellModelQueryResult']
                      )?.max_accumulation_gaslift_flow__thos_m3_per_day
                    }
                    disabled
                    saveNewValue={saveNewWellModelValue}
                  />
                  <p className="active-object__row-input-unit">
                    <span className="relative">
                      тыс.м<span className="index unit-small">3</span>
                    </span>
                    &nbsp; /сут
                  </p>
                </div>
              </div>
            </div>
            <div className="active-object__row">
              <div className="active-object__input-with-unit-wrapper short">
                <ObjectInput
                  className="input active-object__row-input short with-unit"
                  label="Текущий"
                  name="accumulation_gaslift_flow__thos_m3_per_day"
                  initValue={
                    (
                      wellModel?.model as components['schemas']['GetGaslift5dWellModelQueryResult']
                    )?.accumulation_gaslift_flow__thos_m3_per_day
                  }
                  saveNewValue={saveNewWellModelValue}
                />
                <p className="active-object__row-input-unit">
                  <span className="relative">
                    тыс.м<span className="index unit-small">3</span>
                  </span>
                  &nbsp; /сут
                </p>
              </div>
            </div>
            <div className="project-task-settings__separator" />
          </>
        )}
        <p className="active-object__subtitle well">
          Длительность интервала работы
        </p>
        <div className="active-object__row">
          <div className="d-flex">
            <div className="active-object__input-with-unit-wrapper left">
              <ObjectInput
                className={classNames(
                  'input active-object__row-input',
                  'with-unit',
                )}
                label="Минимальная"
                name="min_work_time__min"
                initValue={
                  (
                    wellModel?.model as components['schemas']['GetPump5dWellModelQueryResult']
                  )?.min_work_time__min
                }
                saveNewValue={saveNewWellModelValue}
                disabled
              />
              <p className="active-object__row-input-unit">мин</p>
            </div>
            <div className="active-object__input-with-unit-wrapper">
              <ObjectInput
                className={classNames(
                  'input active-object__row-input',
                  'with-unit',
                )}
                label="Максимальная"
                name="max_work_time__min"
                initValue={
                  (
                    wellModel?.model as components['schemas']['GetPump5dWellModelQueryResult']
                  )?.max_work_time__min
                }
                disabled
                saveNewValue={saveNewWellModelValue}
              />
              <p className="active-object__row-input-unit">мин</p>
            </div>
          </div>
        </div>
        <div className="active-object__row">
          <div className="active-object__input-with-unit-wrapper short">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Текущая"
              name="work_time__min"
              initValue={
                (
                  wellModel?.model as components['schemas']['GetPump5dWellModelQueryResult']
                )?.work_time__min
              }
              saveNewValue={saveNewWellModelValue}
            />
            <p className="active-object__row-input-unit">мин</p>
          </div>
        </div>
        <div className="project-task-settings__separator" />
        <p className="active-object__subtitle well">
          Длительность интервала накопления
        </p>
        <div className="active-object__row">
          <div className="d-flex">
            <div className="active-object__input-with-unit-wrapper left">
              <ObjectInput
                className={classNames(
                  'input active-object__row-input',
                  'with-unit',
                )}
                label="Минимальная"
                name="min_accumulation_time__min"
                initValue={
                  (
                    wellModel?.model as components['schemas']['GetPump5dWellModelQueryResult']
                  )?.min_accumulation_time__min
                }
                saveNewValue={saveNewWellModelValue}
                disabled
              />
              <p className="active-object__row-input-unit">мин</p>
            </div>
            <div className="active-object__input-with-unit-wrapper">
              <ObjectInput
                className={classNames(
                  'input active-object__row-input',
                  'with-unit',
                )}
                label="Максимальная"
                name="max_accumulation_time__min"
                initValue={
                  (
                    wellModel?.model as components['schemas']['GetPump5dWellModelQueryResult']
                  )?.max_accumulation_time__min
                }
                disabled
                saveNewValue={saveNewWellModelValue}
              />
              <p className="active-object__row-input-unit">мин</p>
            </div>
          </div>
        </div>
        <div className="active-object__row">
          <div className="active-object__input-with-unit-wrapper short">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Текущая"
              name="accumulation_time__min"
              initValue={
                (
                  wellModel?.model as components['schemas']['GetPump5dWellModelQueryResult']
                )?.accumulation_time__min
              }
              saveNewValue={saveNewWellModelValue}
            />
            <p className="active-object__row-input-unit">мин</p>
          </div>
        </div>
      </>
    );
  };

  const gaslift5dReducedWellFields = () => {
    return (
      <>
        <div className="active-object__row">
          <ObjectStringInput
            label="Режим работы"
            className="input active-object__row-input"
            initValue={getControlType(well?.type)}
            disabled
            name=""
            saveNewValue={() => {}}
          />
        </div>
        <div className="active-object__row">
          <div className="d-flex">
            <div className="active-object__input-with-unit-wrapper left">
              <ObjectInput
                className={classNames(
                  'input active-object__row-input',
                  'with-unit',
                )}
                label="Расход АГ работы"
                name="gaslift_flow__thos_m3_per_day"
                initValue={
                  (
                    wellModel?.model as components['schemas']['GetReducedPeriodicGasliftWellModelQueryResult']
                  )?.gaslift_flow__thos_m3_per_day
                }
                saveNewValue={saveNewWellModelValue}
                disabled
              />
              <p className="active-object__row-input-unit">
                <span className="relative">
                  тыс.м<span className="index unit-small">3</span>
                </span>
                &nbsp; /сут
              </p>
            </div>
            <div className="active-object__input-with-unit-wrapper">
              <ObjectInput
                className={classNames(
                  'input active-object__row-input',
                  'with-unit',
                )}
                label="Интервал работы"
                name="pumping_time__min"
                initValue={
                  (
                    wellModel?.model as components['schemas']['GetReducedPeriodicGasliftWellModelQueryResult']
                  )?.pumping_time__min
                }
                saveNewValue={saveNewWellModelValue}
                disabled
              />
              <p className="active-object__row-input-unit">мин</p>
            </div>
          </div>
        </div>
        <div className="active-object__row">
          <div className="active-object__input-with-unit-wrapper short">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Офсет"
              name="offset_time__min"
              initValue={
                (
                  wellModel?.model as components['schemas']['GetReducedPeriodicGasliftWellModelQueryResult']
                )?.offset_time__min
              }
              saveNewValue={saveNewWellModelValue}
            />
            <p className="active-object__row-input-unit">мин</p>
          </div>
        </div>
        <div className="project-task-settings__separator" />
        <p className="active-object__subtitle well">Интервал накопления</p>
        <div className="active-object__row">
          <div className="d-flex">
            <div className="active-object__input-with-unit-wrapper left">
              <ObjectInput
                className={classNames(
                  'input active-object__row-input',
                  'with-unit',
                )}
                label="Минимальный"
                name="minimum_accumulation_time__min"
                initValue={
                  (
                    wellModel?.model as components['schemas']['GetReducedPeriodicGasliftWellModelQueryResult']
                  )?.minimum_accumulation_time__min
                }
                saveNewValue={saveNewWellModelValue}
              />
              <p className="active-object__row-input-unit">мин</p>
            </div>
            <div className="active-object__input-with-unit-wrapper">
              <ObjectInput
                className={classNames(
                  'input active-object__row-input',
                  'with-unit',
                )}
                label="Максимальный"
                name="maximum_accumulation_time__min"
                initValue={
                  (
                    wellModel?.model as components['schemas']['GetReducedPeriodicGasliftWellModelQueryResult']
                  )?.maximum_accumulation_time__min
                }
                saveNewValue={saveNewWellModelValue}
              />
              <p className="active-object__row-input-unit">мин</p>
            </div>
          </div>
        </div>
        <div className="active-object__row">
          <div className="active-object__input-with-unit-wrapper short">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Текущий"
              name="accumulation_time__min"
              initValue={
                (
                  wellModel?.model as components['schemas']['GetReducedPeriodicGasliftWellModelQueryResult']
                )?.accumulation_time__min
              }
              saveNewValue={saveNewWellModelValue}
            />
            <p className="active-object__row-input-unit">мин</p>
          </div>
        </div>
      </>
    );
  };

  const pump5dReducedWellFields = () => {
    return (
      <>
        <div className="active-object__row">
          <ObjectStringInput
            label="Режим работы"
            className="input active-object__row-input"
            initValue={getControlType(well?.type)}
            disabled
            name=""
            saveNewValue={() => {}}
          />
        </div>
        <div className="active-object__row">
          <div className="d-flex">
            <div className="active-object__input-with-unit-wrapper left">
              <ObjectInput
                className={classNames(
                  'input active-object__row-input',
                  'with-unit',
                )}
                label="Частота работы"
                name="frequency__Hz"
                initValue={
                  (
                    wellModel?.model as components['schemas']['GetReducedPeriodicPumpWellModelQueryResult']
                  )?.frequency__Hz
                }
                saveNewValue={saveNewWellModelValue}
                disabled
              />
              <p className="active-object__row-input-unit">Гц</p>
            </div>
            <div className="active-object__input-with-unit-wrapper">
              <ObjectInput
                className={classNames(
                  'input active-object__row-input',
                  'with-unit',
                )}
                label="Интервал накопления"
                name="accumulation_time__min"
                initValue={
                  (
                    wellModel?.model as components['schemas']['GetReducedPeriodicPumpWellModelQueryResult']
                  )?.accumulation_time__min
                }
                saveNewValue={saveNewWellModelValue}
                disabled
              />
              <p className="active-object__row-input-unit">мин</p>
            </div>
          </div>
        </div>
        <div className="active-object__row">
          <div className="d-flex">
            <div className="active-object__input-with-unit-wrapper short">
              <ObjectInput
                className="input active-object__row-input short with-unit"
                label="Офсет"
                name="offset_time__min"
                initValue={
                  (
                    wellModel?.model as components['schemas']['GetReducedPeriodicPumpWellModelQueryResult']
                  )?.offset_time__min
                }
                saveNewValue={saveNewWellModelValue}
              />
              <p className="active-object__row-input-unit">мин</p>
            </div>
          </div>
        </div>
        <div className="project-task-settings__separator" />
        <p className="active-object__subtitle well">Интервал работы</p>
        <div className="active-object__row">
          <div className="d-flex">
            <div className="active-object__input-with-unit-wrapper left">
              <ObjectInput
                className={classNames(
                  'input active-object__row-input',
                  'with-unit',
                )}
                label="Минимальный"
                name="minimum_pumping_time__min"
                initValue={
                  (
                    wellModel?.model as components['schemas']['GetReducedPeriodicPumpWellModelQueryResult']
                  )?.minimum_pumping_time__min
                }
                saveNewValue={saveNewWellModelValue}
              />
              <p className="active-object__row-input-unit">мин</p>
            </div>
            <div className="active-object__input-with-unit-wrapper">
              <ObjectInput
                className={classNames(
                  'input active-object__row-input',
                  'with-unit',
                )}
                label="Максимальный"
                name="maximum_pumping_time__min"
                initValue={
                  (
                    wellModel?.model as components['schemas']['GetReducedPeriodicPumpWellModelQueryResult']
                  )?.maximum_pumping_time__min
                }
                saveNewValue={saveNewWellModelValue}
              />
              <p className="active-object__row-input-unit">мин</p>
            </div>
          </div>
        </div>
        <div className="active-object__row">
          <div className="active-object__input-with-unit-wrapper short">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Текущий"
              name="work_time__min"
              initValue={
                (
                  wellModel?.model as components['schemas']['GetReducedPeriodicPumpWellModelQueryResult']
                )?.pumping_time__min
              }
              saveNewValue={saveNewWellModelValue}
            />
            <p className="active-object__row-input-unit">мин</p>
          </div>
        </div>
      </>
    );
  };

  const fountainHoleClearanceWellFields = () => {
    return (
      <>
        <div className="active-object__row">
          <ObjectStringInput
            label="Режим работы"
            className="input active-object__row-input"
            initValue={getControlType(well?.type)}
            disabled
            name=""
            saveNewValue={() => {}}
          />
        </div>
        <div className="project-task-settings__separator" />
        <p className="active-object__subtitle well">Частота работы</p>
        <div className="active-object__row">
          <div className="d-flex">
            <div className="active-object__input-with-unit-wrapper left">
              <ObjectInput
                className={classNames(
                  'input active-object__row-input',
                  'with-unit',
                )}
                label="Минимальная"
                name="min_frequency__Hz"
                initValue={
                  (
                    wellModel?.model as components['schemas']['GetPermanentAnnularFountainWellModelQueryResult']
                  )?.min_frequency__Hz
                }
                saveNewValue={saveNewWellModelValue}
              />
              <p className="active-object__row-input-unit">Гц</p>
            </div>
            <div className="active-object__input-with-unit-wrapper">
              <ObjectInput
                className={classNames(
                  'input active-object__row-input',
                  'with-unit',
                )}
                label="Максимальная"
                name="max_frequency__Hz"
                initValue={
                  (
                    wellModel?.model as components['schemas']['GetPermanentAnnularFountainWellModelQueryResult']
                  )?.max_frequency__Hz
                }
                saveNewValue={saveNewWellModelValue}
              />
              <p className="active-object__row-input-unit">Гц</p>
            </div>
          </div>
        </div>
        <div className="active-object__row">
          <div className="active-object__input-with-unit-wrapper short">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Текущая"
              name="frequency__Hz"
              initValue={
                (
                  wellModel?.model as components['schemas']['GetPermanentAnnularFountainWellModelQueryResult']
                )?.frequency__Hz
              }
              saveNewValue={saveNewWellModelValue}
            />
            <p className="active-object__row-input-unit">Гц</p>
          </div>
        </div>
        <div className="project-task-settings__separator" />
        <p className="active-object__subtitle well">
          Перепад затрубного давления
        </p>
        <div className="active-object__row">
          <div className="d-flex">
            <div className="active-object__input-with-unit-wrapper left">
              <ObjectInput
                className={classNames(
                  'input active-object__row-input',
                  'with-unit',
                )}
                label="Минимальный"
                name="min_annular_pressure_drop__atm"
                initValue={
                  (
                    wellModel?.model as components['schemas']['GetPermanentAnnularFountainWellModelQueryResult']
                  )?.min_annular_pressure_drop__atm
                }
                saveNewValue={saveNewWellModelValue}
              />
              <p className="active-object__row-input-unit">атм</p>
            </div>
            <div className="active-object__input-with-unit-wrapper">
              <ObjectInput
                className={classNames(
                  'input active-object__row-input',
                  'with-unit',
                )}
                label="Максимальный"
                name="max_annular_pressure_drop__atm"
                initValue={
                  (
                    wellModel?.model as components['schemas']['GetPermanentAnnularFountainWellModelQueryResult']
                  )?.max_annular_pressure_drop__atm
                }
                saveNewValue={saveNewWellModelValue}
              />
              <p className="active-object__row-input-unit">атм</p>
            </div>
          </div>
        </div>
        <div className="active-object__row">
          <div className="active-object__input-with-unit-wrapper short">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Текущий"
              name="annular_pressure_drop__atmg"
              initValue={
                (
                  wellModel?.model as components['schemas']['GetPermanentAnnularFountainWellModelQueryResult']
                )?.annular_pressure_drop__atmg
              }
              saveNewValue={saveNewWellModelValue}
            />
            <p className="active-object__row-input-unit">атм</p>
          </div>
        </div>
        <div className="project-task-settings__separator" />
        <p className="active-object__subtitle well">
          Перепад буферного давления
        </p>
        <div className="active-object__row">
          <div className="d-flex">
            <div className="active-object__input-with-unit-wrapper left">
              <ObjectInput
                className={classNames(
                  'input active-object__row-input',
                  'with-unit',
                )}
                label="Минимальный"
                name="min_well_head_pressure_drop__atmg"
                initValue={
                  (
                    wellModel?.model as components['schemas']['GetPermanentAnnularFountainWellModelQueryResult']
                  )?.min_well_head_pressure_drop__atmg
                }
                saveNewValue={saveNewWellModelValue}
              />
              <p className="active-object__row-input-unit">атм</p>
            </div>
            <div className="active-object__input-with-unit-wrapper">
              <ObjectInput
                className={classNames(
                  'input active-object__row-input',
                  'with-unit',
                )}
                label="Максимальный"
                name="max_well_head_pressure_drop__atmg"
                initValue={
                  (
                    wellModel?.model as components['schemas']['GetPermanentAnnularFountainWellModelQueryResult']
                  )?.max_well_head_pressure_drop__atmg
                }
                saveNewValue={saveNewWellModelValue}
              />
              <p className="active-object__row-input-unit">атм</p>
            </div>
          </div>
        </div>
        <div className="active-object__row">
          <div className="active-object__input-with-unit-wrapper short">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Текущий"
              name="well_head_pressure_drop__atmg"
              initValue={
                (
                  wellModel?.model as components['schemas']['GetPermanentAnnularFountainWellModelQueryResult']
                )?.well_head_pressure_drop__atmg
              }
              saveNewValue={saveNewWellModelValue}
            />
            <p className="active-object__row-input-unit">атм</p>
          </div>
        </div>
      </>
    );
  };

  const fountainHoleClearanceReduceWellFields = () => {
    return (
      <>
        <div className="active-object__row">
          <ObjectStringInput
            label="Режим работы"
            className="input active-object__row-input"
            initValue={getControlType(well?.type)}
            disabled
            name=""
            saveNewValue={() => {}}
          />
        </div>
        <div className="active-object__row">
          <div className="d-flex">
            <div className="active-object__input-with-unit-wrapper left">
              <ObjectInput
                className={classNames(
                  'input active-object__row-input',
                  'with-unit',
                )}
                label="Частота работы"
                name="frequency__Hz"
                disabled
                initValue={
                  (
                    wellModel?.model as components['schemas']['GetReducedPermanentAnnularFountainWellModelQueryResult']
                  )?.frequency__Hz
                }
                saveNewValue={saveNewWellModelValue}
              />
              <p className="active-object__row-input-unit">Гц</p>
            </div>
            <div className="active-object__input-with-unit-wrapper">
              <ObjectInput
                className={classNames(
                  'input active-object__row-input',
                  'with-unit',
                )}
                label="Перепад буф. давления"
                name="well_head_pressure_drop__atmg"
                disabled
                initValue={
                  (
                    wellModel?.model as components['schemas']['GetReducedPermanentAnnularFountainWellModelQueryResult']
                  )?.well_head_pressure_drop__atmg
                }
                saveNewValue={saveNewWellModelValue}
              />
              <p className="active-object__row-input-unit">атм</p>
            </div>
          </div>
        </div>
        <div className="project-task-settings__separator" />
        <p className="active-object__subtitle well">
          Перепад затрубного давления
        </p>
        <div className="active-object__row">
          <div className="d-flex">
            <div className="active-object__input-with-unit-wrapper left">
              <ObjectInput
                className={classNames(
                  'input active-object__row-input',
                  'with-unit',
                )}
                label="Минимальный"
                name="min_annular_pressure_drop__atm"
                initValue={
                  (
                    wellModel?.model as components['schemas']['GetReducedPermanentAnnularFountainWellModelQueryResult']
                  )?.min_annular_pressure_drop__atm
                }
                saveNewValue={saveNewWellModelValue}
              />
              <p className="active-object__row-input-unit">атм</p>
            </div>
            <div className="active-object__input-with-unit-wrapper">
              <ObjectInput
                className={classNames(
                  'input active-object__row-input',
                  'with-unit',
                )}
                label="Максимальный"
                name="max_annular_pressure_drop__atm"
                initValue={
                  (
                    wellModel?.model as components['schemas']['GetReducedPermanentAnnularFountainWellModelQueryResult']
                  )?.max_annular_pressure_drop__atm
                }
                saveNewValue={saveNewWellModelValue}
              />
              <p className="active-object__row-input-unit">атм</p>
            </div>
          </div>
        </div>
        <div className="active-object__row">
          <div className="active-object__input-with-unit-wrapper short">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Текущий"
              name="annular_pressure_drop__atmg"
              initValue={
                (
                  wellModel?.model as components['schemas']['GetReducedPermanentAnnularFountainWellModelQueryResult']
                )?.annular_pressure_drop__atmg
              }
              saveNewValue={saveNewWellModelValue}
            />
            <p className="active-object__row-input-unit">атм</p>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="active-object__row">
        <ObjectStringInput
          label="Способ эксплуатации"
          className="input active-object__row-input"
          initValue={getWellOperationType(well?.type)}
          disabled
          name=""
          saveNewValue={() => {}}
        />
      </div>
      <div className="active-object__row">
        <div className="d-flex">
          <>
            <div className="active-object__input-with-unit-wrapper left">
              <ObjectInput
                className="input active-object__row-input short with-unit"
                label="Газовый фактор"
                name="gas_factor__m3_per_t"
                initValue={(wellModel?.model as any)?.gas_factor__m3_per_t}
                saveNewValue={saveNewWellModelValue}
                disabled
              />
              <p className="active-object__row-input-unit">
                <span className="relative">
                  м<span className="index unit-small">3</span>
                </span>
                &nbsp; /т
              </p>
            </div>
          </>
          <div className="active-object__input-with-unit-wrapper">
            <>
              <ObjectInput
                className="input active-object__row-input short with-unit"
                label="Обводненность"
                name="watercut__percent"
                maxValue={99.999}
                initValue={wellModel?.model?.watercut__percent}
                saveNewValue={saveNewWellModelValue}
                disabled
              />
              <p className="active-object__row-input-unit">%</p>
            </>
          </div>
        </div>
      </div>
      <div className="active-object__row">
        <div className="d-flex">
          <div className="active-object__input-with-unit-wrapper left short">
            <ObjectInput
              className="input active-object__row-input with-unit"
              label="Температура смеси"
              name="temperature__degC"
              initValue={wellModel?.model?.temperature__degC}
              saveNewValue={saveNewWellModelValue}
              disabled
            />
            <p className="active-object__row-input-unit">˚C</p>
          </div>
          <div className="active-object__input-with-unit-wrapper short">
            <ObjectSelect
              label="Пакет ФХС"
              classNames="active-object__row-input"
              classNamesWrapper="active-object__row-input-wrapper wide"
              name="phys_chem_package_id"
              options={PCPackages}
              value={PCPackages?.find(
                el => el.value === well?.phys_chem_package_id,
              )}
              saveNewValue={saveNewWellValue}
              disabled
            />
          </div>
        </div>
      </div>
      {['ЭЦН', 'Газлифт'].includes(
        getWellOperationType(well?.type) as string,
      ) && pumpGasliftWellFields()}
      {['Время'].includes(getWellOperationType(well?.type) as string) &&
        timedWellFields()}
      {['5D_ЭЦН'].includes(getWellOperationType(well?.type) as string) &&
        pumpGaslift5dWellFields()}
      {['5D_Газлифт'].includes(getWellOperationType(well?.type) as string) &&
        pumpGaslift5dWellFields()}
      {['Газлифт редуц.'].includes(
        getWellOperationType(well?.type) as string,
      ) && gaslift5dReducedWellFields()}
      {['ЭЦН редуц.'].includes(getWellOperationType(well?.type) as string) &&
        pump5dReducedWellFields()}
      {['ФЧЗ'].includes(getWellOperationType(well?.type) as string) &&
        fountainHoleClearanceWellFields()}
      {['ФЧЗ редуц.'].includes(getWellOperationType(well?.type) as string) &&
        fountainHoleClearanceReduceWellFields()}
      {clusters?.length > 0 && cluster?.id && (
        <div className="active-object__row">
          <ObjectSelect
            label="Кластер"
            classNamesWrapper=""
            classNames="active-object__row-input"
            name="cluster_id"
            options={
              clusters?.map(item => {
                return { label: item.name, value: item.id };
              }) || []
            }
            value={
              cluster ? { label: cluster.name, value: cluster.id } : undefined
            }
            saveNewValue={saveNewWellValue}
            disabled
          />
        </div>
      )}
    </>
  );
};

export default SourceView;
