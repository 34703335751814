import * as React from 'react';

import classNames from 'classnames';
import { components } from 'generated/apiTypes';

import ObjectInput from '../components/ObjectInput';

interface IPeriodGasSourceViewAFProps {
  props?: components['schemas']['GetPeriodicGasliftWellFactStateQueryResult'];
  saveWellFactValue(value: number, name: string): void;
}
export const PeriodGasSourceViewAF: React.FC<IPeriodGasSourceViewAFProps> = ({
  props,
  saveWellFactValue,
}) => {
  return (
    <div>
      <div className="active-object__row">
        <div className="d-flex">
          <div className="active-object__input-with-unit-wrapper left">
            <ObjectInput
              className={classNames(
                'input active-object__row-input',
                'with-unit',
              )}
              label="Дебит жидкости"
              name="fluid_flow__m3_per_day"
              initValue={props?.fluid_flow__m3_per_day}
              saveNewValue={saveWellFactValue}
            />
            <p className="active-object__row-input-unit">
              <span className="relative">
                м<span className="index unit-small">3</span>
              </span>
              &nbsp; /сут
            </p>
          </div>
          <div className="active-object__input-with-unit-wrapper">
            <ObjectInput
              className={classNames(
                'input active-object__row-input',
                'with-unit',
              )}
              label="Поток акт. газа"
              name="gaslift_flow__thos_m3_per_day"
              initValue={props?.gaslift_flow__thos_m3_per_day}
              saveNewValue={saveWellFactValue}
            />
            <p className="active-object__row-input-unit">
              <span className="relative">
                тыс.м<span className="index unit-small">3</span>
              </span>
              &nbsp; /сут
            </p>
          </div>
        </div>
      </div>
      <div className="active-object__row">
        <div className="d-flex">
          <div className="active-object__input-with-unit-wrapper left">
            <ObjectInput
              className={classNames(
                'input active-object__row-input',
                'with-unit',
              )}
              label="Давление"
              name="pressure__atmg"
              initValue={props?.pressure__atmg}
              saveNewValue={saveWellFactValue}
            />
            <p className="active-object__row-input-unit">атм</p>
          </div>
          <div className="active-object__input-with-unit-wrapper">
            <ObjectInput
              className={classNames(
                'input active-object__row-input',
                'with-unit',
              )}
              label="Офсет"
              name="offset_time__min"
              initValue={props?.offset_time__min}
              saveNewValue={saveWellFactValue}
            />
            <p className="active-object__row-input-unit">мин</p>
          </div>
        </div>
      </div>
    </div>
  );
};
