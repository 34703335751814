import * as React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams, withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import {
  selectTaskId,
  setExperimentStatus,
  setExperimentType as saveExperimentType,
  setIsActual,
  setShowResults,
} from 'store/taskSlice';

import { components } from '../../generated/apiTypes';
import {
  applyOptimalTechMode,
  cancelTask,
  createProjectFromFile,
  deleteProject,
  getExcelProjectFile,
  getExcelSolution,
  getJSONProjectFile,
  getTasks,
  randomizeOffsets,
  recoverFactState,
} from '../../services/apiRequests';
import {
  resetLastProjectId,
  selectShowDialog,
  setShowDialog,
} from '../../store/commonSlice';
import {
  setGatheringCentersModels,
  setWellsControlModels,
} from '../../store/projectSlice';
import { setProjects } from '../../store/projectsSlice';
import { RootState } from '../../store/store';
import ApplyOptimalTechMode from './ApplyOptimalTechMode';
import CancelTaskConfirmationWindow from './CancelTaskConfirmationWindow';
import ChooseExportType from './ChooseExportType';
import ChooseSubnetWindow from './ChooseSubnetWindow';
import DeleteConfirmationWindow from './DeleteConfirmationWindow';
import ErrorWindow from './ErrorWindow';
import ExportResults from './ExportResults';
import RecoverFactState from './RecoverFactState';
import ResetOffsets from './ResetOffsets';
import WellModelConfirmationWindow from './WellModelConfirmationWindow';

const DialogWindow: React.FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const showDialog = useSelector(selectShowDialog);
  const taskId = useSelector(selectTaskId);
  const [createInProgress, setCreateInProgress] =
    React.useState<boolean>(false);
  const [exportInProgress, setExportInProgress] =
    React.useState<boolean>(false);
  const params = useParams() as { id: string };

  const closeDialog = () => {
    dispatch(
      setShowDialog({
        dialogType: null,
        objectType: null,
        objectName: null,
        objectId: null,
        isConfirm: null,
        isDiscard: null,
        isCancel: true,
        file: null,
        options: null,
        message: null,
      }),
    );
  };

  const stopTask = async () => {
    try {
      await cancelTask({ task_id: taskId });
    } catch (e) {
      //
    }
  };

  const resetOffsets = async () => {
    try {
      const response = await randomizeOffsets(params.id);
      if (response?.data?.wells_control_models)
        dispatch(setWellsControlModels(response?.data?.wells_control_models));
      const res = await getTasks(params.id);
      if (res?.data?.length > 0) {
        const task = res?.data?.find(item => item.task_uid === taskId);
        if (task && !task.is_actual) {
          dispatch(setIsActual(task.is_actual!));
        }
      }
    } catch (e) {
      //
    }
  };

  const exportProject = async (type: string) => {
    if (type === 'JSON') {
      try {
        const response = await getJSONProjectFile(params.id);
        const url = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${showDialog?.objectName}.json`);
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        //
      }
    } else if (type === 'EXCEL') {
      try {
        const response = await getExcelProjectFile(params.id);
        const url = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${showDialog?.objectName}.xlsx`);
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        //
      }
    }
  };

  const createProject = async (
    projectName: string,
    url: string,
    sinkId: string,
    fileToSend: FormData,
  ) => {
    setCreateInProgress(true);
    try {
      const response = await createProjectFromFile({
        project_name: projectName,
        iskra_project_url: url,
        gathering_center_id: sinkId,
        file: fileToSend,
      });
      closeDialog();
      setCreateInProgress(false);
      if (response.status === 201) {
        const id = (response.data as components['schemas']['PNOProjectData'])
          ?.project_uid;
        if (id) history.push(`/project/${id}`);
      }
    } catch (e: any) {
      setCreateInProgress(false);
      if (e?.response?.status === 422) {
        dispatch(
          setShowDialog({
            dialogType: 'ERROR',
            objectName: 'Невозможно создать проект',
            message: e?.response?.data?.detail[0]?.msg,
          }),
        );
      } else {
        dispatch(
          setShowDialog({
            dialogType: 'ERROR',
            objectName: 'Невозможно создать проект',
            message: e?.response?.data?.user_message,
          }),
        );
      }
    }
  };

  const exportResults = async (dynamics: boolean) => {
    try {
      setExportInProgress(true);
      const response = await getExcelSolution(params.id, dynamics);
      const url = window.URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `results-${taskId}.zip`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      //
    }
    setExportInProgress(false);
    closeDialog();
  };
  const applyOptimal = async () => {
    const response = await applyOptimalTechMode(params.id);
    if (response?.data?.wells_control_models) {
      dispatch(setWellsControlModels(response?.data?.wells_control_models));
      dispatch(setExperimentStatus('INITIAL'));
      dispatch(saveExperimentType('Базовый режим'));
      dispatch(setShowResults(false));
    }
    const res = await getTasks(params.id);
    if (res?.data?.length > 0) {
      const task = res?.data?.find(item => item.task_uid === taskId);
      if (task && !task.is_actual) {
        dispatch(setIsActual(task.is_actual!));
      }
    }
  };
  const saveWellModel = async () => {
    dispatch(setShowDialog({ isConfirm: true }));
  };

  const discardWellModel = async () => {
    dispatch(setShowDialog({ isDiscard: true }));
  };

  const recoverFact = async () => {
    const response = await recoverFactState(params.id);
    if (response?.data?.gathering_centers_models) {
      dispatch(
        setGatheringCentersModels(response.data.gathering_centers_models),
      );
    }
    if (response?.data?.wells_control_models) {
      dispatch(setWellsControlModels(response.data.wells_control_models));
    }
    const res = await getTasks(params.id);
    if (res?.data?.length > 0) {
      const task = res?.data?.find(item => item.task_uid === taskId);
      if (task && !task.is_actual) {
        dispatch(setIsActual(task.is_actual!));
      }
    }
  };
  const deleteObject = async () => {
    switch (showDialog?.objectType) {
      case 'JUNCTION':
        dispatch(setShowDialog({ isConfirm: true }));
        break;
      case 'PIPELINE':
        dispatch(setShowDialog({ isConfirm: true }));
        break;
      case 'SOURCE':
        dispatch(setShowDialog({ isConfirm: true }));
        break;
      case 'SINK':
        dispatch(setShowDialog({ isConfirm: true }));
        break;
      case 'PROJECT': {
        const response = await deleteProject(showDialog?.objectId as string);
        if (response.data) dispatch(resetLastProjectId());
        dispatch(
          setProjects(
            response.data?.filter(
              project => project.username === localStorage.username,
            ),
          ),
        );
        closeDialog();
        history.push('/');
        break;
      }
      default:
        break;
    }
  };

  if (showDialog?.dialogType === 'DELETE_CONFIRM')
    return (
      <DeleteConfirmationWindow
        closeWindow={closeDialog}
        deleteObject={deleteObject}
      />
    );
  if (showDialog?.dialogType === 'CANCEL_TASK_CONFIRM')
    return (
      <CancelTaskConfirmationWindow
        closeWindow={closeDialog}
        cancelTask={stopTask}
      />
    );
  if (showDialog?.dialogType === 'WELL_MODEL_CONFIRM')
    return (
      <WellModelConfirmationWindow
        closeWindow={closeDialog}
        saveChanges={saveWellModel}
        discardChanges={discardWellModel}
      />
    );
  if (showDialog?.dialogType === 'CHOOSE_SUBNET')
    return (
      <ChooseSubnetWindow
        closeWindow={closeDialog}
        createProject={createProject}
        progress={createInProgress}
      />
    );
  if (showDialog?.dialogType === 'CHOOSE_EXPORT')
    return (
      <ChooseExportType
        closeWindow={closeDialog}
        exportProject={exportProject}
      />
    );
  if (showDialog?.dialogType === 'ERROR')
    return <ErrorWindow closeWindow={closeDialog} />;
  if (showDialog?.dialogType === 'RESET_OFFSETS')
    return (
      <ResetOffsets closeWindow={closeDialog} resetOffsets={resetOffsets} />
    );
  if (showDialog?.dialogType === 'EXPORT_RESULTS')
    return (
      <ExportResults
        closeWindow={closeDialog}
        exportResults={exportResults}
        progress={exportInProgress}
      />
    );
  if (showDialog?.dialogType === 'APPLY_OPTIMAL')
    return (
      <ApplyOptimalTechMode
        closeWindow={closeDialog}
        applyOptimal={applyOptimal}
      />
    );
  if (showDialog?.dialogType === 'RECOVER_FACT_STATE')
    return (
      <RecoverFactState closeWindow={closeDialog} recoverFact={recoverFact} />
    );
  return <></>;
};

const mapStateToProps = (state: RootState) => ({
  socketState: state.socket.socketState,
  nodes: state.project.nodes,
});

export default withRouter(connect(mapStateToProps)(DialogWindow));
