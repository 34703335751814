import * as React from 'react';
import { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { NamedProps } from 'react-select';

import classNames from 'classnames';
import Dropdown from 'Components/Dropdown';
import { components } from 'generated/apiTypes';
import { ArrayElement } from 'Pages/Project/types';
import { selectSolution } from 'store/taskSlice';

import useChartData from './hooks/use-chart-data';
import { dropdownOptions, options } from './utils';

import 'Pages/Project/ResultsPanel/chartStyle.scss';

type OptionType = ArrayElement<typeof dropdownOptions> | null;

const SourcesChart: React.FC = () => {
  const [option, setOption] = React.useState<OptionType>(dropdownOptions[0]);
  const projectSolution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOTechModeOptimizationProblem'];

  const [activeButton, setActiveButton] = useState<any>(null);
  const onChangeHandler: NamedProps['onChange'] = value =>
    setOption(value as OptionType);
  const chartData = useChartData(projectSolution, option, activeButton);

  const onClickChartButton = (e: any) => {
    setActiveButton(e.target.value);
  };
  const renderRadioButtons = () => {
    return (
      <div>
        {projectSolution?.task?.pipeline_network?.nodes
          ?.filter(node => node.type === 'JUNCTION')
          ?.map((node: any, index: number) => {
            index === 0 && activeButton === null && setActiveButton(node.name);
            return (
              <button
                type="button"
                value={node.name}
                onMouseDown={onClickChartButton}
                className={classNames(
                  node.name === activeButton && 'is-clicked',
                )}
              >
                {node.name}
              </button>
            );
          })}
      </div>
    );
  };
  return (
    <div className="results__single-chart">
      <div className="results__single-chart-dropdown">
        <Dropdown
          options={dropdownOptions}
          onChange={onChangeHandler}
          value={option}
          menuWidth="100%"
        />
      </div>
      <div>
        <Line
          data={chartData}
          options={options(option?.label as string) as any}
        />
      </div>
      {renderRadioButtons()}
    </div>
  );
};

export default SourcesChart;
